export const formatCpr = (value?: string, missingValue = '') => {
  if (!value) return missingValue;

  if (value.length === 10) {
    return `${value.substr(0, value.length - 4)}-${value.slice(-4)}`;
  }

  return value;
};

export const formatCprNoDash = (cpr: string) => cpr.replace('-', '');

export const isValidCpr = (value: string) => {
  const regex = /^(\d{10}|\d{6}-\d{4})$/;
  return regex.test(value);
};
