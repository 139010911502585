import { ISearchField } from '../components/query';
const list = [];

export function getReplicatedFields(searchFields: ISearchField[] | undefined) {
  if (!searchFields) return;
  var clonedList = JSON.parse(JSON.stringify(searchFields));
  setIsReplicatedToSubProps(clonedList);
  getAllReplicateToList(clonedList);
  return clonedList;
}
function getAllReplicateToList(searchFields: ISearchField[]) {
  searchFields.forEach((x, i) => {
    getAllReplicateToList(x.fields || []);
    if (!x.replicateToLists && !x.fields?.some(x => x.replicateToLists)) {
      delete searchFields[i];
    }
  });
}
function setIsReplicatedToSubProps(searchFields: ISearchField[]) {
  searchFields.forEach((x, i) => {
    if (x.replicateToLists && x.fields) {
      x.fields.forEach(f => (f.replicateToLists = true));
    }
    setIsReplicatedToSubProps(x.fields || []);
  });
}
