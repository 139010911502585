import { Backdrop, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { ColorPalette, TypographySettings } from '../config';

export default function FasitUpdated({ onClose }: { onClose?: () => void }) {
  return (
    <>
      <Dialog
        open
        onClose={() => {
          onClose && onClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          color: ColorPalette.DefaultTextColor,
          fontFamily: TypographySettings.DefaultFontFamily,
          fontSize: TypographySettings.DefaultFontSize,
          zIndex: 21001,
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            color: ColorPalette.DefaultTextColor,
            fontFamily: TypographySettings.AlternativeFontFamily,
            fontSize: 20,
            fontWeight: 500,
            paddingBottom: 3,
            paddingTop: 3,
            textAlign: 'center',
          }}
        >
          Ny opdatering
        </DialogTitle>
        <DialogContent sx={{ overflowY: 'visible' }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontSize: 14, fontFamily: TypographySettings.DefaultFontFamily, color: ColorPalette.DefaultTextColor, '& strong': { fontWeight: 700 } }}
          >
            Fasit er blevet opdateret i baggrunden — <strong>Du bør genindlæse Fasit snarest muligt.</strong>
            <br />
            <br />
            Du kan fortsætte med dit arbejde, men det anbefales at opdatere / genindlæse Fasit så snart som muligt, for at undgå eventuelle problemer.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 2, paddingTop: 0 }}>
          <Button
            sx={{
              textTransform: 'unset',
              fontFamily: TypographySettings.DefaultFontFamily,
              fontWeight: TypographySettings.DefaultFontSemiBold,
              borderColor: ColorPalette.PrimaryMain,
              color: ColorPalette.PrimaryMain,
              '&:hover': {
                backgroundColor: 'rgba(73, 120, 142, 0.04)',
                borderColor: ColorPalette.PrimaryMain,
                color: ColorPalette.PrimaryMain,
              },
            }}
            variant="outlined"
            onClick={() => {
              onClose && onClose();
            }}
          >
            Luk denne besked
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backdropFilter: 'blur(5px)',
          backgroundColor: 'rgb(54, 70, 94, 0.12)',
          zIndex: 21000,
        }}
        open
      />
    </>
  );
}
