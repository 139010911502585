export const TypographySettings = {
  RootFontSize: 16,
  DefaultFontSize: 14,

  /**
   * Font Size for Primary Headings.
   * Primary headings are the largest headings in normal application use.
   * The Primary heading font size is for example used as the Citizen name
   * and CPR number in the header of the Citizen universe.
   */
  PrimaryHeadingSize: '1.42rem',

  /**
   * Font Size for Secondary Headings.
   * Secondary headings are the smallest headings in normal application use.
   * The Secondary heading font size is for example used as column headers
   * in tables.
   */
  SecondaryHeaderSize: '1.14rem',
  TertiaryHeaderSize: '1.125rem',
  SmallFontSize: '0.8rem',
  DefaultFontFamily: '"Source Sans Pro", sans-serif',
  AlternativeFontFamily: '"Roboto", sans-serif',
  AlternativeFontLight: 300,
  DefaultFontWeight: 400,
  DefaultFontSemiBold: 600,
  AlternativeFontSemiBold: 500,
};
