export enum QueryBuilderFieldOptions {
    String = 'text',
    Keyword = 'keyword',
    Integer = 'integer',
    Double = 'double',
    Date = 'date',
    Boolean = 'boolean',
    Picklist = 'picklist',
    Codelist = 'codelist',
    LargeText = 'largetext',
    SmallInteger = 'smallinteger',
}
