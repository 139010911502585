import { isNullOrUndefined } from './null-undefined-helper';

export const formatNumberToDk = (value: number, precision = 0) => value.toLocaleString('da-DK', { minimumFractionDigits: precision, maximumFractionDigits: precision });

export function formatNumber(value: number, precision = 0): string {
  return isNullOrUndefined(value) ? '' : formatNumberToDk(value, precision);
}

export function formatNullableNumber(value?: number, precision = 0): string {
  return isNullOrUndefined(value) ? '-' : formatNumberToDk(value as number, precision);
}

export const formatAge = (value?: number, missingValue = '') => {
  if (!value) {
    return missingValue;
  }

  if (typeof value === 'number') {
    return `${value} år`;
  }

  return value;
};

export const isStringOnlyNumbers = (value: string): boolean => {
  const regex = /^[0-9]+$/;
  return regex.test(value);
};
