import { OmnibarEntry, OmnibarContext } from '../omnibar-interfaces';

export default function entryContextFilter(option: OmnibarEntry, currentContext: OmnibarContext) {
  if (option.context === 'none') return true;
  if (option.context === 'citizen' && currentContext === 'citizen') {
    return true;
  }
  if (option.context === 'company' && currentContext === 'company') {
    return true;
  }
  return false;
}
