import React from 'react';
import { useAuthenticatedUser } from './auth-wrapper';
import { ResourceAction } from './authorization-service';
import { useIsAllowedWithDataBoundary } from './use-is-allowed';
import { ResourceAccessCriteria } from '../api-service/clients';

export interface IRestrictedProps {
  actions: ResourceAction[];
  resourceAccessCriteria?: ResourceAccessCriteria;
  denied?: React.ReactNode;
}

export const defaultDeniedMessage = 'Du har ikke de krævede roller for at se dette indhold';
function Denied() {
  return <div>{defaultDeniedMessage}</div>;
}

export function Restricted(props: React.PropsWithChildren<IRestrictedProps>) {
  const { denied, children, actions, resourceAccessCriteria } = props;

  const user = useAuthenticatedUser();
  const isAllowedCheck = useIsAllowedWithDataBoundary(actions, resourceAccessCriteria);

  if (!user || !isAllowedCheck.isReady) {
    return null;
  }

  if (isAllowedCheck.isAllowed === true) {
    return <>{children}</>;
  }

  return denied ? <>{denied}</> : <Denied />;
}
