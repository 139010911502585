/* eslint-disable react-hooks/exhaustive-deps */
import { faGearCode, faUserShield } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Backdrop, Box, Paper } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { matchSorter } from 'match-sorter';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useAllowedActions } from '../../authentication';
import { useFeatureToggles } from '../../feature-toggles';
import { usePurchasedFeatures } from '../purchased-feature';
import omnibarEntries from './entries';
import { OmnibarEntry } from './omnibar-interfaces';
import entryAccessFilter from './utils/entry-access-filter';
import entryContextFilter from './utils/entry-context-filter';
import entrySort from './utils/entry-sort';
import useOmnibarContext from './utils/use-omnibar-context';
import { updateSelectionCount, getTopSelectedResults } from './utils/top-entries';
import { useOmnibar } from './omnibar-provider';
import { DynamicLinks } from '../dynamic-links-modal/dynamic-links-modal';
import { useTenantConfiguration } from '../../hooks/use-tenant-configuration';

const OmnibarTopResultThreshold = 2;

function RestrictedIcon() {
  return (
    <Box sx={{ marginLeft: 'auto', color: 'rgba(0,0,0,.5)' }} title="Kræver rettigheder, som du muligvis ikke har">
      <FontAwesomeIcon icon={faUserShield} />
    </Box>
  );
}

function CondtionallyEnabledIcon() {
  return (
    <Box sx={{ marginLeft: 'auto', color: 'rgba(0,0,0,.5)' }} title="Funktionalitet afhængig af omstændigheder der muligvis ikke er opfyldt">
      <FontAwesomeIcon icon={faGearCode} />
    </Box>
  );
}

function renderOption(props: React.HTMLAttributes<HTMLLIElement>, option: OmnibarEntry) {
  return (
    <li {...props}>
      {option.label}
      {option.isPossiblyRestricted && <RestrictedIcon />}
      {option.isConditionallyEnabled && <CondtionallyEnabledIcon />}
    </li>
  );
}

const filterOptions = (options: OmnibarEntry[], { inputValue }: { inputValue: string }) => {
  const filteredOptions = matchSorter(options, inputValue, { keys: ['group', 'label'] });

  // Custom sorting function to sort by "group" first, then by "label"
  filteredOptions.sort((a, b) => {
    const groupA = a.group ?? ''; // Use an empty string as default value if a.group is undefined
    const groupB = b.group ?? ''; // Use an empty string as default value if b.group is undefined

    if (groupA === 'Oftest brugt' && groupB !== 'Oftest brugt') {
      return -1; // entryA comes before entryB
    }
    if (groupA !== 'Oftest brugt' && groupB === 'Oftest brugt') {
      return 1; // entryA comes after entryB
    }

    const groupComparison = groupA.localeCompare(groupB);

    if (groupComparison !== 0) {
      return groupComparison;
    }

    return a.label.localeCompare(b.label);
  });

  return filteredOptions;
};

export function Omnibar() {
  const { context = 'none', contextId = '' } = useOmnibarContext();
  const [inputValue, setInputValue] = React.useState<string>('');
  const [dynamicLinksResourceId, setDynamicLinksResourceId] = React.useState<string>('');
  const [dynamicLinksResourceType, setDynamicLinksResourceType] = React.useState<string>('');
  const [modalControl, setModalControl] = React.useState<JSX.Element>();

  const [selectedValue] = React.useState<OmnibarEntry | null>(null);
  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();
  const inputRef = React.useRef<HTMLInputElement>();
  const { isFeatureEnabled } = useFeatureToggles();
  const allowedActions = useAllowedActions();
  const { isFeaturePurchased } = usePurchasedFeatures();
  const { showOmnibar, toggleOmnibar } = useOmnibar();
  const { tenantName, crmUrl } = useTenantConfiguration();

  const omnibarDisabled = useMemo(() => {
    if (pathname.match(/\/embedded\//)) return true;
    return false;
  }, [pathname]);

  function closeDynamicLinks() {
    setDynamicLinksResourceId('');
    setDynamicLinksResourceType('');
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const omnibarLink = params.get('omnibar-link');

    if (omnibarLink) {
      let checkCount = 0;
      const maxCheckCount = (5 * 1000) / 200; // check for max 5 seconds, every 200 ms

      const intervalId = setInterval(() => {
        const element = document.getElementById(omnibarLink);

        if (element) {
          clearInterval(intervalId); // stop checking if the element is found
          const rect = element.getBoundingClientRect();
          setTimeout(() => {
            window.scroll({
              top: rect.y,
              left: 0,
              behavior: 'smooth',
            });
          }, 2500);
        } else if (checkCount >= maxCheckCount) {
          clearInterval(intervalId); // stop checking if the maximum check time is reached
        }
        checkCount = +1;
      }, 200); // check every 200 ms
    }
  }, [location]);

  function filterContextOptions(options: OmnibarEntry[]): OmnibarEntry[] {
    return options.filter((option: OmnibarEntry) => entryContextFilter(option, context));
  }
  function filterAccessOptions(options: OmnibarEntry[]): OmnibarEntry[] {
    return options.filter((option: OmnibarEntry) => entryAccessFilter(option, isFeaturePurchased, isFeatureEnabled, allowedActions));
  }
  function filterAndSortOptions(options: OmnibarEntry[]) {
    return filterAccessOptions(filterContextOptions(options)).sort((optionA: OmnibarEntry, optionB: OmnibarEntry) => entrySort(optionA, optionB, context));
  }

  function close() {
    toggleOmnibar(false);
  }

  useEffect(() => {
    if (!showOmnibar) {
      setInputValue('');
      inputRef && inputRef.current && inputRef.current.blur();
    } else {
      inputRef && inputRef.current && inputRef.current.focus();
    }
  }, [showOmnibar]);

  function handleChange(event: React.SyntheticEvent, value: OmnibarEntry | null) {
    if (!value) return;
    updateSelectionCount(value);

    const { callbackFn, url, dynamicLinksAction, getModalControl } = value;
    let openInNewWindow = false;
    if (event.nativeEvent instanceof KeyboardEvent && event.nativeEvent.ctrlKey) openInNewWindow = true;
    if (event.nativeEvent instanceof MouseEvent && event.nativeEvent.ctrlKey) openInNewWindow = true;
    if (url) {
      const newUrl = value.context !== 'none' && url.indexOf(':contextId') > -1 ? url.replace(':contextId', contextId) : url;
      if (openInNewWindow) {
        window.open(newUrl);
        close();
      } else {
        history.push(newUrl);
      }
    } else if (callbackFn) {
      callbackFn(contextId, crmUrl || '', tenantName || '');
      close();
    } else if (dynamicLinksAction) {
      const { resourceType, resourceId } = dynamicLinksAction(contextId);
      setDynamicLinksResourceId(resourceId);
      setDynamicLinksResourceType(resourceType);
    } else if (getModalControl) {
      setModalControl(getModalControl(contextId));
      close();
    }
  }

  const { numberOfTopResults, allItemsSorted } = getTopSelectedResults(omnibarEntries);

  let options: OmnibarEntry[] = [];
  if (inputValue || numberOfTopResults < OmnibarTopResultThreshold) {
    options = filterAndSortOptions(omnibarEntries);
  } else if (numberOfTopResults >= OmnibarTopResultThreshold) {
    options = filterAccessOptions(filterContextOptions(allItemsSorted));
  }

  return omnibarDisabled ? null : (
    <>
      <Paper
        elevation={14}
        sx={{
          left: '50%',
          maxWidth: '80%',
          minWidth: 500,
          opacity: showOmnibar ? 1 : 0,
          position: 'fixed',
          top: '30%',
          transform: 'translateX(-50%)',
          zIndex: showOmnibar ? 21000 : -1,
        }}
      >
        <Autocomplete
          autoComplete
          autoHighlight
          handleHomeEndKeys
          disablePortal
          blurOnSelect
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          value={selectedValue}
          onChange={handleChange}
          options={options}
          openOnFocus={numberOfTopResults >= OmnibarTopResultThreshold}
          groupBy={(option: OmnibarEntry) => option.group || ''}
          onBlur={close}
          getOptionLabel={(option: OmnibarEntry) => option.label}
          filterOptions={filterOptions}
          renderOption={renderOption}
          renderInput={params => {
            const { InputProps, inputProps, ...restParams } = params;

            return (
              <TextField
                {...restParams}
                InputProps={{
                  ...InputProps,
                  sx: {
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none !important',
                    },
                  },
                }}
                variant="outlined"
                placeholder="Hvor skal vi hen?"
                inputRef={inputRef}
                inputProps={{
                  ...inputProps,
                  tabIndex: -1,
                  'aria-hidden': showOmnibar ? 'false' : 'true',
                }}
              />
            );
          }}
        />
      </Paper>
      <Backdrop
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backdropFilter: `blur(${showOmnibar ? 5 : 0}px)`,
          backgroundColor: `rgb(54, 70, 94, ${showOmnibar ? 0.12 : 0})`,
          zIndex: showOmnibar ? 20999 : -1,
        }}
        open={showOmnibar}
      />
      {dynamicLinksResourceId && dynamicLinksResourceType && (
        <DynamicLinks resourceType={dynamicLinksResourceType} resourceId={dynamicLinksResourceId} citizenId={dynamicLinksResourceId} onClose={closeDynamicLinks} />
      )}
      {modalControl}
    </>
  );
}
