import { ResourceOperations, ResourceTypes } from '../../../authentication/resources';
import { PurchasedFeatureConstants } from '../../purchased-feature';
import { OmnibarEntry } from '../omnibar-interfaces';

const functions: OmnibarEntry[] = [
  {
    id: '7321c456-15d7-4922-baaf-a3c9e74c3498',
    label: 'Jobannoncer',
    context: 'none',
    group: 'Indsatser',
    featurePurchased: [PurchasedFeatureConstants.JobAds],
    resourceActions: [{ resourceOperation: ResourceOperations.Read, resourceType: ResourceTypes.JobAd }],
    isPossiblyRestricted: true,
    url: '/deeplink/efforts/job-ads',
  },
  {
    id: 'f37c3828-7c17-420e-a754-d655cb6c3b3e',
    label: 'Opret jobannonce',
    context: 'none',
    group: 'Indsatser',
    featurePurchased: [PurchasedFeatureConstants.JobAds],
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.JobAd }],
    isPossiblyRestricted: true,
    callbackFn: () => {
      window.open('/deeplink/efforts/job-ads/create-job-ad');
    },
  },
  {
    id: 'd6fbb580-251d-402c-82b2-dccde89aa595',
    label: 'Opret rekrutteringsanmodning',
    context: 'none',
    group: 'Indsatser',
    callbackFn: () => {
      window.open('/deeplink/efforts/company-recruitments/create-company-recruitment-wizard');
    },
  },
  {
    id: '2e3c7c4f-835f-4f1b-b326-c52d3bb6382b',
    label: 'Rekrutteringsanmodninger',
    context: 'none',
    group: 'Indsatser',
    url: '/deeplink/efforts/company-recruitments',
  },
  {
    id: '1c51d088-2316-45b2-a702-176976af9e79',
    label: 'Tilbudskatalog',
    context: 'none',
    group: 'Indsatser',
    url: '/deeplink/efforts/jobs-and-offers',
  },
];

export default functions;
