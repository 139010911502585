import React from 'react';
import { getFasitProblemDetails } from '../../api-service';
import { Severity } from '../../api-service/clients';

export interface IErrorZoneProps {
  error: any;
}

export function ErrorZone(props: IErrorZoneProps) {
  const { error } = props;

  if (error) {
    const problemDetails = getFasitProblemDetails(error);
    if (problemDetails) {
      const severity = problemDetails.severity || Severity.Error;
      return (
        <div className={`error-zone ${severity.toLowerCase()}`}>
          <div className="error-zone__title">{problemDetails.title}</div>
          <div className="error-zone__detail">{problemDetails.detail}</div>
          {severity === Severity.Error && <div className="error-zone__correlation-identifier">{problemDetails.correlationIdentifier}</div>}
        </div>
      );
    }

    return (
      <div className="error-zone has-error">
        <div className="error-zone__message">{error.message || error.toString()}</div>
      </div>
    );
  }

  return null;
}
