import { v4 as uuidv4 } from 'uuid';
export function getGuid(guidStr: string | undefined): string | undefined {
  if (typeof guidStr === 'string') {
    if (/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/.test(guidStr)) {
      return (guidStr.length !== 36 ? guidStr.replace(/[{}]/g, '') : guidStr).toLowerCase();
    }
  }
  return undefined;
}

export function toGuid(guidStr: string): string {
  const guid = getGuid(guidStr);
  if (!guid) throw new Error(`Value is not a GUID: ${guidStr}`);
  return guid;
}
export function generateGuid() {
  return uuidv4();
}
