import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useApiOnRender } from '../../../hooks/use-api';
import { RowActionApiService, IRowAction } from '../components/query/rowaction-api-service';
import { NotifyAction } from '../store/reducers/notify-reducer';

const rowActionApiService = new RowActionApiService();

export const RowActionContext = React.createContext([] as IRowAction[]);

type Props = {
  children: React.ReactNode;
};
export function RowActionProvider({ children }: Props) {
  const rowActionsApi = useApiOnRender(cancelToken => rowActionApiService.getRowActions({ cancelToken }), []);
  const dispatch = useDispatch<React.Dispatch<NotifyAction>>();

  React.useEffect(() => {
    if (rowActionsApi.isError) {
      dispatch({ type: 'notify/enqueueError', message: 'Fejl ved hentning af arbejdslister init data' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowActionsApi.error]);
  return <RowActionContext.Provider value={rowActionsApi.result}> {children} </RowActionContext.Provider>;
}

export const useRowActions = (): IRowAction[] => React.useContext(RowActionContext);
