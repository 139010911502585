export const effortsAreaPathName = 'efforts';

export const jobAndOffersAreaMenuName = 'Arbejdsområde Job & tilbud';
export const jobAndOffersAreaSubMenuName = 'Job & tilbud';
export const jobAndOffersRecruitmentsRequestsMenuName = 'Rekrutteringsanmodninger';
export const jobAndOffersCatalogueMenuName = 'Tilbudskatalog';
export const jobAndOffersCataloguePathName = 'jobs-and-offers';

export const companyRecruitmentsTabLabel = 'Rekrutteringsanmodninger';
export const companyRecruitmentsPathName = 'company-recruitments';

export const companyRecruitmentWizardTabLabel = 'Ny Rekrutteringsanmodning';
export const companyRecruitmentWizardPathName = 'create-company-recruitment-wizard';

export const companyRecruitmentDetailsPathName = 'details';
export const companyRecruitmentDetailsFromCandidatePathName = 'candidate';

export const companyRecruitmentListPathName = 'list';

export const smartMatchPathName = 'smart-match';
export const intelligentMatchCitizenPathName = 'intelligent-match-citizen';
export const jobMatchPathName = 'job-match';
export const companyRecruitmentFromOfferPathName = 'create-company-recruitment-from-offer-wizard';
export const companyRecruitmentFromCompanyPathName = 'create-company-recruitment-from-company-wizard';

export const jobAdWizardTabLabel = 'Ny jobannonce';
export const jobAdEditWizardTabLabel = 'Redigér jobannonce';
export const jobAdCreateWizardPathName = 'create-job-ad';
export const jobAdEditWizardPathName = 'edit-job-ad';
export const jobAdViewPathName = 'view-job-ad';

export const jobAdsTabName = 'Jobannoncer';
export const jobAdsMenuName = 'Jobannoncer';
export const jobAdsPathName = 'job-ads';

export const citizenCrowdLetterPathName = '/citizens/crowd-letter';
