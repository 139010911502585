import { useRouteMatch } from 'react-router-dom';

function useAreaRouteGuard(requiredPrefix: string | string[]) {
  const match = useRouteMatch(requiredPrefix);

  if (!match) {
    // eslint-disable-next-line max-len
    throw new Error(
      `Route does not match${Array.isArray(requiredPrefix) && ' any of'} the required prefix${Array.isArray(requiredPrefix) && 'es'}: ${
        Array.isArray(requiredPrefix) ? requiredPrefix.join(', ') : requiredPrefix
      }`
    );
  }
}

export { useAreaRouteGuard };
