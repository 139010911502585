/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function useMetaTitle(title = '') {
  const location = useLocation();
  const appname = 'Schultz Fasit';

  return useEffect(() => {
    if (title !== '') document.title = `${title} - ${appname}`;
    return function revertTitle() {
      document.title = appname;
    };
  }, [location, title]);
}
