import { IValueEditorProps } from '../value-editor-props';
import { DateRangeEditor } from './date-range-editor';
import React from 'react';
import { QueryBuilderFieldOptions } from '../../../query/query-builder-field-options';
import { NumberRangeEditor } from './number-range-editor';
import { DecimalField } from '../../../../../../components/number-fields/number-field';
import { IntegerField } from '../../../../../../components/number-fields/number-field';
import { SmallIntegerField } from '../../../../../../components/number-fields/number-field';

export function rangeValueEditor(props: IValueEditorProps) {
  switch (props.type) {
    case QueryBuilderFieldOptions.Date: {
      return <DateRangeEditor {...props} />;
    }
    case QueryBuilderFieldOptions.Integer: {
      return <NumberRangeEditor component={IntegerField} {...props} />;
    }

    case QueryBuilderFieldOptions.SmallInteger: {
      return <NumberRangeEditor component={SmallIntegerField} {...props} />;
    }

    case QueryBuilderFieldOptions.Double: {
      return <NumberRangeEditor component={DecimalField} {...props} />;
    }
    default: {
      return null;
    }
  }
}
