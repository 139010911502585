import React from 'react';
import { IRelativeDateApiService, RelativeDateApiService } from './relative-date-api-service';
import { RelativeDateEditorForm } from './relative-date-editor-form';
import { defaultValues, unitOperators } from './relative-date-options';
import { parser } from './expression-parser';
import { FormDialog } from '../../../../../../components/form-dialog/form-dialog';
import { defaultDateFormat, formatDate } from '../../../../../../utilities';

export const relativeDateEditorDialogTestIds = {
  FormDialog: 'RelativeDateEditorDialog-FormDialog',
  Form: 'RelativeDateEditorDialog-Form',
};

export interface IRelativeDateEditorDialogProps {
  open: boolean;
  value: string;
  className?: string;
  onConfirm: (value: string) => void;
  onCancel: () => void;
}

interface IRelativeDateEditorDialogState {
  value: string;
  start?: string;
  end?: string;
}

const timeFormat = 'HH:mm';
const dateFormat = defaultDateFormat;
export class RelativeDateEditorDialog extends React.Component<IRelativeDateEditorDialogProps, IRelativeDateEditorDialogState> {
  private readonly apiService: IRelativeDateApiService = new RelativeDateApiService();

  static defaultProps = { value: '' };

  constructor(props: IRelativeDateEditorDialogProps) {
    super(props);
    this.state = {
      value: this.props.value,
    };
  }

  componentDidMount() {
    this.calculateRangeForRelativeDate(this.state.value || defaultValues);
  }

  componentDidUpdate(prevProps: IRelativeDateEditorDialogProps, prevState: IRelativeDateEditorDialogState) {
    prevState.value !== this.state.value && this.calculateRangeForRelativeDate(this.state.value || defaultValues);
  }

  private readonly handleConfirm = () => {
    this.props.onConfirm(this.state.value || defaultValues);
  };

  private readonly calculateRangeForRelativeDate = async (value: string) => {
    const dates = await this.apiService.getRange(value);
    this.setState({ ...dates });
  };

  private readonly handleCancel = () => {
    this.setState({ value: this.props.value });
    this.props.onCancel();
  };

  private readonly handleChange = (value: string) => this.setState({ value });

  render() {
    const { unit } = parser.toObjects(this.state.value);
    const periodFormatToUse = unit.value === unitOperators.hours ? timeFormat : dateFormat;
    const startTime = formatDate(this.state.start, periodFormatToUse);
    const endTime = formatDate(this.state.end, periodFormatToUse);

    return (
      <FormDialog
        data-testid={relativeDateEditorDialogTestIds.FormDialog}
        title="Angiv dynamisk tidsinterval"
        open={this.props.open}
        onConfirmClick={this.handleConfirm}
        cancelButtonText="AFBRYD"
        onCancelClick={this.handleCancel}
      >
        <RelativeDateEditorForm data-testId={relativeDateEditorDialogTestIds.Form} onChange={this.handleChange} value={this.state.value} />
        <div style={{ marginTop: '20px' }}>
          Udtrykket svarer (lige nu), til tidsintervallet {startTime} - {endTime}
        </div>
      </FormDialog>
    );
  }
}
