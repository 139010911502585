import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

import * as React from 'react';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

export interface ICheckmarkIconProps {
  color?: string;
  size?: SizeProp;
}

export function CheckmarkIcon({ color = '', size = 'sm' }: ICheckmarkIconProps) {
  const extraProps = {
    focusable: false,
  };
  const styleObj: React.CSSProperties = color ? { color } : {};

  return <FontAwesomeIcon icon={faCheck} size={size} style={styleObj} {...extraProps} />;
}
