import './polyfills';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { init as initApm } from '@elastic/apm-rum';
import { App } from './App';
import './index.scss';
import { configuration } from './configuration';
import { getTenant } from './tenant-support/get-tenant';

const apm = initApm({
  active: configuration.apmEnabled,
  serverUrl: configuration.apmServerUrl,
  serviceName: 'jobcenter-desktop-frontend',
  serviceVersion: configuration.imageTag,
  environment: configuration.apmEnvironment,
  distributedTracing: true,
  distributedTracingOrigins: [configuration.bffBase],
  disableInstrumentations: ['eventtarget'],
});
const tenant = getTenant();
apm.addLabels({ tenant: tenant.name });

ReactDOM.render(<App />, document.getElementById('root'));

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mod = module as any;
  if (mod.hot) {
    mod.hot.accept('./App', () => {
      ReactDOM.render(<App />, document.getElementById('root'));
    });
  }
}
