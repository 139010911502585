/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';

export function useTransform<T extends any[], R>(transform: (...args: T) => R, ...args: T): R;
export function useTransform(transform: (...args: any[]) => any, ...args: any[]) {
  return useMemo(
    () => transform(...args),
    // eslint-disable-next-line
    [...args]
  );
}
