import { IOption } from './field-interfaces';

export enum QueryBuilderOperator {
  GreaterThan = 'gt',
  GreaterThanOrEqual = 'ge',
  Equal = 'eq',
  NotEqual = 'ne',
  LessThanOrEqual = 'le',
  LessThan = 'lt',
  Between = 'between',
  NotBetween = 'notbetween',
  In = 'in',
  NotIn = 'notin',
  Contains = 'contains',
  NotContains = 'notcontains',
  StartsWith = 'startswith',
  NotStartsWith = 'notstartswith',
  Empty = 'empty',
  NotEmpty = 'notempty',
  Relative = 'relative',
  True = 'true',
  False = 'false',
  CodeListNameEqual = 'codelistnameeq',
}

export const genericOperators: IOption[] = [
  { name: QueryBuilderOperator.GreaterThan, label: 'Større end' },
  { name: QueryBuilderOperator.GreaterThanOrEqual, label: 'Større end eller lig med' },
  { name: QueryBuilderOperator.Equal, label: 'Lig med' },
  { name: QueryBuilderOperator.NotEqual, label: 'Ikke lig med' },
  { name: QueryBuilderOperator.LessThanOrEqual, label: 'Mindre end eller lig med' },
  { name: QueryBuilderOperator.LessThan, label: 'Mindre end' },
  { name: QueryBuilderOperator.Between, label: 'Mellem' },
  { name: QueryBuilderOperator.NotBetween, label: 'Ikke mellem' },
  { name: QueryBuilderOperator.In, label: 'Indeholdt i' },
  { name: QueryBuilderOperator.NotIn, label: 'Ikke indeholdt i' },
  { name: QueryBuilderOperator.Contains, label: 'Indeholder' },
  { name: QueryBuilderOperator.NotContains, label: 'Indeholder ikke' },
  { name: QueryBuilderOperator.StartsWith, label: 'Begynder med' },
  { name: QueryBuilderOperator.NotStartsWith, label: 'Begynder ikke med' },
  { name: QueryBuilderOperator.True, label: 'Ja' },
  { name: QueryBuilderOperator.False, label: 'Nej' },
  { name: QueryBuilderOperator.Empty, label: 'Indeholder ikke data' },
  { name: QueryBuilderOperator.NotEmpty, label: 'Indeholder data' },
];
export const dateOperators: IOption[] = [
  { name: QueryBuilderOperator.Equal, label: 'På' },
  { name: QueryBuilderOperator.GreaterThanOrEqual, label: 'På eller efter' },
  { name: QueryBuilderOperator.LessThanOrEqual, label: 'På eller før' },
  { name: QueryBuilderOperator.GreaterThan, label: 'Efter' },
  { name: QueryBuilderOperator.LessThan, label: 'Før' },
  { name: QueryBuilderOperator.Relative, label: 'Indenfor' },
  { name: QueryBuilderOperator.Between, label: 'Mellem' },
  { name: QueryBuilderOperator.NotBetween, label: 'Ikke mellem' },
  { name: QueryBuilderOperator.In, label: 'Indeholdt i' },
  { name: QueryBuilderOperator.Empty, label: 'Indeholder ikke data' },
  { name: QueryBuilderOperator.NotEmpty, label: 'Indeholder data' },
];

export const stringOperators: QueryBuilderOperator[] = [
  QueryBuilderOperator.Equal,
  QueryBuilderOperator.NotEqual,
  QueryBuilderOperator.Contains,
  QueryBuilderOperator.NotContains,
  QueryBuilderOperator.StartsWith,
  QueryBuilderOperator.NotStartsWith,
  QueryBuilderOperator.Empty,
  QueryBuilderOperator.NotEmpty,
];
export const integerOperators: QueryBuilderOperator[] = [
  QueryBuilderOperator.GreaterThan,
  QueryBuilderOperator.GreaterThanOrEqual,
  QueryBuilderOperator.Equal,
  QueryBuilderOperator.NotEqual,
  QueryBuilderOperator.LessThanOrEqual,
  QueryBuilderOperator.LessThan,
  QueryBuilderOperator.Between,
  QueryBuilderOperator.NotBetween,
  QueryBuilderOperator.In,
  QueryBuilderOperator.NotIn,
  QueryBuilderOperator.Empty,
  QueryBuilderOperator.NotEmpty,
];
export const decimalOperators: QueryBuilderOperator[] = integerOperators;
export const booleanOperators: QueryBuilderOperator[] = [QueryBuilderOperator.True, QueryBuilderOperator.False, QueryBuilderOperator.Empty, QueryBuilderOperator.NotEmpty];
export const codeListOperators: QueryBuilderOperator[] = [
  QueryBuilderOperator.Equal,
  QueryBuilderOperator.NotEqual,
  QueryBuilderOperator.In,
  QueryBuilderOperator.NotIn,
  QueryBuilderOperator.Empty,
  QueryBuilderOperator.NotEmpty,
];
export const lookupOperators: QueryBuilderOperator[] = [QueryBuilderOperator.Equal, QueryBuilderOperator.NotEqual, QueryBuilderOperator.Empty, QueryBuilderOperator.NotEmpty];

export const largeTextOperators: QueryBuilderOperator[] = [
  QueryBuilderOperator.Contains,
  QueryBuilderOperator.NotContains,
  QueryBuilderOperator.Empty,
  QueryBuilderOperator.NotEmpty,
];
