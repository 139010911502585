import React from 'react';
import { MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { moduleClientFactory } from '../../../api-service/module-client-factory';
import { Dimensions } from '../../config';
import { IMainNavigationChildItem } from './main-navigation-items';

const useStyles = makeStyles({
  childIcon: {
    display: 'inline-block',
    width: Dimensions.ThemeSpacingUnit * 4,
  },
});

export interface IMainNavigationSubMenuRedirectButton {
  onClose: () => void;
  childItem: IMainNavigationChildItem;
}

export function MainNavigationSubMenuRedirectButton(props: IMainNavigationSubMenuRedirectButton) {
  const { childItem, onClose } = props;
  const classes = useStyles({});
  const configurationClient = moduleClientFactory.createConfigurationClient();
  const schultzCaseItem = 'ea5cc038-ea0c-4d05-a131-20bfd26e155f';

  function handleClick() {
    onClose();

    if (childItem.guid === schultzCaseItem) {
      redirectToSchultzCase();
    } else {
      redirectToCrm();
    }
  }

  async function redirectToSchultzCase() {
    const schultzCaseConfiguration = await configurationClient.getSchultzCaseConfiguration();
    window.open(schultzCaseConfiguration?.schultzSagUrl ?? '');
  }

  async function redirectToCrm() {
    const tenantConfiguration = await configurationClient.getTenantConfiguration();
    const { tenantName, crmUrl } = tenantConfiguration;
    if (crmUrl && tenantName && childItem.url) {
      const redirectUrl = childItem.url.replace('{tenanturl}', crmUrl).replace('{tenant}', tenantName);
      window.open(redirectUrl);
    }
  }

  return (
    <MenuItem onClick={handleClick}>
      <div className={classes.childIcon}>
        <FontAwesomeIcon icon={childItem.icon} />
      </div>
      {childItem.name}
    </MenuItem>
  );
}
