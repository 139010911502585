import { useEffect, useState } from 'react';
import { GuideLinkGuideId, GuideLinkModel } from '../../api-service/clients';
import { moduleClientFactory } from '../../api-service/module-client-factory';

export function useGuideLinks(citizenId?: string, guideIdentifier?: GuideLinkGuideId) {
  const [links, setLinks] = useState<GuideLinkModel[]>([]);
  const [headline, setHeadline] = useState<string>('');

  useEffect(() => {
    const fetchLinks = async () => {
      if (!guideIdentifier) {
        return;
      }
      const response = await moduleClientFactory.createGuideLinksClient().getGuideLinks({ citizenId, guideIdentifier });

      if (!response?.hasGuideLinks || !response?.guideLinks) {
        return;
      }
      setHeadline(response.headLine ?? 'Links til interne regler:');
      setLinks(response.guideLinks);
    };

    if (!links.length && guideIdentifier) {
      fetchLinks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citizenId, guideIdentifier]);

  return {
    links,
    headline,
  };
}
