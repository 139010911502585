import { useState, useEffect, Dispatch, SetStateAction } from 'react';

export function useCachedValue<T>(value: T): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState(value);
  useEffect(() => {
    if (value !== state) {
      setState(value);
    }
    // eslint-disable-next-line
  }, [value]);
  return [state, setState];
}
