import { useMemo } from 'react';
import { ISearchField } from '../components/query';
import { IResultTypeOption } from '../components/query/search-result-type';
import { getFields } from '../components/query-builder/get-fields';
import { getReplicatedFields } from '../helpers/get-replicated-fields';
export function useSortingFields(searchFields: ISearchField[] | undefined, universe: string, resultTypes?: IResultTypeOption[] | undefined, resultType?: string | undefined) {
  return useMemo(() => getSortingFields(searchFields, universe, resultTypes, resultType), [searchFields, resultTypes, resultType, universe]);
}

export function getSortingFields(searchFields: ISearchField[] | undefined, universe: string, resultTypes?: IResultTypeOption[] | undefined, resultType?: string | undefined) {
  if (!searchFields) {
    return [];
  }

  if (searchFields.some(f => f.isJoinList)) {
    const resultTypeFields = getResultTypeFields(searchFields, resultType) || [];
    const matchingResultType = resultTypes?.find(t => t.name === resultType);
    const replicatedFields = getReplicatedFields(searchFields) || [];
    const extraFields = getFields(replicatedFields, `[${universe}] `);
    const fields = getFields(resultTypeFields, matchingResultType ? `[${matchingResultType.label ?? ''}] ` : undefined, extraFields);

    return fields.filter(f => f.type !== 'largetext');
  }

  const fieldLabelPrefix = resultType && resultTypes && resultTypes.length > 0 ? `[${resultTypes[0].label}] ` : undefined;
  return getFields(searchFields, fieldLabelPrefix).filter(f => f.type !== 'largetext');
}

function getResultTypeFields(searchFields: ISearchField[] | undefined, resultType?: string | undefined): ISearchField[] | undefined {
  if (!resultType) {
    return searchFields;
  }
  return getFieldsForMatchingResultType(searchFields, resultType);
}

function getFieldsForMatchingResultType(searchFields: ISearchField[] | undefined, resultType?: string | undefined): ISearchField[] | undefined {
  if (searchFields) {
    const fieldList = searchFields.find(f => f.isList && f.name === resultType);
    if (fieldList) {
      return fieldList.fields;
    }

    for (const listField of searchFields.filter(f => f.isList)) {
      const matchingFields = getFieldsForMatchingResultType(listField.fields, resultType);
      if (matchingFields) {
        return matchingFields;
      }
    }
  }
  return undefined;
}
