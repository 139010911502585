/* eslint-disable no-shadow */
import React from 'react';
import { useParams } from 'react-router-dom';
import { FileResponse } from '../../api-service/clients';
import { AbsolutelyCentered } from '../../components/absolutely-centered/absolutely-centered';
import { CircularProgress } from '../../components/circular-progress/circular-progress';
import { useApiOnRender, useMetaTitle } from '../../hooks';
import { win } from '../../utilities';
import { moduleClientFactory } from '../../api-service/module-client-factory';

export function IncomingMailFileViewer() {
  useMetaTitle('Bilag');
  console.log('IncomingMailFileViewer');

  const incomingMailTaskClient = moduleClientFactory.createIncomingMailTaskClient();
  const { fileId } = useParams();
  const { result, isLoading } = useApiOnRender(cancelToken => incomingMailTaskClient.downloadIncomingMailTaskAttachment({ id: fileId }, cancelToken), {} as FileResponse);

  const file = new File([result.data], result.fileName ?? 'Bilag', { type: result.data?.type ?? 'application/pdf' });

  // Build a URL from the file
  const fileURL = win.URL.createObjectURL(file);

  document.body.style.overflow = 'hidden';
  document.body.style.margin = '0';

  return isLoading ? (
    <AbsolutelyCentered>
      <CircularProgress />
    </AbsolutelyCentered>
  ) : (
    <iframe src={fileURL} title={file.name} scrolling="no" frameBorder="0" width="100%" style={{ height: '100vh' }} />
  );
}
