import * as React from 'react';
import { Avatar } from '../avatar';
import { DrawerContent } from './drawer-content/drawer-content';
import { IDrawerProps } from './drawer-props';
import { IDrawerState } from './drawer-state';
import { DrawerHandle } from './drawer-handle';
import { IDrawerContentBlockProps } from './drawer-content-block';

export class Drawer extends React.Component<IDrawerProps, IDrawerState> {
  static defaultProps = {
    isOpen: false,
  };

  constructor(props: IDrawerProps) {
    super(props);
    const { isOpen } = props;
    this.state = {
      open: isOpen,
    };
  }

  componentDidUpdate(prevProps: IDrawerProps) {
    const { isOpen } = this.props;
    if (isOpen !== prevProps.isOpen) {
      // TODO Fix bad state handling
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ open: isOpen });
    }
  }

  private getAvatarsForHandle() {
    const { children } = this.props;
    let contentBlocks = children as React.ReactElement<IDrawerContentBlockProps>[];

    // Check for no children
    if (!contentBlocks) {
      return null;
    }

    // Ensure childrean are in array format (single child is direct access not contentBlocks[0])
    if (!Array.isArray(contentBlocks)) {
      contentBlocks = [contentBlocks];
    }
    // eslint-disable-next-line react/no-array-index-key
    return contentBlocks.filter(item => item !== null && item.props.type).map((contentBlock, i) => <Avatar key={i} type={contentBlock.props.type} />);
  }

  private readonly toggleDrawer = () => {
    const { onShow, onHide } = this.props;

    const { open } = this.state;
    this.setState({ open: !open }, () => {
      const handler = open ? onHide : onShow;
      handler && handler();
    });
  };

  public static testIds = {
    root: 'drawer-root-wrapper',
  };

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, onShow, onHide, isOpen, ...rest } = this.props;
    const { open } = this.state;
    const avatars = this.getAvatarsForHandle();

    return (
      <>
        {avatars && avatars.length !== 0 && <DrawerHandle onClick={this.toggleDrawer}>{avatars}</DrawerHandle>}
        <DrawerContent open={open} toggleDrawer={this.toggleDrawer} data-testid={Drawer.testIds.root} {...rest}>
          {children}
        </DrawerContent>
      </>
    );
  }
}
