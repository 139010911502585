import * as React from 'react';
import _ from 'lodash';
import { defaultControlClassNames } from './defaults';
import { IQueryBuilderControls, IControlClassNames, IQueryBuilderTranslations } from './query-builder-interfaces';
import { IOption, IField } from './field-interfaces';
import { ISearchField } from '../query/search-field';
import { ISearchSchema } from '../query';

import { QueryAction } from '../query/query-reducer';
import { ValidationErrors } from '../../../../utilities';
import { defaultControlElements } from './defaults-control-elements';
import { QueryViewAction } from '../../store/reducers/query-view-reducer';
import { ISearchRequestBody } from '../../models/dtos';

export interface IQueryBuilderValidationErrors {
  subject: any;
  validationErrors: any;
}

export interface IQueryBuilderContext {
  fields: ISearchField[];
  validationErrors: IQueryBuilderValidationErrors[];
  conditions: IOption[];
  classNames: IControlClassNames;
  controls: IQueryBuilderControls;
  translations: IQueryBuilderTranslations;
  getOperators: (field?: IField) => IOption[];
  queryDispatch: (action: QueryAction) => void;
  queryViewDispatch: (action: QueryViewAction) => void;
  requestBody: ISearchRequestBody;
  universe?: ISearchSchema;
}

export function getValidationErrors<T>(validationErrors: IQueryBuilderValidationErrors[], subject: T): ValidationErrors<T> {
  const found = validationErrors.find(x => _.isEqual(x.subject, subject));
  return found ? found.validationErrors : {};
}

// START NOSONAR
export function createContext(
  // END NOSONAR
  fields: ISearchField[],
  validationErrors: IQueryBuilderValidationErrors[],
  operators: IOption[],
  conditions: IOption[],
  controlElements: IQueryBuilderControls,
  translations: IQueryBuilderTranslations,
  controlClassnames: IControlClassNames,
  getOperators: (field: IOption) => IOption[],
  queryDispatch: (action: QueryAction) => void,
  queryViewDispatch: (action: QueryViewAction) => void,
  requestBody: ISearchRequestBody,
  universe?: ISearchSchema
): IQueryBuilderContext {
  const classNames = { ...defaultControlClassNames, ...controlClassnames };
  const controls = { ...defaultControlElements, ...controlElements };

  const handleGetOperators = (field?: IField) => {
    if (getOperators && field) {
      const ops = getOperators(field);
      if (ops) return ops;
    }

    return operators;
  };

  return {
    fields,
    validationErrors,
    conditions,
    classNames,
    controls,
    translations,
    getOperators: (field?: IField) => handleGetOperators(field),
    queryDispatch,
    queryViewDispatch,
    universe,
    requestBody,
  };
}

const QueryBuilderContext = React.createContext({} as IQueryBuilderContext);

export const QueryBuilderContextProvider = (props: React.PropsWithChildren<IQueryBuilderContext>) => {
  const { children, ...context } = props;
  return <QueryBuilderContext.Provider value={context}>{children}</QueryBuilderContext.Provider>;
};

export function useQueryBuilderContext(): IQueryBuilderContext {
  return React.useContext(QueryBuilderContext);
}
