import * as React from 'react';
import { IDrawerHandleProps } from './drawer-handle-props';
import { Paper } from '../../paper';
import './drawer-handle.scss';

export class DrawerHandle extends React.Component<IDrawerHandleProps> {
  public static testIds = {
    handle: 'drawer-handle',
  };

  render() {
    const { children, onClick } = this.props;

    return (
      <Paper className="drawer__handle" data-testid={DrawerHandle.testIds.handle} elevation={0} onClick={onClick} square={false}>
        {children}
      </Paper>
    );
  }
}
