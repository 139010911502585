import React, { useState, useEffect } from 'react';
import { BrowseControl } from '../../../../../../components/browse-control/browse-control';
import { RelativeDateEditorDialog } from './relative-date-editor-dialog';
import { parser } from './expression-parser';

export interface IRelativeDateEditorProps {
  className?: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

export const relativeDateEditorTestIds = {
  BrowseControl: 'RelativeDateEditor-BrowseControl',
  Dialog: 'RelativeDateEditor-Dialog',
};

export function RelativeDateEditor(props: IRelativeDateEditorProps) {
  const { value, onChange, className, disabled } = props;
  const [state, setState] = useState({ open: false, value: parser.toText(value) });
  useEffect(() => {
    setState({ open: false, value: parser.toText(value) });
  }, [value]);
  function handleCancel() {
    setState({ ...state, open: false });
  }
  function handleClick() {
    setState({ ...state, open: true });
  }

  function handleChange(val: string) {
    setState({ open: false, value: parser.toText(val) });
    onChange(val);
  }

  return (
    <>
      <div style={{ width: '350px' }}>
        <BrowseControl
          data-testid={relativeDateEditorTestIds.BrowseControl}
          fullWidth
          value={state.value}
          className={className}
          onButtonClick={handleClick}
          label="Værdi"
          disabled={disabled}
        />
      </div>
      <RelativeDateEditorDialog
        data-testid={relativeDateEditorTestIds.Dialog}
        open={state.open}
        value={value}
        onConfirm={handleChange}
        onCancel={handleCancel}
        className={className}
      />
    </>
  );
}
