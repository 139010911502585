import { faBell } from '@fortawesome/pro-light-svg-icons';
import { faChevronRight, faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Drawer, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { green } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import { isEqual } from 'lodash';
import React, { memo, useEffect } from 'react';
import { apm } from '@elastic/apm-rum';
import { moduleClientFactory } from '../../api-service/module-client-factory';
import { formatDateTime } from '../../utilities';
import { ColorPalette, Dimensions } from '../config';
import { IDisplayMessageCenterMessage, IMessageCenterProps, TMessageSeverity } from './message-center-props';
import { getIcon, getIconColor, getMessageState, isUnread, setMessageState } from './message-center-utils';
import { MessageCenterConfigurationModel } from '../../api-service/clients';
import { ToggleText } from '../toggle-text/toggle-text';

async function getMessages() {
  const configurationClient = moduleClientFactory.createConfigurationClient();
  const tenantConfigurationResult = await configurationClient.getTenantConfiguration();
  const systemConfigurationResult = await configurationClient.getSystemConfiguration();
  const { messageCenterMessages: messagesFromSystemConfiguration } = systemConfigurationResult;
  const { messageCenterMessages: messagesFromTenantConfiguration } = tenantConfigurationResult;
  const jsonData = [...(messagesFromTenantConfiguration ?? []), ...(messagesFromSystemConfiguration ?? [])];
  const readMessageState = getMessageState();
  try {
    return jsonData
      ? jsonData
          .map((message: MessageCenterConfigurationModel) => {
            const newMessage: IDisplayMessageCenterMessage = { ...message };
            const rightNow = new Date();
            let showFrom;
            let showTo;
            try {
              showFrom = new Date(newMessage.showFrom);
              showTo = new Date(newMessage.showTo);
            } catch (e) {
              console.error(e);
            }
            if (!showFrom || !showTo) {
              newMessage.hidden = true;
            } else {
              newMessage.hidden = !(showFrom < rightNow && rightNow < showTo);
            }
            const unread = newMessage.hidden ? false : isUnread(newMessage.id, newMessage.updated, readMessageState);
            newMessage.showAsUrgent = newMessage.hidden ? false : !!(newMessage.urgent && unread);
            newMessage.unread = unread;
            newMessage.isNew = !readMessageState[newMessage.id];
            return newMessage;
          })
          .filter((message: IDisplayMessageCenterMessage) => message.hidden === false)
          .sort((a: IDisplayMessageCenterMessage, b: IDisplayMessageCenterMessage) => {
            const { updated: updatedA, severity: severityA } = a;
            const { updated: updatedB, severity: severityB } = b;
            if (severityA === 'error' && severityB === 'info') return -1;
            if (severityA === 'info' && severityB === 'error') return 1;

            if (updatedA < updatedB) return 1;
            if (updatedA > updatedB) return -1;
            return 0;
          })
      : [];
  } catch (e) {
    console.log(e);
    apm.captureError('Error parsing MessageCenter messages');
    return [];
  }
}

const interval = 60000; // 1 minute
let intervalId: null | ReturnType<typeof setInterval> = null;

export default function MessageCenter({ sx }: IMessageCenterProps) {
  const [messages, setMessages] = React.useState<IDisplayMessageCenterMessage[]>([]);
  const [open, setIsOpen] = React.useState(false);
  const [unreadMessages, setUnreadMessages] = React.useState(0);

  async function fetchMessages() {
    const data = await getMessages();
    return data;
  }

  const goFetch = async () => {
    const newMessages = await fetchMessages();
    setMessages(prevMessages => (isEqual(newMessages, prevMessages) ? prevMessages : newMessages));
  };

  const handleVisibilityChange = () => {
    if (document.hidden) {
      clearInterval(intervalId as ReturnType<typeof setInterval>);
    } else {
      goFetch();
      intervalId = setInterval(goFetch, interval);
    }
  };

  // Initial
  useEffect(() => {
    goFetch();

    if (!document.hidden) {
      intervalId = setInterval(goFetch, interval);
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(intervalId as ReturnType<typeof setInterval>);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    setUnreadMessages((messages.filter(message => !!message.unread) ?? []).length);

    // Force open drawer if there's at least 1 new message that's urgent.
    setIsOpen(messages.filter(message => !!message.unread && !!message.urgent && !!message.isNew).length > 0);
  }, [messages]);

  function handleMessageCenterClick() {
    if (!messages.length) return;
    setIsOpen(true);
  }

  function toggleDrawerClose(event: object, reason: string) {
    if (reason === 'backdropClick') return;
    setMessageState([...messages.map(x => ({ id: x.id, updated: x.updated }))]);
    setIsOpen(false);
    goFetch();
  }

  return (
    <>
      <Tooltip title={messages.length ? 'Åbn meddelelsescenter' : 'Ingen meddelelser'} placement="left" disableInteractive>
        <Button
          size="large"
          aria-label={messages.length ? 'Åbn meddelelsescenter' : 'Ingen meddelelser'}
          onClick={handleMessageCenterClick}
          sx={{ ...sx, ml: 1, position: 'relative', color: ColorPalette.WHITE, cursor: messages.length ? 'pointer' : 'default', opacity: messages.length ? 1 : 0.4 }}
        >
          <Badge badgeContent={unreadMessages} color="error">
            <FontAwesomeIcon icon={faBell} />
          </Badge>
        </Button>
      </Tooltip>
      <MemoizedDrawer open={open} toggleDrawerClose={toggleDrawerClose} messages={messages} />
    </>
  );
}

const MemoizedDrawer = memo(({ open = false, toggleDrawerClose, messages = [] }: { open?: boolean; toggleDrawerClose: any; messages: IDisplayMessageCenterMessage[] }) => (
  <Drawer anchor="right" open={open} onClose={toggleDrawerClose}>
    <Button
      sx={{
        background: 'transparent',
        border: 'none',
        borderBottom: `1px solid ${ColorPalette.LightAsteroidDust}`,
        cursor: 'pointer',
        display: 'flex',
        outline: 'none',
        textAlign: 'left',
        px: 2,
        height: 50,
        justifyContent: 'left',
        '&:hover': {
          backgroundColor: ColorPalette.LightAsteroidDust,
        },
        '&:focus': {
          backgroundColor: ColorPalette.LightAsteroidDust,
        },
      }}
      onClick={(e: any) => toggleDrawerClose(e, '')}
      title="Luk meddelelser"
      aria-label="Luk meddelelser"
      data-vanguard="message-center-dismiss-button"
    >
      <FontAwesomeIcon icon={faChevronRight} /> <Box sx={{ display: 'inline-block', ml: 1 }}>Luk meddelelser</Box>
    </Button>
    <Typography variant="h3" sx={{ borderLeft: '3px solid transparent', py: 1, pl: 2, mb: 2 }}>
      Aktuelle meddelelser
    </Typography>
    {messages.map((message: IDisplayMessageCenterMessage) => (
      <Message {...message} key={message.id} />
    ))}
  </Drawer>
));

function Message(props: IDisplayMessageCenterMessage) {
  const { message, updated, updateMessage, date, title, solvedDate, severity, tags, showAsUrgent } = props;

  let borderColor = 'transparent';
  if (showAsUrgent && !solvedDate) {
    borderColor = ColorPalette.Crimson;
  }

  return (
    <Box sx={{ width: 400, borderBottom: `1px solid ${ColorPalette.AreaBoxBorder}`, borderLeft: `3px solid ${borderColor}` }}>
      <Box sx={{ p: 2, paddingBottom: 0 }}>
        <Typography sx={{ fontSize: 13 }} color="text.secondary" gutterBottom>
          {`Opdateret ${formatDateTime(updated)}`}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bolder' }}>
          <Box sx={{ color: getIconColor(severity as TMessageSeverity), fontSize: 20, flex: 0, mr: 1 }}>
            <FontAwesomeIcon icon={getIcon(severity as TMessageSeverity)} />
          </Box>
          <Box>{title}</Box>
        </Box>
        {!!tags && (
          <Box
            sx={{
              display: 'flex',
              mt: 1,
              '& > div': {
                flex: 0,
                border: `1px solid ${ColorPalette.AreaBoxBorder}`,
                fontSize: 12,
                color: ColorPalette.SecondaryTextColor,
                px: 0.5,
                mr: 1,
                textTransform: 'uppercase',
                whiteSpace: 'pre',
              },
            }}
          >
            {tags.map(tag => (
              <Box key={tag}>{tag}</Box>
            ))}
          </Box>
        )}
      </Box>
      <Box sx={{ p: 2, fontSize: 15, '& p:first-of-type': { mt: 0 }, '& details[open] p:first-of-type': { mt: 1, pl: 1 } }}>
        <Box sx={{ mb: 1 }}>
          {severity === 'error' && (
            <>
              <strong>Status: </strong>

              <Box sx={{ display: 'inline-block' }}>
                {solvedDate ? `Løst ${formatDateTime(solvedDate)}` : 'Igangværende'}
                {!!solvedDate && (
                  <Box sx={{ color: green[700], display: 'inline-block', ml: 0.5 }}>
                    <FontAwesomeIcon icon={faCircleCheck} />
                  </Box>
                )}
              </Box>
            </>
          )}
          {updateMessage && (
            <Box
              sx={{
                backgroundColor: ColorPalette.FormDialogBackground,
                py: 0.5,
                px: 1,
                mt: 0.5,
                borderRadius: `${Dimensions.BorderRadius}px`,
              }}
              title="Seneste status"
            >
              {updateMessage}
            </Box>
          )}
        </Box>
        {message && <ToggleText text={message} maxLength={100} />}
        <Typography sx={{ fontSize: 13 }} color="text.secondary" marginTop={2} display="block" component="span">
          {`Oprettet ${formatDateTime(date)}`}
        </Typography>
      </Box>
    </Box>
  );
}
