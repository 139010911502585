export const offerAndActivityAreaMenuName = 'Tilbud & aktiviteter';
export const offerAndActivityAreaPathName = 'offer-and-activity';

export const offerWizardTabLabel = 'Nyt tilbud';
export const offerWizardPathName = 'create-offer';
export const viewOfferPathName = 'view-offer';

export const activityWizardTabLabel = 'Ny aktivitet';
export const activityWizardPathName = 'create-activity';
export const viewActivityPathName = 'view-activity';
export const viewEvaluationPathName = 'evaluation';
export const activityReimbursementCreatePathName = 'create-reimbursement';
export const activityReimbursementEditPathName = 'reimbursement';

export const activityCancelWizardTabLabel = 'Annullér/afbryd';
export const activityCancelWizardTabApprovalFlowLabel = 'Godkend/afvis';
export const activityCancelWizardPathName = 'cancel-activity';

export const activityApproveRejectPathName = 'approve-reject';

export const createActivityOfferSelect = 'create-activity-offer-select';

export const offerPathName = 'offer';
export const offerPriceGuidePathName = 'offerpriceguide';
export const caseGuidePathname = 'edit-case';
export const associatedCaseWorkerGuidePathname = 'case-worker';

export const assignCaseworkerPathName = 'assign-caseworker';
export const communicationFormsGuidePathName = 'activity-guide-form';
