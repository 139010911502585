import React from 'react';
import MuiLinearProgress from '@mui/material/LinearProgress';

export interface ILinearProgressProps {
  show?: boolean;
  style?: React.CSSProperties;
  height?: number;
}

export function LinearProgress(props: ILinearProgressProps) {
  const { show = false, style = {}, height = 4 } = props;

  const sx = {
    transition: 'opacity .5s linear, height .25s linear .25s',
    opacity: show ? 1 : 0,
    height: show ? height : 0,
  };

  return <MuiLinearProgress style={style} sx={sx} />;
}
