import * as React from 'react';
import { IBackdropProps } from './backdrop-props';

export class Backdrop extends React.Component<IBackdropProps> {
  constructor(props: IBackdropProps) {
    super(props);
  }

  handleOnClick = () => {
    this.props.openedWindow.focus();
  };

  render() {
    return (
      <div
        onClick={this.handleOnClick}
        style={{
          display: 'blocked',
          position: 'fixed',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.26)',
          zIndex: 10000,
        }}
      />
    );
  }
}
