import { useLocation } from 'react-router';
import { OmnibarContext } from '../omnibar-interfaces';

const contextRegEx = /\/(?<context>citizen|company)\/(?<contextId>[a-z0-9-]{36})?\/(?<tab>[a-z-]+)?/i;

export default function useOmnibarContext() {
  const { pathname } = useLocation();
  const match = pathname.match(contextRegEx);
  if (match && match.groups) {
    const { context, contextId, tab }: { context?: OmnibarContext; contextId?: string; tab?: string } = match.groups;
    return { context, contextId, tab };
  }
  return {};
}
