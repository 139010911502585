import { AxiosRequestConfig } from 'axios';
import { SecureApiService } from '../../../../../../api-service';
import { IConfiguration, configuration } from '../../../../../../configuration';

export interface ICodeListRequest {
  codelistType: string;
  type?: string;
  field?: string;
  universe?: string;
}

export interface ICodeListOption {
  id: string;
  name: string;
}

export interface ICodeListApiService {
  getCodeListOptions(codeListRequest: ICodeListRequest, config?: AxiosRequestConfig): Promise<ICodeListOption[]>;
}

export class CodeListApiService implements ICodeListApiService {
  private readonly baseService: SecureApiService = new SecureApiService();
  private readonly configuration: IConfiguration = configuration;

  getCodeListOptions = async (codeListRequest: ICodeListRequest, config?: AxiosRequestConfig): Promise<ICodeListOption[]> => {
    const url = `${this.getApiAddress()}/CodeList/Options`;
    return this.baseService.post<ICodeListRequest, ICodeListOption[]>(url, codeListRequest, config);
  };

  private readonly getApiAddress = () => `${this.configuration.searchBaseAddress}`;
}
export const codeListApiService = new CodeListApiService();
