import * as React from 'react';
import { IGroupRule } from './rule-interface';
import { RuleFactory } from './rule-factory';
import { IGroupRulesProps } from './fields-and-types';
import { useQueryBuilderContext } from './query-builder-context';
import { getValueForOperator } from './operators-functions';
import { isRule } from './rule';
import { ConfirmDialog } from '../../../../components/confirm-dialog/confirm-dialog';

export function GroupRuleActions<T extends IGroupRule>(props: IGroupRulesProps<T>) {
  const [open, setOpen] = React.useState(false);
  const context = useQueryBuilderContext();
  const {
    isRoot,
    fieldsAndTypes: { fields, types },
    value,
  } = props;
  const { controls, translations, classNames, universe, requestBody } = context;

  const addRule = (event: any, ruleName: string) => {
    event.preventDefault();
    event.stopPropagation();
    const rule = RuleFactory.factory(ruleName, context, fields, value);
    context.queryDispatch({ type: 'addRule', parent: props.value, rule: isRule(rule) ? { ...rule, value: getValueForOperator(rule.operator, rule.value) } : rule });
  };

  const handleAddRule = (event: any) => addRule(event, 'rule');
  const handleAddGroup = (event: any) => addRule(event, 'group');
  const handleAddHaving = (event: any) => addRule(event, 'having');

  const handleRemove = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const isResultType = value.type === requestBody.resultType;
    const resultTypeCount = requestBody.query.rules.filter((x: any) => requestBody.resultType && x.type === requestBody.resultType)?.length;
    const hasResultTypeColumns = requestBody.columns?.some(x => requestBody.resultType && x.name.startsWith(requestBody.resultType));
    if (isResultType && resultTypeCount === 1) {
      if (hasResultTypeColumns) {
        setOpen(true);
      } else {
        removeRule(true);
      }
    } else {
      removeRule(false);
    }
  };
  function removeRule(resetResultType: boolean) {
    context.queryDispatch({ type: 'deleteRule', rule: props.value, resetResultType });
  }
  const disabled = !types || types.length === 0 || types.every(x => x.inUse || (!x.name && !x.inUse));
  // eslint-disable-next-line react/destructuring-assignment
  const resultTypeLabel = props.types.find(x => x.name === requestBody?.resultType)?.label;
  return (
    <>
      {React.createElement(controls.addRuleAction, {
        label: translations.addRule.label,
        title: translations.addRule.title,
        icon: translations.addRule.icon,
        disabled: !fields || fields.length === 0,
        className: `${classNames.addRule} group-action`,
        handleOnClick: handleAddRule,
      })}
      {React.createElement(controls.addGroupAction, {
        label: translations.addGroup.label,
        title: translations.addGroup.title,
        icon: translations.addGroup.icon,
        disabled: !fields || fields.length === 0,
        className: `${classNames.addGroup} group-action`,
        handleOnClick: handleAddGroup,
      })}
      {React.createElement(controls.addHavingAction, {
        disabled,
        label: translations.addHavingOperator.label,
        title: translations.addHavingOperator.title,
        icon: translations.addHavingOperator.icon,
        className: `${classNames.addHaving} group-action`,
        handleOnClick: handleAddHaving,
      })}
      {!isRoot &&
        React.createElement(controls.removeGroupAction, {
          label: translations.removeGroup.label,
          title: translations.removeGroup.title,
          disabled: false,
          className: classNames.removeGroup,
          handleOnClick: handleRemove,
        })}
      <ConfirmDialog
        title="Slet gruppe?"
        open={open}
        cancelButtonText="Annullér"
        confirmButtonText="Ok"
        onCancelClick={() => setOpen(false)}
        message={`Vær opmærksom på at 'Vis som resultat' bliver sat til ${universe?.displayName}. Kolonner tilhørende '${resultTypeLabel}' bliver fjernet.`}
        onConfirmClick={() => removeRule(true)}
        cancelIsPrimary
      />
    </>
  );
}
