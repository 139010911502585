import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { secureAxiosInstance } from './secure-axios-instance';

export interface ISecureApiService {
  get<TData, TResponse>(url: string, data: TData, config?: AxiosRequestConfig): Promise<TResponse>;
  post<TData, TResponse>(url: string, data: TData, config?: AxiosRequestConfig): Promise<TResponse>;
  delete<TResponse>(url: string, config?: AxiosRequestConfig): Promise<TResponse>;
  put<TData, TResponse>(url: string, data: TData, config?: AxiosRequestConfig): Promise<TResponse>;
}

export class SecureApiService implements ISecureApiService {
  async get<TData, TResponse>(url: string, data?: TData, config?: AxiosRequestConfig): Promise<TResponse> {
    const response = await secureAxiosInstance.get<TResponse>(url, { ...config, params: data });

    return this.handleResponse(response);
  }

  async post<TData, TResponse>(url: string, data: TData, config?: AxiosRequestConfig): Promise<TResponse> {
    const response = await secureAxiosInstance.post<TResponse>(url, data, config);
    return this.handleResponse(response);
  }

  async put<TData, TResponse>(url: string, data: TData, config?: AxiosRequestConfig): Promise<TResponse> {
    const response = await secureAxiosInstance.put<TResponse>(url, data, config);

    return this.handleResponse(response);
  }

  async delete<TResponse>(url: string, config?: AxiosRequestConfig): Promise<TResponse> {
    const response = await secureAxiosInstance.delete(url, config);

    return this.handleResponse(response);
  }

  // eslint-disable-next-line class-methods-use-this
  handleResponse<TResponse>(response: AxiosResponse<TResponse>): TResponse {
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }

    throw new Error(response.statusText);
  }
}
