import { useMemo } from 'react';
import { useApiOnRender } from '../hooks/use-api';
import { ResourceAction, AuthorizationService } from './authorization-service';
import { ResourceAccessCriteria } from '../api-service/clients';
import { useAllowedActions } from './use-allowed-actions';

export function useIsAllowed(actions: ResourceAction[]): boolean {
  const allowedActions = useAllowedActions();

  return allowedActions.isAnyAllowed(actions);
}

export function useIsAllowedWithDataBoundary(actions: ResourceAction[], resourceAccessCriteria?: ResourceAccessCriteria): { isReady: boolean; isAllowed: boolean | undefined } {
  const allowedActions = useAllowedActions();

  const isAllowedByActions = allowedActions.isAnyAllowed(actions);

  const resourceAccessCriteriaMemo = useMemo<ResourceAccessCriteria>(
    () => ({ ...resourceAccessCriteria }),
    [resourceAccessCriteria?.dataType, resourceAccessCriteria?.dataIdentifier, resourceAccessCriteria?.dataIdentifierType]
  );

  const isAllowedApiResult = useApiOnRender(
    (cancelToken, resourceAccessCriteriaArg) =>
      resourceAccessCriteria ? new AuthorizationService().isAllowed(actions, resourceAccessCriteriaArg, cancelToken) : Promise.resolve(isAllowedByActions),
    resourceAccessCriteria ? undefined : isAllowedByActions,
    resourceAccessCriteriaMemo
  );

  return resourceAccessCriteria
    ? {
        isReady: isAllowedApiResult.isReady,
        isAllowed: isAllowedApiResult.result,
      }
    : {
        isReady: true,
        isAllowed: isAllowedByActions,
      };
}
