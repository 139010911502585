export interface ISearchField {
  name: string;
  displayName: string;
  type?: string;
  isList?: boolean;
  isJoinList?: boolean;
  fields?: ISearchField[];
  uiHint?: UiHint;
  codeListType?: string;
  replicateToLists?: boolean;
  codeListName?: string;
  toBeDeleted?: boolean;
}

export enum UiHint {
  Hide = 'hide',
  Lookup = 'lookup',
  Money = 'money',
  Time = 'time',
  MultipleSelectLookup = 'multipleSelectLookup',
  TextCodeList = 'textCodeList',
  Url = 'url',
}
