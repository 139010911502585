import * as React from 'react';
import classnames from 'classnames';
import { IField } from './field-interfaces';
import { IHavingRule, INestedRuleBase, IRuleBase } from './rule-interface';
import { CardinalityOptions, Cardinality } from './cardinality-options';
import { GroupRules } from './group-rules';
import { GroupRuleActions } from './group-rule-actions';
import { IRuleProps } from './rule-props';
import { RuleFactory } from './rule-factory';
import { Typography } from '../../../../components/typography/typography';
import { useFieldsAndTypes } from './hooks/useFieldsAndTypes';
import { useQueryBuilderContext, IQueryBuilderContext } from './query-builder-context';
import { IResultTypeUsage } from '../query/search-result-type/result-type-options';
import { ValidationErrors } from '../../../../utilities';
import { validationMessages } from './defaults';
import { ISearchSchema } from '../query';
import { QueryBuilderFieldOptions } from '../query/query-builder-field-options';
import { QueryBuilderOperator } from './operators';

export function canSelectResultType(rule: IHavingRule) {
  const { type, cardinality } = rule;
  return !!type && cardinality !== 'none';
}

export function hideSelectResultType(rule: IHavingRule, schema: ISearchSchema | undefined): boolean {
  const allowAsResultTypeDefault = true;
  const schemaAllowListsAsResults = schema?.allowListsAsResultType ?? allowAsResultTypeDefault;
  return rule.cardinality === 'none' || !schemaAllowListsAsResults;
}

function isDisabled(types: IResultTypeUsage[]) {
  return !types || types.length === 0 || types.every(x => x.inUse || (!x.name && !x.inUse));
}

export function HavingRule(props: IRuleProps<IHavingRule>) {
  const context = useQueryBuilderContext();
  const fieldsAndTypes = useFieldsAndTypes(props);

  const handleChange = (value: IHavingRule) => {
    context.queryDispatch({ type: 'updateRule', oldRule: props.value, newRule: value });
  };

  const handleCardinalityChange = (value: string) => {
    handleChange({ ...props.value, cardinality: value });
  };

  function handleBetweenChange(value: string | string[]) {
    handleChange({ ...props.value, min: value[0], max: value[1] });
  }

  const handleTypeListChange = (value: string) => {
    handleChange({ ...props.value, type: value, rules: [] });
  };

  const handleToggleChange = (event: React.ChangeEvent<HTMLElement>, checked: boolean) => {
    const {
      value: { type },
    } = props;

    context.queryDispatch({ type: 'resultType', resultType: checked && type ? type : undefined, resultTypeHasChanged: true });
    context.queryViewDispatch({ type: 'queryView/updateColumnFilters', columnFilters: [] });
  };

  const HavingActions = () => {
    const {
      value,
      value: { cardinality, type, min, max },
      types,
      resultType: topLevelResultType,
    } = props;
    const { controls, translations, classNames, universe } = context;
    const disabled = isDisabled(types);
    const toggleDisabled = !canSelectResultType(value);
    const toggleHidden = hideSelectResultType(value, universe);
    const toggleElementClassNames = classnames('havingRule__toggle', { 'havingRule__toggle--disabled': toggleDisabled, 'havingRule__toggle--hidden': toggleHidden });

    const shouldValidateMinMax = !canSelectResultType(value);

    const typeOptions = types.filter(x => x.name);

    const isMinMax = cardinality === Cardinality.MinMax;

    return (
      <div className="groupRule__having-actions">
        <div className="havingRule__actions">
          <Typography className="havingRule__actions__label">Som har</Typography>
          {[
            React.createElement(controls.inlineSelector, {
              field: '',
              options: CardinalityOptions,
              value: cardinality,
              title: translations.addHavingOperator.title,
              className: `${classNames.havingOperators} group-action dotted`,
              handleOnChange: handleCardinalityChange,
              key: 0,
            }),
            isMinMax &&
              React.createElement(controls.valueEditor, {
                field: '',
                value: [min || '', max || ''],
                title: '',
                type: QueryBuilderFieldOptions.SmallInteger,
                operator: QueryBuilderOperator.Between,
                className: `${classNames.havingFields}${!type ? '' : ' dotted'} validation-error group-action`,
                handleOnChange: handleBetweenChange,
                separator: 'og/eller',
                key: 3,
                placeholder: ['min', 'max'],
                validateGraterThanZero: true,
                validateStartValueMustBeEntered: true,
                validateDisabled: shouldValidateMinMax,
              }),
            React.createElement(controls.inlineSelector, {
              disabled,
              field: '',
              options: typeOptions,
              value: type || '',
              title: translations.addHavingField.title,
              className: `${classNames.havingFields}${!type ? '' : ' dotted'} validation-error group-action`,
              handleOnChange: handleTypeListChange,
              key: 1,
            }),
            React.createElement(controls.toggleElement, {
              label: translations.havingToggleAsResult.label,
              title: translations.havingToggleAsResult.title,
              className: toggleElementClassNames,
              checked: !!topLevelResultType && type === topLevelResultType,
              onChange: handleToggleChange,
              disabled: toggleDisabled,
              key: 2,
            }),
          ]}
        </div>
      </div>
    );
  };

  const { classNames } = context;

  return (
    <div className={`${classNames.groupRule} groupRule--having`}>
      <HavingActions />
      <div className="groupRule__actions">
        <GroupRuleActions {...props} fieldsAndTypes={fieldsAndTypes} />
      </div>

      <GroupRules {...props} fieldsAndTypes={fieldsAndTypes} />
    </div>
  );
}

export function createHavingRule(context: IQueryBuilderContext, fields: IField[], parent: INestedRuleBase): IHavingRule {
  return {
    rules: [],
    condition: context.conditions[0].name,
    cardinality: Cardinality.Any,
    // TODO? type: types[0].name,
  };
}

export function validateHavingRule(rule: IHavingRule, resultTypeUsage: IResultTypeUsage[]): ValidationErrors<IHavingRule> {
  const validationErrors: ValidationErrors<IHavingRule> = {};

  if (!rule.cardinality) {
    validationErrors.cardinality = validationMessages.cardinalityRequired;
  }
  if (!rule.type) {
    validationErrors.type = validationMessages.havingTypeRequired;
  } else {
    const type = resultTypeUsage.find(x => x.name === rule.type);
    if (!type) {
      validationErrors.type = validationMessages.unknownHavingType;
    }
  }
  if (!rule.condition) {
    validationErrors.condition = validationMessages.conditionRequired;
  }

  return validationErrors;
}

export function isHavingRule(rule: IRuleBase): rule is IHavingRule {
  return !!(rule as IHavingRule).cardinality;
}

function componentFactory(key: string | number, props: IRuleProps<IHavingRule>): React.ReactNode {
  const { value } = props;
  return isHavingRule(value) ? <HavingRule {...props} key={key} /> : null;
}

RuleFactory.register('having', createHavingRule, componentFactory);
