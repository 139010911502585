import React from 'react';
import { NavLink } from 'react-router-dom';
import { IMainNavigationItem } from './main-navigation-items';
import { joinURL } from '../../../utilities';
import { ItemAvatar } from './main-navigation-item-avatar';
import { LightTooltip } from './styled-tooltip';
import { useTenantConfiguration } from '../../../hooks/use-tenant-configuration';

export interface IMainNavigationMenu {
  item: IMainNavigationItem;
  isActive: boolean;
}

export function MainNavigationNavMenu(props: IMainNavigationMenu) {
  const { item, isActive } = props;
  const tenantDisplayConfiguration = useTenantConfiguration();
  const { crmUrl } = tenantDisplayConfiguration;
  // Temporary open crm button in left menu
  const isCrmLink = item.guid === '950c8062-f130-43d9-9469-ab55eed6f749';

  function redirect(mainItem: IMainNavigationItem) {
    if (mainItem.forceRedirect) {
      window.location.href = joinURL('/', mainItem.url);
    }
  }

  return (
    <>
      {!isCrmLink && (
        <LightTooltip arrow enterDelay={500} title={item.name} placement="right">
          <NavLink
            onClick={() => redirect(item)}
            to={item.forceRedirect ? '' : joinURL('/', item.url)}
            className="main-navigation__link"
            activeClassName="main-navigation__link--active"
            exact={!!item.exact}
            isActive={() => isActive}
            data-vanguard={`left-menu_${item.name}`}
          >
            <ItemAvatar item={item} isActive={isActive} />
          </NavLink>
        </LightTooltip>
      )}
    </>
  );
}
