import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { IFormDialogProps } from './form-dialog-props';
import { capitalizeFirstLetter } from '../../utilities/string-helper';
import { Button } from '../button';
import { DeprecationWarning } from '../deprecation-warning';

const ariaLabelledBy = 'form-dialog-title';
const ariaDescribedBy = 'form-dialog-description';

export const formDialogTestIds = {
  confirmButton: 'form-dialog-confirm-button',
  cancelButton: 'form-dialog-cancel-button',
};

export function FormDialog(props: React.PropsWithChildren<IFormDialogProps>) {
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    } else if (props.onCancelClick) {
      props.onCancelClick();
    } else {
      props.onConfirmClick();
    }
  };

  const handleConfirm = () => {
    props.onConfirmClick();
  };

  const handleCancel = () => {
    if (props.onCancelClick) {
      props.onCancelClick();
    }
  };

  const {
    open,
    title,
    description,
    confirmButtonText,
    cancelButtonText,
    onCancelClick,
    isConfirm,
    isValid,
    isLoading,
    children,
    maxWidth = 'sm',
    dialogContentClass,
    extraDialogAction,
    fullWidth,
    cancelIsPrimary = false,
    disableBackdropClick,
    sx,
  } = props;
  const showCancel = Boolean(onCancelClick);
  const dialogClass = isConfirm ? 'confirm-dialog' : 'form-dialog';

  const descriptionWarning = description ? (
    <DeprecationWarning
      type="prop"
      name="description"
      location="FormDialog"
      more="Please remove the usage and move the content of the description prop to the children's area, if still needed."
    >
      {description}
      <br />
    </DeprecationWarning>
  ) : (
    description
  );

  return (
    <Dialog
      maxWidth={maxWidth}
      className={dialogClass}
      onClose={(event, reason) => {
        if ((reason === 'backdropClick' || reason === 'escapeKeyDown') && disableBackdropClick) {
          return;
        }
        handleClose();
      }}
      open={open}
      scroll="body"
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      fullWidth={fullWidth}
      sx={sx}
    >
      <DialogTitle id={ariaLabelledBy}>{title}</DialogTitle>
      <DialogContent className={dialogContentClass}>
        {descriptionWarning}
        {children}
      </DialogContent>
      <DialogActions className="actions">
        {extraDialogAction}
        <Button
          onClick={handleConfirm}
          color="primary"
          variant={cancelIsPrimary ? 'outlined' : 'contained'}
          disabled={!isValid || isLoading}
          data-testid={formDialogTestIds.confirmButton}
        >
          {isLoading && (
            <CircularProgress
              size={24}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -12,
                marginLeft: -12,
              }}
            />
          )}
          {confirmButtonText ? capitalizeFirstLetter(confirmButtonText) : 'Ok'}
        </Button>
        {showCancel && (
          <Button onClick={handleCancel} color="primary" variant={cancelIsPrimary ? 'contained' : 'outlined'} data-testid={formDialogTestIds.cancelButton} disabled={isLoading}>
            {cancelButtonText ? capitalizeFirstLetter(cancelButtonText) : 'Annuller'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

FormDialog.defaultProps = {
  isConfirm: false,
  isValid: true,
  isLoading: false,
};
