/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import { Box, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { faGear } from '@fortawesome/pro-light-svg-icons';
import { faArrowAltUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import { TextField } from '../text-field';
import { ColorPalette } from '../config';
import { useChat, IChatMessage, CitationSourceType } from '../../hooks/ai-search/use-chat';
import { Tooltip } from '../tooltip';
import { IconButton } from '../icon-button/icon-button';
import { TrashIcon } from '../icons/trash-icon/trash-icon';
import { Checkbox } from '../checkbox';
import { useFeatureToggles } from '../../feature-toggles';
import { useErrorSnackbar } from '../../hooks';
import { INotifierMessage } from '../notifier/interfaces';
import { ChatMessageComponent } from './chat-message';
import { PromptExecutionSettingsDto, CitationContentType } from '../../api-service/clients';
import { PromptSettings } from './settings/prompt-settings';

const useStyles = (height: string) =>
  makeStyles((theme: Theme) =>
    createStyles({
      outerInputBox: { width: '100%', backgroundColor: 'white', boxShadow: '0 -2px 10px rgba(0,0,0,0.2)' },
      mainBox: { p: 1, position: 'relative', maxHeight: height, height: height || '92vh', display: 'flex', flexDirection: 'column' },
      assistant: {
        fontSize: '.875rem',
        marginBottom: theme.spacing(1),
        position: 'relative',
        borderLeft: '5px solid rgb(73, 120, 142)',
        backgroundColor: ColorPalette.WHITE,
      },
      sendButton: { minWidth: 20, backgroundColor: ColorPalette.PrimaryMain, color: ColorPalette.WHITE, height: 45, width: 45, marginLeft: 10, marginRight: 10 },
      user: {
        fontSize: '.875rem',
        marginBottom: theme.spacing(1),
        position: 'relative',
        borderLeft: '5px solid rgb(213, 26, 101)',
        backgroundColor: ColorPalette.WHITE,
      },
      customBox: {
        paddingRight: theme.spacing(2),
        overflowY: 'auto',
        flexGrow: 1,
      },
    })
  );

export function ChatComponent(props: { height: string }) {
  const { height } = props;
  const [inputString, setInputString] = useState('');
  const [settings, setSettings] = useState<PromptExecutionSettingsDto>({});
  const { messages, handlePrompt, isLoading, error, clearChat, setChatFeedback, citationsTypes, setCitationsTypes } = useChat();
  const classes = useStyles(height)();
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const prevMessagesLength = useRef(messages.length);
  const prevLastMessageContentLength = useRef(messages[messages.length - 1]?.content.length || 0);
  const [showConfig, setShowConfig] = useState(false);
  const { isFeatureEnabled } = useFeatureToggles();
  const isIaDocumentsFeatureEnabled = isFeatureEnabled('AiSearchIADocuments');
  const isLegalDocumentsFeatureEnabled = isFeatureEnabled('AiSearchLegalDocuments');
  const isConfigMenuEnabled = isFeatureEnabled('AiSearchAllowCustomPromptSettings');
  const showDataSources = isIaDocumentsFeatureEnabled || isLegalDocumentsFeatureEnabled;

  const [errorMsg, setErrorMsg] = useState<INotifierMessage | undefined>(undefined);

  useEffect(() => {
    if (error) {
      setErrorMsg({ message: error, severity: 'error' } as INotifierMessage);
    } else {
      setErrorMsg(undefined);
    }
  }, [error]);

  useErrorSnackbar(errorMsg);

  useEffect(() => {
    // Ensure the input is focused only after the drawer is open and the component is fully mounted
    if (inputRef.current) {
      const timeoutId = setTimeout(() => {
        inputRef.current?.focus();
      }, 300);

      return () => clearTimeout(timeoutId); // Cleanup timeout on unmount
    }
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      const lastMessage = messages[messages.length - 1];
      const prevLastMessageContentLengthValue = prevLastMessageContentLength.current;

      if (messages.length > prevMessagesLength.current || (lastMessage && lastMessage.content.length !== prevLastMessageContentLengthValue)) {
        messagesEndRef.current.scrollIntoView();
      }

      prevMessagesLength.current = messages.length;
      prevLastMessageContentLength.current = lastMessage?.content.length || 0;
    }
  }, [messages]);

  function submitPrompt() {
    if (isLoading) return;
    showConfig && setShowConfig(false);
    handlePrompt({ content: inputString.trim(), contentTypes: citationsTypes.map(mapCitationSourceTypeToCitationContentType), customPromptSettings: settings });
    inputString && setInputString('');
  }

  function mapCitationSourceTypeToCitationContentType(sourceType: CitationSourceType): CitationContentType {
    switch (sourceType) {
      case CitationSourceType.FasitGuides:
        return CitationContentType.UserGuide;
      case CitationSourceType.IaDoduments:
        return CitationContentType.IaDocument;
      case CitationSourceType.LegalDocuments:
        return CitationContentType.LegalDocument;
      default:
        throw new Error(`Unknown CitationSourceType: ${sourceType}`);
    }
  }

  async function handleChatFeedbackChanged(msg: IChatMessage) {
    await setChatFeedback(msg);
  }

  const handleCheckboxChange = (type: CitationSourceType) => {
    if (citationsTypes.find(x => x === type) !== undefined) {
      setCitationsTypes(citationsTypes.filter(x => x !== type));
    } else {
      setCitationsTypes([...citationsTypes, type]);
    }
  };

  const updateSettingProperty = (property: keyof PromptExecutionSettingsDto, value: any) => {
    setSettings({ ...settings, [property]: value });
  };

  const lastMessageIndex = messages && messages.filter(msg => msg.content).length - 1;
  return (
    <Box className={classes.mainBox}>
      <Box className={classes.customBox}>
        {messages
          .filter(msg => msg.content)
          .map((msg, index) => (
            <span key={index}>
              <ChatMessageComponent message={msg} isAssistant={msg.role === 'Assistent'} onChatFeedbackChanged={handleChatFeedbackChanged} />
              <span ref={lastMessageIndex === index ? messagesEndRef : null} />
            </span>
          ))}
      </Box>
      <Box className={classes.outerInputBox}>
        <Box sx={{ color: ColorPalette.SecondaryTextColor, fontSize: '.650rem', p: 1, whiteSpace: 'pre-line', textAlign: 'right' }}>
          Svarene i denne chat er skabt af en generativ AI. Vær kritisk i brugen af svarene
        </Box>
        {showDataSources && (
          <Box sx={{ marginLeft: 2, marginBottom: 2, color: ColorPalette.SecondaryTextColor }}>
            <Box>Svar baseres på valgte datakilder</Box>
            <Checkbox
              label="Fasit vejledninger"
              checked={citationsTypes.find(x => x === CitationSourceType.FasitGuides) !== undefined}
              onChange={() => handleCheckboxChange(CitationSourceType.FasitGuides)}
            />
            {isIaDocumentsFeatureEnabled && (
              <Checkbox
                label="Interne arbejdsgange"
                checked={citationsTypes.find(x => x === CitationSourceType.IaDoduments) !== undefined}
                onChange={() => handleCheckboxChange(CitationSourceType.IaDoduments)}
              />
            )}
            {isLegalDocumentsFeatureEnabled && (
              <Checkbox
                label="Lovtekster"
                checked={citationsTypes.find(x => x === CitationSourceType.LegalDocuments) !== undefined}
                onChange={() => handleCheckboxChange(CitationSourceType.LegalDocuments)}
              />
            )}
          </Box>
        )}
        <Box sx={{ p: 1, display: 'flex', alignItems: 'center', flexShrink: 0 }}>
          <TextField
            inputRef={inputRef}
            style={{ backgroundColor: ColorPalette.WHITE }}
            readOnly={isLoading}
            label="Stil et spørgsmål"
            variant="outlined"
            value={inputString}
            onChange={e => setInputString(e.target.value.replace(/^[\r\n]+/, ''))}
            onKeyDown={e => e.key === 'Enter' && !e.shiftKey && submitPrompt()}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
            <Tooltip title="Send">
              <Button variant="contained" disabled={isLoading} className={classes.sendButton} onClick={() => submitPrompt()} size="medium">
                <FontAwesomeIcon icon={faArrowAltUp} size="xl" />
              </Button>
            </Tooltip>
          </Box>
        </Box>
        <Box sx={{ marginLeft: '5px', marginBottom: '5px' }}>
          <Tooltip title="Ryd chat">
            <IconButton onClick={() => clearChat()} style={{ right: '0px' }} size="small">
              <TrashIcon />
            </IconButton>
          </Tooltip>
          {isConfigMenuEnabled && (
            <Tooltip title={showConfig ? 'Skjul indstillinger' : 'Vis indstillinger'}>
              <IconButton onClick={() => setShowConfig(!showConfig)} size="small">
                <FontAwesomeIcon icon={faGear} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        {showConfig && (
          <Box sx={{ color: ColorPalette.SecondaryTextColor, fontSize: '.850rem', pl: 1, pr: 1, pb: 1, whiteSpace: 'pre-line' }}>
            <Box sx={{ marginBottom: 2 }}>
              <h3>Promptindstillinger</h3>
            </Box>
            <PromptSettings promptSettings={settings} onUpdateSettings={updateSettingProperty}></PromptSettings>
          </Box>
        )}
      </Box>
    </Box>
  );
}
