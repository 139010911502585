import { useMemo } from 'react';
import { IField } from '../components/query-builder';
import { ISearchField } from '../components/query';
import { getFields } from '../components/query-builder/get-fields';

function getNestedFields(searchFields: ISearchField[], resultType: string | undefined): IField[] {
  if (resultType) {
    const found = searchFields.find(x => x.name === resultType);
    if (found && found.fields) {
      return getFields(found.fields);
    }
  }
  return [];
}

export function useNestedFields(searchFields: ISearchField[], resultType: string | undefined) {
  return useMemo(() => getNestedFields(searchFields, resultType), [searchFields, resultType]);
}
