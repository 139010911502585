import React, { useEffect } from 'react';
import { Drawer, Button, Box, Tooltip, BoxProps } from '@mui/material';
import { faRobot, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorPalette } from '../config';
import { ChatComponent } from './chat';
import { useAiFeature } from '../../hooks/ai-search/use-ai-feature';
import { useShortcut } from '../../hooks/use-shortcut';

export interface IMessageCenterProps {
  sx?: BoxProps['sx'];
}
export function ChatDrawer(props: IMessageCenterProps) {
  const { sx } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  useShortcut({ ctrlKey: true, shortcutKey: '½', onShortcut: () => setIsOpen(!isOpen) });
  const { isAiFeatureEnabled } = useAiFeature('AiSearchChatBot');
  const isChatBotOpenStorageKey = 'FASIT_ISCHATDRAWEROPEN_KEY';

  useEffect(() => {
    const openOnLoad = sessionStorage.getItem(isChatBotOpenStorageKey);
    if (openOnLoad) {
      setIsOpen(true);
    }
  }, []);

  function handleClose() {
    sessionStorage.removeItem(isChatBotOpenStorageKey);
    setIsOpen(!isOpen);
  }

  function handleOpen() {
    sessionStorage.setItem(isChatBotOpenStorageKey, 'true');
    setIsOpen(!isOpen);
  }

  const DrawerComponent = (
    <>
      <Tooltip title="Fasit assistent (ctrl+½)" placement="left" disableInteractive>
        <Button size="large" aria-label="Åbn Fasit assistent" onClick={handleOpen} sx={{ ...sx }}>
          <FontAwesomeIcon icon={faRobot} style={{ color: ColorPalette.WHITE }} />
        </Button>
      </Tooltip>

      <Drawer
        anchor="right"
        open={isOpen}
        onClose={() => handleClose()}
        variant="persistent"
        PaperProps={{
          sx: { width: 850 },
        }}
      >
        <Button
          sx={{
            border: 'none',
            borderBottom: `1px solid ${ColorPalette.LightAsteroidDust}`,
            cursor: 'pointer',
            display: 'flex',
            outline: 'none',
            textAlign: 'left',
            px: 2,
            height: 50,
            justifyContent: 'left',
            '&:hover': {
              backgroundColor: ColorPalette.LightAsteroidDust,
            },
            '&:focus': {
              backgroundColor: ColorPalette.LightAsteroidDust,
            },
          }}
          onClick={(e: any) => handleClose()}
          title="Luk Fasit assistent"
          aria-label="Luk Fasit assistent"
        >
          <FontAwesomeIcon icon={faChevronRight} /> <Box sx={{ display: 'inline-block', ml: 1 }}>Luk Fasit assistent</Box>
        </Button>

        <Box sx={{ height: 'calc(100% - 50px)', overflowY: 'auto', textAlign: 'left' }} onKeyDown={e => e.key === 'Escape' && setIsOpen(!isOpen)}>
          {
            //Workaround to set focus on the chat input field}
            isOpen && <ChatComponent height="100%" />
          }
        </Box>
      </Drawer>
    </>
  );

  return isAiFeatureEnabled ? DrawerComponent : <span />;
}
