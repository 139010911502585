import { units, directions, directionCurrent, defaultOptions, currentDirectionUnits, currentDirectionDefaultOptions } from './relative-date-options';
import { ISelectOption } from '../../../../../../components/select/select-props';

type result = { direction: ISelectOption; duration?: number; unit: ISelectOption };

class ExpressionParser {
  toObjects = (value: string): result => {
    if (typeof value !== 'string') {
      return defaultOptions;
    }
    const values: string[] = value.split('_');
    const direction = directions.find(o => o.value === values[0]);

    if (direction && values) {
      if (direction.value !== directionCurrent) {
        const unit = units.find(o => o.value === values[2]);
        return unit ? { direction, unit, duration: parseInt(values[1], 10) } : defaultOptions;
      }
      const unit = currentDirectionUnits.find(o => o.value === values[1]);
      return unit ? { direction, unit } : currentDirectionDefaultOptions;
    }
    return defaultOptions;
  };

  toText = (value: string): string => {
    const { direction, duration, unit } = this.toObjects(value);
    return value && `${direction.label} ${duration || ''} ${unit.label}`;
  };
}

export const parser: ExpressionParser = new ExpressionParser();
