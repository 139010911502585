import { useState, SetStateAction, Dispatch } from 'react';
import { ISearchRequestBody } from '../models/dtos';
import { useNextEffect } from '../../../hooks';

interface ISearchJsonState {
  searchJson: string;
  searchJsonError?: string;
}

export function useSearchJson(requestBody: ISearchRequestBody): [ISearchJsonState, Dispatch<SetStateAction<ISearchJsonState>>] {
  const [state, setState] = useState({
    searchJson: JSON.stringify(requestBody, null, 2),
  } as ISearchJsonState);
  useNextEffect(() => {
    setState({
      searchJson: JSON.stringify(requestBody, null, 2),
      searchJsonError: undefined,
    });
  }, [requestBody]);
  return [state, setState];
}
