import { GridLocaleText } from '@mui/x-data-grid-pro';
import { daDK as daDKCore } from '@mui/material/locale';
import { LabelDisplayedRowsArgs } from '@mui/material/TablePagination';

export interface Localization {
  components: {
    MuiDataGrid: {
      defaultProps: {
        localeText: Partial<GridLocaleText>;
      };
    };
  };
}

const getGridLocalization = (gridTranslations: Partial<GridLocaleText>, coreTranslations?: any): Localization => ({
  components: {
    MuiDataGrid: {
      defaultProps: {
        localeText: {
          ...gridTranslations,
          MuiTablePagination: coreTranslations?.components?.MuiTablePagination.defaultProps || {},
        },
      },
    },
  },
});

export const daDKGrid: Partial<GridLocaleText> = {
  groupingColumnHeaderName: 'Kolonnegruppe',
  groupColumn: (name: string) => name,
  unGroupColumn: (name: string) => name,
  // Root
  noRowsLabel: 'Ingen rækker',
  noResultsOverlayLabel: 'Ingen resultater',
  errorOverlayDefaultLabel: 'Der skete en fejl.',

  // Density selector toolbar button text
  toolbarDensity: '',
  toolbarDensityLabel: 'Tæthed',
  toolbarDensityCompact: 'Kompakt',
  toolbarDensityStandard: 'Standard',
  toolbarDensityComfortable: 'Luftig',

  // Columns selector toolbar button text
  toolbarColumns: '',
  toolbarColumnsLabel: 'Vælg kolonne',

  // Filters toolbar button text
  toolbarFilters: '',
  toolbarFiltersLabel: 'Filtre',
  toolbarFiltersTooltipHide: 'Skjul filtre',
  toolbarFiltersTooltipShow: 'Filter',
  toolbarFiltersTooltipActive: count => (count !== 1 ? `${count} aktive filtre` : `${count} aktivt filter`),

  // Export selector toolbar button text
  toolbarExport: '',
  toolbarExportLabel: 'Eksporter',
  toolbarExportCSV: 'Download som CSV',
  toolbarExportPrint: 'Udskriv',

  // Columns panel text
  columnsPanelTextFieldLabel: 'Find kolonne',
  columnsPanelTextFieldPlaceholder: 'Kolonnenavn',
  columnsPanelDragIconLabel: 'Reorder kolonne',
  columnsPanelShowAllButton: 'Vis alle',
  columnsPanelHideAllButton: 'Skjul alle',

  // Filter panel text
  filterPanelAddFilter: 'Tilføj filter',
  filterPanelDeleteIconLabel: 'Slet',
  filterPanelOperators: 'Operatorer',
  filterPanelOperatorAnd: 'Og',
  filterPanelOperatorOr: 'Eller',
  filterPanelColumns: 'Kolonne',
  filterPanelInputLabel: 'Værdi',
  filterPanelInputPlaceholder: 'Filter værdi',

  // Filter operators text
  filterOperatorContains: 'indeholder',
  filterOperatorEquals: 'lig med',
  filterOperatorStartsWith: 'Begynder med',
  filterOperatorEndsWith: 'Ender med',
  filterOperatorIs: 'er',
  filterOperatorNot: 'er ikke',
  filterOperatorAfter: 'efter',
  filterOperatorOnOrAfter: 'på eller efter',
  filterOperatorBefore: 'før',
  filterOperatorOnOrBefore: 'på eller før',
  filterOperatorIsEmpty: 'indeholder ikke data',
  filterOperatorIsNotEmpty: 'indeholder data',
  filterOperatorIsAnyOf: 'indeholdt i',

  // Filter values text
  filterValueAny: 'Alle',
  filterValueTrue: 'Ja',
  filterValueFalse: 'Nej',

  // Column menu text
  columnMenuLabel: 'Menu',
  columnMenuShowColumns: 'Vis Kolonner',
  columnMenuFilter: 'Filter',
  columnMenuHideColumn: 'Skjul',
  columnMenuUnsort: 'Fjern sortering',
  columnMenuSortAsc: 'Sorter stigende',
  columnMenuSortDesc: 'Sorter faldende',

  // Column header text
  columnHeaderFiltersTooltipActive: count => (count !== 1 ? `${count} aktive filtre` : `${count} aktivt filter`),
  columnHeaderFiltersLabel: 'Vis filtre',
  columnHeaderSortIconLabel: 'Sorter',

  // Rows selected footer text
  footerRowSelected: count => (count !== 1 ? `${count.toLocaleString()} rækker valgt` : `${count.toLocaleString()} række valgt`),

  // Total rows footer text
  footerTotalRows: 'Totale rækker:',

  // Total visible rows footer text
  footerTotalVisibleRows: (visibleCount, totalCount) => `${visibleCount.toLocaleString()} af ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: 'Afkrydsningsvalg',

  // Boolean cell text
  booleanCellTrueLabel: 'true',
  booleanCellFalseLabel: 'false',

  // Actions cell more text
  actionsCellMore: 'mere',

  // Column pinning text
  pinToLeft: 'Fastgør til venstre',
  pinToRight: 'Fastgør til højre',
  unpin: 'Frigiv',

  // Tree Data
  treeDataGroupingHeaderName: 'Gruppering',
  treeDataExpand: 'Vis underelementer',
  treeDataCollapse: 'Skjul underelementer',

  // Used core components translation keys
  MuiTablePagination: {
    labelRowsPerPage: 'Rækker pr. side',
    labelDisplayedRows: (paginationInfo: LabelDisplayedRowsArgs) => `${paginationInfo.from} - ${paginationInfo.to} af ${paginationInfo.count} (side ${paginationInfo.page + 1})`,
    nextIconButtonProps: {
      title: 'Næste side',
    },
    backIconButtonProps: {
      title: 'Forrige side',
    },
  },
};

export const daDK: Localization = getGridLocalization(daDKGrid, daDKCore);
