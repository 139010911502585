/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
export function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export const getListStyle = (isDraggingOver: boolean): {} => ({
  background: isDraggingOver ? '#e9edf1' : 'transparent',
  padding: '0 10px 5px 10px',
  margin: '0 -20px',
});

export const getItemStyle = (draggableStyle: any, isDragging: boolean): {} => {
  const grid = 8;

  return {
    userSelect: 'none',
    padding: 10,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? '#c4dee2' : 'transparent',
    ...draggableStyle,
  };
};
