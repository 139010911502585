/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import { CancelToken } from 'axios';
import { GetNegativeEventUrlResponse } from '../../../../../api-service/clients';
import { moduleClientFactory } from '../../../../../api-service/module-client-factory';
import { useApiOnRender } from '../../../../../hooks';
import { AbsolutelyCentered } from '../../../../../components/absolutely-centered/absolutely-centered';
import { CircularProgress } from '../../../../../components/circular-progress/circular-progress';
import { ConfirmDialog } from '../../../../../components/confirm-dialog/confirm-dialog';
import { CssBaseDecorator } from '../../../../../components/cssbasedecorator/css-base-decorator';

export function NegativeEventRerouter() {
  const { negativeEventId } = useParams<{ negativeEventId: string }>();

  const citizenAvailabilityClient = moduleClientFactory.createCitizenAvailabilityClient();
  const getNegativeEventUrlApi = useApiOnRender(
    (cancelToken: CancelToken, id: string) => citizenAvailabilityClient.getNegativeEventUrl({ recordId: id }, cancelToken),
    {} as GetNegativeEventUrlResponse,
    negativeEventId
  );

  const negativeEventUrl = getNegativeEventUrlApi.result?.negativeEventUrl;
  return (
    <CssBaseDecorator>
      {getNegativeEventUrlApi.isLoading ? (
        <AbsolutelyCentered>
          <CircularProgress />
        </AbsolutelyCentered>
      ) : (
        <>
          <ConfirmDialog
            type="alert"
            title="Noget gik galt ved hentning af den negative hændelses URL, vinduet lukkes"
            open={getNegativeEventUrlApi.isError}
            onConfirmClick={() => {
              window.close();
            }}
            confirmButtonText="Ok"
          />
          {negativeEventUrl && window.location.assign(negativeEventUrl)}
        </>
      )}
    </CssBaseDecorator>
  );
}
