import React, { useState, useCallback, useMemo, useEffect } from 'react';

interface IOmnibarContext {
  showOmnibar: boolean;
  toggleOmnibar: (forcedState?: boolean) => void;
}

export const OmnibarContext = React.createContext<IOmnibarContext | undefined>(undefined);

export function OmnibarProvider({ children }: { children?: React.ReactNode }) {
  const [showOmnibar, setShowOmnibar] = useState(false);

  const toggleOmnibar = useCallback(
    (forcedState?: boolean) => {
      const newState = forcedState !== undefined ? forcedState : !showOmnibar;
      setShowOmnibar(newState);
    },
    [showOmnibar]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.code === 'Space') {
        toggleOmnibar();
      }
      if (event.code === 'Escape') {
        toggleOmnibar(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [toggleOmnibar]);

  const value = useMemo(() => ({ showOmnibar, toggleOmnibar }), [showOmnibar, toggleOmnibar]);

  return <OmnibarContext.Provider value={value}>{children}</OmnibarContext.Provider>;
}

export function useOmnibar() {
  const context = React.useContext(OmnibarContext);
  if (context === undefined) {
    throw new Error('useOmnibar must be used within a OmnibarProvider');
  }
  return context;
}
