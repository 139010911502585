import { OmnibarEntry } from '../omnibar-interfaces';

const functions: OmnibarEntry[] = [
  {
    id: '9d0358c6-4dfc-4079-b062-8c8784357a3c',
    label: 'Udbakke',
    context: 'none',
    group: 'Udbakke',
    url: '/outbox',
  },
];

export default functions;
