/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { InputAdornment, IconButton } from '@mui/material';
import { FilledTextFieldProps, TextFieldProps } from '@mui/material/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import { TextField } from '../text-field';

export interface IDatePickerCustomInputProps {
  onClick?: (event: any) => void;
  onChange?: (event: any) => void;
  className?: string;
  label?: FilledTextFieldProps['label'];
  value?: string;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  error?: boolean;
  errorText?: React.ReactNode;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  warningText?: string;
  fullWidth?: boolean;
  variant?: TextFieldProps['variant'];
  helperText?: TextFieldProps['helperText'];
}

// This component must be a class component as react-datepicker uses ref to component
// eslint-disable-next-line react/prefer-stateless-function
export class DatePickerCustomInput extends React.Component<IDatePickerCustomInputProps> {
  public render() {
    const {
      className,
      label,
      onClick,
      onChange,
      onKeyDown,
      value,
      error,
      errorText,
      disabled,
      required,
      readOnly,
      warningText,
      variant = 'filled',
      fullWidth = false,
      helperText,
    } = this.props;

    let classes = 'date-picker__text-field';

    if (className) {
      classes = `${classes} ${className}`;
    }

    if (fullWidth) {
      classes = `${classes} full-width`;
    }

    return (
      <TextField
        className={classes}
        label={label}
        onClick={onClick}
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton key="datePickerCalendarIcon" size="large">
                <FontAwesomeIcon icon={faCalendarAlt} size="xs" />
              </IconButton>
            </InputAdornment>
          ),
          readOnly,
        }}
        error={error}
        errorText={error && (errorText || 'Indtast en gyldig dato')}
        helperText={warningText || helperText}
        disabled={disabled}
        required={required}
        readOnly={readOnly}
        fullWidth={fullWidth}
        variant={variant}
        multiline={false}
        inputProps={{
          autoComplete: 'off',
        }}
      />
    );
  }
}
