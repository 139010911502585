import { useEffect } from 'react';

interface IUseShortcut {
  shortcutKey: string;
  onShortcut: () => void;
  ctrlKey?: boolean;
  altKey?: boolean;
}

export function useShortcut(props: IUseShortcut) {
  const { shortcutKey, onShortcut, ctrlKey = false, altKey = false } = props;
  if (!ctrlKey && !altKey) {
    throw new Error('At least one of ctrlKey or altKey must be true');
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    // Check for the correct combination of modifier keys and shortcut key
    const isCtrlCorrect = ctrlKey ? event.ctrlKey : true;
    const isAltCorrect = altKey ? event.altKey : true;

    if (isCtrlCorrect && isAltCorrect && event.key === shortcutKey) {
      event.preventDefault(); // Prevent the default browser action
      onShortcut();
    }
  };

  useEffect(() => {
    // Attach the event listener
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [ctrlKey, altKey, shortcutKey, onShortcut]);
}
