/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { QueryBuilderFieldOptions } from '../../query/query-builder-field-options';
import { Checkbox } from '../../../../../components/checkbox/checkbox';
import { TextField } from '../../../../../components/text-field/text-field';
import { IntegerField } from '../../../../../components/number-fields/number-field';
import { DecimalField } from '../../../../../components/number-fields/number-field';
import { IValueEditorProps } from './value-editor-props';
import { DynamicDatePicker } from '../../dynamic-date-picker';

function getBoolean(value: string | number) {
  switch (value) {
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'yes':
      return true;
    default:
      return false;
  }
}
// START NOSONAR
type ChangeEventHandlerSignature = (e: React.ChangeEvent<HTMLInputElement>, checked?: boolean) => void;
// END NOSONAR

export function singleValueEditor(
  key: number | undefined,
  value: string,
  props: IValueEditorProps,
  onChange: ChangeEventHandlerSignature,
  onInternalStateChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
) {
  const { disabled, type, title, error } = props;

  const handleOnNumberChange = (event: React.FocusEvent<HTMLInputElement>) => {
    onChange({
      target: {
        value: event.target.value,
      },
    } as any);
  };

  switch (type) {
    case QueryBuilderFieldOptions.Date: {
      const handleChange = (date: any) => {
        onChange({
          target: {
            value: date,
          },
        } as any);
      };

      return <DynamicDatePicker label="Dato" value={value} onChange={handleChange} disabled={disabled} />;
    }

    case QueryBuilderFieldOptions.Integer:
      return (
        <IntegerField
          key={key}
          disabled={disabled}
          value={value}
          onBlur={handleOnNumberChange}
          fullWidth={false}
          label={title}
          thousandSeparator=""
          error={!!error}
          errorText={error}
        />
      );

    case QueryBuilderFieldOptions.Double:
      return <DecimalField key={key} disabled={disabled} value={value} onBlur={handleOnNumberChange} fullWidth={false} label={title} error={!!error} errorText={error} />;

    case QueryBuilderFieldOptions.Boolean:
      return <Checkbox key={key} disabled={disabled} checked={getBoolean(value)} label={title} onChange={onChange} />;

    default:
      return (
        <TextField
          key={key}
          disabled={disabled}
          value={value}
          onChange={onInternalStateChange}
          onBlur={onChange as any}
          fullWidth={false}
          multiline={false}
          label={title}
          error={!!error}
          errorText={error}
        />
      );
  }
}
