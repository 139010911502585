import MuiGrid, { GridSize } from '@mui/material/Grid';
import * as React from 'react';
import { ICellProps } from './cell-props';
import './cell.scss';

export const Cell: React.FunctionComponent<ICellProps> = ({ children, size, textAlign, verticalAlign, ...otherProps }) => {
  function classes() {
    let classNames: string = 'cell';

    if (textAlign !== 'left') {
      classNames += ` cell--text-align--${textAlign}`;
    }
    if (verticalAlign !== 'top') {
      classNames += ` cell--vertical-align--${verticalAlign}`;
    }

    return classNames;
  }

  let sizesObj = {};

  if (!!size) {
    let columnSpan: GridSize = 12;

    switch (size) {
      case 'full':
        columnSpan = 12;
        break;
      case 'half':
        columnSpan = 6;
        break;
      case 'thirds':
        columnSpan = 4;
        break;
      case 'quarters':
        columnSpan = 3;
        break;
      case 'two-thirds':
        columnSpan = 8;
        break;
      case 'three-quarters':
        columnSpan = 9;
        break;
      default:
        columnSpan = size as GridSize;
        break;
    }

    sizesObj = {
      xs: columnSpan,
    };
  } else {
    sizesObj = {
      xs: 12,
      md: 8,
      lg: 6,
      xl: 5,
    };
  }

  return (
    <MuiGrid {...otherProps} item={true} {...sizesObj} className={classes()}>
      {children}
    </MuiGrid>
  );
};

Cell.defaultProps = {
  textAlign: 'left',
  verticalAlign: 'top',
};
