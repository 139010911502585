import React, { useState, useEffect } from 'react';
import { IBulkActionApiService, BulkActionApiService } from '../bulk-action/bulkaction-api-service';
import { IBulkAction } from '../bulk-action/bulkaction';

const bulkActionService: IBulkActionApiService = new BulkActionApiService();

const BulkActionsContext = React.createContext([] as IBulkAction[]);

type BulkActionsProviderProps = {
  children: React.ReactNode;
};

export function BulkActionsProvider({ children }: BulkActionsProviderProps) {
  const [state, setState] = useState([] as IBulkAction[]);

  useEffect(() => {
    async function fetchBulkActions() {
      const bulkActions = await bulkActionService.getBulkActions();
      setState(bulkActions);
    }
    fetchBulkActions();
  }, []);

  return <BulkActionsContext.Provider value={state}>{children}</BulkActionsContext.Provider>;
}

export const useBulkActions = (): IBulkAction[] => React.useContext(BulkActionsContext);
