/* eslint-disable react/no-array-index-key */
import { useState, useContext, useEffect } from 'react';
import { UserData } from 'react-oidc';
import { v4 as uuid } from 'uuid';
import { getPrompt, IPrompt } from '../../api-service/clients/ai-search-client';
import { UserChatMessageDto, Vote } from '../../api-service/clients';
import { moduleClientFactory } from '../../api-service/module-client-factory';
import { formatDateTime } from '../../utilities';

export interface IChatMessage {
  content: string;
  role?: 'User' | 'Assistent';
  citations?: ICitation[];
  chatCorrelationId?: string; // Correlates User and Assistent messages
  vote?: Vote;
  qualityComment?: string;
  timestamp?: string;
  userName?: string;
}

export interface ICitation {
  link: string;
  text: string;
  title: string;
  id: string;
  CitationSourceType: number;
}
export enum CitationSourceType {
  FasitGuides = 0,
  IaDoduments = 1,
  LegalDocuments = 2,
}

export const useChat = () => {
  const [messages, setMessages] = useState<IChatMessage[]>([{ content: '' }]);
  const [citationsTypes, setCitationsTypes] = useState<CitationSourceType[]>([CitationSourceType.FasitGuides]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const context = useContext(UserData);
  const userDisplayName = context?.user?.profile?.name ?? 'Ukendt bruger';
  const chatMessageStorageKey = 'FASIT_CHATMESSAGE_KEY';
  const citationTypesStorageKey = 'FASIT_CITATIONTYPES_KEY';

  useEffect(() => {
    messages.length > 1 && sessionStorage.setItem(chatMessageStorageKey, JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    const datastr = sessionStorage.getItem(chatMessageStorageKey);
    if (datastr) {
      try {
        setMessages(JSON.parse(datastr));
      } catch (e) {
        console.error(e);
      }
    }

    const citationTypesStr = localStorage.getItem(citationTypesStorageKey);
    try {
      if (citationTypesStr) {
        setCitationsTypes(JSON.parse(citationTypesStr));
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(citationTypesStorageKey, JSON.stringify(citationsTypes));
  }, [citationsTypes]);

  const clearChat = () => {
    setMessages([{ content: '' }]);
    sessionStorage.removeItem(chatMessageStorageKey);
  };

  const handlePrompt = async (prompt: IPrompt) => {
    setIsLoading(true);
    error && setError(undefined);
    const chatCorrelationId = uuid();
    prompt &&
      prompt.content &&
      setMessages(prev => [
        ...prev,
        { content: prompt.content || '', role: 'User', chatCorrelationId, timestamp: formatDateTime(new Date().toISOString()), userName: userDisplayName },
        { content: '', role: 'Assistent', chatCorrelationId },
      ]);

    try {
      const user = await context.userManager?.getUser();
      const response = await getPrompt(prompt, user?.access_token as string);

      if (!response?.body || !response.ok) {
        throw new Error();
      }

      const reader = response.body.getReader();
      await readStream(reader);
    } catch (errorMessage) {
      setError('Der skete en fejl under læsning af data');
    } finally {
      setIsLoading(false);
    }
  };

  const readStream = async (reader: ReadableStreamDefaultReader<Uint8Array>) => {
    const decoder = new TextDecoder();
    let buffer = '';

    const processChunk = ({ done, value }: ReadableStreamReadResult<Uint8Array>) => {
      if (done) {
        setIsLoading(false);
        return;
      }

      const chunkText = decoder.decode(value, { stream: true });
      buffer += chunkText;

      const lastIncompleteLine = processBuffer(buffer);
      buffer = lastIncompleteLine;

      reader.read().then(processChunk);
    };

    reader.read().then(processChunk);
  };

  const processBuffer = (buffer: string): string => {
    const lines = buffer.split('\n');
    const lastLine = lines.pop() ?? ''; // Keep the last incomplete part in buffer

    const newChatMessages = lines.filter(line => line.trim()).map(parseJSONLine);
    const content = newChatMessages
      .filter(Boolean)
      .map(msg => msg?.content)
      .join('');
    const concatMessage = { content, Role: 'Assistent', citations: newChatMessages[0]?.citations, chatCorrelationId: newChatMessages[0]?.chatCorrelationId };
    updateLastMessageContentAndCitations(concatMessage);
    return lastLine;
  };

  const parseJSONLine = (line: string): IChatMessage | null => {
    try {
      const message = JSON.parse(line);
      return {
        content: message.Content,
        userName: 'Fasit assistent',
        citations: message.Citations?.map(
          (citation: any) => ({ id: citation.Id, link: citation.Link, title: citation.Title, text: citation.Text, CitationSourceType: citation.CitationContentType } as ICitation)
        ),
      } as IChatMessage;
    } catch (errorMessage) {
      setError('Der skete en fejl under læsning af data');
      console.error('Error parsing JSON:', errorMessage, line);
      return null;
    }
  };

  const updateLastMessageContentAndCitations = (message: IChatMessage) => {
    setMessages(prevMessages => {
      if (prevMessages.length === 0) {
        return [message];
      }

      const lastMessage = { ...prevMessages[prevMessages.length - 1] };
      lastMessage.content += message.content;
      lastMessage.userName = 'Fasit assistent';
      lastMessage.timestamp = formatDateTime(new Date().toISOString());
      if (message.citations) {
        lastMessage.citations = message.citations;
      }

      return [...prevMessages.slice(0, -1), lastMessage];
    });
  };

  const updateMessageFeedback = (chatFeedbackMsg: IChatMessage) => {
    setMessages(prevMessages =>
      prevMessages.map(msg =>
        msg.chatCorrelationId === chatFeedbackMsg.chatCorrelationId && msg.role === 'Assistent'
          ? { ...msg, qualityComment: chatFeedbackMsg.qualityComment, vote: chatFeedbackMsg.vote }
          : msg
      )
    );
  };
  const setChatFeedback = async (chatFeedbackMsg: IChatMessage) => {
    const correlatedMessages = messages.filter(msg => msg.chatCorrelationId === chatFeedbackMsg.chatCorrelationId);

    if (correlatedMessages.length === 2) {
      const userMessage = correlatedMessages.find(msg => msg.role === 'User');
      let assistentMessage = correlatedMessages.find(msg => msg.role === 'Assistent');

      if (!userMessage || !assistentMessage) {
        setError('Der skete en fejl');
        return;
      }

      assistentMessage = { ...assistentMessage, qualityComment: chatFeedbackMsg.qualityComment, vote: chatFeedbackMsg.vote };

      const dto: UserChatMessageDto = {
        id: assistentMessage.chatCorrelationId,
        qualityComment: assistentMessage.qualityComment,
        question: userMessage.content,
        response: assistentMessage.content,
        vote: assistentMessage.vote,
      };

      const client = moduleClientFactory.createAiSearchClient();

      await client.createOrUpdateUserChatMessage({ userChatMessageDto: dto });

      updateMessageFeedback(assistentMessage);
    } else {
      setError('Der skete en fejl');
    }
  };

  return { messages, isLoading, handlePrompt, error, clearChat, setChatFeedback, citationsTypes, setCitationsTypes };
};
