import React from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { secureAxiosInstanceWithErrorMessageInterceptor } from '../api-service/secure-axios-instance';
import { useNotifier } from '../components/notifier/notifier-context';
import { FasitProblemDetails } from '../api-service/clients';
import { useFeatureToggles } from '../feature-toggles';

export function ErrorMessageInterceptor() {
  const { dispatch } = useNotifier();
  const { isFeatureEnabled } = useFeatureToggles();

  React.useEffect(() => {
    secureAxiosInstanceWithErrorMessageInterceptor.interceptors.response.use(
      (response: AxiosResponse) =>
        // Handle successful response
        response,
      (error: AxiosError<FasitProblemDetails>) => {
        // Handle error
        let isDispatched = false;
        if (isFeatureEnabled('UseDeveloperDetailForErrorsInControllers')) {
          try {
            const errorObject = { ...error };
            const devDetails = errorObject.response?.data?.developerDetail;
            if (devDetails?.includes('"systemName":"Dfdg"')) {
              const responseString = devDetails.split('Response: ')[1];
              const responseObject = JSON.parse(responseString);
              const { helpLink, messageTitle, correlationIdentifier, status, detail, title, timestamp } = responseObject;
              dispatch({ type: 'notify', payload: { correlationIdentifier, severity: 'error', status, detail, title, timestamp, helpLink, messageTitle } });
              isDispatched = true;
            }
          } catch {
            // Resume flow on error
          }
        }

        const { response: { data: { helpLink, messageTitle, correlationIdentifier, status, detail, title, timestamp } } = { data: {} } } = error;
        if (correlationIdentifier && !isDispatched) {
          dispatch({ type: 'notify', payload: { correlationIdentifier, severity: 'error', status, detail, title, timestamp, helpLink, messageTitle } });
        }
        return Promise.reject(error);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}
