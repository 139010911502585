/* eslint-disable max-classes-per-file */
import { ISearchRequestBody } from '../../../models/dtos';
import { Cardinality } from '../../query-builder/cardinality-options';
import { INestedRuleBase, IRuleBase } from '../../query-builder/rule-interface';
import { isHavingRule } from '../../query-builder/having-rule';
import { isNestedRule } from '../../query-builder/rule-functions';
import { IResultTypeOption, IResultTypeUsage } from './result-type-options';
import { ISearchSchema } from '../query-api-service';
import { ISearchField, UiHint } from '../search-field';
import { IBulkAction } from '../../../bulk-action';
import { QueryTypeOptions } from '../query-type-options';

export interface IRowAction {
  resultType: string;
  displayName: string;
  url: string;
  openNewWindow: boolean;
  entityType: string;
}

export interface ISearchResultTypeProvider {
  getSearchResultTypes(universe: ISearchSchema, searchFields: ISearchField[]): Array<IResultTypeOption>;
  getResultTypeUsage(resultTypes: IResultTypeOption[], searchRequestBody: ISearchRequestBody): IResultTypeUsage[];
}

export function getBulkActions(bulkActions: IBulkAction[], resultType: string): IBulkAction[] {
  return bulkActions.filter(action => action.resultType.toLocaleLowerCase() === resultType.toLocaleLowerCase());
}

export function getDisplayName(rowActions: IRowAction[], resultType: string): string | null {
  const singularis = resultType.endsWith('s') ? resultType.substr(0, resultType.length - 1) : resultType;
  const pluralis = `${singularis}s`;
  const rowAction = rowActions.find(
    action =>
      action.resultType.localeCompare(singularis, undefined, { sensitivity: 'base' }) === 0 || action.resultType.localeCompare(pluralis, undefined, { sensitivity: 'base' }) === 0
  );

  return rowAction ? rowAction.displayName : '';
}

class ResultTypesList {
  public resultTypes: IResultTypeOption[] = [];

  constructor(label: string, fields: ISearchField[]) {
    this.resultTypes.push({ label, fields } as IResultTypeOption);
  }

  add(name: string, label: string, fields: ISearchField[]) {
    this.resultTypes.push({ name, label, fields } as IResultTypeOption);
  }

  addLists(searchFields: ISearchField[]): void {
    for (const field of searchFields.filter(x => x.uiHint !== UiHint.Hide && x.isList && !x.toBeDeleted)) {
      this.add(field.name, field.displayName, field.fields || []);
      if (field.fields) {
        this.addLists(field.fields);
      }
    }
  }
}

interface IResultTypeCardinality {
  name: string;
  cardinality: string;
}

export class SearchResultTypeProvider implements ISearchResultTypeProvider {
  // eslint-disable-next-line class-methods-use-this
  public getSearchResultTypes(universe: ISearchSchema, searchFields: ISearchField[]): Array<IResultTypeOption> {
    if (universe && searchFields && searchFields.length > 0) {
      const list = new ResultTypesList(universe.displayName, searchFields);
      list.addLists(searchFields);
      return list.resultTypes;
    }
    return [];
  }

  public getResultTypeUsage(resultTypes: IResultTypeOption[], searchRequestBody: ISearchRequestBody): IResultTypeUsage[] {
    if (resultTypes) {
      if (searchRequestBody && searchRequestBody.queryType === QueryTypeOptions.FasitDsl) {
        const typesInUse = this.getTypesInUse(searchRequestBody.query);
        return resultTypes.map(x => {
          const hasAny = x.name && typesInUse.some(use => use.name === x.name && use.cardinality === Cardinality.Any);
          const hasNone = x.name && typesInUse.some(use => use.name === x.name && use.cardinality === Cardinality.None);
          return {
            ...x,
            inUse: hasAny,
            blocked: hasNone && !hasAny,
          } as IResultTypeUsage;
        });
      }
      return resultTypes.map(x => ({ ...x, inUse: false, blocked: false }));
    }
    return [];
  }

  private getTypesInUse(nested: INestedRuleBase): IResultTypeCardinality[] {
    const resultTypeCardinalities: IResultTypeCardinality[] = [];
    if (nested && nested.rules) this.addResultTypes(resultTypeCardinalities, nested.rules);
    return resultTypeCardinalities;
  }

  private addResultTypes(resultTypeUsage: IResultTypeCardinality[], rules: IRuleBase[]): void {
    for (const rule of rules) {
      if (isHavingRule(rule)) {
        const resultType = rule.type;
        if (resultType) resultTypeUsage.push({ name: resultType, cardinality: rule.cardinality });
      }

      if (isNestedRule(rule)) {
        this.addResultTypes(resultTypeUsage, rule.rules);
      }
    }
  }
}
