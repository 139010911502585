/* eslint-disable no-nested-ternary */
import { faBullseyeArrow } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { ColorPalette, TypographySettings } from '../config';
import { useOmnibar } from '../omnibar/omnibar-provider';
import FasitLogoFile from './fasit-logo.svg';
import MessageCenter from '../message-center/message-center';
import { isFeatureEnabled } from '../../feature-toggles';
import { ChatDrawer } from '../chat/chat-drawer';

export function FasitLogo() {
  return (
    <SVG
      src={FasitLogoFile}
      style={{
        height: 16,
        width: 'auto',
        fill: ColorPalette.WHITE,
      }}
      title="Fasit"
      description="Fasit logo"
      cacheRequests
    />
  );
}

const IconButtonCSS = { width: 35, height: 35, flex: 0, minWidth: 35, fontSize: 19, p: 0, ml: 0.5, display: 'block' };

export function AppHeader(props: React.PropsWithChildren<unknown>) {
  const { children } = props;
  const { toggleOmnibar } = useOmnibar();

  return (
    <Box
      sx={{
        alignContent: 'space-between',
        alignItems: 'center',
        backgroundColor: ColorPalette.DeepGalaxy,
        color: ColorPalette.WHITE,
        display: 'flex',
        fontFamily: TypographySettings.DefaultFontFamily,
        height: 50,
        px: 2,
      }}
      role="banner"
    >
      <a href="/">
        <FasitLogo />
      </a>
      <Box
        sx={{
          flex: 1,
          fontSize: TypographySettings.DefaultFontSize,
          lineHeight: 1.25,
          textAlign: 'right',
          marginRight: 3,
          justifyContent: 'flex-end',
          display: 'flex',
        }}
      >
        <Box>{children}</Box>

        <MessageCenter sx={{ ...IconButtonCSS }} />
        <ChatDrawer sx={{ ...IconButtonCSS }} />
        <Tooltip title="ctrl+mellemrum" placement="left" disableInteractive>
          <Button size="large" aria-label="Åbn Omnibar" onClick={() => toggleOmnibar(true)} sx={{ ...IconButtonCSS }}>
            <FontAwesomeIcon
              icon={faBullseyeArrow}
              style={
                {
                  '--fa-primary-color': ColorPalette.HotAurora,
                  '--fa-primary-opacity': 1,
                  '--fa-secondary-color': ColorPalette.WHITE,
                  '--fa-secondary-opacity': 0.8,
                } as React.CSSProperties
              }
            />
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
}
