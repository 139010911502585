/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef } from 'react';
import axios, { CancelTokenSource, CancelToken } from 'axios';

export enum ApiStatus {
  initial,
  waiting,
  success,
  error,
  canceled,
}

export interface ApiState<R, E = any> {
  status: ApiStatus;
  result: R;
  error?: E;
}

export interface IUseApiResult<T extends any[], R, E = any> extends ApiState<R, E> {
  invoke: (...args: T) => Promise<ApiState<R, E>>;
  isLoading: boolean;
  isReady: boolean;
  isError: boolean;
  isCanceled: boolean;
}

export function useApi<T extends any[], R, E = any>(asyncFunction: (cancelToken: CancelToken, ...args: T) => Promise<R>, initialValue: R): IUseApiResult<T, R, E> {
  const [state, setState] = useState<ApiState<R, E>>({
    status: ApiStatus.initial,
    result: initialValue,
  });
  const { status } = state;
  const isMountedRef = useRef<boolean>(true);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  async function invoke(...args: T): Promise<ApiState<R, E>> {
    let localState: ApiState<R, E> = state;

    function saveState(s: ApiState<R, E>) {
      localState = s;
      setState(s);
    }
    const cancelCallMessage = 'call canceled';
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(cancelCallMessage);
    }
    if (isMountedRef.current) {
      cancelTokenSourceRef.current = axios.CancelToken.source();
      saveState({
        status: ApiStatus.waiting,
        result: initialValue,
      });
      try {
        const result = await asyncFunction(cancelTokenSourceRef.current.token, ...args);

        isMountedRef.current &&
          saveState({
            status: ApiStatus.success,
            result,
          });
      } catch (error) {
        isMountedRef.current &&
          saveState({
            status: error.message === cancelCallMessage ? ApiStatus.canceled : ApiStatus.error,
            result: initialValue,
            error: error.message === cancelCallMessage ? undefined : error,
          });
      }
    }
    cancelTokenSourceRef.current = undefined;
    return localState;
  }

  useEffect(
    () => () => {
      isMountedRef.current = false;
      if (cancelTokenSourceRef?.current) {
        cancelTokenSourceRef.current.cancel('unloaded');
      }
    },
    []
  );

  return {
    ...state,
    invoke,
    isLoading: status === ApiStatus.waiting,
    isReady: status === ApiStatus.success,
    isError: status === ApiStatus.error,
    isCanceled: status === ApiStatus.canceled,
  };
}

export interface IUseApiOnRenderResult<T extends any[], R, E = any> extends ApiState<R, E> {
  reload: (...args: T) => void;
  isLoading: boolean;
  isReady: boolean;
  isError: boolean;
  isCanceled: boolean;
}

export function useApiOnRender<T extends any[], R, E = any>(
  asyncFunction: (cancelToken: CancelToken, ...args: T) => Promise<R>,
  initialValue: R,
  ...args: T
): IUseApiOnRenderResult<T, R, E> {
  const [state, setState] = useState<ApiState<R, E>>({
    status: ApiStatus.initial,
    result: initialValue,
  });
  const { status } = state;

  const isMountedRef = useRef<boolean>(true);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  function reload(...reloadArgs: T) {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel('reload');
    }
    async function performAsync() {
      if (isMountedRef.current) {
        cancelTokenSourceRef.current = axios.CancelToken.source();
        setState({
          status: ApiStatus.waiting,
          result: initialValue,
        });
        try {
          const result = await asyncFunction(cancelTokenSourceRef.current.token, ...reloadArgs);

          isMountedRef.current &&
            setState({
              status: ApiStatus.success,
              result,
            });
        } catch (error) {
          isMountedRef.current &&
            setState({
              status: ApiStatus.error,
              result: initialValue,
              error,
            });
        }
      }
      cancelTokenSourceRef.current = undefined;
    }

    performAsync();
  }

  useEffect(
    () => () => {
      // Effect cleanup function should only be called ONCE for this component (not when args change).
      isMountedRef.current = false;
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel('unloaded');
      }
    },
    []
  );

  // Effect that invokes API every time 'args' change.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reload(...args), args);

  return {
    ...state,
    reload,
    isLoading: status === ApiStatus.waiting,
    isReady: status === ApiStatus.success,
    isError: status === ApiStatus.error,
    isCanceled: status === ApiStatus.canceled,
  };
}

export function useApiMock<T extends any[], R, E = any>(mockFunction: (...args: T) => R, initialValue: R): IUseApiResult<T, R, E> {
  const [state, setState] = useState<ApiState<R, E>>({
    status: ApiStatus.initial,
    result: initialValue,
  });

  const { status } = state;
  const isMountedRef = useRef<boolean>(true);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  async function invoke(...args: T): Promise<ApiState<R, E>> {
    console.log('mock api call');

    if (isMountedRef.current) {
      setState({
        status: ApiStatus.waiting,
        result: initialValue,
      });

      // Mimic async behavior for consistency
      setTimeout(() => {
        const result = mockFunction(...args);
        isMountedRef.current &&
          setState({
            status: ApiStatus.success,
            result,
          });
      }, 0);
    }
    return state;
  }

  useEffect(
    () => () => {
      isMountedRef.current = false;
      if (cancelTokenSourceRef?.current) {
        cancelTokenSourceRef.current.cancel('unloaded');
      }
    },
    []
  );

  return {
    ...state,
    invoke,
    isLoading: status === ApiStatus.waiting,
    isReady: status === ApiStatus.success,
    isError: status === ApiStatus.error,
    isCanceled: status === ApiStatus.canceled,
  };
}
