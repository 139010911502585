import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { AbsolutelyCentered } from './components/absolutely-centered';
import { ColorPalette } from './components/config/color-palette';
import { TypographySettings } from './components/config/typography-settings';
import { DefaultLayout, StandaloneLayout } from './components/layout/default-layout';
import { TabContent } from './components/tab-content';
import { useMetaTitle } from './hooks';

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      flexDirection: 'column',
      zIndex: -1,
    },
    header: {
      color: ColorPalette.DeepGalaxy,
      fontFamily: TypographySettings.AlternativeFontFamily,
      fontSize: '12rem',
      fontWeight: 300,
      lineHeight: 1,
      margin: 0,
    },
    content: {
      fontFamily: TypographySettings.AlternativeFontFamily,
      fontSize: '1rem',
      margin: 0,
    },
  })
);

export function PageNotFound(props: { standAlone?: boolean }) {
  const { standAlone } = props;
  const classes = useStyles();
  const Layout = standAlone ? StandaloneLayout : DefaultLayout;

  useMetaTitle('404 - Siden ikke fundet');

  return (
    <Layout>
      <TabContent>
        <AbsolutelyCentered className={classes.wrapper}>
          <h1 data-testid="h1" className={classes.header}>
            404
          </h1>
          <p className={classes.content}>Siden du forsøgte at tilgå findes ikke.</p>
        </AbsolutelyCentered>
      </TabContent>
    </Layout>
  );
}

PageNotFound.defaultProps = {
  standAlone: false,
};
