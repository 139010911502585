interface DocumentTypeDescriptor {
  type: string;
  types: string;
  color: string;
}

export function getDocumentTypeDescriptor(documentType: string): DocumentTypeDescriptor | undefined {
  const textType: { [key: string]: DocumentTypeDescriptor; } = {
    citizen: { type: 'Borger', types: 'Borgere', color: '#4895EF' },
    company: { type: 'Virksomhed', types: 'Virksomheder', color: '#4895EF' },
    journalnote: { type: 'Journalnotat', types: 'Journalnotater', color: '#4895EF' },
    task: { type: 'Opgaver og Adviser', types: 'Opgaver og Adviser', color: '#4895EF' },
    cases: { type: 'Sag', types: 'Sager', color: '#9C27B0' },
    recruitmentrequests: { type: 'Rekrutteringsanmodning', types: 'Rekrutteringsanmodninger', color: '#9C27B0' },
    activities: { type: 'Aktivitet', types: 'Aktiviteter', color: '#2E7D32' },
    absences: { type: 'Fravær', types: 'Fravær', color: '#ED6C02' },
    companyspecialcircumstances: { type: 'Særligt forhold', types: 'Særlige forhold', color: '#ED6C02' },
    documents: { type: 'Dokument', types: 'Dokumenter', color: '#EF5350' },
    offers: { type: 'Tilbud', types: 'Tilbud', color: '#EF5350' },
    personcontacts: { type: 'Kontaktperson', types: 'Kontaktpersoner', color: '#42A5F5' },
    recruitmentrequestcandidates: { type: 'Kandidatformidling', types: 'Kandidatformidlinger', color: '#42A5F5' },
    companynotes: { type: 'Notat', types: 'Notater', color: '#FF9800' },
    companytags: { type: 'Tag', types: 'Tags', color: '#4895EF' },
    dfdgcitizen: {
      type: 'Borger',
      types: 'Borgere',
      color: '#BACAD3',
    },
    cvrcompany: {
      type: 'Virksomhed',
      types: 'Virksomheder',
      color: '#BACAD3',
    },
    cimcitizen: {
      type: 'Borger',
      types: 'Borgere',
      color: '#000000',
    },
    cmcompany: {
      type: 'Virksomhed',
      types: 'Virksomheder',
      color: '#000000',
    },
  };

  let documentTypeDescriptor = textType[documentType?.toLowerCase() || 'citizen'];
  if (!documentTypeDescriptor) {
    documentTypeDescriptor = { type: documentType, types: documentType, color: '#4895EF' };
  }

  return documentTypeDescriptor;
}
