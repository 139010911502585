export enum BulkActionEnum {
  BulkActionInterviewNotice = 'InterviewNotice',
  BulkActionCreateLetter = 'CreateLetter',
  BulkActionAssignTeamOrUser = 'AssignTeamOrUser',
  BulkActionTaskAssignTeamOrUser = 'TaskAssignTeamOrUser',
  BulkActionPersonGroupMarking = 'PersonGroupMarking',
  BulkActionCompleteActivity = 'CompleteActivity',
  BulkActionTagCompany = 'TagCompany',
  BulkActionCitizenCv = 'CitizenCV',
  BulkActionShowLink = 'ShowLink',
  BulkActionSchultzCaseLink = 'SchultzCaseLink',
  BulkActionOfferAssignTeamOrUser = 'OfferAssignTeamOrUser',
  BulkActionCopyOffer = 'CopyOffer',
  BulkActionCreateCrowdLetter = 'CreateCrowdLetter',
  BulkActionCreateCrowdLetterCompany = 'CreateCrowdLetterCompany',
  BulkActionCompanyAssignTeamOrUser = 'CompanyAssignTeamOrUser',
  BulkActionCompanyCompleteAppointment = 'CompanyCompleteAppointment',
  BulkActionCompanyAppointmentAssignTeamOrUser = 'CompanyAppointmentAssignTeamOrUser',
}
