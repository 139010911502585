import { AxiosRequestConfig } from 'axios';
import { IPeriod } from './relative-date-dtos';
import { SecureApiService } from '../../../../../../api-service';
import { IConfiguration, configuration } from '../../../../../../configuration';

export interface IRelativeDateApiService {
  getRange(value: string): Promise<IPeriod>;
}

export class RelativeDateApiService implements IRelativeDateApiService {
  private readonly baseService: SecureApiService = new SecureApiService();
  private readonly configuration: IConfiguration = configuration;

  getRange(expression: string, config?: AxiosRequestConfig): Promise<IPeriod> {
    const url = `${this.getApiAddress()}/RelativeDate/${expression}`;
    return this.baseService.get<undefined, IPeriod>(url, undefined, config);
  }

  private readonly getApiAddress = () => this.configuration.searchBaseAddress;
}
