import * as React from 'react';
import Portal from '@mui/material/Portal';
import URL from 'url-parse';
import { GenericLookupWindowComponent } from '../lookup-control/generic-lookup-window-component';
import { IEntityLookupProps, ILookupControlDataObj } from './entity-lookup-props';
import { Backdrop } from './backdrop';

export class EntityLookup extends GenericLookupWindowComponent<IEntityLookupProps, {}, ILookupControlDataObj[]> {
  private readonly entityLookupUrl: string;
  private readonly entityLookupOrigin: string;
  private lookupItem: string;
  static defaultProps = {
    useBackdrop: true,
  };

  constructor(props: IEntityLookupProps) {
    super(props);
    this.entityLookupUrl = props.entityLookupRelativeUri ? `https://${location.hostname}${props.entityLookupRelativeUri}` : props.entityLookupAbsoluteUri || '';
    this.entityLookupOrigin = new URL(this.entityLookupUrl).origin;
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.receiveMessage, false);
  }

  private openWindow(url: string): Window | null {
    const features = this.getWindowFeatures();
    const newWindow = window.open(url, 'lookup', features) as any;

    window.addEventListener('message', this.receiveMessage, false);

    const timer = setInterval(() => {
      if (newWindow == null || newWindow.closed) {
        clearInterval(timer);
        this.handleClose();
      }
    }, 500);

    return newWindow;
  }

  private getWindowFeatures() {
    const w = 700;
    const h = 950;
    const y = window.top.outerHeight / 2 + window.top.screenY - h / 2;
    const x = window.top.outerWidth / 2 + window.top.screenX - w / 2;
    return `scrollbars=yes,resizable=yes,width=${w},height=${h},top=${y},left=${x}`;
  }

  private readonly receiveMessage = (event: MessageEvent) => {
    if (event.origin !== this.entityLookupOrigin) {
      return;
    }

    if (event.source !== null && event.data.type === 'loaded') {
      const openerOriginMessage = { type: 'openerOrigin' };
      (event.source as Window).postMessage(openerOriginMessage, this.entityLookupOrigin);
      return;
    }

    const message = event.data;
    if (message !== null && message.type === 'result') {
      this.lookupItem = message.content;
    }
  };

  private handleClose() {
    if (this.lookupItem) {
      const lookupControlDataObj = this.parseJsonResultToArray(this.lookupItem);
      this.props.onValueChosen(lookupControlDataObj);
    } else {
      this.props.onCancel();
    }
  }

  private parseJsonResultToArray(entityResult: string): ILookupControlDataObj[] {
    if (!entityResult) return [];

    const resultArray: ILookupControlDataObj[] = [];
    const entity = JSON.parse(entityResult);

    if (entity && entity.items.length > 0) {
      entity.items.map((item: any) => {
        const id = item.id.replace(/[{}]/g, '');
        const { name } = item;
        resultArray.push({ id, value: id, text: name });
      });
    }

    return resultArray;
  }

  public render() {
    const openedWindow = this.openWindow(this.entityLookupUrl);

    return (
      <>
        {this.props.useBackdrop && openedWindow ? (
          <Portal container={window.top.document.body}>
            <Backdrop openedWindow={openedWindow} />
          </Portal>
        ) : null}
      </>
    );
  }
}
