import * as React from 'react';
import Box, { BoxProps } from '@mui/material/Box';

export interface IContainerProps {
  children?: React.ReactNode;
  sx?: BoxProps['sx'];
}

export function Container({ children, sx }: IContainerProps) {
  return <Box sx={sx}>{children}</Box>;
}
