import { apm } from '@elastic/apm-rum';
import { User, UserManager } from 'oidc-client';
import React, { useContext } from 'react';
import { Callback, makeAuthenticator, UserData } from 'react-oidc';
import { Route, Switch } from 'react-router-dom';

import { win } from '../utilities/window';
import { onAuthenticationError, onAuthenticationSuccess } from './auth-callback-handlers';
import { FkoErrorPage } from './fko-error-page';
import { IUser } from './user';

export interface AuthWrapperProps {
  children: JSX.Element;
  renderError: () => JSX.Element;
  renderFkoErrorPage: () => JSX.Element;
  userManager: UserManager | null;
}

export function AuthWrapper(props: AuthWrapperProps) {
  const { userManager, renderError, renderFkoErrorPage } = props;

  if (userManager === null) {
    return props.renderError();
  }

  const renderContent = () => props.children;

  const returnPath = win.location.pathname + win.location.search + win.location.hash;
  const ProtectedContent = makeAuthenticator({
    userManager,
    signinArgs: {
      state: {
        authenticationState: returnPath,
      },
    },
  })(renderContent);

  userManager.getUser()?.then((user: User | null) => {
    if (!user) {
      return;
    }
    try {
      apm.setUserContext({
        id: user?.profile?.id,
        username: user?.profile?.name,
        email: user?.profile?.sub,
      });
    } catch (e) {
      // Not much we can do here
    }
  });

  return (
    <Switch>
      <Route
        path="/callback"
        render={routeProps => (
          <Callback onSuccess={onAuthenticationSuccess(routeProps)} onError={onAuthenticationError(routeProps)} userManager={props.userManager as UserManager} />
        )}
      />
      <Route path="/error" render={renderError} />
      <Route path="/fko-error-page">
        <FkoErrorPage />
      </Route>
      <Route path="/">
        <ProtectedContent />
      </Route>
    </Switch>
  );
}

export function useAuthenticatedUser(): IUser | undefined {
  const context = useContext(UserData);
  return context.user ? (context.user as unknown as IUser) : undefined;
}
