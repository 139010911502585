import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import * as React from 'react';

export const DragIcon = ({ color = '' }) => {
  const styleObj: React.CSSProperties = !!color ? { color } : {};
  const extraProps = {
    focusable: false,
  };

  return <FontAwesomeIcon style={styleObj} icon={faGripVertical} size="sm" {...extraProps} />;
};
