import { ResourceAction } from './authorization-service';

export class AllowedActions {
  actions: ResourceAction[] = [];

  constructor(actions: ResourceAction[]) {
    this.actions = actions;
  }

  private isAllowed(action: ResourceAction) {
    return this.actions.some(
      a => a.resourceType.toLowerCase() === action.resourceType.toLowerCase() && a.resourceOperation.toLowerCase() === action.resourceOperation.toLowerCase()
    );
  }

  isAnyAllowed(actions: ResourceAction[]) {
    return actions.some(a => this.isAllowed(a));
  }
}
