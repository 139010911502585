import { useState, useEffect } from 'react';
import axios, { CancelTokenSource, CancelToken } from 'axios';

export function isReady(asyncState: { error?: any; isLoading: boolean }) {
  const { error, isLoading } = asyncState;
  return !isLoading && !error;
}

/**
 * @deprecated Please use useApiOnRender
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useAxiosAsync<T extends any[], R, E = any>(
  asyncAxiosLoader: (cancelToken: CancelToken, ...args: T) => Promise<R>,
  initialValue: R,
  ...args: T
): {
  result: R;
  error?: E;
  isLoading: boolean;
  reload: (...args: T) => void;
} {
  const [state, setState] = useState({
    result: initialValue,
    error: undefined as E | undefined,
    isLoading: true,
  });
  let isMounted = true;
  let source: CancelTokenSource;

  const reload = (...reloadArgs: T) => {
    if (source) {
      source.cancel('reload');
    }
    source = axios.CancelToken.source();
    const performAsync = async function performAsync() {
      if (isMounted) {
        setState({
          result: initialValue,
          error: undefined,
          isLoading: true,
        });
        try {
          const result = await asyncAxiosLoader(source.token, ...reloadArgs);

          isMounted &&
            setState({
              result,
              error: undefined,
              isLoading: false,
            });
        } catch (error) {
          isMounted &&
            setState({
              result: initialValue,
              error,
              isLoading: false,
            });
        }
      }
    };

    performAsync();

    return () => {
      isMounted = false;
      source.cancel('Component unmounted');
    };
  };

  useEffect(() => reload(...args), args);

  return { ...state, reload };
}
