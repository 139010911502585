import { UserManager } from 'oidc-client';
import { AxiosRequestConfig } from 'axios';
import { secureAxiosInstance, secureAxiosInstanceWithErrorMessageInterceptor } from '../api-service';

/**
 * If the current user has a valid access token, an Authorization header with the token, will bee added to all outgoing axios requests.
 * @param userManager a preconfigured OIDC userManager, with which to get the current users access_token
 */
export function sendAccessTokenWithHttpRequests(userManager: UserManager) {
  secureAxiosInstance.interceptors.request.use(
    async requestConfig => addAuthorizationHeaderToRequest(requestConfig, userManager),
    error => Promise.reject(error)
  );

  secureAxiosInstanceWithErrorMessageInterceptor.interceptors.request.use(
    async requestConfig => addAuthorizationHeaderToRequest(requestConfig, userManager),
    error => Promise.reject(error)
  );
}

export async function addAuthorizationHeaderToRequest(requestConfig: AxiosRequestConfig, userManager: UserManager) {
  try {
    const user = await userManager.getUser();
    if (!user || !user.access_token || user.expired) {
      return requestConfig;
    }
    if (!requestConfig.headers) requestConfig.headers = {};
    requestConfig.headers.Authorization = `Bearer ${user.access_token}`;
    return requestConfig;
  } catch (error) {
    // if we couldn't get the access_token, just try the request anyway. Maybe it doesn't need an access token.
    return requestConfig;
  }
}
