import * as React from 'react';
import { IDrawerContentBlockProps } from './drawer-content-block-props';
import { Avatar } from '../../avatar';
import './drawer-content-block.scss';

export class DrawerContentBlock extends React.Component<IDrawerContentBlockProps> {
  public static testIds = {
    root: 'drawer-content-block-root',
    content: 'drawer-content-block-content',
    title: 'drawer-content-block-title',
  };

  render() {
    const { children, title, type } = this.props;

    const Title = title ? (
      <h4 className="drawer__content-block__title" data-testid={DrawerContentBlock.testIds.title}>
        {title}
      </h4>
    ) : null;

    return (
      <div className="drawer__content-block" data-testid={DrawerContentBlock.testIds.root}>
        {type && (
          <div className="drawer__content-block__avatar">
            <Avatar type={type} />
          </div>
        )}
        <div className="drawer__content-block__content" data-testid={DrawerContentBlock.testIds.content}>
          {Title}
          {children}
        </div>
      </div>
    );
  }
}
