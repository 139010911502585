import * as React from 'react';
import { IGroupRule } from './rule-interface';
import { RuleFactory } from './rule-factory';
import { IRuleProps } from './rule-props';
import { IGroupRulesProps } from './fields-and-types';
import { useQueryBuilderContext } from './query-builder-context';

function ConditionSelector<T extends IGroupRule>(props: IGroupRulesProps<T>) {
  const context = useQueryBuilderContext();
  const {
    value: { condition },
  } = props;
  const { conditions, controls, translations, classNames } = context;

  const handleConditionChange = (value: string) => {
    context.queryDispatch({ type: 'updateRule', oldRule: props.value, newRule: { ...props.value, condition: value } });
  };

  return (
    <div className="groupRule__conditionSelector">
      {React.createElement(controls.conditionSelector, {
        field: '',
        options: conditions,
        value: condition,
        title: translations.conditions.title,
        className: classNames.conditions,
        handleOnChange: handleConditionChange,
      })}
    </div>
  );
}

export function GroupRules<T extends IGroupRule>(props: IGroupRulesProps<T>) {
  const { resultType } = props;
  const {
    value: { rules },
    fieldsAndTypes: { fields, types },
  } = props;

  const renderChild = (child: any, index: number): React.ReactNode => {
    const ruleProps: IRuleProps<any> = {
      resultType,
      fields,
      types,
      value: child,
      isRoot: false,
    };

    return RuleFactory.componentFactory(index, ruleProps);
  };

  return (
    <>
      {rules.length !== 0 && (
        <div className="groupRule__actions-and-rules">
          {rules.length > 1 && <ConditionSelector {...props} />}
          <div className={`groupRule__rules${rules.length > 1 ? ' groupRule__rules--multiple-rules' : ''}`}>{rules.map((x, index) => renderChild(x, index))}</div>
        </div>
      )}
    </>
  );
}
