import { IOption } from './field-interfaces';

export enum Cardinality {
  None = 'none',
  Any = 'any',
  MinMax = 'minmax'
}

export const CardinalityOptions: IOption[] = [
  { name: Cardinality.None, label: 'Ingen' },
  { name: Cardinality.Any, label: '1 eller flere' },
  { name: Cardinality.MinMax, label: 'Mellem' },
];
