import React from 'react';
import './relative-date-editor.scss';
import { Select } from '../../../../../../components/select/select';
import { IntegerField } from '../../../../../../components/number-fields/number-field';
import { INumericValue } from '../../../../../../components/number-fields/number-field';
import { directions, units, directionCurrent, defaultUnitForCurrentDirection, defaultOptions, currentDirectionUnits } from './relative-date-options';
import { parser } from './expression-parser';

export interface IRelativeDateEditorFormProps {
  value: string;
  className?: string;
  onChange: (value: any) => void;
}

export const relativeDateEditorFormTestIds = {
  Direction: 'RelativeDateEditorForm-Direction',
  Duration: 'RelativeDateEditorForm-Duration',
  Unit: 'RelativeDateEditorForm-Unit',
};
const minimum = 1;
const maximum = 999;

export function RelativeDateEditorForm(props: IRelativeDateEditorFormProps) {
  const { direction, duration, unit } = parser.toObjects(props.value);

  const handleDirectionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDirection = event.target.value;

    if (newDirection && direction.value !== directionCurrent && newDirection === directionCurrent) {
      props.onChange(`${event.target.value}_${defaultUnitForCurrentDirection}`);
    } else if (newDirection && direction.value === directionCurrent && newDirection !== directionCurrent) {
      props.onChange(`${event.target.value}_${defaultOptions.duration}_${defaultOptions.unit.value}`);
    } else {
      newDirection && props.onChange(`${event.target.value}_${duration}_${unit.value}`);
    }
  };

  const handleDurationChanged = (event: React.FocusEvent<HTMLInputElement>) => {
    const parsedValue = parseInt(event.target.value, 10);
    if (event.target.value && parsedValue && parsedValue >= minimum && parsedValue <= maximum) {
      props.onChange(`${direction.value}_${parsedValue}_${unit.value}`);
    } else {
      props.onChange(`${direction.value}_${minimum}_${unit.value}`);
    }
  };

  const handleUnitChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const unit = event.target.value;
    const durationText = duration !== null && duration !== undefined ? `_${duration}` : '';
    unit && props.onChange(`${direction.value}${durationText}_${event.target.value}`);
  };

  return (
    <div className="relative-date-form">
      <div style={{ width: '200px', marginRight: '10px' }}>
        <Select
          fullWidth
          required
          className={props.className}
          data-testid={relativeDateEditorFormTestIds.Direction}
          value={direction.value}
          options={directions}
          onChange={handleDirectionChanged}
          label="Tidsramme"
        />
      </div>
      {direction.value !== directionCurrent && (
        <div style={{ width: '80px', marginRight: '10px' }}>
          <IntegerField
            required
            data-testid={relativeDateEditorFormTestIds.Duration}
            onBlur={handleDurationChanged}
            allowNegative={false}
            label="Antal"
            filters={[(v: INumericValue) => v.floatValue === undefined || v.floatValue <= maximum, (v: INumericValue) => v.floatValue === undefined || v.floatValue >= minimum]}
            multiline={false}
            fullWidth
            value={duration}
          />
        </div>
      )}
      <div style={{ width: '200px' }}>
        <Select
          required
          fullWidth
          value={unit.value}
          options={direction.value !== directionCurrent ? units : currentDirectionUnits}
          data-testid={relativeDateEditorFormTestIds.Unit}
          onChange={handleUnitChanged}
          label="Tidsenhed"
        />
      </div>
    </div>
  );
}

RelativeDateEditorForm.defaultValues = {
  value: '',
};
