import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { ColorPalette } from '../../../../../components/config/color-palette';
import { IconButton } from '../../../../../components/icon-button/icon-button';

export interface IRemoveRuleElementProps {
  className: string;
  disabled: boolean;
  handleOnClick: React.MouseEventHandler<HTMLElement>;
  title: string;
}

export function RemoveRuleElement(props: IRemoveRuleElementProps) {
  const { disabled, handleOnClick, className, title } = props;

  const onClick = (value: React.MouseEvent<HTMLElement, MouseEvent>) => handleOnClick(value);

  return (
    <IconButton
      disabled={disabled}
      onClick={onClick}
      className={className}
      title={title}
      size="large">
      <FontAwesomeIcon
        icon={faTimes}
        size="sm"
        style={{
          color: ColorPalette.HotAurora,
        }}
      />
    </IconButton>
  );
}
