import api from "!../../../../.yarn/__virtual__/style-loader-virtual-0d23fa67ef/0/cache/style-loader-npm-2.0.0-b9a5c4a2aa-129f8d7124.zip/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../.yarn/__virtual__/css-loader-virtual-f6d70b9d41/0/cache/css-loader-npm-5.2.7-e1e8b8d16f-ce7cb59b7a.zip/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../.yarn/__virtual__/sass-loader-virtual-5c5cfa8dc4/0/cache/sass-loader-npm-14.1.0-42a1756e97-ac5f8dc02b.zip/node_modules/sass-loader/dist/cjs.js!./drawer-content-block.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};