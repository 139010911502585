import { Component } from 'react';

export interface ILookupSelectedObject {
  id: string;
  text: string;
}

export interface IGenericLookupWindowComponentProps<
  TResultValue extends ILookupSelectedObject[]
> {
  onValueChosen: (value: TResultValue) => void;
  onCancel: () => void;
}

export abstract class GenericLookupWindowComponent<
  TProps extends IGenericLookupWindowComponentProps<
    TResultValue
  > = IGenericLookupWindowComponentProps<ILookupSelectedObject[]>,
  TState = {},
  TResultValue extends ILookupSelectedObject[] = ILookupSelectedObject[]
> extends Component<TProps, TState> {}
