import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { IQueryBuilderTranslations, IControlClassNames, IQueryBuilderControls } from './query-builder-interfaces';
import { IOption } from './field-interfaces';

export const defaultResultType = 'citizen';

export const defaultTranslations: IQueryBuilderTranslations = {
  fields: {
    title: 'Felt',
  },
  operators: {
    title: 'Operator',
  },
  value: {
    title: 'Værdi',
  },
  removeRule: {
    label: 'x',
    title: 'Slet regel',
  },
  removeGroup: {
    label: 'Slet gruppe',
    title: 'Slet gruppe',
  },
  addRule: {
    label: 'Regel',
    title: 'Tilføj regel',
    icon: faPlusCircle,
  },
  addGroup: {
    label: 'Gruppe',
    title: 'Tilføj gruppe',
    icon: faPlusCircle,
  },
  addHavingOperator: {
    label: 'Som har',
    title: 'Tilføj som har',
    icon: faPlusCircle,
  },
  addHavingField: {
    label: 'Felt',
    title: 'Felt',
  },
  conditions: {
    title: 'Conditions',
  },
  mainToggleAsResult: {
    title: 'Vis som resultat',
  },
  havingToggleAsResult: {
    title: 'Vis som resultat',
  },
};

export const defaultConditions: IOption[] = [
  { name: 'and', label: 'og' },
  { name: 'or', label: 'eller' },
];

export const defaultControlClassNames: IControlClassNames = {
  queryBuilder: 'queryBuilder',

  groupRule: 'groupRule',
  conditions: 'groupRule-conditions',
  addRule: 'groupRule-addRule',
  addGroup: 'groupRule-addGroup',
  addHaving: 'groupRule-addHaving',
  removeGroup: 'groupRule-remove',

  rule: 'rule',
  fields: 'rule-fields',
  operators: 'rule-operators',
  value: 'rule-value',
  removeRule: 'rule-remove',
  havingFields: 'rule-fields--having',
  havingOperators: 'rule-operators--having',
};

export const validationMessages = {
  cardinalityRequired: 'Kardinalitet er påkrævet',
  havingTypeRequired: 'Som har type er påkrævet',
  conditionRequired: 'Betingelse er påkrævet',
  fieldRequired: 'Felt er påkrævet',
  operatorRequired: 'Operator er påkrævet',
  valueRequired: 'Værdi er påkrævet',
  universeRequired: 'Område er påkrævet',
  unknownHavingType: 'Ukendt type',
  unknownEmbeddedType: 'Ukendt indlejret type',
  unknownResultType: 'Ukendt resultat type',
  unknownColumn: 'Ukendt kolonne',
  unknownEmbeddedColumn: 'Ukendt kolonne',
  unknownField: 'Ukendt felt',
  unknownOperator: 'Ukendt operator',
  noColumns: 'Der skal være mindst en kolonne',
  noColumnsFromResultType: 'Der skal være mindst en kolonne fra den valgte resultattype',
  noEmbeddedColumns: 'Der skal være mindst en kolonne for indlejret liste',
};
