/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { Autocomplete, Checkbox, AutocompleteChangeReason, AutocompleteChangeDetails, PopperProps, Popper } from '@mui/material';
import { ICodeListApiService, CodeListApiService, ICodeListOption } from './codelist-api-service';
import { QueryBuilderOperator } from '../../operators';
import { useAxiosAsync } from '../../../../../../hooks';
import { SingleSelect } from '../../../../../../components/single-select/single-select';
import { ITextFieldProps, TextField } from '../../../../../../components/text-field';

export interface ICodeListSelectorProps {
  value: string[];
  handleOnChange: (value: string | string[]) => void;
  operator: string;
  codelistType: string;
  disabled?: boolean;
  type?: string;
  textFieldProps: ITextFieldProps;
}

type option = { label: string; value: string };

function deriveInitialSelection(options: option[], values: string[]): option[] {
  const initialSelection: {
    label: string;
    value: string;
  }[] = [];

  if (values && values.length > 0 && options) {
    options.forEach(option => {
      values.forEach(v => {
        v === option.value && initialSelection.push(option);
      });
    });
  }
  return initialSelection;
}

function codeListToOptionMapper(option: ICodeListOption) {
  return {
    label: option.name,
    value: option.id,
  };
}

export interface CodelistSelectorValue {
  label: string;
  value: string;
}

function CustomPopper(props: PopperProps) {
  const styles = {
    popper: {
      width: 'fit-content',
    },
  };
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
}

export function CodeListSelector(props: ICodeListSelectorProps) {
  const { codelistType, type, value, handleOnChange, disabled, operator, textFieldProps } = props;

  const { error, errorText } = textFieldProps;

  const codelistService: ICodeListApiService = new CodeListApiService();
  const [selectedValue, setValue] = useState<option[]>([]);

  const codeListAsync = useAxiosAsync(
    async (cancelToken, codelistType, type) => {
      const result = await codelistService.getCodeListOptions({ codelistType, type }, { cancelToken });
      return result.map(codeListToOptionMapper);
    },
    [],
    codelistType,
    type
  );

  useEffect(() => {
    setValue(deriveInitialSelection(codeListAsync.result, value));
  }, [codeListAsync.result, value]);

  function onChange(data: CodelistSelectorValue | CodelistSelectorValue[]) {
    const values = data ? (Array.isArray(data) ? data : [data]) : [];
    handleOnChange(values.map(x => x.value));
  }

  const isMulti = operator === QueryBuilderOperator.In || operator === QueryBuilderOperator.NotIn;

  return (
    <>
      {isMulti && (
        <Autocomplete<CodelistSelectorValue, true, true>
          multiple
          options={codeListAsync.result}
          disableCloseOnSelect
          getOptionLabel={option => option.label}
          renderOption={(renderOptionProps, option, { selected }) => (
            <li {...renderOptionProps}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {option.label}
            </li>
          )}
          value={selectedValue.map(v => ({ label: v.label, value: v.value }))}
          renderInput={params => <TextField label="Værdi" errorText={errorText} error={error} multiline={false} {...params} />}
          renderTags={tags => tags.map(tag => tag.label).join(', ')}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          onChange={(
            event: React.ChangeEvent<{}>,
            changeValue: CodelistSelectorValue[],
            reason: AutocompleteChangeReason,
            details?: AutocompleteChangeDetails<unknown> | undefined
          ) => onChange(changeValue)}
          PopperComponent={CustomPopper}
        />
      )}
      {!isMulti && (
        <SingleSelect<CodelistSelectorValue>
          label="Værdi"
          error={error}
          errorText={errorText}
          options={codeListAsync.result}
          value={selectedValue[0]}
          disabled={disabled}
          onChange={onChange}
          getOptionLabel={option => option.label}
          renderOption={(optionProps: object, option: CodelistSelectorValue) => (
            <li {...optionProps} key={option.value}>
              {option.label}
            </li>
          )}
          popperComponent={CustomPopper}
          style={{ minWidth: '300px' }}
        />
      )}
    </>
  );
}
