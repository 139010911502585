import { NotifierMessageSeverityType } from '../../../../components/notifier/interfaces';

export interface INotification {
  message: string;
  id: string;
  correlationIdentifier?: string;
  severity?: NotifierMessageSeverityType;
}

export type NotifyErrorAction = { type: 'notify/enqueueError'; message: string; correlationIdentifier?: string };

export function notifyReducer(state: INotification[] = [], action: NotifyErrorAction): INotification[] {
  switch (action.type) {
    case 'notify/enqueueError':
      return [...state, { message: action.message, id: Math.random().toString(), correlationIdentifier: action.correlationIdentifier, severity: 'error' }];
    default:
      return state;
  }
}
