import * as React from 'react';
import MuiTextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { ITextFieldProps } from './text-field-props';
import { sanitizeInput } from '../../utilities/text-sanitizer';

export function TextField(props: ITextFieldProps) {
  const { children, errorText, variant, lazy, ...otherProps } = props;
  const { value, onChange, onBlur } = otherProps;
  const [stateValue, setStateValue] = useState(value);

  useEffect(() => {
    if (lazy) {
      setStateValue(otherProps.value);
    }
  }, [otherProps.value, lazy]);

  if (variant === 'outlined') {
    if (!otherProps.InputLabelProps) otherProps.InputLabelProps = {};
    otherProps.InputLabelProps.shrink = true;
  }

  if (otherProps.required && otherProps.readOnly) {
    otherProps.InputLabelProps = otherProps.InputLabelProps || {};
    otherProps.InputLabelProps.required = false;
  }
  if (otherProps.readOnly) {
    otherProps.InputProps = otherProps.InputProps || {};
    otherProps.InputProps.readOnly = otherProps.readOnly;
  }

  if (otherProps.maxLength) {
    otherProps.inputProps = otherProps.inputProps || {};
    otherProps.inputProps.maxLength = otherProps.maxLength;
  }

  if (otherProps.disabled || otherProps.readOnly) {
    (otherProps as any)['data-disabled-state'] = true;
  }

  if (otherProps.maxRows && otherProps.rows) {
    if (!otherProps.minRows) {
      otherProps.minRows = otherProps.rows;
    }
    delete otherProps.rows;
  }

  otherProps.inputProps = otherProps.inputProps || {};
  otherProps.inputProps.autoComplete = 'off';

  if (errorText) {
    otherProps.helperText = otherProps.error ? errorText : otherProps.helperText;
  }
  const text = lazy ? stateValue : otherProps.value;
  const cleanedText = React.useMemo(() => sanitizeInput(text), [text]);
  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (lazy) {
      setStateValue(event.target.value);
    } else {
      onChange && onChange(event);
    }
  }
  function handleBlur(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) {
    lazy && onChange && onChange(event);
    onBlur && onBlur(event);
  }
  return (
    <MuiTextField variant={variant} {...otherProps} value={cleanedText} onChange={handleChange} onBlur={handleBlur}>
      {children}
    </MuiTextField>
  );
}

TextField.defaultProps = {
  disabled: false,
  error: false,
  errorText: '',
  fullWidth: true,
  label: 'Label',
  multiline: true,
  readOnly: false,
  required: false,
  rows: undefined,
  maxRows: 6,
  variant: 'filled',
  autoFocus: false,
};
