/* eslint-disable class-methods-use-this */
import { win } from './utilities/window';

export interface IConfiguration {
  baseAddress: string;
  stsBaseAddress: string;
  bffBaseAddress: string;
  // BFF Without /api
  bffBase: string;
  /**
   * @deprecated Search no longer has its own endpoint. Use bffBaseAddress instead.
   */
  searchBaseAddress: string;
  defaultTenant: string | undefined;
  imageTag: string;
  showNonProductionReadyComponents: boolean;
  showReduxDevTools: boolean | undefined;
  apmServerUrl: string;
  apmEnvironment: string;
  apmEnabled: boolean | undefined;
  schultzCaseBaseUrl: string;
}

export interface GlobalConfiguration {
  stsBaseUrl: string;
  jobcenterDesktopBffBaseUrl: string;
  imageTag: string;
  showNonProductionReadyComponents: string;
  showReduxDevTools: string;
  elasticApmServerUrl: string;
  elasticApmEnvironment: string;
  elasticApmEnabled: string;
  schultzCaseBaseUrl: string;
}

export const ConfigurationPlaceholders: GlobalConfiguration = {
  stsBaseUrl: 'Fasit__FasitSTS__BaseUri',
  jobcenterDesktopBffBaseUrl: 'Fasit__JobcenterDesktopBff__BaseUri',
  imageTag: 'IMAGETAG',
  showNonProductionReadyComponents: 'Fasit__ShowNonProductionReadyComponents',
  showReduxDevTools: 'Fasit__ShowReduxDevTools',
  elasticApmServerUrl: 'ElasticApm__ServerUrls',
  elasticApmEnvironment: 'ElasticApm__Environment',
  elasticApmEnabled: 'ElasticApm__Enabled',
  schultzCaseBaseUrl: 'SchultzCase__BaseUrl'
};

class Configuration {
  get globalConfiguration(): GlobalConfiguration {
    return (window as unknown) as GlobalConfiguration;
  }

  public get baseAddress(): string {
    return `${win.location.protocol}//${win.location.hostname}`;
  }

  public get stsBaseAddress(): string {
    const { stsBaseUrl } = this.globalConfiguration;

    // When running the app using a local webpack developement server,
    // we expect the base address to be set via a React environment variable
    /* eslint no-undef: "off" */
    if (typeof stsBaseUrl === 'undefined' || !stsBaseUrl || stsBaseUrl === ConfigurationPlaceholders.stsBaseUrl) {
      return process.env.AUTHORITY_URI as string;
    }
    // when running in kubernetes, we expect the sts base address
    // to be injected into this global variable in index.html
    /* eslint no-undef: "off" */
    return stsBaseUrl;
  }

  public get bffBaseAddress(): string {
    const { jobcenterDesktopBffBaseUrl } = this.globalConfiguration;
    // When running the app using a local webpack developement server,
    // we expect the base address to be set via a React environment variable
    /* eslint no-undef: "off" */
    if (typeof jobcenterDesktopBffBaseUrl === 'undefined' || !jobcenterDesktopBffBaseUrl || jobcenterDesktopBffBaseUrl === ConfigurationPlaceholders.jobcenterDesktopBffBaseUrl) {
      return process.env.BFF_URI as string;
    }

    // when running in kubernetes, we expect the sts base address
    // to be injected into this global variable in index.html
    /* eslint no-undef: "off" */
    return jobcenterDesktopBffBaseUrl;
  }

  public get bffBase(): string {
    return this.bffBaseAddress?.replace('/api', '');
  }

  /**
   * @deprecated Search no longer has its own endpoint. Use bffBaseAddress instead.
   */
  public get searchBaseAddress(): string {
    return `${this.bffBaseAddress}`;
  }

  public get defaultTenant(): string | undefined {
    if (process !== undefined && process.env !== undefined && !!process.env.DEFAULT_TENANT) {
      return process.env.DEFAULT_TENANT;
    }
    return undefined;
  }

  public get showReduxDevTools(): boolean {
    const { showReduxDevTools } = this.globalConfiguration;
    // When running the app using Visual Studio & react developement server,
    // we expect sts authentication to be set via a React environment variable
    /* eslint no-undef: "off" */
    if (typeof showReduxDevTools === 'undefined' || showReduxDevTools === ConfigurationPlaceholders.showReduxDevTools) {
      return process !== undefined && process.env !== undefined && process.env.SHOW_REDUX_DEV_TOOLS === 'true';
    }
    // when running in kubernetes, we expect the sts base address
    // to be injected into this global variable in index.html
    /* eslint no-undef: "off" */
    return showReduxDevTools === 'true';
  }

  public get imageTag(): string {
    const { imageTag } = this.globalConfiguration;
    // When running the app using a local webpack developement server,
    // we expect the image tag to be set via a React environment variable
    /* eslint no-undef: "off" */
    if (typeof imageTag === 'undefined' || !imageTag || imageTag === ConfigurationPlaceholders.imageTag) {
      return process.env.IMAGETAG as string;
    }
    // when running in kubernetes, we expect the image tag
    // to be injected into this global variable in index.html
    /* eslint no-undef: "off" */
    return imageTag;
  }

  public get showNonProductionReadyComponents(): boolean {
    const { showNonProductionReadyComponents } = this.globalConfiguration;
    if (typeof showNonProductionReadyComponents === 'undefined' || showNonProductionReadyComponents === ConfigurationPlaceholders.showNonProductionReadyComponents) {
      return process !== undefined && process.env !== undefined && process.env.SHOW_NON_PRODUCTION_READY_COMPONENTS === 'true';
    }
    return showNonProductionReadyComponents === 'true';
  }

  public get apmServerUrl(): string {
    const { elasticApmServerUrl } = this.globalConfiguration;
    // When running the app using a local webpack developement server,
    // we expect the apm server url to be set via a React environment variable
    /* eslint no-undef: "off" */
    if (typeof elasticApmServerUrl === 'undefined' || !elasticApmServerUrl || elasticApmServerUrl === ConfigurationPlaceholders.elasticApmServerUrl) {
      return process.env.APM_SERVER_URL as string;
    }

    // when running in kubernetes, we expect the apm server url
    // to be injected into this global variable in index.html
    /* eslint no-undef: "off" */
    return elasticApmServerUrl;
  }

  public get apmEnvironment(): string {
    const { elasticApmEnvironment } = this.globalConfiguration;
    // When running the app using a local webpack developement server,
    // we expect the apm environment to be set via a React environment variable
    /* eslint no-undef: "off" */
    if (typeof elasticApmEnvironment === 'undefined' || !elasticApmEnvironment || elasticApmEnvironment === ConfigurationPlaceholders.elasticApmEnvironment) {
      return process.env.APM_ENVIRONMENT as string;
    }

    // when running in kubernetes, we expect the apm environment
    // to be injected into this global variable in index.html
    /* eslint no-undef: "off" */
    return elasticApmEnvironment;
  }

  public get apmEnabled(): boolean {
    const { elasticApmEnabled } = this.globalConfiguration;
    // When running the app using a local webpack developement server,
    // we expect the apm environment to be set via a React environment variable
    /* eslint no-undef: "off" */
    if (typeof elasticApmEnabled === 'undefined' || elasticApmEnabled === ConfigurationPlaceholders.elasticApmEnabled) {
      return process.env.APM_ENABLED === 'true';
    }

    // when running in kubernetes, we expect the apm environment
    // to be injected into this global variable in index.html
    /* eslint no-undef: "off" */
    return elasticApmEnabled === 'true';
  }

  public get schultzCaseBaseUrl(): string {
    const { schultzCaseBaseUrl } = this.globalConfiguration;
    // When running the app using a local webpack developement server,
    // we expect the apm server url to be set via a React environment variable
    /* eslint no-undef: "off" */
    if (typeof schultzCaseBaseUrl === 'undefined' || !schultzCaseBaseUrl || schultzCaseBaseUrl === ConfigurationPlaceholders.schultzCaseBaseUrl) {
      return process.env.SCHULTZCASE_BASEURL as string;
    }

    // when running in kubernetes, we expect the apm server url
    // to be injected into this global variable in index.html
    /* eslint no-undef: "off" */
    return schultzCaseBaseUrl;
  }
}

export const configuration: IConfiguration = new Configuration();
