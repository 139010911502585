import { useMemo } from 'react';
import { ISearchRequestBody } from '../models/dtos';
import { SearchResultTypeProvider, ISearchResultTypeProvider, IResultTypeOption, IResultTypeUsage } from '../components/query/search-result-type';
import { ISearchSchema } from '../components/query/query-api-service';

export function useResultTypeUsage(resultTypes: IResultTypeOption[], requestBody: ISearchRequestBody) {
  const resultTypeProvider: ISearchResultTypeProvider = new SearchResultTypeProvider();
  return useMemo(
    () => resultTypeProvider.getResultTypeUsage(resultTypes, requestBody),
    // eslint-disable-next-line
    [resultTypes, requestBody]
  );
}

export function useAllowedSelectAsResultTypes(searchSchemaForResultTypes: ISearchSchema | undefined, resultTypeUsage: IResultTypeUsage[]): IResultTypeUsage[] {
  return useMemo(
    () => allowedSelectAsResultTypes(searchSchemaForResultTypes, resultTypeUsage),
    // eslint-disable-next-line
    [searchSchemaForResultTypes, resultTypeUsage]
  );
}

function allowedSelectAsResultTypes(searchSchemaForResultTypes: ISearchSchema | undefined, resultTypeUsage: IResultTypeUsage[]): IResultTypeUsage[] {
  const allowListsAsResultTypeDefault = true;
  const allowListsAsResultTypeSearchSchema = searchSchemaForResultTypes && searchSchemaForResultTypes.allowListsAsResultType;
  const allowListsAsResultType = allowListsAsResultTypeSearchSchema === undefined ? allowListsAsResultTypeDefault : allowListsAsResultTypeSearchSchema;

  const defaultResultType = resultTypeUsage.find(rt => rt.label === searchSchemaForResultTypes?.displayName);
  const defaultResultTypeUsage: IResultTypeUsage[] = defaultResultType ? [defaultResultType] : [];
  return allowListsAsResultType ? resultTypeUsage : defaultResultTypeUsage;
}
