/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import { apm } from '@elastic/apm-rum';
import { createUrlToActualResource } from './resource-url-builder';
import { useCrmHostUrlContext } from './crm-host-url-context';
import { win } from '../../utilities';
import { useFeatureToggles } from '../../feature-toggles';

export function ResourceRedirect() {
  const { url: crmHostUrl, isLoading } = useCrmHostUrlContext();
  const { resourceName } = useParams<{ resourceName: string }>();
  const searchParams = new URLSearchParams(win.location.search);
  const resourceId = searchParams.get('resourceId');
  const history = useHistory();
  const { isFeatureEnabled } = useFeatureToggles();
  const disableCRMReferences = isFeatureEnabled('DisableCRMReferences');

  let redirectUrl = '/notfound';

  useEffect(() => {
    async function redirectWithRoutePush() {
      if (resourceId && crmHostUrl) {
        // we dont currently support resource urls without IDs. Change this code when/if we need to support that.
        const createdResourceUrl = await createUrlToActualResource(resourceName, resourceId, crmHostUrl, '', searchParams, disableCRMReferences);
        if (disableCRMReferences) {
          apm.captureError(new Error('CRM URL reference prevented by DisableCRMReferences FT in: "components/resource-redirect"'));
        }
        if (createdResourceUrl !== null) {
          redirectUrl = createdResourceUrl;
        }
        if (redirectUrl.startsWith(crmHostUrl)) {
          win.location.assign(redirectUrl);
        } else {
          history.push(redirectUrl.startsWith('/') ? redirectUrl : `/${redirectUrl}`);
        }
      }
    }
    redirectWithRoutePush();
  }, [resourceId, crmHostUrl]);

  return null;
}
