import { WebStorageStateStore } from 'oidc-client';
import { AuthConfig } from './auth-config';
import { configuration } from '../configuration';
import { session } from '../utilities/session-storage';

export function createConfig(config: AuthConfig) {
  const sessionStore = new WebStorageStateStore({ prefix: 'oidc.', store: session });

  return {
    authority: configuration.stsBaseAddress,
    client_id: config.clientId,
    redirect_uri: `${configuration.baseAddress}/callback`,
    response_type: 'code',
    scope: `openid profile ${config.apiName}`,
    post_logout_redirect_uri: configuration.baseAddress,
    userStore: sessionStore,
    stateStore: sessionStore,
  };
}
