import CitizenFunctions from './citizen';
import CompanyFunctions from './company';
import EffortsFunctions from './efforts';
import OutboxFunctions from './outbox';
import SearchFunctions from './search';
import FromComponentsFunctions from './fromComponents';

const Index = [...CitizenFunctions, ...CompanyFunctions, ...EffortsFunctions, ...OutboxFunctions, ...SearchFunctions, ...FromComponentsFunctions];

export default Index;
