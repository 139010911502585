import React, { useMemo } from 'react';
import { AllowedActions } from './allowed-actions';
import { ResourceAction } from './authorization-service';
import { useApiOnRender } from '../hooks';
import { moduleClientFactory } from '../api-service/module-client-factory';
import { CircularProgressCentered } from '../components/circular-progress-centered';

export const AllowedActionsContext = React.createContext<AllowedActions>(new AllowedActions([]));

type AllowedActionsProviderProps = {
  children: React.ReactNode;
};

export function AllowedActionsProvider({ children }: AllowedActionsProviderProps) {
  const loading = useApiOnRender(cancelToken => moduleClientFactory.createAuthorizationClient().getAllowedActions(cancelToken), {
    allowedActions: [],
  });

  if (loading.isError) {
    throw new Error(`Could not load user rights: ${loading.error}`);
  }

  const allowedActions = useMemo(() => new AllowedActions(loading.result.allowedActions?.map(a => a as ResourceAction) ?? []), [loading.result]);

  if (!loading.isReady) {
    return <CircularProgressCentered />;
  }

  return <AllowedActionsContext.Provider value={allowedActions}>{children}</AllowedActionsContext.Provider>;
}

export const useAllowedActions = (): AllowedActions => React.useContext(AllowedActionsContext);
