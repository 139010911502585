import { parse, isValid } from 'date-fns';
import { defaultDateFormat, today, formatDateFromJSDate } from '../../utilities';

function expandDate(value: string): string {
  const digitPattern = /^\d*$/;
  const isAllDigits = digitPattern.test(value);

  if (isAllDigits) {
    switch (value.length) {
      // ddmm
      case 4:
        return `${value.substring(0, 2)}-${value.substring(2, 4)}-${new Date().getFullYear()}`;
      // ddmmyy
      case 6: {
        const year = parseInt(value.substring(4, 6), 10);
        const adjustedYear = year + (year < 30 ? 2000 : 1900);
        return `${value.substring(0, 2)}-${value.substring(2, 4)}-${adjustedYear}`;
      }
      // ddmmyyyy
      case 8:
        return `${value.substring(0, 2)}-${value.substring(2, 4)}-${value.substring(4, 8)}`;
      default:
        break;
    }
  }
  return value;
}

export function validateDefaultDateFormat(value: string): Date | undefined {
  const date = parse(value, defaultDateFormat, today());
  if (isValid(date)) {
    const formattetDate = formatDateFromJSDate(date);
    if (formattetDate !== '01-01-0001' && formattetDate !== '31-12-9999') {
      return date;
    }
  }
  return undefined;
}

export function validateExpandedDateFormat(value: string): Date | undefined {
  const expanded = expandDate(value);
  if (expanded !== value) {
    return validateDefaultDateFormat(expanded);
  }
  return undefined;
}
