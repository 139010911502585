import { FormControl, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Cell } from '../../../../../components/cell/cell';
import { Container } from '../../../../../components/container/container';
import { FormDialog } from '../../../../../components/form-dialog/form-dialog';
import { RadioOption } from '../../../../../components/radio-group/radio-option/radio-option';
import { Row } from '../../../../../components/row/row';
import { SingleSelect } from '../../../../../components/single-select/single-select';

import { ChangeJobcenterErrors, ChangeJobcenterModel, JobcenterType } from './interfaces';

import { ChangeJobcenterHelpTextMenu } from './help-text-menu';
import { texts } from './help-texts';

import { ChangeJobcenterViewModel, JobcenterModel, SubjobcenterModel } from '../../../../../api-service/clients';
import { moduleClientFactory } from '../../../../../api-service/module-client-factory';

const defaultState: ChangeJobcenterModel = {
  jobcenterRadio: undefined,
  jobcenter: undefined,
  municipality: undefined,
};

export interface IChangeJobcenter {
  isOpen: boolean;
  onCancel?: () => void;
  onClose?: () => void;
  onSave?: (state: ChangeJobcenterModel) => void;
  citizenId: string;
}

export interface IChangeJobcenterComponent extends IChangeJobcenter {
  isLoading?: boolean;
  changeJobcenterData?: ChangeJobcenterViewModel;
  initialState: ChangeJobcenterModel;
}

export function ChangeJobcenter(props: IChangeJobcenter) {
  const citizenJobcenterClient = moduleClientFactory.createCitizenJobcenterClient(true);
  const { onSave, citizenId } = props;

  const [changeJobcenterViewModel, setChangeJobcenterViewModel] = useState<ChangeJobcenterViewModel | undefined>();
  const [isLoading, setisLoading] = useState(false);
  useEffect(() => {
    async function init() {
      try {
        setisLoading(true);
        const result = await citizenJobcenterClient.getChangeJobcenterData();
        setChangeJobcenterViewModel(result.changeJobcenterViewModel);
      } finally {
        setisLoading(false);
      }
    }
    init();
  }, []);

  async function handleSave(newJobcenterState: ChangeJobcenterModel) {
    try {
      setisLoading(true);
      let subjobcenterId;
      if (newJobcenterState.jobcenterRadio === 'otherjobcenter') {
        subjobcenterId = newJobcenterState?.jobcenter?.id;
      } else if (newJobcenterState.jobcenterRadio === 'mine') {
        subjobcenterId = changeJobcenterViewModel?.userSubjobcenter?.id;
      }
      await citizenJobcenterClient.updateJobcenterAssociation({
        citizenId,
        jobcenterId: newJobcenterState.jobcenterRadio === 'othermunicipality' ? newJobcenterState?.municipality?.id : undefined,
        subjobcenterId,
      });
      onSave && onSave(newJobcenterState);
    } finally {
      setisLoading(false);
    }
  }

  return <ChangeJobcenterComponent {...props} isLoading={isLoading} initialState={defaultState} onSave={handleSave} changeJobcenterData={changeJobcenterViewModel} />;
}

function ChangeJobcenterComponent(props: IChangeJobcenterComponent) {
  const { initialState, onSave, onCancel, onClose, isOpen, changeJobcenterData, isLoading } = props;
  const [state, setState] = useState<ChangeJobcenterModel>(initialState);
  const [errors, setErrors] = useState<ChangeJobcenterErrors>({} as ChangeJobcenterErrors);

  function handleSave() {
    const isValid = handleValidate();
    if (isValid) {
      onSave && onSave(state);
    }
  }

  function handleCancel() {
    onCancel && onCancel();
  }

  function handleClose() {
    onClose && onClose();
  }

  function handleValidate() {
    const tmpErrors = {} as ChangeJobcenterErrors;

    if (!state.jobcenterRadio) {
      tmpErrors.jobcenterRadio = 'Du skal vælge en mulighed';
    } else {
      if (state.jobcenterRadio === 'otherjobcenter' && !state.jobcenter) {
        tmpErrors.jobcenter = 'Du skal vælge et jobcenter';
      }
      if (state.jobcenterRadio === 'othermunicipality' && !state.municipality) {
        tmpErrors.municipality = 'Du skal vælge en kommune';
      }
    }

    setErrors(tmpErrors);
    const isValid = Object.values(tmpErrors).every(x => x === null || x === '');
    return isValid;
  }

  function handleGenericObjectChange(name: string, value: object) {
    setState({ ...state, [name]: value });
    setErrors({ ...errors, [name]: '' });
  }

  function handleJobcenterRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
    setState({ ...state, jobcenterRadio: event.target.value as JobcenterType });
    setErrors({ ...errors, jobcenterRadio: '', jobcenter: '', municipality: '' });
  }

  function getJobcenterName() {
    return changeJobcenterData?.userSubjobcenter?.name ? `(${changeJobcenterData?.userSubjobcenter?.name})` : '';
  }

  return (
    <FormDialog
      open={isOpen}
      title="Jobcentertilknytning"
      isLoading={isLoading}
      confirmButtonText="Gem"
      cancelButtonText="Annullér"
      onConfirmClick={handleSave}
      onClose={handleClose}
      onCancelClick={handleCancel}
      maxWidth="md"
    >
      <FormControl fullWidth>
        <Container>
          <Row>
            <Cell size="two-thirds">
              <FormControl component="fieldset" error={!!errors.jobcenterRadio} sx={{ width: '100%' }} required>
                <Row>
                  <Cell size="full">
                    <FormLabel id="caseWorkerContact-radios" component="legend">
                      Ret borgerens jobcentertilknytning til:
                    </FormLabel>
                  </Cell>
                </Row>
                <RadioGroup aria-labelledby="caseWorkerContact-radios" row name="caseWorkerContact" value={state.jobcenterRadio || ''} onChange={handleJobcenterRadioChange}>
                  <Row>
                    <Cell size="full">
                      <RadioOption<JobcenterType>
                        value="mine"
                        control={<Radio />}
                        label={`Mit jobcenter ${getJobcenterName()}`}
                        disabled={!changeJobcenterData?.userSubjobcenter}
                      />
                    </Cell>
                  </Row>
                  <Row>
                    <Cell size="full">
                      <RadioOption<JobcenterType>
                        value="otherjobcenter"
                        control={<Radio />}
                        label="Et jobcenter i min kommune"
                        disabled={!changeJobcenterData?.municipalitySubjobcenters}
                      />
                    </Cell>
                  </Row>
                  <Row>
                    <Cell size="full">
                      <SingleSelect<SubjobcenterModel>
                        label="Vælg jobcenter"
                        value={state.jobcenter}
                        options={changeJobcenterData?.municipalitySubjobcenters ?? []}
                        isOptionEqualToValue={(option, val) => option.id === val.id}
                        getOptionLabel={(option: SubjobcenterModel) => option.name ?? ''}
                        renderOption={(renderProps: object, option: SubjobcenterModel) => (
                          <li {...renderProps} key={option.id}>
                            {option.name ?? ''}
                          </li>
                        )}
                        variant="outlined"
                        onChange={obj => handleGenericObjectChange('jobcenter', obj)}
                        error={!!errors.jobcenter}
                        errorText={errors.jobcenter}
                        disabled={state.jobcenterRadio !== 'otherjobcenter' || !changeJobcenterData?.municipalitySubjobcenters}
                        required={state.jobcenterRadio === 'otherjobcenter'}
                      />
                    </Cell>
                  </Row>
                  <Row>
                    <Cell size="full">
                      <RadioOption<JobcenterType> value="othermunicipality" control={<Radio />} label="Anden kommune" />
                    </Cell>
                  </Row>
                  <Row>
                    <Cell size="full">
                      <SingleSelect<JobcenterModel>
                        label="Vælg kommune"
                        value={state.municipality}
                        options={changeJobcenterData?.jobcenters ?? []}
                        isOptionEqualToValue={(option, val) => option.id === val.id}
                        getOptionLabel={(option: JobcenterModel) => option.name ?? ''}
                        renderOption={(renderProps: object, option: JobcenterModel) => (
                          <li {...renderProps} key={option.id}>
                            {option.name ?? ''}
                          </li>
                        )}
                        variant="outlined"
                        onChange={obj => handleGenericObjectChange('municipality', obj)}
                        error={!!errors.municipality}
                        errorText={errors.municipality}
                        disabled={state.jobcenterRadio !== 'othermunicipality'}
                        required={state.jobcenterRadio === 'othermunicipality'}
                      />
                    </Cell>
                  </Row>
                </RadioGroup>
                <FormHelperText sx={{ ml: 0 }}>{errors.jobcenterRadio}</FormHelperText>
              </FormControl>
            </Cell>
            <Cell size="thirds">
              <ChangeJobcenterHelpTextMenu texts={texts} />
            </Cell>
          </Row>
        </Container>
      </FormControl>
    </FormDialog>
  );
}
