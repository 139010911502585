export const userProfilesListPathName = 'user-profile';

export const usersListPathName = 'users';
export const subjobcentersPathName = 'subjobcenters';
export const userCreatePathName = 'edit';

export const teamListPathName = 'teams';

export const externalOperatorPathName = 'externaloperator';
export const externalOperatorAgreementsEditPathName = '/:externalOperatorAgreementId';

export const externalOperatorResponsibilityAreaPathName = 'external-operator-responsibility-area';
