/* eslint-disable react/no-array-index-key */
import React from 'react';
import { ICitation, IChatMessage } from '../../hooks/ai-search/use-chat';
import { Tooltip } from '../tooltip';

export interface TooltipLinkProps {
  href: string | undefined;
  children: any;
  chatMessage: IChatMessage;
  node: any;
}
export function TooltipLink(props: TooltipLinkProps) {
  const { href, children, chatMessage, node } = props;

  // Extract GUID from a string
  const extractGuid = (str: string): string | null => {
    try {
      const guidPattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
      const match = str.match(guidPattern);
      return match ? match[0] : null;
    } catch (e) {
      return null;
    }
  };

  // Get citation by ID
  const findCitationById = (id: string, message: IChatMessage): ICitation | undefined => message.citations?.find(citation => citation.id === id);

  // Extract the first GUID from children
  const id = extractGuid(children[0]);

  // Find the corresponding citation
  const citation = id ? findCitationById(id, chatMessage) : undefined;

  // Find the index of the citation
  const citationIndex = chatMessage.citations?.findIndex(x => x.id === id);

  // Generate tooltip text
  const tooltipText = citation ? `${citation.title}\n\n${citation.text}` : '';

  // Generate display text
  const displayText = citationIndex !== undefined && citationIndex > -1 ? citationIndex + 1 : children;

  return (
    <Tooltip title={tooltipText}>
      <a target="_blank" href={href ? decodeURIComponent(href) : undefined} rel="noreferrer" data-tip={href}>
        [{displayText}]
      </a>
    </Tooltip>
  );
}
