import { useEffect } from 'react';
import { useError } from './use-error';
import { isFasitProblemDetails } from '../api-service';
import { useNotifier } from '../components/notifier/notifier-context';
import { Severity } from '../api-service/clients';
import { NotifierMessageSeverityType, INotifierMessage } from '../components/notifier/interfaces';

export function useErrorSnackbar(...errors: any) {
  const error = useError(...errors);
  const { dispatch } = useNotifier();

  useEffect(() => {
    if (error) {
      let severity: NotifierMessageSeverityType = 'error';
      if (isFasitProblemDetails(error)) {
        const errSeverity = error.severity && error.severity.toLowerCase();
        if (errSeverity === Severity.Error) severity = 'error';
        if (errSeverity === Severity.Information) severity = 'info';
        // if (errSeverity === Severity.Success) severity = 'success';
        if (errSeverity === Severity.Warning) severity = 'warning';
      }
      const payload = { ...error, severity } as INotifierMessage;
      dispatch({ type: 'notify', payload });
    }
    // eslint-disable-next-line
  }, [error]);
}
