import * as React from 'react';
import { IRadioOptionProps } from './radio-option-props';
import { FormControlLabel } from '../../form-control-label';
import { Radio } from '../radio/radio';
import './radio-option.scss';

type RadioOptionFunctionComponent = (<TEnumValue extends string>(props: IRadioOptionProps<TEnumValue>) => JSX.Element) & React.FunctionComponent<IRadioOptionProps<any>>;

export const RadioOption: RadioOptionFunctionComponent = <TEnumValue extends string>({ children, control, ...otherProps }: IRadioOptionProps<TEnumValue>) => <FormControlLabel control={control!} classes={{ label: 'radio-option__label' }} {...otherProps} />;

RadioOption.defaultProps = {
  control: <Radio />,
};
