import React, { useState } from 'react';
import { produce } from 'immer';
import { Grid } from '@mui/material';
import { SingleSelect } from '../../../../../components/single-select';
import { RankedSearchFilterField } from '../../../../../api-service/clients';
import { getDocumentTypeDescriptor } from '../utils/document-type-descriptor';
import { IHaving } from '../../../store/reducers/ranked-search-reducer';

export interface ISearchDrawerCustomFilters {
  filters: RankedSearchFilterField[];
  onFilterChange: (selectedFilters: ISelectedFilter[]) => void;
  selectedHavings: IHaving[];
}
export interface ISelectedFilter {
  values: string[];
  name: string;
  documentType: string;
}
export function SearchDrawerCustomFilters(props: ISearchDrawerCustomFilters) {
  const { filters, onFilterChange, selectedHavings } = props;
  const [selectedFilters, setSelectedFilterFilters] = useState<ISelectedFilter[]>([]);

  function onChange(values: string[], name: string, documentType: string) {
    const index = selectedFilters.findIndex(x => x.name === name && x.documentType === documentType);
    const newFilters = produce(draft => {
      if (index !== -1) {
        // eslint-disable-next-line no-param-reassign
        draft[index].values = values.filter(x => x).length > 0 ? values : undefined;
      } else {
        draft.push({ name, documentType, values });
      }
    })(selectedFilters);

    setSelectedFilterFilters(newFilters);
    onFilterChange(newFilters);
  }

  function translateOption(option: string, filter: RankedSearchFilterField) {
    if (filter.fieldType === 'Boolean') {
      switch (option) {
        case '0':
          return 'Nej';
        case '1':
          return 'Ja';
        default:
          return option;
      }
    }
    return option;
  }

  function getValue(value: RankedSearchFilterField) {
    const found = selectedFilters.find(x => x.name === value.name && x.documentType === value.documentType);
    if (found && found.values) {
      return found.values[0];
    }
    return undefined;
  }

  return (
    <Grid container spacing="7px" style={{ paddingTop: '7px' }}>
      {filters
        .sort((a, b) => (a.displayName || '').localeCompare(b.displayName || ''))
        .filter(x => (x.values || []).length > 0)
        .map(x => (
          <Grid item xs={4} key={x.name}>
            <SingleSelect<string>
              style={{ borderLeft: `solid 7px ${getDocumentTypeDescriptor(x.documentType || '')?.color}` }}
              options={x.values || []}
              value={getValue(x)}
              label={x.displayName || ''}
              onChange={v => onChange([v], x.name || '', x.documentType || '')}
              readOnly={!selectedHavings.some(sh => sh.value === x.documentType)}
              getOptionLabel={o => translateOption(o, x)}
              renderOption={(optionProps: object, option: string) => (
                <li {...optionProps} key={option} title={translateOption(option, x)}>
                  {translateOption(option, x)}
                </li>
              )}
            />
          </Grid>
        ))}
    </Grid>
  );
}
