import React, { PropsWithChildren } from 'react';

export const AbsolutelyCentered = (props: PropsWithChildren<any>) => {
  const { children, className } = props;

  return (
    <div
      className={className}
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );
};
