/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import { secureAxiosInstance } from '../../../api-service';
import { AdminAuditLogClient, FileResponse } from '../../../api-service/clients';
import { CircularProgressCentered } from '../../../components/circular-progress-centered/circular-progress-centered';
import { configuration } from '../../../configuration';
import { useApiOnRender } from '../../../hooks';
import { downloadAsFile } from '../../../utilities';

export function DownloadAuditlogReport() {
  const { reportId } = useParams();

  const auditLogClient = new AdminAuditLogClient(configuration.bffBase, secureAxiosInstance);

  const { result, isLoading, isReady, isError } = useApiOnRender(
    cancelToken => auditLogClient.getReport({ reportId }, cancelToken),
    {} as FileResponse
  );

  if (isReady) {
    downloadAsFile(result.data, result.fileName || 'auditlog.csv');
  }

  return (
    <>
      {!isError && (
        <>
          <h1>Downloader auditlog csv fil</h1>
          <p>Bemærk at excel maks kan håndtere 1 million rækker i en csv fil.</p>
        </>
      )}

      {isError && <h1> Filen kunne ikke findes.</h1>}
      {isLoading && <CircularProgressCentered />}
    </>
  );
}
