export const formatBoolean = (value?: boolean | number | string, missingValue = '-') => {
  if (value === undefined || value === null) return missingValue;
  if (typeof value === 'number') return value ? 'Ja' : 'Nej';
  if (typeof value === 'string') {
    switch (value) {
      case 'true':
        return 'Ja';
      case 'false':
        return 'Nej';
      default:
        return value;
    }
  }
  return value ? 'Ja' : 'Nej';
};
