import { IField } from './field-interfaces';
import { IRule } from './rule-interface';
import { getValueForOperator } from './operators-functions';
import { IQueryBuilderContext } from './query-builder-context';

export function getFieldChanges(context: IQueryBuilderContext, fields: IField[], rule: IRule, newFieldName: string): IRule | null {
  if (newFieldName === rule.field) return null;
  const selectedField = fields.find(x => x.name === newFieldName);
  if (!selectedField) return null;
  const previousField = fields.find(x => x.name === rule.field);

  const operators = context.getOperators(selectedField);
  const operator = operators.find(x => x.name === rule.operator) || operators[0];

  let value: string | string[] = '';
  if (previousField && previousField.type === selectedField.type && previousField.codelistType === selectedField.codelistType) {
    value = rule.value;
  }

  return {
    field: newFieldName,
    operator: operator.name,
    value: getValueForOperator(operator.name, value),
  } as IRule;
}
