import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiAvatar from '@mui/material/Avatar';
import { IMainNavigationItem } from './main-navigation-items';
import { ColorPalette } from '../../config';

const useStyles = makeStyles({
  avatarInactive: {
    backgroundColor: 'transparent',
    color: ColorPalette.DefaultTextColor,
  },
  avatarActive: {
    backgroundColor: ColorPalette.HotAurora,
    color: ColorPalette.WHITE,
  },
  avatarActiveAlternative: {
    backgroundColor: ColorPalette.DeepGalaxy,
    color: ColorPalette.WHITE,
  },
});

export interface IItemAvaterProps {
  item: IMainNavigationItem;
  isActive: boolean;
}

export function ItemAvatar(props: IItemAvaterProps) {
  const { item, isActive } = props;
  const classes = useStyles({});

  return isActive ? (
    <MuiAvatar data-testid="item-active" className={`${item.alternative ? classes.avatarActiveAlternative : classes.avatarActive} main-navigation__avatar when--active`}>
      <FontAwesomeIcon icon={item.selectedIcon} className="main-navigation__icon when--active" />
    </MuiAvatar>
  ) : (
    <MuiAvatar data-testid="item-inactive" className={`${classes.avatarInactive} main-navigation__avatar when--inactive`}>
      <FontAwesomeIcon icon={item.icon} className="main-navigation__icon when--inactive" />
    </MuiAvatar>
  );
}
