import * as React from 'react';
import MuiAvatar from '@mui/material/Avatar';
import { IAvatarProps, IAvatarType } from './avatar-props';
import { InfoIcon, LinkIcon } from '../icons';

export class Avatar extends React.Component<IAvatarProps> {
  private static getIcon(type: IAvatarType) {
    switch (type) {
      case 'info':
        return <InfoIcon />;

      case 'link':
        return <LinkIcon />;

      default:
        return <InfoIcon />;
    }
  }

  private getClassName(type: IAvatarType) {
    const { children } = this.props;
    if (children) {
      return '';
    }

    switch (type) {
      case 'info':
        return 'primary';

      case 'link':
        return 'hot-aurora';

      default:
        return 'primary';
    }
  }

  public static testIds = {
    root: 'avatar-root-wrapper',
  };

  render() {
    const { children, ...props } = this.props;

    let renderItem = null;

    if (!children) {
      renderItem = Avatar.getIcon(props.type);
    } else {
      renderItem = children;
    }

    return (
      <MuiAvatar className={this.getClassName(props.type)} data-testid={Avatar.testIds.root} {...props}>
        {renderItem}
      </MuiAvatar>
    );
  }
}
