import React, { useState, useEffect } from 'react';
import { FilledTextFieldProps, TextFieldProps } from '@mui/material/TextField';
import ReactDatePicker from 'react-datepicker';
import { defaultDateFormat, formatDate, toDate, correctCentury } from '../../utilities';
import { DatePickerCustomInput } from './date-picker-custom-input';

import './date-picker.scss';
import { validateDefaultDateFormat, validateExpandedDateFormat } from './date-validators';

export type DatePickerValue = Date | string | undefined;

export interface IDatePickerProps {
  value: DatePickerValue;
  onChange: (date: DatePickerValue) => void;
  name?: string;
  className?: string;
  label?: FilledTextFieldProps['label'];
  minDate?: Date;
  maxDate?: Date;
  error?: boolean;
  errorText?: React.ReactNode;
  warningText?: string;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  inlineDisplay?: boolean;
  // Validators are functions that return ether "undefined"=invalid or any value=valid.
  // Validators are executed in the order they are specified until a validator returns a value other than "undefined".
  // If all validators return undefined then the value is considered invalid.
  validators?: Array<(value: string) => DatePickerValue>;
  invalidDateMessage?: string;
  showTimeSelect?: boolean | undefined;
  fullWidth?: boolean;
  variant?: TextFieldProps['variant'];
  helperText?: TextFieldProps['helperText'];
  onCalendarOpen?(): void;
  onCalendarClose?(): void;
  onInternalValidation?(failiureCause: string | undefined): void;
}

function validate(s: string, validators: Array<(value: string) => DatePickerValue>) {
  for (const validator of validators) {
    const result = validator(s);
    if (result) {
      return result;
    }
  }
  return undefined;
}

export function DatePicker(props: IDatePickerProps) {
  const {
    value,
    onChange,
    name,
    className,
    label,
    minDate,
    maxDate,
    required,
    readOnly,
    error,
    errorText,
    disabled,
    inlineDisplay,
    warningText,
    validators = [validateDefaultDateFormat, validateExpandedDateFormat],
    invalidDateMessage = 'Indtast en gyldig dato',
    variant = 'filled',
    showTimeSelect,
    helperText,
    fullWidth,
    onCalendarOpen,
    onCalendarClose,
    onInternalValidation,
  } = props;
  const [selected, setSelected] = useState(toDate(value));
  const [rawState, setRawState] = useState(formatDate(value));

  useEffect(() => {
    setRawState(formatDate(value));
    setSelected(toDate(value));
  }, [value]);

  function apply() {
    if (!rawState) {
      onChange(undefined);
      return;
    }

    let validatedDate = validate(rawState, validators);
    if (validatedDate) {
      validatedDate = correctCentury(validatedDate);
      onChange(validatedDate);
    }
    onInternalValidation && onInternalValidation(validatedDate === undefined ? invalidDateMessage : undefined);
  }

  function handleChange(date: Date | null) {
    const val = date == null ? undefined : date;
    setSelected(toDate(val));
    setRawState(formatDate(val));
    onChange(val);
  }

  function handleChangeRaw(event: React.FocusEvent<HTMLInputElement>) {
    setRawState(event.target.value);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' || event.key === 'Tab') {
      apply();
    }
  }

  const dateError = rawState && validate(rawState, validators) === undefined ? invalidDateMessage : undefined;

  function customInput() {
    return (
      <DatePickerCustomInput
        className={className}
        label={label}
        disabled={disabled}
        error={error || !!dateError}
        errorText={errorText || dateError}
        warningText={warningText}
        variant={variant}
        fullWidth={fullWidth}
        helperText={helperText}
      />
    );
  }

  return (
    <ReactDatePicker
      wrapperClassName={inlineDisplay ? 'inline-display' : undefined}
      selected={selected}
      value={rawState}
      onChange={handleChange}
      onChangeRaw={handleChangeRaw}
      onClickOutside={apply}
      onKeyDown={handleKeyDown}
      placeholderText={defaultDateFormat}
      dateFormat={defaultDateFormat}
      minDate={minDate}
      maxDate={maxDate}
      customInput={customInput()}
      name={name}
      required={required}
      locale="da-dk"
      showWeekNumbers
      weekLabel="uge"
      strictParsing
      className={className}
      disabled={disabled}
      readOnly={readOnly}
      onCalendarOpen={onCalendarOpen}
      onCalendarClose={onCalendarClose}
      showYearDropdown
      yearDropdownItemNumber={10}
    />
  );
}
