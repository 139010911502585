export const searchAreaPathName = 'search';
export const searchAreaMenuName = 'Arbejdslister og diagrammer';

export const savedQueriesPathName = 'saved';
export const editQueryPathName = 'edit';
export const worklistPathName = 'worklist';
export const diagramPathName = 'chart';
export const savedChartsPathName = 'saved-charts';
export const dashboardsPathName = 'dashboards';
export const savedDashboardsPathName = 'saved-dashboards';
export const sharingOverviewPathName = 'sharing-overview';
