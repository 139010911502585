import { OmnibarEntry } from '../omnibar-interfaces';

const functions: OmnibarEntry[] = [
  {
    id: 'adc523fc-ee4b-4221-8816-2df9f54ed3a2',
    label: 'Gemte arbejdslister',
    context: 'none',
    group: 'Arbejdslister',
    url: '/deeplink/search/saved',
  },
  {
    id: 'c68fbeb8-e912-4d6e-9df1-ed788f23cad1',
    label: 'Gemte diagrammer',
    context: 'none',
    group: 'Arbejdslister',
    url: '/deeplink/search/saved-charts',
  },
  {
    id: '3713ca32-02d0-45e2-be8d-be0f93171b24',
    label: 'Ny arbejdsliste',
    context: 'none',
    group: 'Arbejdslister',
    url: '/deeplink/search/edit',
  },
  {
    id: 'b98a0b77-b7d3-4888-a9a1-5a2bd5ec05c9',
    label: 'Nyt diagram',
    context: 'none',
    group: 'Arbejdslister',
    url: '/deeplink/search/chart',
  },
];

export default functions;
