import { useEffect, useRef, DependencyList, EffectCallback } from 'react';

// UseEffect, but skip effect of initial values.
export function useNextEffect(effect: EffectCallback, deps?: DependencyList) {
  const first = useRef(true);
  useEffect(() => {
    if (first.current) {
      first.current = false;
    } else {
      effect();
    }
    // eslint-disable-next-line
  }, deps);
}
