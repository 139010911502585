import * as React from 'react';
import Switch from '@mui/material/Switch';
import { alpha, styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { FormControlLabel } from '@mui/material';

export interface IToggleElementProps {
  label?: string;
  className: string;
  checked: boolean;
  onChange: (value: React.ChangeEvent<HTMLElement>, checked: boolean) => void;
  title: string;
  disabled: boolean;
}

export function ToggleElement(props: IToggleElementProps) {
  const { checked, onChange, title, disabled, className } = props;

  const handleChange = (value: React.ChangeEvent<HTMLElement>) => {
    onChange(value, !checked);
  };

  const Toggle = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      '&:hover': {
        backgroundColor: alpha(green[400], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: green[400],
    },
  }));

  return (
    <div className={className}>
      <FormControlLabel
        value="start"
        control={<Toggle disableRipple color="default" checked={checked} onChange={handleChange} disabled={disabled} />}
        label={title}
        labelPlacement="start"
      />
    </div>
  );
}
