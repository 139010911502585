/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useRef } from 'react';
import { FilledTextFieldProps, TextFieldProps, PopperProps, Autocomplete, Box } from '@mui/material';
import { StyledComponentProps } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '../text-field';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      fontSize: '0.85rem',
      whiteSpace: 'nowrap',
    },
  })
);
// Please think carefully before adding new props to this.
// If you need multiple select, then create separate component for that.
export interface ISingleSelectProps<T, ClassKey extends string = string> extends StyledComponentProps<ClassKey> {
  label: string;
  value: T | undefined;
  onChange: (value: T) => void;
  onOpen?: () => void;
  onClose?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onEnterDown?: (isOpen: boolean, event: React.KeyboardEvent<HTMLDivElement>) => void;
  loadingText?: string;
  loading?: boolean;
  getOptionLabel: (option: T) => string;
  renderOption: (props: object, option: T, state: object) => React.ReactNode;
  options: T[];
  disabled?: boolean;
  disableClearable?: true;
  error?: boolean;
  errorText?: FilledTextFieldProps['helperText'];
  helperText?: FilledTextFieldProps['helperText'];
  name?: FilledTextFieldProps['name'];
  required?: boolean;
  className?: string;
  isOptionEqualToValue?: (option: T, val: T) => boolean;
  style?: React.CSSProperties;
  noOptionsText?: string;
  variant?: TextFieldProps['variant'];
  popperComponent?: React.ComponentClass<PopperProps, any> | React.FunctionComponent<PopperProps> | undefined;
  autoHighlight?: boolean;
  readonly?: boolean; // old wrong name for readOnly
  readOnly?: boolean; // new name for readonly
  inputRootClass?: string;
  inputElementRootClass?: string;
  size?: TextFieldProps['size'];
  placeholder?: TextFieldProps['placeholder'];
  title?: string;
  autoFocus?: TextFieldProps['autoFocus'];
  inputRef?: ((instance: any) => void) | React.RefObject<any> | undefined;
  onOpenEntity?: () => void;
}

export function SingleSelect<T>(props: ISingleSelectProps<T>) {
  const {
    className,
    label,
    required,
    onChange,
    value,
    error,
    errorText,
    helperText,
    renderOption,
    noOptionsText,
    variant = 'filled',
    autoHighlight = true,
    popperComponent,
    readonly = false,
    readOnly = false,
    disabled = false,
    inputRootClass = '',
    inputElementRootClass = '',
    size,
    placeholder,
    title,
    autoFocus,
    inputRef,
    onKeyDown,
    onEnterDown,
    onOpen,
    onClose,
    onOpenEntity,
    ...rest
  } = props;
  const isOpenRef = useRef(false);

  function handleOnChange(event: ChangeEvent<{}>, selected: T) {
    onChange && onChange(selected);
  }
  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    onKeyDown && onKeyDown(event);
    event.code === 'Enter' && onEnterDown && onEnterDown(isOpenRef.current, event);
  }
  const classes = useStyles();

  const getValue = () => (value === null || value === undefined ? (null as any) : value);

  return (
    <Autocomplete<T, false, true>
      {...rest}
      value={getValue()}
      onChange={handleOnChange as never}
      classes={{
        paper: classes.paper,
        inputRoot: inputRootClass,
      }}
      onOpen={() => {
        isOpenRef.current = true;
        onOpen && onOpen();
      }}
      onClose={() => {
        isOpenRef.current = false;
        onClose && onClose();
      }}
      onKeyDown={e => {
        handleKeyDown(e);
      }}
      className={className || ''}
      PopperComponent={popperComponent}
      disableListWrap
      autoHighlight={autoHighlight}
      selectOnFocus
      renderOption={renderOption}
      renderInput={params => {
        const { inputProps } = params;
        const { className: textFieldClassName } = inputProps;
        return (
          <TextField
            inputRef={inputRef}
            autoFocus={autoFocus}
            {...params}
            label={label}
            required={required}
            error={error}
            errorText={errorText}
            helperText={helperText}
            variant={variant}
            multiline={false}
            title={title}
            inputProps={{
              ...inputProps,
              className: `${textFieldClassName} ${inputElementRootClass}`,
              autoComplete: 'off',
              placeholder,
            }}
            disabled={params.disabled}
            readOnly={readonly || readOnly}
            size={size}
            InputProps={{
              ...params.InputProps,
              startAdornment:
                onOpenEntity && getValue() ? (
                  <Box sx={{ padding: '6px' }}>
                    <FontAwesomeIcon cursor="pointer" icon={faArrowUpRightFromSquare} onClick={onOpenEntity} />
                  </Box>
                ) : undefined,
            }}
          />
        );
      }}
      noOptionsText={noOptionsText || 'Ingen valgmuligheder'}
      disabled={disabled || readonly || readOnly}
      forcePopupIcon={readonly || readOnly ? false : 'auto'}
    />
  );
}
