import { RouteComponentProps } from 'react-router-dom';
import { StaticContext } from 'react-router';
import { User } from 'oidc-client';
import { win } from '../utilities/window';

export function onAuthenticationSuccess(routeProps: RouteComponentProps<any, StaticContext, any>): ((user: User) => void) | undefined {
  return (user: User) => {
    const accessToken = JSON.parse(atob(user.access_token.split('.')[1]));
    const stsUserProvisioningMessage = accessToken['X-UserProvisioningMessage'];
    const returnPathFromCallback = user.state.authenticationState;
    if (stsUserProvisioningMessage) {
      routeProps.history.push('/fko-error-page', { message: stsUserProvisioningMessage, returnPathFromCallback });
      return;
    }
    if (!returnPathFromCallback) {
      // we don't know where we are going, so just go to the front page
      routeProps.history.push('/');
      return;
    }
    // it's important that we don't use the routeProps.history.push method here,
    // since that would set the location to a path relative to the Routers basename. eg. if the app is running with basename: /search
    // but the returnPath is an absolute path that we must redirect to exactly, so we use window.location in this case.
    win.location.href = returnPathFromCallback;
  };
}

export function onAuthenticationError(routeProps: RouteComponentProps<any, StaticContext, any>): ((err: any) => void) | undefined {
  return error => {
    routeProps.history.push('/error');
  };
}
