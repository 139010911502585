/**
 * Creates a new URL by combining the specified URLs
 *
 * @param {string[]} urls The urls to join
 * @returns {string} The combined URL
 */
function joinParts(a: string, b: string) {
  if (!a) return b;
  if (!b) return a;

  return `${a.replace(/\/$/, '')}/${b.replace(/^\/+/, '').replace(/\/+$/, '')}`;
}

export function joinURL(...urls: string[]): string {
  if (!urls) return '';

  switch (urls.length) {
    case 0:
      return '';
    case 1:
      return urls[0].replace(/\/+$/, '');
    default:
      return urls.reduce(joinParts, '');
  }
}
