import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { TextField } from '../text-field';
import { IconButton } from '@mui/material';
import { InputAdornment } from '../input-adornment';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { IBrowseControlProps } from './browse-control-props';

import './browse-control.scss';

export const browseControlTestIds = {
  textField: 'browse-control-textfield',
  browseButton: 'browse-control-browse-button',
};

export const BrowseControl: React.FunctionComponent<IBrowseControlProps> = props => {
  function createInputProps() {
    const title: string = props.value as string;

    return {
      title,
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label={props.buttonLabel}
            data-testid={browseControlTestIds.browseButton}
            className="browse-control__button"
            onClick={props.onButtonClick}
            size="large">
            <FontAwesomeIcon icon={faEllipsisH} className="lookup-control__svg-icon" />
          </IconButton>
        </InputAdornment>
      ),
    };
  }

  return (
    <TextField
      className="browse-control"
      label={props.label}
      onClick={props.onClick}
      onKeyDown={props.onKeyDown}
      onChange={props.onChange}
      value={props.value}
      error={props.error}
      inputProps={{ className: 'browse-control__custom-input' }}
      inputRef={props.inputRef}
      InputProps={createInputProps()}
      data-testid={browseControlTestIds.textField}
      helperText={props.helperText}
      errorText={props.errorText}
      readOnly={props.readOnly}
      disabled={props.disabled}
      required={props.required}
      fullWidth={props.fullWidth}
      multiline={false}
    />
  );
};

