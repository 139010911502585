import React, { PropsWithChildren } from 'react';
import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { TypographySettings } from '../config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: TypographySettings.AlternativeFontFamily,
      fontSize: '1rem',
      fontWeight: 400,
      margin: `0 0 ${theme.spacing(3)} 0`,
      padding: 0,
    },
    noMargin: {
      marginBottom: 0,
    },
  })
);

export function FormDialogSubHeading(props: PropsWithChildren<{ noMargin?: boolean }>) {
  const { children, noMargin = false } = props;
  const classes = useStyles();
  return (
    <Typography component="h5" className={`${classes.root} ${noMargin ? classes.noMargin : ''}`}>
      {children}
    </Typography>
  );
}
