import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Button } from '../../../../../components/button/button';

export interface IActionElementProps {
  label?: string;
  icon?: IconDefinition;
  className: string;
  disabled: boolean;
  handleOnClick: React.MouseEventHandler<HTMLElement>;
  title: string;
}

export function ActionElement(props: IActionElementProps) {
  const { label, disabled, handleOnClick, className, icon } = props;

  const onClick = (value: React.MouseEvent<HTMLElement, MouseEvent>) => handleOnClick(value);

  return (
    <Button className={className} disabled={disabled} variant="text" color="primary" onClick={onClick}>
      {icon ? <FontAwesomeIcon icon={icon} style={{ marginRight: '7px' }} /> : null}
      {label}
    </Button>
  );
}
