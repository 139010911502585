import { Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { SearchDrawerContainer } from '../../areas/ranked-search/components/search-drawer/search-drawer-container';
import { useAuthenticatedUser } from '../../authentication';
import { useTenantConfiguration } from '../../hooks/use-tenant-configuration';
import { AppHeader } from '../app-header';
import { CssBaseDecorator } from '../cssbasedecorator';
import { PurchasedFeaturesProvider } from '../purchased-feature';
import './layout.scss';
import { MainNavigation } from './main-navigation/main-navigation';

export function RootLayout(props: PropsWithChildren<Record<string, unknown>>) {
  const { children } = props;

  return (
    <CssBaseDecorator>
      <div className="layout__container">{children}</div>
    </CssBaseDecorator>
  );
}

export function DefaultLayout(props: PropsWithChildren<Record<string, unknown>>) {
  const { children } = props;
  const user = useAuthenticatedUser();

  const tenantDisplayConfiguration = useTenantConfiguration();
  const { tenantDisplayName } = tenantDisplayConfiguration;

  return (
    <PurchasedFeaturesProvider>
      <RootLayout>
        <div className="layout__main-navigation">
          <MainNavigation />
        </div>
        <div className="layout__app-header">
          <AppHeader>
            {user && user.profile && user.profile.name}
            <br />
            <Typography style={{ fontSize: '12px' }}>{tenantDisplayName}</Typography>
          </AppHeader>
        </div>
        <div className="layout__content">{children}</div>
        <SearchDrawerContainer />
      </RootLayout>
    </PurchasedFeaturesProvider>
  );
}

export function StandaloneLayout(props: PropsWithChildren<Record<string, unknown>>) {
  const { children } = props;

  return (
    <RootLayout>
      <div className="layout__content">{children}</div>
    </RootLayout>
  );
}
