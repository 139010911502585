import { FasitProblemDetails } from './clients';

function getDataFromArrayBuffer(buffer: ArrayBuffer): FasitProblemDetails {
  const decoder = new TextDecoder();
  const decodedText = decoder.decode(buffer);
  return JSON.parse(decodedText);
}

function getResponseData(error: any): FasitProblemDetails | undefined {
  const responseData = error?.response?.data;

  if (!responseData) {
    return undefined;
  }

  if (responseData instanceof ArrayBuffer) {
    return getDataFromArrayBuffer(responseData);
  }

  return responseData;
}

export function isFasitProblemDetails(data: any): data is FasitProblemDetails {
  const ec = data as FasitProblemDetails;
  return ec && !!ec.title && ec.detail !== undefined && !!ec.severity;
}

export function getFasitProblemDetails(error: any): FasitProblemDetails | undefined {
  if (error) {
    if (isFasitProblemDetails(error)) {
      return error;
    }

    const responseData = getResponseData(error);

    if (isFasitProblemDetails(responseData)) {
      return responseData;
    }
  }
  return undefined;
}
