import MuiCheckbox, { CheckboxClassKey } from '@mui/material/Checkbox';
import * as React from 'react';
import { CheckmarkIcon } from './checkmark-icon/checkmark-icon';
import { ICheckboxProps } from './checkbox-props';
import { FormControlLabel } from '../form-control-label';
import './checkbox.scss';

function generateClasses(): Partial<Record<CheckboxClassKey, string>> {
  return {
    root: 'checkbox-scope checkbox',
    checked: 'checkbox--checked',
    disabled: 'checkbox--disabled',
  };
}

export class Checkbox extends React.PureComponent<ICheckboxProps> {
  static defaultProps = {
    checked: false,
    disabled: false,
    label: '',
  };

  render() {
    const { children, ...props } = this.props;
    const checkbox = (
      <MuiCheckbox
        checkedIcon={
          <span className="checkbox__icon-container">
            <CheckmarkIcon />
          </span>
        }
        icon={<span className="checkbox__icon-container" />}
        classes={generateClasses()}
        TouchRippleProps={{ className: 'checkbox__ripple' }}
        {...props}
      />
    );

    if (props.label) {
      return (
        <FormControlLabel<ICheckboxProps>
          control={checkbox}
          label={props.label}
          disabled={props.disabled}
          classes={{
            label: 'checkbox__label',
          }}
        />
      );
    }

    return checkbox;
  }
}
