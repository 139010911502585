import React from 'react';
import { Checkbox, Autocomplete, AutocompleteChangeReason, AutocompleteChangeDetails, PopperProps, Popper, CircularProgress } from '@mui/material';
import { IAutocompleteValue } from 'areas/search/save-dialog';
import { QueryBuilderOperator } from '../operators';
import { ITextFieldProps } from '../../../../../components/text-field/text-field-props';
import { TextField } from '../../../../../components/text-field/text-field';

export interface ILookupSelectorProps {
  onSearch?: (text: string) => void;
  value: IAutocompleteValue[];
  field?: string;
  type?: string;
  operator?: string | QueryBuilderOperator;
  onSelect: (value: IAutocompleteValue | IAutocompleteValue[]) => void;
  error?: boolean;
  errorText?: string | string[];
  disabled?: boolean;
  textFieldProps?: ITextFieldProps;
  data: IAutocompleteValue[];
  isLoading: boolean;
  isMulti: boolean;
  onInputChange?: (event: any) => void;
  noOptionsMessage: string;
}

function CustomPopper(props: PopperProps) {
  const styles = {
    popper: {
      width: 'auto',
    },
  };
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
}

export function LookupSelector(props: ILookupSelectorProps) {
  const { onSearch, value, onSelect, noOptionsMessage, data, error, errorText, isLoading, isMulti, onInputChange, textFieldProps, disabled } = props;
  function handleOnKeyDown(e: any) {
    const enterKey = 13;
    if (e.target.value && e.keyCode === enterKey) {
      onSearch && onSearch(e.target.value);
    } else {
      onInputChange && onInputChange(e);
    }
  }

  return (
    <>
      {isMulti && (
        <Autocomplete<IAutocompleteValue, true, boolean>
          multiple
          renderInput={params => <TextField {...params} error={error} errorText={errorText} label={textFieldProps?.label} multiline={false} />}
          value={value}
          onChange={(event: React.ChangeEvent<{}>, value: IAutocompleteValue[], reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<unknown> | undefined) =>
            onSelect(value)
          }
          disabled={disabled}
          ChipProps={{ variant: 'outlined', size: 'small' }}
          onKeyDown={handleOnKeyDown}
          getOptionLabel={option => option.title}
          renderOption={(renderOptionProps, option, { selected }) => (
            <li {...renderOptionProps}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {option.title}
            </li>
          )}
          options={data}
          disableCloseOnSelect
          isOptionEqualToValue={(option: IAutocompleteValue, value: IAutocompleteValue) => value.value === option.value}
          PopperComponent={CustomPopper}
          noOptionsText={noOptionsMessage}
          loading={isLoading}
          disableClearable={isLoading && !!value}
          popupIcon={isLoading ? <CircularProgress size={20} /> : null}
          style={{ minWidth: '300px', maxWidth: '570px' }}
        />
      )}
      {!isMulti && (
        <Autocomplete<IAutocompleteValue, false, true>
          renderInput={params => <TextField {...params} error={error} errorText={errorText} label={textFieldProps?.label} multiline={false} />}
          value={value[0] || null}
          onChange={(event: React.ChangeEvent<{}>, value: IAutocompleteValue, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<unknown> | undefined) =>
            onSelect(value)
          }
          disabled={disabled}
          clearOnBlur
          ChipProps={{ variant: 'outlined', size: 'small' }}
          onKeyDown={handleOnKeyDown}
          getOptionLabel={option => option.title}
          isOptionEqualToValue={(option: IAutocompleteValue, value: IAutocompleteValue) => value.value === option.value}
          options={data}
          PopperComponent={CustomPopper}
          noOptionsText={noOptionsMessage}
          loading={isLoading}
          disableClearable={isLoading ? true : undefined}
          popupIcon={isLoading ? <CircularProgress size={20} /> : null}
          filterOptions={x => x}
          style={{ minWidth: '300px' }}
        />
      )}
    </>
  );
}
