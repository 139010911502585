import React, { useState, useEffect } from 'react';
import { IAutocompleteValue } from '../../../save-dialog';
import { QueryBuilderFieldOptions } from '../../query/query-builder-field-options';
import { QueryBuilderOperator } from '../operators';
import { RelativeDateEditor } from './relative-date-editor';
import { CodeListSelector } from './codelist-selector';
import { LookupValueSelector } from './lookupvalue-selector';
import { singleValueEditor } from './single-value-editor';
import { rangeValueEditor } from './range-value-editor';
import { useCachedValue } from '../../../../../hooks';
import { IValueEditorProps } from './value-editor-props';
import { UiHint } from '../../query/search-field';
import { LookupSelector } from './lookup-selector';
import { moduleClientFactory } from '../../../../../api-service/module-client-factory';
import { validationMessages } from '../defaults';
import { MultiSelectEntityLookup } from '../../../../../api-service/clients';
import { useFeatureToggles } from '../../../../../feature-toggles';

function varyingNumberOfInputs(props: IValueEditorProps) {
  const { value, handleOnChange } = props;
  const arr = Array.isArray(value) ? value.slice(0) : [value];
  if (arr[arr.length - 1] !== '') {
    arr.push('');
  }
  return (
    <>
      {arr.map((single, index) => {
        const onArrayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          let arr2 = arr.slice(0);
          arr2[index] = e.target.value;
          arr2 = arr2.filter(x => x !== '');
          return handleOnChange(arr2);
        };
        return singleValueEditor(index, single, props, onArrayChange);
      })}
    </>
  );
}

export const SingleInput: React.FunctionComponent<IValueEditorProps> = props => {
  const { handleOnChange } = props;
  const [value, setValue] = useCachedValue(props.value);

  const handleInternalStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  const handleValueChanged = (e: React.ChangeEvent<HTMLInputElement>, checked = false) => {
    if (e.target.type === 'checkbox') {
      handleOnChange(checked ? 'true' : 'false');
    } else {
      handleOnChange(e.target.value);
    }
  };
  const val = Array.isArray(value) ? value[0] : value;
  return singleValueEditor(undefined, val, props, handleValueChanged, handleInternalStateChange);
};

function codeListSelector(props: IValueEditorProps) {
  const { codeListType, operator, field, value, type, handleOnChange, disabled, className, error } = props;
  if (codeListType && field && operator && operator !== QueryBuilderOperator.NotEmpty && operator !== QueryBuilderOperator.Empty) {
    return (
      <CodeListSelector
        codelistType={codeListType}
        type={type}
        operator={operator}
        handleOnChange={handleOnChange}
        value={Array.isArray(value) ? value : [value]}
        disabled={disabled}
        textFieldProps={{ className, error: !!error, errorText: error }}
      />
    );
  }
  return null;
}

function lookupValueSelector(props: IValueEditorProps) {
  const { codeListType, operator, handleOnChange, value, disabled, className, error } = props;

  if (codeListType && operator && operator !== QueryBuilderOperator.NotEmpty && operator !== QueryBuilderOperator.Empty) {
    return (
      <LookupValueSelector
        codeListType={codeListType}
        handleOnChange={handleOnChange}
        value={value}
        disabled={disabled}
        className={className}
        error={!!error}
        errorText={Array.isArray(error) ? error[0] : error}
      />
    );
  }
  return null;
}

export function ValueEditor(props: IValueEditorProps) {
  const { operator, type, uiHint, codeListName, universe, value, field } = props;
  const { disabled } = props;
  const [data, setData] = useState<IAutocompleteValue[]>([]);
  const [selected, setSelected] = useState<IAutocompleteValue[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const multiSelectEntityLookupClient = moduleClientFactory.createMultiSelectEntityLookupClient();
  const useLookupSelector = uiHint === UiHint.MultipleSelectLookup;
  const [showNoOptionMessage, setShowNoOptionMessage] = useState<boolean>(false);
  const featureToggles = useFeatureToggles();
  // Should be removed when all Uppercase guids in saved worklists is changed to lowercase
  const loweredValues = toLower(value);

  function toLower(val: string | string[]) {
    // value could be date but changing the type will result in many typings to be updated

    if (Array.isArray(val)) {
      return val?.map(x => {
        if (typeof x !== 'string') {
          return x;
        }
        return (x as string).toLocaleLowerCase();
      });
    }
    if (typeof val === 'string') {
      return val?.toLocaleLowerCase();
    }
    return val;
  }

  useEffect(() => {
    if (useLookupSelector && value) {
      getSelectedValues(Array.isArray(value) ? value : [value]);
    }
  }, [useLookupSelector, universe, value]);

  async function getSelectedValues(entityIds: string[]) {
    try {
      if (universe && codeListName) {
        setIsLoading(true);
        const filteredEntity = entityIds.filter(x => x !== '@bruger' && x !== '@team');
        let result: MultiSelectEntityLookup[] = [];
        if (filteredEntity.length > 0) {
          result = await multiSelectEntityLookupClient.getResultByEntityIds(universe.name, codeListName, filteredEntity);
          addStaticSearchEntityFromIds(entityIds, result);
        } else {
          addStaticSearchEntityFromIds(entityIds, result);
        }
        const dataResult = result.map(r => ({ title: r.shortLabel, value: r.value } as IAutocompleteValue));

        setSelected(dataResult);
      }
    } finally {
      setIsLoading(false);
    }
  }
  function addStaticSearchEntityFromIds(ids: string[], result: MultiSelectEntityLookup[]) {
    if (ids.some(x => x.toLocaleLowerCase() === '@bruger')) {
      result.push({ value: '@bruger', label: '@bruger', shortLabel: '@bruger' });
    } else if (ids.some(x => x.toLocaleLowerCase() === '@team')) {
      result.push({ value: '@team', label: '@team', shortLabel: '@team' });
    }
  }
  function addStaticSearchEntity(codeList: string, result: MultiSelectEntityLookup[]) {
    if (codeList.toLocaleLowerCase() === 'systemuser') {
      result.push({ value: '@bruger', label: '@bruger', shortLabel: '@bruger' });
    } else if (codeList.toLocaleLowerCase() === 'team') {
      result.push({ value: '@team', label: '@team', shortLabel: '@team' });
    }
  }
  async function handleSearch(text: string) {
    if (text && universe && codeListName) {
      setShowNoOptionMessage(true);
      setIsLoading(true);

      const result = await multiSelectEntityLookupClient.getResultByLookupEntity(universe.name, codeListName, text, 50);
      addStaticSearchEntity(codeListName, result);
      const dataResult = result.map(r => ({ title: r.shortLabel, value: r.value } as IAutocompleteValue));

      setData(dataResult);
      setIsLoading(false);
    }
  }
  function handleSelect(value: IAutocompleteValue[] | IAutocompleteValue) {
    const { handleOnChange } = props;
    if (value) {
      const arrValue = Array.isArray(value) ? value : [value];
      setSelected(arrValue);
      handleOnChange(arrValue.map(x => x.value));
    } else {
      setSelected([]);
      handleOnChange([]);
    }
  }
  function handleOnInputChange(e: any) {
    const ignoreKeys = [13, 37, 38, 39, 40]; // Enter, left,up.right,down
    if (!ignoreKeys.includes(e.keyCode)) {
      setData([]);
      setShowNoOptionMessage(false);
    }
  }
  if (type === QueryBuilderFieldOptions.Codelist || type === QueryBuilderFieldOptions.Picklist) {
    switch (uiHint) {
      case UiHint.Lookup:
        return lookupValueSelector({ ...props, value: loweredValues });
      case UiHint.MultipleSelectLookup:
        if (operator && operator !== QueryBuilderOperator.NotEmpty && operator !== QueryBuilderOperator.Empty) {
          return (
            <LookupSelector
              noOptionsMessage={showNoOptionMessage ? 'Ingen resultater' : ''}
              isLoading={isLoading}
              error={selected.length === 0}
              errorText={validationMessages.valueRequired}
              data={data}
              type={type}
              operator={operator}
              onSearch={handleSearch}
              onSelect={handleSelect}
              onInputChange={handleOnInputChange}
              value={selected}
              disabled={disabled}
              isMulti={operator === QueryBuilderOperator.In || operator === QueryBuilderOperator.NotIn}
              textFieldProps={{ label: 'Værdi' }}
            />
          );
        }
        return null;
      default:
        return codeListSelector({ ...props, value: loweredValues });
    }
  }

  switch (operator) {
    case QueryBuilderOperator.True:
    case QueryBuilderOperator.False:
    case QueryBuilderOperator.Empty:
    case QueryBuilderOperator.NotEmpty:
      return null;
    case QueryBuilderOperator.Between:
    case QueryBuilderOperator.NotBetween:
      return rangeValueEditor(props);
    case QueryBuilderOperator.In:
    case QueryBuilderOperator.NotIn:
      return varyingNumberOfInputs({ ...props, value: loweredValues });
    case QueryBuilderOperator.Relative:
      return (
        <RelativeDateEditor
          value={Array.isArray(props.value) ? props.value[0] : props.value}
          onChange={props.handleOnChange}
          className={props.className}
          disabled={props.disabled}
        />
      );
    default:
      return <SingleInput {...props} />;
  }
}
