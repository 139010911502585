import * as React from 'react';
import { Menu, MenuItem, PopoverOrigin } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../button';
import { IButtonMenuProps, IButtonMenuItem } from './button-menu-props';

export const ButtonMenu: React.FunctionComponent<IButtonMenuProps> = props => {
  const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);
  const menuId = 'button-menu';

  const handleOpen = (event: { currentTarget: any }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (target: IButtonMenuItem) => {
    if (target.onMenuClick) {
      target.onMenuClick(target);
    } else if (props.onMenuClick) {
      props.onMenuClick(target);
    }
    handleClose();
  };

  const renderMenuItems = (items: IButtonMenuItem[]) =>
    items.map((item, i) => (
      <MenuItem disabled={item.disabled} key={i} dense onClick={() => handleClick(item)}>
        {item.icon && <FontAwesomeIcon icon={item.icon} style={{ marginRight: '10px' }} />}
        {item.label}
      </MenuItem>
    ));

  const { menuItems, children, onMenuClick, menuPlacement = 'under', ...otherProps } = props;

  const menuItems2: IButtonMenuItem[] = typeof menuItems[0] === 'string' ? (menuItems as string[]).map(x => ({ label: x } as IButtonMenuItem)) : (menuItems as IButtonMenuItem[]);

  const childMenuItems = renderMenuItems(menuItems2);

  return (
    <div>
      <Button aria-owns={anchorEl ? menuId : undefined} aria-haspopup="true" onClick={handleOpen} {...otherProps}>
        {children}
      </Button>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        anchorOrigin={getMenuPlacement(menuPlacement)}
        transformOrigin={getTransformOrigin(menuPlacement)}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {childMenuItems}
      </Menu>
    </div>
  );
};

function getTransformOrigin(transformOrigin: string) {
  switch (transformOrigin) {
    case 'left':
      return {
        vertical: 'top',
        horizontal: 'right',
      } as PopoverOrigin;
    case 'under':
      return {
        vertical: 0,
        horizontal: 'center',
      } as PopoverOrigin;
    default:
      return {
        vertical: 'center',
        horizontal: 'right',
      } as PopoverOrigin;
  }
}

function getMenuPlacement(menuPlacement: string) {
  switch (menuPlacement) {
    case 'left':
      return {
        vertical: 'bottom',
        horizontal: 'left',
      } as PopoverOrigin;
    case 'under':
      return {
        vertical: 'bottom',
        horizontal: 'center',
      } as PopoverOrigin;
    default:
      return {
        vertical: 'top',
        horizontal: 'right',
      } as PopoverOrigin;
  }
}
