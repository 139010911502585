import { configuration } from '../configuration';
import { win } from '../utilities/window';
import { Tenant } from './tenant';

export function getTenant(): Tenant {
  const domainRegex = /https?:\/\/(?<tenant>[^.]+)\./;

  const parsedUrl = domainRegex.exec(win.location.origin);
  const tenant = parsedUrl && parsedUrl.groups ? parsedUrl.groups.tenant : undefined;
  if (!tenant) {
    if (configuration.defaultTenant) {
      return { name: configuration.defaultTenant };
    }
    /*  Empty tenant information will be handled on infrastructure level
        although Tenant information should be always provided and this error should never appear.

        Still from the technical perspective this information may be undefined so I'm leaving the case handle
    */
    throw new Error('Tenant information is not provided, make sure your URL has correct format https://tenant.domain.dk/*');
  }

  return { name: getTenantNameByUrlName(tenant) };
}

function getTenantNameByUrlName(urlName: string) {
  // This mapping is also set up as TenantProperty (OverrideJdcTenantPrefix), but we cannot read it via BFF
  // because we cannot set X-Tenant header. Therefore we have made this hack.
  switch (urlName) {
    case 'taarnby-dragoer':
      return 'taarnbydragoer';
    default:
      return urlName;
  }
}
