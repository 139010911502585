/* istanbul ignore file */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { INotification } from '../../store/reducers/notify-reducer';
import { useNotifier } from '../../../../components/notifier/notifier-context';
import { RankedSearchStoreState } from '../../store/configure-ranked-search-store';

const ids: { [id: string]: boolean } = {};

export function NotifyListener(): JSX.Element {
  const { dispatch } = useNotifier();
  const notifications = useSelector<RankedSearchStoreState, INotification[]>(state => state.notifications);

  useEffect(() => {
    notifications
      .filter(notification => !ids[notification.id])
      .forEach(notification => {
        ids[notification.id] = true;
        dispatch({ type: 'notify', payload: notification });
      });
  }, [notifications, dispatch]);

  return <></>;
}
