import { CancelToken } from 'axios';
import { moduleClientFactory } from '../api-service/module-client-factory';
import { IAuthorizationClient, GetAuthorizationResultRequest, ResourceAccessCriteria } from '../api-service/clients';

export interface ResourceAction {
  resourceType: string;
  resourceOperation: string;
}

export class AuthorizationService {
  private authorizationClient: IAuthorizationClient;

  constructor() {
    if (moduleClientFactory.createAuthorizationClient) {
      this.authorizationClient = moduleClientFactory.createAuthorizationClient();
    }
  }

  async isAllowed(actions: ResourceAction[], resourceAccessCriteria: ResourceAccessCriteria, cancelToken?: CancelToken) {
    const promises = actions.map(action => this.getAuthorizationResult(action, resourceAccessCriteria, cancelToken));

    const isAllowed = await Promise.all(promises);

    return isAllowed.includes(true);
  }

  private async getAuthorizationResult(action: ResourceAction, resourceAccessCriteria: ResourceAccessCriteria | undefined, cancelToken?: CancelToken) {
    const request: GetAuthorizationResultRequest = {
      resourceAccess: {
        resourceType: action.resourceType,
        resourceAction: action.resourceOperation,
      },
      resourceAccessCriteria,
    };

    const response = await this.authorizationClient.getAuthorizationResult(request, cancelToken);

    return response.isAuthorized;
  }
}
