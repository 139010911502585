import * as React from 'react';
import { IOption } from '../field-interfaces';
import { ButtonMenu } from '../../../../../components/button-menu/button-menu';
import { IButtonMenuItem } from '../../../../../components/button-menu/button-menu-props';

export interface IInlineSelectorProps {
  field?: string;
  value?: string;
  options: IOption[];
  className: string;
  handleOnChange: (value: string) => void;
  title: string;
  disabled?: boolean;
}

export function InlineSelector(props: IInlineSelectorProps) {
  const { value, options, handleOnChange, className } = props;

  const selectedOption = options.filter(option => option.name === value);

  const changeHandler = (selectedItem: IButtonMenuItem) => {
    const selected = options.find(option => option.label === selectedItem.label);
    handleOnChange((selected && selected.name) as any);
  };

  const transformedOptions = options.map(obj => ({
    label: obj.label,
  }));

  return (
    <ButtonMenu className={className} color="primary" variant="text" disabled={props.disabled} menuItems={transformedOptions} onMenuClick={changeHandler}>
      {selectedOption.length ? <span className="MuiButton-label" >{selectedOption[0].label}</span> : <span style={{ border: '1px solid red' }}>...</span>}
    </ButtonMenu>
  );
}
