import React, { useEffect, useState } from 'react';
import { useReleasePoller } from './use-release-poller';
import FasitUpdated from './fasit-updated';

export default function FasitReleasePoller() {
  const { isNewVersionAvailable } = useReleasePoller({ deploymentUrl: window.location.origin });
  const [showNewUpdateModal, setShowNewUpdateModal] = useState(false);

  useEffect(() => {
    if (isNewVersionAvailable) {
      setShowNewUpdateModal(true);
    } else {
      setShowNewUpdateModal(false);
    }
  }, [isNewVersionAvailable]);

  return showNewUpdateModal ? <FasitUpdated onClose={() => setShowNewUpdateModal(false)} /> : null;
}
