import { ActionElement, ConditionSelector, RemoveRuleElement, ValueSelector, AutoCompleteValueSelector, ValueEditor, ToggleElement, InlineSelector } from './controls';
import { IQueryBuilderControls } from './query-builder-interfaces';

export const defaultControlElements: IQueryBuilderControls = {
  addGroupAction: ActionElement,
  addHavingAction: ActionElement,
  removeGroupAction: ActionElement,
  addRuleAction: ActionElement,
  removeRuleAction: RemoveRuleElement,
  conditionSelector: ConditionSelector,
  fieldSelector: AutoCompleteValueSelector,
  operatorSelector: ValueSelector,
  valueEditor: ValueEditor,
  toggleElement: ToggleElement,
  inlineSelector: InlineSelector,
};
