import React from 'react';
import { Typography } from '../../../../../components/typography/typography';
import { Button } from '../../../../../components/button/button';
import { IOption } from '../field-interfaces';

export interface IConditionSelectorProps {
  className?: string;
  handleOnChange: (name: string) => void;
  options: IOption[];
  title: string;
  value?: string;
}

const convertValueToLowerCase = (value: string | undefined) => (value ? value.toLowerCase() : value);

export function ConditionSelector(props: IConditionSelectorProps) {
  const { value, options, handleOnChange, className } = props;

  const selectedOption = options.filter(option => convertValueToLowerCase(option.name) === convertValueToLowerCase(value))[0];
  const selectedIndex = options.findIndex(option => convertValueToLowerCase(option.name) === convertValueToLowerCase(value));
  const changeHandler = () => {
    const selectNewIndex: number = selectedIndex + 1 < options.length ? selectedIndex + 1 : 0;
    handleOnChange(options[selectNewIndex].name as any);
  };

  return (
    <div className={`groupRule__conditionSelector__wrapper  groupRule__conditionSelector__wrapper--${selectedOption.name}`}>
      <Button className={className} onClick={changeHandler} variant="contained">
        {selectedOption.label}
      </Button>
      <Typography variant="caption" classes={{ root: 'groupRule__conditionSelector__value' }}>
        {selectedOption.label}
      </Typography>
    </div>
  );
}
