import * as React from 'react';
import Grid from '@mui/material/Grid';
import { Dimensions } from '../config';

import { IRowProps } from './row-props';

export function Row(props: IRowProps) {
  const { children, dense = false, ...otherProps } = props;
  const defaultSpacing = Dimensions.DefaultGridSpacing;

  const spacing = dense ? 8 : defaultSpacing;

  return (
    <Grid container mb={3} spacing={spacing} {...otherProps}>
      {children}
    </Grid>
  );
}
