import { IField } from './field-interfaces';
import { ISearchField, UiHint } from '../query/search-field';
import { QueryBuilderFieldOptions } from '../query/query-builder-field-options';

const isBusinessObject = (searchField: ISearchField) => searchField.type === QueryBuilderFieldOptions.Codelist && searchField.fields;

function mapToIField(searchField: ISearchField, prefix: string): IField {
  return {
    name: searchField.name,
    label: isBusinessObject(searchField) ? `${prefix}[${searchField.displayName}]` : `${prefix}${searchField.displayName}`,
    displayName: searchField.displayName,
    type: searchField.type,
    codelistType: searchField.codeListType,
    uiHint: searchField.uiHint,
    codeListName: searchField.codeListName,
  } as IField;
}

const compareFunction = (a: IField, b: IField) => a.label.localeCompare(b.label, 'da', { sensitivity: 'base' });

function handleComplexField(complexField: ISearchField, prefix = ''): string {
  return prefix ? `${prefix}[${complexField.displayName}]` : `[${complexField.displayName}] `;
}

export function getReplicateFieldUniverseName(universe: string, dataType: string | undefined, universeName: string | undefined) {
  return universe && dataType && universe !== dataType ? universeName || '' : undefined;
}

export function getFields(searchFields: ISearchField[] | undefined, prefix = '', fieldsToAdd: IField[] = []): IField[] {
  if (!searchFields) return [];
  const fields = searchFields.filter(x => !!x.type && !x.fields && x.uiHint !== UiHint.Hide && !x.toBeDeleted).map(x => mapToIField(x, prefix));
  let complexFields: IField[] = [];
  for (const complexField of searchFields.filter(x => x.fields && !x.isList)) {
    if (complexField.uiHint !== UiHint.Hide && !complexField.toBeDeleted && complexField.type === QueryBuilderFieldOptions.Codelist) {
      complexFields = complexFields.concat([mapToIField(complexField, prefix)]);
    }
    if (complexField.uiHint !== UiHint.Hide && !complexField.toBeDeleted) {
      complexFields = complexFields.concat(getFields(complexField.fields, handleComplexField(complexField, prefix)));
    }
  }
  complexFields = complexFields.concat(fieldsToAdd);
  fields.sort(compareFunction);
  complexFields.sort(compareFunction);
  complexFields.forEach(x => {
    if (!fields.some(f => f.name === x.name)) {
      fields.push(x);
    }
  });
  return fields;
}

export function getChartFields(searchFields: ISearchField[] | undefined, prefix = '', fieldsToAdd: IField[] = []): IField[] {
  if (!searchFields) return [];

  let fields = searchFields.filter(x => !!x.type && !x.fields && x.uiHint !== UiHint.Hide && x.type !== 'largetext' && !x.toBeDeleted).map(x => mapToIField(x, prefix));
  let complexFields: IField[] = [];
  for (const complexField of searchFields.filter(x => x.fields && !x.isList)) {
    complexFields = complexFields.concat(getChartFields(complexField.fields, handleComplexField(complexField, prefix)));
  }

  complexFields = complexFields.concat(fieldsToAdd);
  fields.sort(compareFunction);
  complexFields.sort(compareFunction);
  return fields.concat(complexFields);
}
