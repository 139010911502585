export const citizensAreaCitizensTabPathName = '';
export const citizensAreaCitizensTabLabel = 'Borgere';
export const citizensAreaCasesTabPathName = 'cases';
export const citizensAreaCasesTabLabel = 'Sager';
export const citizensAreaTasksTabPathName = 'tasks';
export const citizensAreaTasksTabLabel = 'Opgaver';
export const citizensAreaContactsTabPathName = 'contacts';
export const citizensAreaContactsTabLabel = 'Borgeres kontaktpersoner';
export const citizensAreaCVSearchTabLabel = 'CV Søgning';
export const citizensAreaSchultzCaseTabLabel = 'Schultz Sag';
export const citizensAreaPrivateContactTabLabel = 'Private kontaktpersoner';
export const citizensAreaPrivateContactTabPathName = 'contactpersons';
export const bulkPersonGroupMarkingsPathName = 'bulk-persongroupmarkings';
export const citizensAreaCreateRapportLabel = 'Rapporter';
