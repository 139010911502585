import React, { useState, useMemo } from 'react';
import { queryApiService } from '../components/query/query-api-service';

import { ISearchField } from '../components/query';

const BulkActionsContext = React.createContext({} as any);

type BulkActionsProviderProps = {
  children: React.ReactNode;
};
interface ISearchFieldResult {
  [universe: string]: ISearchField[];
}
interface SearchFieldProviderResult {
  fields: ISearchFieldResult;
  ensureFields: (universe: string) => void;
}
const universes: string[] = [];
export function SearchFieldProvider({ children }: BulkActionsProviderProps) {
  const [fields, setFields] = useState({ '': [] } as ISearchFieldResult);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function ensureFields(universe: string) {
    if (!universe || universes.some(x => x === universe)) {
      return;
    }
    universes.push(universe);
    const getFields = async () => {
      if (!fields[universe]) {
        const result = await queryApiService.getSearchFields(universe, false);
        fields[universe] = result;
        setFields({ ...fields });
      }
    };
    getFields();
  }
  const providerValue = useMemo(() => ({ ensureFields, fields }), [ensureFields, fields]);
  return <BulkActionsContext.Provider value={providerValue}>{children}</BulkActionsContext.Provider>;
}

export const useSearchFields = (): SearchFieldProviderResult => React.useContext(BulkActionsContext);
