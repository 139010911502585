import { useState, useEffect } from 'react';
import { useApi } from './use-api';
import { moduleClientFactory } from '../api-service/module-client-factory';

export function useDynamicLinksIsConfiguredForResource(resourceType: string) {
  const [dynamicLinksIsConfiguredForResource, setDynamicLinksIsConfiguredForResource] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const client = moduleClientFactory.createDynamicLinkClient();
  const api = useApi(cancelToken => client.getConfiguredResourceTypes(cancelToken), { resourceTypes: [] });

  useEffect(() => {
    api
      .invoke()
      .then(x => {
        if (x.result && x.result.resourceTypes) {
          setDynamicLinksIsConfiguredForResource(x.result.resourceTypes.includes(resourceType.toLocaleLowerCase()));
        }
      })
      .catch(err => {
        setError(err);
      });

    // eslint-disable-next-line
  }, [resourceType]);

  return { dynamicLinksIsConfiguredForResource, error };
}
