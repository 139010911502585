import { faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { blue, red } from '@mui/material/colors';
import { StateObjs, TMessageSeverity } from './message-center-props';

export function getIcon(severity: TMessageSeverity) {
  let icon;
  switch (severity) {
    case 'error':
      icon = faExclamationTriangle;
      break;
    case 'info':
      icon = faInfoCircle;
      break;
    default:
      icon = faInfoCircle;
      break;
  }
  return icon;
}

export function getIconColor(severity: TMessageSeverity) {
  let color;
  const { 700: errorColor } = red;
  const { 700: infoColor } = blue;

  switch (severity) {
    case 'error':
      color = errorColor;
      break;
    case 'info':
      color = infoColor;
      break;
    default:
      color = infoColor;
      break;
  }
  return color;
}

export function getMessageState() {
  return JSON.parse(localStorage.getItem('_m') || '[]');
}

export function setMessageState(objs: StateObjs[]) {
  const state = objs.reduce((prev: Record<string, Date | string>, obj: StateObjs) => {
    const curr = { ...prev };
    curr[obj.id] = obj.updated;
    return curr;
  }, {});
  localStorage.setItem('_m', JSON.stringify(state));
}

export function isUnread(id: string, updated: Date | string, messageState: Record<string, Date | string>) {
  const stateDate = messageState[id] ? new Date(messageState[id]) : false;
  if (!stateDate) return true;

  const updatedDate = updated ? new Date(updated) : false;
  if (!updatedDate) return true;

  return stateDate < updatedDate;
}
