import { useState, useEffect } from 'react';
import { useFeatureToggles } from '../../feature-toggles';
import { moduleClientFactory } from '../../api-service/module-client-factory';

export function useAiFeature(featureName: string) {
  const [isAiFeatureEnabled, setIsAiFeatureEnabled] = useState<boolean>(false);
  const { isFeatureEnabled } = useFeatureToggles();
  const isFeatureToggleEnabled = isFeatureEnabled(featureName);

  useEffect(() => {
    const checkUserAuthorization = async () => {
      const client = moduleClientFactory.createAiSearchClient();
      const response = await client.getIsAiPilotUser();
      if (response?.isAiPilotUser) {
        setIsAiFeatureEnabled(true);
      }
    };
    isFeatureToggleEnabled && checkUserAuthorization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureName, isFeatureToggleEnabled]);

  return { isAiFeatureEnabled };
}
