export const adminAreaMenuName = 'Administration';
export const adminAreaPathName = 'admin';
export const auditTabPathName = 'audit';
export const auditTabLabel = 'Brugerlog';

export const transactionLogTabPathName = 'transactionlog';
export const transactionLogTabLabel = 'Transaktionslog';

export const organizationTabPathName = 'organisation';
export const organizationTabLabel = 'Organisation';

export const generalTabPathName = 'general';
export const generalTabLabel = 'Generelt';

export const citizensTabPathName = 'citizens';
export const citizensTabLabel = 'Borgere';

export const casesTabPathName = 'cases';
export const casesTabLabel = 'Sager';

export const companyTabPathName = 'company';
export const companyTabLabel = 'Virksomhed';

export const jobAndOffersTabPathName = 'jobAndOffer';
export const jobAndOffersTabLabel = 'Job & tilbud';

export const configurationLogTabPathName = 'configurationlog';
export const configurationLogTabLabel = 'Konfigurationslog';

export const templatesAndAppendixTabPathName = 'templatesAndAppendix';
export const templatesAndAppendixTabLabel = 'Skabeloner & bilag';
