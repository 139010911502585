import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { IButtonProps } from './button-props';
import './button.scss';

export const buttonTestIds = {
  button: 'button',
};

export function Button(props: IButtonProps) {
  const { children, loading, disabled, variant, iconPos, className, disableElevation = false, ...restProps } = props;

  let classes = '';

  if (iconPos === 'left') {
    classes = 'button--with-icon button--with-icon--left';
  }
  if (iconPos === 'right') {
    classes = 'button--with-icon button--with-icon--right';
  }

  if (className) {
    classes = classes !== '' ? `${classes} ${className}` : `${className}`;
  }

  return (
    <MuiButton disabled={loading || disabled} variant={variant} className={classes} data-testid={buttonTestIds.button} disableElevation={disableElevation} {...restProps}>
      {children}
      {loading && (
        <CircularProgress
          size={24}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
          }}
        />
      )}
    </MuiButton>
  );
}

Button.defaultProps = {
  color: 'secondary',
  disabled: false,
  loading: false,
  variant: 'contained',
  iconPos: 'none',
  disableRipple: false,
};
