import { IQueryDefinition } from '../models/dtos';
import { IResultTypeOption } from '../components/query/search-result-type';
import { useTransform } from '../../../hooks';

export function useWorkListName(queryDefinitionState: IQueryDefinition | undefined, searchResultTypeState: IResultTypeOption[]): string {
  return useTransform(
    (queryDefinition, searchResultType) => {
      let name = 'Arbejdsliste';
      let resultTypeText = '';
      if (!!queryDefinition && queryDefinition.name) {
        name = queryDefinition.name;
      }

      if (queryDefinition && searchResultType && searchResultType.length > 0) {
        const savedQueryResultTypeName = queryDefinition.requestBody && queryDefinition.requestBody.resultType;

        if (savedQueryResultTypeName) {
          const resultTypeDefinition = searchResultType.find(x => x.name === savedQueryResultTypeName);

          if (resultTypeDefinition) {
            resultTypeText = resultTypeDefinition.label;
          }
        } else {
          resultTypeText = `${searchResultType[0].label}`;
        }
      }
      return `${name} (${resultTypeText})`;
    },
    queryDefinitionState,
    searchResultTypeState
  );
}
