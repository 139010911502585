/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { postRequestFormId } from './generic-post-request-page';
import { GetPostRequestParametersResponse, PostRequestType } from '../../../../../api-service/clients';
import { win } from '../../../../../utilities/window';

export type GenericPostRequestPageFormComponent = 'NfsMediConnectIntegration' | 'SapaIntegration' | 'NovaIntegration' | 'UnoUngIntegration' | 'CandenoIntegration' | 'None';

export function GenericPostRequestPageForm(props: {
  onCompleted: () => void;
  getPostRequestParametersResponse: GetPostRequestParametersResponse;
  componentName: GenericPostRequestPageFormComponent;
  linkurl: string;
  autoSubmit?: boolean;
}) {
  const { getPostRequestParametersResponse, componentName, linkurl, onCompleted, autoSubmit } = props;
  const { candenoPassword, citizenCpr, municipalityCvr, municipalityOrganisationCode = '', primaryCaseId } = getPostRequestParametersResponse;

  if (autoSubmit) {
    setTimeout(() => {
      const frm = (window as any).document.getElementById(postRequestFormId);
      if (frm) {
        frm.submit();
        onCompleted();
      } else {
        autoSubmitFunc();
      }
    }, 500);
  }

  let auto = 0;
  function autoSubmitFunc() {
    if (auto) {
      clearTimeout(auto);
    }
    auto = window.setTimeout(() => {
      const frm = (window as any).document.getElementById(postRequestFormId);
      if (frm) {
        frm.submit();
        onCompleted();
      } else {
        autoSubmitFunc();
      }
    }, 1000);
  }

  if (componentName === 'CandenoIntegration') {
    return (
      <form id={postRequestFormId} method="post" action={linkurl} target="_blank" style={autoSubmit ? { display: 'none' } : { display: 'block' }}>
        <label htmlFor="secret">secret</label>
        <input type="text" name="secret" value={candenoPassword} />
        <label htmlFor="cpr_number">cpr_number</label>
        <input type="text" name="cpr_number" value={citizenCpr} />
        <input type="submit" value="Submit" />
      </form>
    );
  }

  if (componentName === 'UnoUngIntegration') {
    const shortenedMunicipalityCode = municipalityOrganisationCode.substring(0, 3);
    return (
      <form id={postRequestFormId} method="post" action={linkurl} target="_blank" style={autoSubmit ? { display: 'none' } : { display: 'block' }}>
        <label htmlFor="cprnr">CPR-nummer</label>
        <input type="text" name="cprnr" value={citizenCpr} />
        <label htmlFor="komkod">Kommunekode</label>
        <input type="text" name="komkod" value={shortenedMunicipalityCode} />
        <input type="submit" value="Submit" />
      </form>
    );
  }

  if (componentName === 'SapaIntegration') {
    return (
      <form id={postRequestFormId} method="post" action={linkurl} target="_blank" style={autoSubmit ? { display: 'none' } : { display: 'block' }}>
        <label htmlFor="Myndighed">Myndighed</label>
        <input type="number" name="Myndighed" value={municipalityCvr} />
        <label htmlFor="Kontekst">Kontekst</label>
        <input type="text" name="Kontekst" value="part" />
        <label htmlFor="Objekt1">Objekt1</label>
        <input type="text" name="Objekt1" value="PART" />
        <label htmlFor="ObjektVaerdi1">ObjektVaerdi1</label>
        <input type="text" name="ObjektVaerdi1" value={citizenCpr} />
        <label htmlFor="Version">Version</label>
        <input type="number" name="Version" value="ver" />
        <input type="submit" value="Submit" />
      </form>
    );
  }

  if (componentName === 'NovaIntegration') {
    return (
      <form id={postRequestFormId} method="post" action={linkurl} target="_blank" style={autoSubmit ? { display: 'none' } : { display: 'block' }}>
        <label htmlFor="Myndighed">Myndighed</label>
        <input type="text" name="Myndighed" value={municipalityCvr} />
        <label htmlFor="Kontekst">Kontekst</label>
        <input type="text" name="Kontekst" value="PART" />
        <label htmlFor="Objekt1">Objekt1</label>
        <input type="text" name="Objekt1" value="PART" />
        <label htmlFor="ObjektVaerdi1">ObjektVaerdi1</label>
        <input type="text" name="ObjektVaerdi1" value={citizenCpr} />
        <input type="submit" value="Submit" />
      </form>
    );
  }

  if (componentName === 'NfsMediConnectIntegration') {
    let urlWithProtocol = linkurl;
    if (!linkurl.toLowerCase().startsWith('http')) {
      urlWithProtocol = `http://${linkurl}`;
    }

    return (
      <form id={postRequestFormId} method="post" action={urlWithProtocol} target="_blank" style={autoSubmit ? { display: 'none' } : { display: 'block' }}>
        <label htmlFor="relaydatafield">Create Case XML:</label>
        <textarea id="theXML" name="relaydatafield" style={{ width: '650px', height: '150px' }}>
          {`<Command Name="CreateCase">
  <Parameters>
    <SystemInitiator>SchultzFacit</SystemInitiator>
    <CPR>${citizenCpr}</CPR>
    <CaseIdentifier>${primaryCaseId}</CaseIdentifier>
  </Parameters>
</Command>`}
        </textarea>
        <br />
        <br />
        <input type="submit" value="Submit" />
      </form>
    );
  }

  return null;
}

export function PostRequestTypeToComponent(postRequestType: PostRequestType): GenericPostRequestPageFormComponent {
  switch (postRequestType) {
    case PostRequestType.Sapa:
      return 'SapaIntegration';
    case PostRequestType.Candeno:
      return 'CandenoIntegration';
    case PostRequestType.Nova:
      return 'NovaIntegration';
    case PostRequestType.UnoUng:
      return 'UnoUngIntegration';
    case PostRequestType.NfsMediConnect:
      return 'NfsMediConnectIntegration';
    default:
      win.alert(`${postRequestType} integration not implemented`);
      return 'None';
  }
}
