import React from 'react';
import { ValidationError, ValidationErrors } from '.';
import { sanitizeInput } from './text-sanitizer';

export function bindModel<T>(value: T, onChange: (value: T) => void, validationErrors?: ValidationErrors<T>) {
  function handleChange<K extends keyof T>(name: K, changedValue: unknown) {
    const change = { [name]: changedValue };
    const updated = { ...value, ...change };
    onChange && onChange(updated);
  }

  function bindChecked<K extends keyof T>(name: K, defaultValue?: T[K]) {
    const errorText: ValidationError = validationErrors && validationErrors[name];
    const binder: {
      checked: T[K];
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
      error?: true;
      errorText?: ValidationError;
    } = {
      checked: value[name] ?? (defaultValue as T[K]),
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChange(name, event.target.checked),
    };
    if (errorText) {
      binder.error = true;
      binder.errorText = errorText;
    }
    return binder;
  }

  function bindEvent<K extends keyof T>(name: K, defaultValue?: T[K]) {
    const errorText: ValidationError = validationErrors && validationErrors[name];
    const binder: {
      value: T[K];
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
      error?: true;
      errorText?: ValidationError;
    } = {
      value: value[name] ?? (defaultValue as T[K]),
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = typeof event === 'string' ? event : event.target?.value;
        handleChange(name, sanitizeInput(text));
      },
    };
    if (errorText) {
      binder.error = true;
      binder.errorText = errorText;
    }
    return binder;
  }

  function bindValue<K extends keyof T>(name: K, defaultValue?: T[K]) {
    const errorText: ValidationError = validationErrors && validationErrors[name];
    const binder: {
      value: T[K];
      onChange: (changedValue: T[K]) => void;
      error?: true;
      errorText?: ValidationError;
    } = {
      value: value[name] ?? (defaultValue as T[K]),
      onChange: (changedValue: T[K]) => handleChange(name, changedValue),
    };
    if (errorText) {
      binder.error = true;
      binder.errorText = errorText;
    }
    return binder;
  }

  return {
    bindChecked,
    bindEvent,
    bindValue,
  };
}

export class IModelControlProps<T> {
  value: T;
  onChange: (value: T) => void;
  validationErrors: ValidationErrors<T>;
}

export function bindProps<T>(props: IModelControlProps<T>) {
  const { value, onChange, validationErrors } = props;
  return bindModel<T>(value, onChange, validationErrors);
}
