import { OmnibarEntry } from '../omnibar-interfaces';

const functions: OmnibarEntry[] = [
  {
    id: 'ab62bf64-4e61-4592-8eab-a3513447bb59',
    label: 'Opgaver',
    context: 'none',
    group: 'Virksomheder',
    url: '/deeplink/company/tasks',
  },
  {
    id: 'a3e7c4d9-da78-4eed-acd6-8802dd171601',
    label: 'Virksomheder',
    context: 'none',
    group: 'Virksomheder',
    url: '/deeplink/company',
  },
  {
    id: '0ce2e9ef-fb2d-453e-b51b-a44b340c1236',
    label: 'Opret aftale',
    context: 'company',
    group: 'Virksomhed',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/company/${contextId}/create-appointment`);
    },
  },
   {
    id: '0ce2e9ef-fb2d-453e-b51b-a44b340c1237',
    label: 'Opret notat',
    context: 'company',
    group: 'Virksomhed',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/company/${contextId}/create-company-note`);
    }
  },
   {
    id: '0ce2e9ef-fb2d-453e-b51b-a44b340c1238',
    label: 'Opret kontaktperson',
    context: 'company',
    group: 'Virksomhed',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/company/${contextId}/edit-company-contact-person`);
    },
    featurePurchased: ['virksomhedsservice'],
   },
  {
    id: '0ce2e9ef-fb2d-453e-b51b-a44b340c1239',
    label: 'Opret Tag',
    context: 'company',
    group: 'Virksomhed',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/company/${contextId}/edit-tags`);
    },
    featurePurchased: ['virksomhedsservice','virksomhedstags'],
  },
  {
    id: '0ce2e9ef-fb2d-453e-b51b-a44b340c1249',
    label: 'Opret opgave',
    context: 'company',
    group: 'Virksomhed',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/company/${contextId}/tasks/create`);
    }
  },
  {
    id: '0ce2e9ef-fb2d-453e-b51b-a44b340c1251',
    label: 'Opret arbejdskraftsbehov',
    context: 'company',
    group: 'Virksomhed',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/company/${contextId}/labourneeds`);
    },
    featurePurchased: ['virksomhedsservice'],
  },
  {
    id: '0ce2e9ef-fb2d-453e-b51b-a44b340c1261',
    label: 'Opret særlige forhold',
    context: 'company',
    group: 'Virksomhed',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/company/${contextId}/edit-company-special-circumstance`);
    },
    featurePurchased: ['virksomhedsservice'],
  },
  {
    id: '0ce2e9ef-fb2d-453e-b51b-a44b340c1281',
    label: 'Opret bibranche',
    context: 'company',
    group: 'Virksomhed',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/company/${contextId}/edit-trade-codes`);
    }
  },
  {
    id: '0ce2e9ef-fb2d-453e-b51b-a44b340c1271',
    label: 'Opret supplerende sagsbehandler',
    context: 'company',
    group: 'Virksomhed',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/company/${contextId}/edit-associated-caseworker`);
    }
  } 
  ,
  {
    id: '0ce2e9ef-fb2d-453e-b51b-a44b340c1291',
    label: 'Dan blanket',
    context: 'company',
    group: 'Virksomhed',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/company/${contextId}/guide-form`);
    }
  } 
  ,
  {
    id: '0ce2e9ef-fb2d-453e-b51b-a44b340c1391',
    label: 'Dan brev',
    context: 'company',
    group: 'Virksomhed',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/company/${contextId}/create-letter`);
    }
  },
  {
    id: '0ce2e9ef-fb2d-453e-b51b-a44b340c1491',
    label: 'Virksomhedsforberedelse',
    context: 'company',
    group: 'Virksomhed',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/company/${contextId}/companyvisitpreparation`);
    }
    }, 
    {
    id: 'abxxxx64-4e61-4592-8eab-a3513447bb59',
    label: 'Opret privat kontaktperson',
    context: 'none',
    group: 'Virksomheder',
    url: '/deeplink/citizens/contactpersons'
  }
  
];

export default functions;
