import * as React from 'react';
import { IOption } from '../field-interfaces';
import { Select } from '../../../../../components/select/select';

export interface IValueSelectorProps {
  field?: string;
  value?: string;
  options: IOption[];
  className: string;
  handleOnChange: (value: string) => void;
  title: string;
  disabled?: boolean;
  error?: string | string[];
}

export function ValueSelector(props: IValueSelectorProps) {
  const { value, options, handleOnChange, title, className, disabled, error } = props;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleOnChange(e.target.value);
  };

  return (
    <Select
      disabled={disabled}
      className={className}
      value={value}
      label={title}
      onChange={onChange}
      options={options.map(option => ({
        label: option.label,
        value: option.name,
      }))}
      error={!!error}
      errorText={error}
    />
  );
}
