import { ISelectOption } from '../../../../../../components/select/select-props';

export const directionOperators = {
  next: 'Next',
  last: 'Last',
  current: 'Current',
  currentAndNext: 'CurrentAndNext',
  currentAndLast: 'CurrentAndLast',
};

export const unitOperators = {
  hours: 'Hours',
  days: 'Days',
  weeks: { singular: 'Week', plural: 'Weeks' },
  month: { singular: 'Month', plural: 'Months' },
  year: { singular: 'Year', plural: 'Years' },
};

export const directions: ISelectOption[] = [
  { value: directionOperators.next, label: 'Næste' },
  { value: directionOperators.last, label: 'Seneste' },
  { value: directionOperators.current, label: 'Indeværende' },
  { value: directionOperators.currentAndNext, label: 'Indeværende og næste' },
  { value: directionOperators.currentAndLast, label: 'Indeværende og seneste' },
];

export const units: ISelectOption[] = [
  { value: unitOperators.hours, label: 'Time(r)' },
  { value: unitOperators.days, label: 'Dag(e)' },
  { value: unitOperators.weeks.plural, label: 'Kalenderuge(r)' },
  { value: unitOperators.month.plural, label: 'Kalendermåned(er)' },
  { value: unitOperators.year.plural, label: 'Kalenderår' },
];

export const currentDirectionUnits: ISelectOption[] = [
  { value: unitOperators.weeks.singular, label: 'Kalenderuge' },
  { value: unitOperators.month.singular, label: 'Kalendermåned' },
  { value: unitOperators.year.singular, label: 'Kalenderår' },
];

export const defaultOptions = { direction: directions[0], duration: 1, unit: units[1] };
export const defaultValues = `${defaultOptions.direction.value}_${defaultOptions.duration}_${defaultOptions.unit.value}`;

export const currentDirectionDefaultOptions = { direction: directions[2], unit: currentDirectionUnits[0] };
export const currentDirectionDefaultValues = `${directionOperators.current}_${unitOperators.weeks.singular}`;

export const directionCurrent = directionOperators.current;
export const defaultUnitForCurrentDirection = unitOperators.weeks.singular;
