import { OmnibarEntry } from '../omnibar-interfaces';
import { ResourceAction } from '../../../authentication/authorization-service';
import { AllowedActions } from '../../../authentication/allowed-actions';

export default function entryAccessFilter(
  item: OmnibarEntry,
  isFeaturePurchased: (featureNames: string[]) => boolean,
  isFeatureEnabled: <T extends string = string>(featureName: T) => boolean,
  allowedActions: AllowedActions
) {
  if (!checkUserHasAccess(item.resourceActions, allowedActions)) {
    return false;
  }
  if (item.featureToggleRequired) {
    const featureTogglingEnabled = isFeatureEnabled(item.featureToggleRequired);
    if (!featureTogglingEnabled) {
      return false;
    }
  }

  if (item.featurePurchased) {
    return isFeaturePurchased(item.featurePurchased);
  }

  return true;
}

function checkUserHasAccess(actions: ResourceAction[] | undefined, allowedActions: AllowedActions) {
  if (actions) {
    return allowedActions.isAnyAllowed(actions);
  }

  return true;
}
