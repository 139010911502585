import { useState, useEffect } from 'react';
import { WindowService } from '../components/window-service/window-service';
import { useNotifier } from '../components/notifier/notifier-context';

export function useDirty<TStateModel>(initialState: TStateModel, state: TStateModel) {
  const [isDirty, setIsDirty] = useState(false);
  const [baseState, setBaseState] = useState(initialState);
  const { dispatch } = useNotifier();

  useEffect(() => {
    setIsDirty(JSON.stringify(baseState) !== JSON.stringify(state));
  }, [baseState, state]);

  const onBeforeUnloadMessage = 'Vil du lukke uden at gemme?';
  useEffect(() => {
    if (isDirty) {
      WindowService.setOnBeforeUnload(onBeforeUnloadMessage);
    } else {
      WindowService.unsetOnBeforeUnload();
    }

    return () => WindowService.unsetOnBeforeUnload();
  }, [isDirty]);

  function doClose() {
    WindowService.unsetOnBeforeUnload();
    WindowService.close();
    setTimeout(() => {
      dispatch({ type: 'notify', payload: { message: 'Vinduet kunne ikke lukkes automatisk. Du skal selv sørge for at lukke det', severity: 'info' } });
    }, 1000);
  }

  function closeWindow(ignoreIsDirty = false) {
    if (!isDirty || ignoreIsDirty) {
      doClose();
      // eslint-disable-next-line no-alert
    } else if (window.confirm(onBeforeUnloadMessage)) {
      doClose();
    }
  }

  const setUseDirtyInitialState = setBaseState;

  return {
    closeWindow,
    isDirty,
    setUseDirtyInitialState,
  };
}
