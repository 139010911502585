/* istanbul ignore file */
import { useEffect, useRef, DependencyList, EffectCallback } from 'react';

// UseEffect, but skip effect of initial values.
export function useNextOnlyOnceEffect(effect: EffectCallback, deps?: DependencyList) {
  const first = useRef(true);
  const once = useRef(false);

  useEffect(() => {
    if (first.current) {
      first.current = false;
    } else if (!once.current) {
      once.current = true;
      effect();
    }
    // eslint-disable-next-line
  }, deps);
}
