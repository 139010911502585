/* eslint-disable max-len */
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SnackbarProvider } from 'notistack';
import { apm } from '@elastic/apm-rum';
import { ToastDisplay } from './toaster';
import { INotifierMessage, Action, State, Dispatch, NotifierProviderProps, INotifierMessageBox } from './interfaces';
import errorDescriptions from './error-descriptions.json';
import { NotifierMessageBox } from './notifier-message-box';
import { CssBaseDecorator } from '../cssbasedecorator/css-base-decorator';

const ErrorNotifierStateContext = React.createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

function filterOutDismissed(obj: INotifierMessage) {
  return !obj.dismissed;
}

export function notifierMessageReducer(state: State, action: Action) {
  switch (action.type) {
    case 'notify': {
      const { message = '', correlationIdentifier = '', severity = 'info', status, timestamp, warningAsInfo, hideExtraInfo, helpLink, messageTitle } = action.payload;
      let { detail = '', title = '' } = action.payload;

      const errorDescription = errorDescriptions.find(x => x.key.toLowerCase() === title.toLowerCase());
      title = errorDescription?.title || title;
      detail = errorDescription?.detail || detail;

      // Check for existing
      const correlationIdentifierArr = state.errors.map(x => x.correlationIdentifier);
      if (correlationIdentifier !== '' && correlationIdentifierArr.indexOf(correlationIdentifier) > -1) return state;

      // If BusinessException and severity == info || warning
      if (status === 400 && severity !== 'error') {
        const notifierMessageBox: INotifierMessageBox = {
          id: uuidv4(),
          detail,
          title,
          correlationIdentifier,
          severity,
          status,
          warningAsInfo,
          hideExtraInfo,
        };
        return { message: notifierMessageBox, errors: [...state.errors], toasts: [...state.toasts] };
      }

      let detailedMessage = `${title}${detail ? ` - ${detail}` : ''}`;
      detailedMessage = detailedMessage || message;
      if (severity === 'info' || severity === 'success') {
        const newToast = {
          id: uuidv4(),
          message,
          severity,
        };
        return { errors: [...state.errors], toasts: [...state.toasts, newToast], message: state.message };
      }
      if (status === 401) {
        // Return old state if there's already a 401 Unauthorized error visible
        if (state.errors.find((err: INotifierMessage) => err.status && err.status === 401)) return { errors: [...state.errors], toasts: [...state.toasts], message: state.message };
        // else just continue

        detailedMessage = 'Du er ikke logget ind i Fasit. Log ind igen. Hvis du fortsat har problemer skal du kontakte din systemadministrator.';
      }
      if (status === 403) {
        // Return old state if there's already a 403 Forbidden error visible
        if (state.errors.find((err: INotifierMessage) => err.status && err.status === 403)) return { errors: [...state.errors], toasts: [...state.toasts], message: state.message };
        // else just continue
        detailedMessage =
          'Handlingen kan ikke udføres. Det skyldes, at du ikke har de nødvendige sikkerhedsroller til at udføre den valgte handling, eller at posten ikke længere findes. Kontakt din systemadministrator.';
      }

      // Specific DFDG errors
      if (messageTitle) {
        const newMessage: INotifierMessageBox = {
          id: uuidv4(),
          title: messageTitle,
          detail,
          helpLink,
        };
        return { errors: [...state.errors], toasts: [...state.toasts], message: newMessage };
      }

      if (detailedMessage === '' && correlationIdentifier === '') return { errors: [...state.errors], toasts: [...state.toasts], message: state.message };

      const newError: INotifierMessage = {
        id: uuidv4(),
        message: detailedMessage,
        correlationIdentifier,
        severity,
        status,
        timestamp,
      };

      apm.captureError({ ...newError, name: title });

      return { errors: [...state.errors.filter(filterOutDismissed), newError], toasts: [...state.toasts], message: state.message };
    }
    case 'dismiss': {
      const newErrors = state.errors.map((msg: INotifierMessage) => {
        const msgCopy = { ...msg };
        if (msgCopy.id === action.payload.id) {
          msgCopy.dismissed = true;
        }
        return msgCopy;
      });
      const newToasts = state.toasts.filter((msg: INotifierMessage) => msg.id !== action.payload.id);
      return { errors: [...newErrors], toasts: [...newToasts], message: state.message?.id === action.payload?.id ? undefined : state.message };
    }
    case 'dismissAll': {
      const newErrors = state.errors.map((msg: INotifierMessage) => {
        const msgCopy = { ...msg };
        msgCopy.dismissed = true;
        return msgCopy;
      });
      const newToasts = state.toasts.filter((msg: INotifierMessage) => msg.id !== action.payload.id);
      return { errors: [...newErrors], toasts: [...newToasts], message: state.message?.id === action.payload?.id ? undefined : state.message };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function NotifierProvider({ children }: NotifierProviderProps) {
  const [state, dispatch] = React.useReducer(notifierMessageReducer, {
    errors: [],
    toasts: [],
    message: undefined,
  });

  const value = React.useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <CssBaseDecorator>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        maxSnack={3}
      >
        <NotifierMessageBox message={state.message} dispatch={dispatch} />
        <ToastDisplay toasts={state.toasts} dispatch={dispatch} />
        <ErrorNotifierStateContext.Provider value={value}>{children}</ErrorNotifierStateContext.Provider>
      </SnackbarProvider>
    </CssBaseDecorator>
  );
}

function useNotifier() {
  const context = React.useContext(ErrorNotifierStateContext);
  if (context === undefined) {
    throw new Error('useNotifier must be used within a NotifierProvider');
  }
  return context;
}

export { NotifierProvider, useNotifier };
