/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import * as React from 'react';
import { MenuItem } from '@mui/material';
import { ISelectProps, SelectValueType } from './select-props';
import { TextField } from '../text-field';

export function Select(props: ISelectProps) {
  const { onChange, options, value, emptyValues, ...rest } = props;

  // Encode nulls and undefined as they are not supported by component
  function encodeValue(val: SelectValueType): string {
    for (const [key, selectValue] of Object.entries(emptyValues)) {
      if (selectValue === val) {
        return key;
      }
    }
    return val as string;
  }

  function decodeValue(val: string): SelectValueType {
    if (emptyValues.hasOwnProperty(val)) {
      return emptyValues[val];
    }
    return val;
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange &&
      onChange({
        ...event,
        target: { ...event.target, value: decodeValue(event.target.value) as string, name: options?.find(x => x?.value === event?.target?.value)?.label.toString() || '' },
      });
  }

  return (
    <TextField multiline={false} value={encodeValue(value)} select onChange={handleChange} {...rest}>
      {options.map(option => {
        const optionValue = encodeValue(option.value);
        return (
          <MenuItem key={optionValue} value={optionValue}>
            {option.label}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

Select.defaultProps = {
  disabled: false,
  error: false,
  errorText: '',
  helperText: '',
  label: 'Label',
  readOnly: false,
  required: false,
  fullWidth: false,
  emptyValues: {
    __null__: null,
    __undefined__: undefined,
  },
};
