import React from 'react';

export interface ITabContent {
  children?: React.ReactNode;
  className?: string;
}

export function TabContent(props: ITabContent) {
  const { children, className = '' } = props;
  return <div className={`${className && className}`}>{children}</div>;
}
