import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { AutoHideDuration } from './toaster-constants';
import { INotifierMessage, Dispatch } from './interfaces';
import { useBroadcastContext } from '../../hooks/use-broadcast';

export function ToastDisplay(props: { toasts: INotifierMessage[]; dispatch: Dispatch }) {
  const { toasts, dispatch } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { subscribe: subscribeSuccess } = useBroadcastContext('GlobalToasterSuccess');
  subscribeSuccess(({ data }) => {
    enqueueSnackbar(data, {
      variant: 'success',
      autoHideDuration: AutoHideDuration,
      preventDuplicate: true,
      onClick: () => closeSnackbar(),
    });
  });
  const { subscribe: subscribeInfo } = useBroadcastContext('GlobalToasterInfo');
  subscribeInfo(({ data }) => {
    enqueueSnackbar(data, {
      variant: 'info',
      autoHideDuration: AutoHideDuration,
      preventDuplicate: true,
      onClick: () => closeSnackbar(),
    });
  });

  useEffect(() => {
    if (toasts) {
      toasts.forEach(toast => {
        enqueueSnackbar(toast.message, {
          variant: toast.severity,
          autoHideDuration: AutoHideDuration,
          preventDuplicate: true,
          key: toast.id,
          onClick: () => closeSnackbar(),
        });
        dispatch({ type: 'dismiss', payload: toast });
      });
    }
  }, [toasts, dispatch, enqueueSnackbar, closeSnackbar]);

  return null;
}
