import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton } from '@mui/material';
import { MainNavigationSubMenuRedirectButton } from './main-navigation-sub-menu-redirect';
import { LightTooltip } from './styled-tooltip';
import { ColorPalette, Dimensions } from '../../config';
import { IMainNavigationItem, IMainNavigationChildItem } from './main-navigation-items';
import { joinURL } from '../../../utilities';
import { ItemAvatar } from './main-navigation-item-avatar';

const useStyles = makeStyles({
  childIcon: {
    display: 'inline-block',
    width: Dimensions.ThemeSpacingUnit * 4,
  },
  childrenMenuTitle: {
    backgroundColor: `${ColorPalette.WHITE} !important`,
    fontWeight: 'bolder',
    pointerEvents: 'none',
  },
  iconButton: {
    padding: '0!important',
  },
  menuPaper: {
    marginLeft: 8,
    borderRadius: 2,
    boxShadow: '6px 6px 9px 0px rgb(0 0 0 / 40%)',
  },
});

export interface IMainNavigationSubMenu {
  item: IMainNavigationItem;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, guid: string) => void;
  onClose: () => void;
  anchorEl?: { anchor?: EventTarget & HTMLButtonElement; guid: string };
  isActive: boolean;
}

export function MainNavigationSubMenu(props: IMainNavigationSubMenu) {
  const { item, onClick, anchorEl, onClose, isActive } = props;
  const [showToolTip, setShowToolTip] = useState<boolean>(false);
  const classes = useStyles({});

  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, guid: string) {
    setShowToolTip(false);
    onClick(event, guid);
  }

  function handleHover(mouseIsOver: boolean) {
    setShowToolTip(mouseIsOver);
  }

  function renderChildItem(childItem: IMainNavigationChildItem) {
    if (childItem.openInNewWindow) {
      return <MainNavigationSubMenuRedirectButton key={childItem.guid} childItem={childItem} onClose={onClose} />;
    }

    return (
      <NavLink
        to={joinURL('/', item.url, childItem.url ?? '')}
        key={childItem.guid}
        className="main-navigation__link__child"
        tabIndex={-1}
        data-vanguard={`left-menu_sub-menu_${item.name}`}
      >
        <MenuItem onClick={onClose}>
          <div className={classes.childIcon}>
            <FontAwesomeIcon icon={childItem.icon} />
          </div>
          {childItem.name}
        </MenuItem>
      </NavLink>
    );
  }

  return (
    <>
      <LightTooltip open={showToolTip} onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)} arrow enterDelay={500} title={item.name} placement="right">
        <IconButton onClick={e => handleClick(e, item.guid)} className={classes.iconButton} size="large" data-vanguard={`left-menu_${item.name}`}>
          <ItemAvatar item={item} isActive={isActive} />
        </IconButton>
      </LightTooltip>
      <Menu
        anchorEl={anchorEl?.anchor}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        open={Boolean(anchorEl?.anchor) && anchorEl?.guid === item.guid}
        onClose={onClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        elevation={4}
        classes={{ paper: classes.menuPaper }}
        disableRestoreFocus
        disableScrollLock
      >
        <MenuItem className={classes.childrenMenuTitle}>{item.subMenuName}</MenuItem>
        {item.children?.map((childItem: IMainNavigationChildItem) => renderChildItem(childItem))}
      </Menu>
    </>
  );
}
