export function capitalizeFirstLetter(text?: string): string {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function compareStringsAsLowerCase(str1: string, str2: string): number {
  const str1LowerCase = str1.toLowerCase();
  const str2LowerCase = str2.toLowerCase();
  if (str1LowerCase < str2LowerCase) {
    return -1;
  }
  if (str1LowerCase > str2LowerCase) {
    return 1;
  }
  return 0;
}

export function toLowerCaseAndSpaceHyphens(str?: string) {
  return str?.replace(/\s/g, '-').toLowerCase();
}

/**
 * This function, `stringDateComparator`, is specifically designed for use with MUIX DataGridPro in date fields.
 * It compares two date strings that follow the format "DD-MM-YYYY" and returns:
 * - 1 if the first date is greater than the second date
 * - -1 if the first date is less than the second date
 * - 0 if both dates are equal
 *
 * Example implementation in DataGridPro's column definition:
 * {
 *      field: 'interactionDate',
 *      headerName: 'Kontaktdato',
 *      flex: 1,
 *      valueGetter: ({ value }) => formatDate(value),
 *      sortComparator: stringDateComparator as GridComparatorFn,
 * }
 */
export function stringDateComparator(value1: string, value2: string): number {
  const date1 = value1.split('-').reverse();
  const date2 = value2.split('-').reverse();

  if (date1 > date2) {
    return 1;
  }
  if (date1 < date2) {
    return -1;
  }
  return 0;
}
