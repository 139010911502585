import { AxiosRequestConfig } from 'axios';
import { SecureApiService } from '../../../../api-service';
import { IConfiguration, configuration } from '../../../../configuration';

export interface IRowActionApiService {
  getRowActions(config?: AxiosRequestConfig): Promise<IRowAction[]>;
}

export interface IRowAction {
  resultType: string;
  displayName: string;
  url: string;
  openNewWindow: boolean;
  entityType: string;
}

export class RowActionApiService implements IRowActionApiService {
  private readonly baseService: SecureApiService = new SecureApiService();
  private readonly configuration: IConfiguration = configuration;

  getRowActions(config?: AxiosRequestConfig): Promise<IRowAction[]> {
    const url = `${this.getApiAddress()}/RowAction/GetRowActions`;
    return this.baseService.get<undefined, IRowAction[]>(url, undefined, config);
  }

  private readonly getApiAddress = () => this.configuration.searchBaseAddress;
}
