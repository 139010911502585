/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router';

interface Parameter {
  parameter: string;
}
/**
 * Reads and removes the last URL parameter, including the leading
 * url part given
 *
 * Returns parameter after specified url part,
 * or undefined if leading url part or parameter was not found.
 */
export function useUrlParameterPop(leadingUrlPart: string): string | undefined {
  const match = useRouteMatch<Parameter>(`*/${leadingUrlPart}/:parameter`);
  const { parameter } = match?.params || {};
  const [savedParameter] = useState(parameter);
  const history = useHistory();
  useEffect(() => {
    const urlPartToRemove = `/${leadingUrlPart}/${parameter}`;
    parameter && history.replace(`${match?.url.replace(urlPartToRemove, '')}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return savedParameter;
}
