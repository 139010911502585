import React from 'react';
import { IControlledAccordionContext } from '../components/controlled-accordion/controlled-accordion-context';

export const useControlledAccordion = (): IControlledAccordionContext => {
  const [expanded, setExpanded] = React.useState<boolean | undefined>();

  const toggleExpanded = React.useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  return {
    expanded,
    toggleExpanded,
  };
};
