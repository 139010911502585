/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { blue, amber, red } from '@mui/material/colors';

import { faInfoCircle, faExclamationTriangle, faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { Link } from '@mui/material';
import { ColorPalette } from '../config';
import { IConfirmDialogProps } from './confirm-dialog-props';
import { FormDialog } from '../form-dialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: (props: IConfirmDialogProps) => {
        const { type } = props;
        switch (type) {
          case 'info':
            return blue[700];
          case 'question':
          case 'warning':
            return amber[700];
          case 'alert':
            return red[700];
          default:
            return blue[700];
        }
      },
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
      textAlign: 'center',
    },
    titleWrapper: {
      textAlign: ({ leftAlignTitle = false }) => (leftAlignTitle ? 'left' : 'center'),
    },
    contentWrapper: {
      marginBottom: theme.spacing(3),
      minWidth: 350,
    },
    details: {
      '& summary': {
        cursor: 'pointer',
        color: blue[700],
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        '&::marker': {
          color: ColorPalette.DefaultTextColor,
        },
        '&:focus': {
          outline: 'none',
        },
      },
      '& div': {
        maxWidth: 'fit-content',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
  })
);

const MessageLines: React.FC<{ msg: string; link?: string }> = React.memo(({ msg, link }) => {
  if (!msg) return null; // Return null if msg is falsy
  const lines = msg.split('\r\n');

  return (
    <div>
      {lines.map((line, index) => (
        <React.Fragment key={`messageLine_${index}`}>
          {line}
          {index < lines.length - 1 && <br />}
        </React.Fragment>
      ))}
      {link && (
        <Link href={link} target="_blank" display="block" marginTop={2}>
          {link}
        </Link>
      )}
    </div>
  );
});

export function ConfirmDialog(props: IConfirmDialogProps) {
  const classes = useStyles(props);
  const { children, title, type = 'info', AdditionalInformationLabel = 'Yderligere oplysninger', message, link, ...rest } = props;

  const getIcon = () => {
    switch (type) {
      case 'info':
        return faInfoCircle;
      case 'question':
        return faQuestionCircle;
      case 'warning':
      case 'alert':
        return faExclamationTriangle;
      default:
        return faInfoCircle;
    }
  };

  const newTitle = title && (
    <div className={classes.titleWrapper}>
      <div className={classes.icon}>
        <FontAwesomeIcon icon={getIcon()} size="2x" />
      </div>
      {title}
    </div>
  );

  const content = () => {
    if (message && children) {
      return (
        <>
          <p>
            <MessageLines msg={message} link={link} />
          </p>
          <details className={classes.details}>
            <summary>{AdditionalInformationLabel}</summary>
            <div>{children}</div>
          </details>
        </>
      );
    }
    if (message) return <MessageLines msg={message} link={link} />;
    return children;
  };

  return (
    <FormDialog isConfirm title={newTitle} {...rest}>
      <div className={classes.contentWrapper}>{content()}</div>
    </FormDialog>
  );
}
