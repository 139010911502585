/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { ILookupValueApiService, LookupValueApiService, ILookupValue } from './lookupvalue-api-service';
import { LookupControl } from '../../../../../../components/lookup-control';
import { EntityLookup, IEntityLookupProps, ILookupControlDataObj } from '../../../../../../components/entity-lookup';
import { ILookupSelectedObject } from '../../../../../../components/lookup-control/generic-lookup-window-component';
import { LookupMacros } from '../../../../helpers/lookup-macros';
import { useAxiosAsync } from '../../../../../../hooks';

export interface ILookupValueSelectorProps {
  value: string | string[];
  handleOnChange: (value: string | string[]) => void;
  codeListType: string;
  disabled?: boolean;
  className?: string;
  error?: boolean;
  errorText?: string;
}

function lookupSelectedToLookupValueMapper(selectedValues: string | string[], codeListType: string): ILookupValue[] {
  const values = selectedValues ? (Array.isArray(selectedValues) ? selectedValues : [selectedValues]) : [];
  return values.map(value => ({ type: codeListType, id: value }));
}

function lookupValuesToLookupControlDataObjectsMapper(lookupValues: ILookupValue[]): ILookupControlDataObj[] {
  return lookupValues.map(value => ({ id: value.id, value: value.id, text: value.name ? value.name : '' }));
}

function isInSelection(value: string | string[], lookupValues: ILookupControlDataObj[]) {
  const values = value ? (Array.isArray(value) ? value : [value]) : [];
  const stateValues = lookupValues.map(x => x.id);
  const diff = _.difference(values, stateValues);
  return diff.length === 0;
}

export function LookupValueSelector(props: ILookupValueSelectorProps) {
  const { codeListType, value, handleOnChange, className, error, errorText } = props;
  const lookupValueService: ILookupValueApiService = new LookupValueApiService();
  const lookupAddressAsync = useAxiosAsync((cancelToken, codeListType) => lookupValueService.getUrlForLookup(codeListType, { cancelToken }), '', codeListType);
  const [lookupValuesState, setLookupValuesState] = useState<ILookupControlDataObj[]>([]);
  const lookupValuesAsync = useAxiosAsync(
    async (cancelToken, codeListType) => {
      const lookupValues = lookupSelectedToLookupValueMapper(value, codeListType);

      if (lookupValues.filter(x => x.id.toLowerCase() === LookupMacros.CurrentUser.toLowerCase() || x.id.toLowerCase() === LookupMacros.UserTeam.toLowerCase()).length === 1) {
        return [{ id: lookupValues[0].id, value: lookupValues[0].id, text: lookupValues[0].id }] as ILookupControlDataObj[];
      }

      const lookupValuesResponse = await lookupValueService.getEntityNamesByIds(lookupValues, { cancelToken });
      return lookupValuesToLookupControlDataObjectsMapper(lookupValuesResponse);
    },
    [],
    codeListType
  );

  useEffect(() => {
    if (lookupValuesAsync.result !== lookupValuesState && !lookupValuesAsync.isLoading) {
      setLookupValuesState(lookupValuesAsync.result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookupValuesAsync.result]);

  useEffect(() => {
    if (!isInSelection(value, lookupValuesState)) {
      lookupValuesAsync.reload(codeListType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function onChange(data: ILookupSelectedObject | ILookupSelectedObject[]) {
    const values = data ? (Array.isArray(data) ? data : [data]) : [];
    const valuedata = values.map(value => ({ id: value.id, value: value.id, text: value.text ? value.text : '' }));

    setLookupValuesState(valuedata);
    handleOnChange(values.map(x => x.id.toLowerCase()));
  }

  function onRawChange(val: any) {
    const values = [{ id: val.target.value, value: val.target.value, text: val.target.value }];
    setLookupValuesState(values);
    handleOnChange(values.map(x => x.id.toLowerCase()));
  }

  return (
    <LookupControl
      lookupWindowComponent={(injectables: IEntityLookupProps) => (
        <EntityLookup onValueChosen={injectables.onValueChosen} onCancel={injectables.onCancel} entityLookupAbsoluteUri={lookupAddressAsync.result} />
      )}
      label="Værdi"
      autoApplySelectedValue={false}
      required
      className={className}
      disabled={false}
      error={error}
      errorText={errorText}
      fullWidth={false}
      readOnly={false}
      value={lookupValuesState}
      onValueChanged={onChange}
      onRawChange={onRawChange}
    />
  );
}
