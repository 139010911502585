import { OmnibarEntry } from '../omnibar-interfaces';

/**
 * This file aggregates items for the omnibar from
 * all over the solution
 *
 * Since this is a manual mapping process, this
 * could quite possibly break when stuff is changed.
 *
 * ADD TEST
 */

const functions: OmnibarEntry[] = [];

export default functions;
