import { IHavingRule } from '../rule-interface';
import { IRuleProps } from '../rule-props';
import { IResultTypeUsage } from '../../query/search-result-type/result-type-options';
import { getFields } from '../get-fields';
import { useTransform } from '../../../../../hooks';

export function getFieldsAndTypes(parentTypes: IResultTypeUsage[], type: string | undefined) {
  const selectedType = parentTypes.find(x => x.name === type);
  if (selectedType && selectedType.fields) {
    const childLists = selectedType.fields.filter(x => x.isList).map(x => x.name);

    return {
      fields: getFields(selectedType.fields),
      types: parentTypes.filter(x => childLists.includes(x.name)),
    };
  }

  return { fields: [], types: [] };
}

export function useFieldsAndTypes(props: IRuleProps<IHavingRule>) {
  const {
    types,
    value: { type },
  } = props;
  return useTransform(getFieldsAndTypes, types, type);
}
