import { useEffect, useState } from 'react';

const SCRIPT_REJEX_MAIN = /^.*<script.*\/(main.*\.js).*$/gim;

export const useReleasePoller = ({ deploymentUrl }: { deploymentUrl: string }) => {
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);
  useEffect(() => {
    const compareVersions = async () => {
      // request the index.html file from the deployment
      const fetchedPage = await fetch(deploymentUrl, { method: 'get', mode: 'cors' });

      // get the text from the response
      const loadedText = await fetchedPage.text();
      // console.log(loadedText);
      // get the main.js file to get hash
      const matchResponses = SCRIPT_REJEX_MAIN.exec(loadedText);
      if (!matchResponses) {
        return;
      }
      const remoteMainScript = matchResponses.length > 0 ? matchResponses[1] : undefined;
      if (remoteMainScript === undefined) {
        // Could not find main script in index.html - development maybe.
        setIsNewVersionAvailable(false);
        return;
      }

      // get the current version hash from current deployment
      let currentMainScript;

      // get text representation of document
      const scriptTags = document.head.getElementsByTagName('script');
      for (let i = 0; i < scriptTags.length; i += 1) {
        const scriptTag = scriptTags[i];
        const scripTagMatches = /^.*\/(main.*\.js).*$/gim.exec(scriptTag.src);
        currentMainScript = scripTagMatches === null ? currentMainScript : scripTagMatches[1];
      }

      // if the current main script or the remote main script is undefined, we can't compare
      // but if they are there, compare them
      setIsNewVersionAvailable(!!currentMainScript && !!remoteMainScript && currentMainScript !== remoteMainScript);
    };

    // compare versions every 30 seconds
    const createdInterval = setInterval(compareVersions, 30000);
    return () => {
      // clear the interval when the component unmounts
      clearInterval(createdInterval);
    };
  }, [deploymentUrl]);

  // return the state
  return { isNewVersionAvailable };
};
