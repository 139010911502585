import { IField } from '../components/query-builder';
import { IResultTypeOption } from '../components/query/search-result-type';
import { getFields } from '../components/query-builder/get-fields';
import { useTransform } from '../../../hooks';

function getNestedFields(resultTypes: IResultTypeOption[], resultTypeName: string, shouldPrefix: boolean): IField[] {
  const nestedResultType = resultTypes.find(x => x.name === resultTypeName);
  if (nestedResultType && nestedResultType.fields) {
    return getFields(nestedResultType.fields, shouldPrefix ? `[${nestedResultType.label}] ` : undefined);
  }
  return [];
}

export function getColumnFields(resultTypes: IResultTypeOption[], resultType: string | undefined) {
  if (resultTypes && resultTypes.length > 0) {
    if (resultType) {
      let columnFields: IField[] = [];
      columnFields = getNestedFields(resultTypes, resultType, false);
      columnFields = columnFields.concat(getFields(resultTypes[0].fields, `[${resultTypes[0].label}] `));

      let index = resultType.lastIndexOf('.');
      while (index > -1) {
        const nestedResultTypeName = resultType.substr(0, index);
        columnFields = columnFields.concat(getNestedFields(resultTypes, nestedResultTypeName, true));
        index = nestedResultTypeName.lastIndexOf('.');
      }
      return columnFields;
    }

    return getFields(resultTypes[0].fields);
  }
  return [];
}

export function useColumnFields(resultTypes: IResultTypeOption[], resultType: string | undefined) {
  return useTransform(getColumnFields, resultTypes, resultType);
}
