export const ColorPalette = {
  PrimaryLight: '#78a7be',
  PrimaryMain: '#49788e',
  PrimaryDark: '#184c61',
  TextColorBright: '#fff',
  SecondaryLight: '#ff5b92',
  SecondaryMain: '#d51a65',
  SecondaryDark: '#9e003b',
  DisabledInputBackground: '#CCC',
  DisabledInputTextColor: '#515151',
  DisabledInputLabel: 'rgba(0, 0, 0, .7)',
  InputLabel: 'rgba(0, 0, 0, .7)',
  LightAsteroidDust: '#F2F2F2',
  LightHeaderBackground: '#F4F4F4',
  HotAurora: '#d51a65',
  HotAuroraDark: '#ba1758',
  CityNightSkies: '#49788e',
  CityNightSkiesDark: '#3e6779',
  DeepGalaxy: '#36465e',
  DefaultTextColor: '#000',
  SecondaryTextColor: 'rgba(0,0,0, .7)',
  ApexTintedLight: '#E9EDF1',
  WHITE: '#fff',
  BLACK: '#000',
  GrannySmithGreen: '#58d05e',
  PostItDefault: '#fffb8c',
  PostItDarker: '#eaeaa3',
  PickledEggyolk: '#F0e68c',
  Crimson: '#e4002d',
  AreaBoxBorder: '#ced4da',
  HyperLink: '#0064b7',
  FormDialogBackground: '#e9edf1',
  ScrubsGreen: '#bacad3',
  SummaryPurple: '#9c27b0',
  SummaryGreen: '#2e7d32',
  SummaryOrange: '#eca400',
  Card: '#fafafa',
};
