import { makeUserManager } from 'react-oidc';
import { UserManager } from 'oidc-client';
import { createConfig } from './oidc-auth-config';
import { sendAccessTokenWithHttpRequests } from './auth-axios-interceptor';
import { configuration } from '../configuration';
import { AuthConfig } from './auth-config';

export function initializeAuthentication(config: AuthConfig): UserManager | null {
  const oidcConfig = createConfig(config);
  const userManager = makeUserManager(oidcConfig);
  sendAccessTokenWithHttpRequests(userManager);
  return userManager;
}
