import * as React from 'react';
import MuiDrawer from '@mui/material/Drawer';
import { IDrawerContentProps } from './drawer-content-props';

import './drawer-content.scss';

export class DrawerContent extends React.Component<IDrawerContentProps> {
  static defaultProps = {
    width: '350px',
  };

  public static testIds = {
    content: 'drawer-content',
  };

  render() {
    const { children, toggleDrawer, width, ...props } = this.props;

    return (
      <MuiDrawer anchor="right" onClose={toggleDrawer} {...props}>
        <div className="drawer__content" data-testid={DrawerContent.testIds.content} style={{ width }}>
          {children}
        </div>
      </MuiDrawer>
    );
  }
}
