import React, { useState, useEffect } from 'react';
import { ITextFieldProps } from './text-field-props';
import { TextField } from './text-field';

/*
 * Text field component that holds the input value in its own state, at the same time allowing to update it by changing
 * the value property. As the result it allows to control the input value from the higher level component but without need to
 * update higher level components state on each change.
 *
 * Typical usage for optimization:
 *
 * The following element requires that the state variable 'title' is updated by the 'handleTitleChange' function, so that the
 * changes are visible when the user is typing. It can cause a lot of re-rendering:
 *
 * <TextField label="Titel til journalnotat" onChange={handleTitleChange} value={title} />
 *
 * To avoid the performance problem, onBlur event could be used instead:
 *
 * <TextField label="Titel til journalnotat" onBlur={handleChangeBlur} value={title} />
 *
 * That does not work correctly, though. Unless the value of 'title' is undefined, the text field will always display that
 * value, so the field will become read-only.
 *
 * This component supports that scenario, so that the user can still type, event though the 'title' remains the same until the
 * blur event comes.
 *
 * <OptimizableTextField label="Titel til journalnotat" onBlur={handleTitleBlur} value={title} />
 */
export function OptimizableTextField(props: ITextFieldProps) {
  const { value, onChange, ...otherProps } = props;

  const [inputValue, setInputValue] = useState<string | undefined>();

  useEffect(() => setInputValue(value as string), [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value);
    if (onChange) {
      onChange(event);
    }
  };

  return <TextField {...otherProps} onChange={handleChange} value={inputValue} />;
}
