/* eslint-disable max-len */
import { IHelpText } from '../../../../../components/helptext';

export const texts: IHelpText[] = [
  {
    header: 'Mit jobcenter',
    paragraphs: [
      'Retter borgerens jobcenter til det jobcenter, du tilhører (fremgår i parentes). Hvis borgeren tilhører en anden kommunes jobcenter, vil borgerens hovedjobcenter blive rettet til din kommunes jobcenter.',
    ],
  },
  {
    header: 'Et jobcenter i min kommune',
    paragraphs: ['Hvis din kommunes jobcenter er opdelt i flere subjobcentre, kan du her angive, hvilket subjobcenter under din kommunes jobcenter borgeren skal flyttes til.'],
  },
  {
    header: 'Anden kommune',
    paragraphs: [
      'Hvis du ønsker at overflytte borgeren til et jobcenter i en anden kommune, kan du her angive den ny kommune. Dette bør kun gøres, hvis du har aftale med den ny kommunes jobcenter herom eller hvis du med sikkerhed ved, at beskæftigelsesindsatsen hører hjemme i den ny kommune.',
    ],
  },
];
