import React, { useCallback } from 'react';
import { IRule, INestedRuleBase, IRuleBase } from './rule-interface';
import { getFieldChanges } from './get-field-changes';
import { IField } from './field-interfaces';
import { RuleFactory } from './rule-factory';
import { IRuleProps } from './rule-props';
import { getValueForOperator, getOperatorsByTypeName, getNumberOfValuesRequired } from './operators-functions';
import { useQueryBuilderContext, IQueryBuilderContext, getValidationErrors } from './query-builder-context';
import { ValidationErrors } from '../../../../utilities';
import { validationMessages } from './defaults';

export function Rule(props: IRuleProps<IRule>) {
  const context = useQueryBuilderContext();

  const onChange = (value: IRule) => {
    context.queryDispatch({ type: 'updateRule', oldRule: props.value, newRule: value });
  };

  const onFieldChanged = (value: string) => {
    const { value: rule, fields } = props;
    const changes = getFieldChanges(context, fields, rule, value);
    if (changes) {
      onChange({ ...rule, ...changes });
    }
  };

  const onOperatorChanged = (value: string) => {
    onChange({
      ...props.value,
      operator: value,
      value: getValueForOperator(value, props.value.value, props.value.operator) as any,
    });
  };

  const onValueChanged = (value: string | string[]) => {
    onChange({ ...props.value, value });
  };

  const { queryDispatch } = context;
  const removeRule = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();

      queryDispatch({ type: 'deleteRule', rule: props.value });
    },
    [queryDispatch, props.value]
  );

  const {
    value: { field, operator, value },
    fields,
  } = props;
  const { controls, translations, getOperators, classNames, validationErrors, universe } = context;

  const schemaField = fields && fields.find(x => x.name === field);
  const validationError = getValidationErrors(validationErrors, props.value);

  return (
    <div className={classNames.rule}>
      {React.createElement(controls.fieldSelector, {
        value: schemaField,
        options: fields,
        label: translations.fields.title,
        className: classNames.fields,
        handleOnChange: onFieldChanged,
        error: validationError.field,
      })}
      {React.createElement(controls.operatorSelector, {
        field,
        options: getOperators(schemaField),
        value: operator,
        title: translations.operators.title,
        className: classNames.operators,
        handleOnChange: onOperatorChanged,
        disabled: !field,
        error: validationError.operator,
      })}
      {React.createElement(controls.valueEditor, {
        field,
        operator,
        value,
        codeListType: schemaField ? schemaField.codelistType : undefined,
        uiHint: schemaField ? schemaField.uiHint : undefined,
        type: schemaField ? schemaField.type.toLowerCase() : undefined,
        title: translations.value.title,
        className: classNames.value,
        handleOnChange: onValueChanged,
        disabled: !field && !operator,
        error: validationError.value,
        universe,
        codeListName: schemaField?.codeListName,
      })}
      {React.createElement(controls.removeRuleAction, {
        label: translations.removeRule.label,
        title: translations.removeRule.title,
        disabled: false,
        className: classNames.removeRule,
        handleOnClick: removeRule,
      })}
    </div>
  );
}

export function createRule(context: IQueryBuilderContext, fields: IField[], parent: INestedRuleBase): IRule {
  const field = fields[0];
  const operator = context.getOperators(field)[0];
  return {
    field: field.name,
    operator: operator.name,
    value: '',
  };
}

function hasValues(value: string | string[], expectedValues: number) {
  const values = Array.isArray(value) ? value : [value];
  switch (expectedValues) {
    case 0:
      return undefined;
    case 1:
    case NaN:
    default:
      if (values.length < 1 || !values[0] || values[0].length === 0) return validationMessages.valueRequired;
      break;
    case 2:
      if (values.length < 2 || values[0].length === 0 || values[1].length === 0) {
        const arr = [];
        (values.length < 1 || values[0].length === 0) && (arr[0] = validationMessages.valueRequired);
        (values.length < 2 || values[1].length === 0) && (arr[1] = validationMessages.valueRequired);
        return arr;
      }
  }
  return undefined;
}

export function validateRule(rule: IRule, fields: IField[]): ValidationErrors<IRule> {
  const validationErrors: ValidationErrors<IRule> = {};

  if (!rule.field) {
    validationErrors.field = validationMessages.fieldRequired;
    return validationErrors;
  }

  const field = fields.find(x => x.name === rule.field);
  if (!field) {
    validationErrors.field = validationMessages.unknownField;
    return validationErrors;
  }

  if (!rule.operator) {
    validationErrors.operator = validationMessages.operatorRequired;
    return validationErrors;
  }

  const operator = getOperatorsByTypeName(field.type as any);
  if (!operator || !operator.some(x => x.name === rule.operator)) {
    validationErrors.operator = validationMessages.unknownOperator;
    return validationErrors;
  }

  const count = getNumberOfValuesRequired(field.type, rule.operator);
  const valueErrors = hasValues(rule.value, count);
  if (valueErrors) validationErrors.value = valueErrors;

  return validationErrors;
}

export function isRule(baseRule: IRuleBase): baseRule is IRule {
  const rule = baseRule as IRule;
  return !!rule.field || !!rule.operator;
}

function componentFactory(key: string | number, props: IRuleProps<IRule>): React.ReactNode {
  return isRule(props.value) ? <Rule {...props} key={key} /> : null;
}

RuleFactory.register('rule', createRule, componentFactory);
