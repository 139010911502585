import { useMemo } from 'react';
import { VariantType } from 'notistack';
import { FasitProblemDetails } from '../api-service/clients';
import { getFasitProblemDetails } from '../api-service';

export interface IError {
  message: string;
  variant?: VariantType;
  correlationIdentifier?: string;
}

export function useError(...errors: IError[]): IError | FasitProblemDetails | undefined {
  return useMemo(() => errors.map(x => getFasitProblemDetails(x)).find(x => !!x) || errors.find(x => !!x), [errors]);
}
