import { useLocation } from 'react-router';
import { useMemo } from 'react';

export function useQuery(queryParam: string) {
  const { search } = useLocation();

  const searchParam = useMemo(() => new URLSearchParams(search), [search]);

  return searchParam.get(queryParam) || undefined;
}

export function useQueries<T>(...queryParams: string[]) {
  const { search } = useLocation();

  const searchParam = useMemo(() => new URLSearchParams(search), [search]);

  const result = {} as any;

  queryParams.forEach(queryParam => {
    result[queryParam] = searchParam.get(queryParam) || undefined;
  });

  return result as T;
}
