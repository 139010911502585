import React from 'react';
import { Button, Typography, Container, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';

export function FkoErrorPage() {
  const { push, location } = useHistory();
  const { returnPathFromCallback, message } = location.state as { message: string; returnPathFromCallback: string };

  function onOkClick() {
    push(returnPathFromCallback);
  }

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 8,
        }}
      >
        <FontAwesomeIcon icon={faTriangleExclamation} size="2xl" style={{ color: '#eed744' }} />
        <Typography component="h1" variant="h4" gutterBottom>
          Advarsel
        </Typography>
        <Typography variant="body1" gutterBottom>
          Du kan godt logge ind, men din bruger er muligvis ikke synkroniseret med data fra det fælleskommunale organisationssystem (FKO)
        </Typography>
        <Typography variant="body1" gutterBottom>
          Det betyder at din email, titel, telefonnummer eller team i Fasit kan være forskelligt fra FKO.
        </Typography>
        <Typography variant="body1" color="error" gutterBottom>
          Detaljeret fejlbesked: {message}
        </Typography>
        <Box mt={3}>
          <Button variant="contained" color="primary" onClick={onOkClick}>
            Fortsæt login
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
