import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Button, ListItemIcon } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-light-svg-icons';
import { TypographySettings } from '../config';
import { DynamicLink, GetPostRequestParametersResponse, PostRequestType } from '../../api-service/clients';
import { WindowService } from '../window-service/window-service';
import { useDynamicLinks } from '../../hooks/useDynamicLinks';
import { AbsolutelyCentered } from '../absolutely-centered';
import { CircularProgress } from '../circular-progress';
import { useApi, useErrorSnackbar } from '../../hooks';
import { moduleClientFactory } from '../../api-service/module-client-factory';
import {
  GenericPostRequestPageForm,
  GenericPostRequestPageFormComponent,
  PostRequestTypeToComponent,
} from '../../areas/citizen/details/components/jump-to/generic-post-request-page-form';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 6,
  p: 0,
  outline: 'none',
  minHeight: 100,
};

export function DynamicLinks({ resourceType, resourceId, citizenId, onClose }: { resourceType: string; resourceId?: string; citizenId?: string; onClose: () => void }) {
  const [open, setOpen] = React.useState(true);
  const [genericPostRequestPageFormComponent, setGenericPostRequestPageFormComponent] = React.useState<
    | {
        componentName: GenericPostRequestPageFormComponent;
        getPostRequestParametersResponse: GetPostRequestParametersResponse;
        linkurl: string;
        autoSubmit: boolean;
      }
    | undefined
  >();
  const [postRequestData, setPostRequestData] = React.useState<GetPostRequestParametersResponse>();
  const { dynamicLinks, loading } = useDynamicLinks(resourceType, resourceId || '');
  const enabledDynamicLinks = dynamicLinks?.filter(x => x.disabled === false);

  const getPostRequestParametersApi = useApi((cancelToken, id: string, requestTypes: PostRequestType[]) => {
    const client = moduleClientFactory.createDynamicLinkClient();
    return client.getPostRequestParameters({ citizenId: id, postRequestTypes: requestTypes }, cancelToken);
  }, {} as GetPostRequestParametersResponse);

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  React.useEffect(() => {
    async function getPostRequestData() {
      if (enabledDynamicLinks && enabledDynamicLinks.length > 0 && !postRequestData && citizenId) {
        const postRequestTypes: PostRequestType[] = enabledDynamicLinks.flatMap((x: DynamicLink) => (x.postRequestType ? x.postRequestType : []));
        if (postRequestTypes && postRequestTypes.length > 0) {
          const { result } = await getPostRequestParametersApi.invoke(citizenId, postRequestTypes);
          setPostRequestData(result);
        }
      }
    }
    getPostRequestData();
  }, [dynamicLinks]);

  useErrorSnackbar(getPostRequestParametersApi.error);

  function openLink(link: DynamicLink) {
    if (link.postRequestType && postRequestData && link.url) {
      const componentSwitched = PostRequestTypeToComponent(link.postRequestType);
      setGenericPostRequestPageFormComponent({
        componentName: componentSwitched,
        getPostRequestParametersResponse: postRequestData,
        linkurl: link.url,
        autoSubmit: link.autoSubmitPostRequest ?? true,
      });
      return;
    }
    link && link.url && WindowService.open(link.url);
    handleClose();
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper sx={style}>
        {loading ? (
          <AbsolutelyCentered>
            <CircularProgress />
          </AbsolutelyCentered>
        ) : (
          <>
            <Typography component="div" sx={{ fontFamily: TypographySettings.AlternativeFontFamily, fontSize: '1rem', fontWeight: 500, color: '#333', p: 2 }}>
              Hop til
            </Typography>
            <Divider />
            <Box sx={{ maxHeight: 'calc(100vh - 90px - 16px)', overflowY: 'auto' }}>
              {enabledDynamicLinks ? (
                <MenuList>
                  {enabledDynamicLinks
                    .filter(x => !x.postRequestType || !getPostRequestParametersApi.isError)
                    .map((link: DynamicLink) => (
                      <MenuItem onClick={() => openLink(link)} key={link.text} sx={{ display: 'flex' }}>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faLink} />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{ sx: { fontSize: 14 } }} secondaryTypographyProps={{ sx: { fontSize: 12 } }} secondary={link.tooltip}>
                          {link.text}
                        </ListItemText>
                      </MenuItem>
                    ))}
                </MenuList>
              ) : (
                <Typography component="div" sx={{ fontFamily: TypographySettings.DefaultFontFamily, fontSize: '1rem', fontWeight: 400, color: '#333', p: 2 }}>
                  Der er ikke konfigureret links for den valgte entitet.
                </Typography>
              )}
            </Box>
            {genericPostRequestPageFormComponent && (
              <GenericPostRequestPageForm
                componentName={genericPostRequestPageFormComponent.componentName}
                getPostRequestParametersResponse={genericPostRequestPageFormComponent.getPostRequestParametersResponse}
                linkurl={genericPostRequestPageFormComponent.linkurl}
                onCompleted={() => setGenericPostRequestPageFormComponent(undefined)}
                autoSubmit={genericPostRequestPageFormComponent.autoSubmit}
              />
            )}
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'end', p: 2 }}>
              <Button onClick={handleClose} color="primary" variant="text">
                Luk
              </Button>
            </Box>
          </>
        )}
      </Paper>
    </Modal>
  );
}
