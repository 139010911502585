/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig } from 'axios';
import { IConfiguration, configuration } from '../../../../../../configuration';
import { SecureApiService } from '../../../../../../api-service';

export interface ILookupValue {
  id: string;
  name?: string;
  type: string;
}

export interface ILookupValueApiService {
  getEntityNamesByIds(lookupValueRequest: ILookupValue[], config?: AxiosRequestConfig): Promise<ILookupValue[]>;
  getUrlForLookup(entityName: string, config?: AxiosRequestConfig): Promise<string>;
}

export class LookupValueApiService implements ILookupValueApiService {
  private readonly baseService: SecureApiService = new SecureApiService();
  private readonly configuration: IConfiguration = configuration;

  async getEntityNamesByIds(lookupValueRequest: ILookupValue[], config?: AxiosRequestConfig): Promise<ILookupValue[]> {
    const url = `${this.getApiAddress()}/EntityLookup/EntityNames`;
    return this.baseService.post<ILookupValue[], ILookupValue[]>(url, lookupValueRequest, config);
  }

  async getUrlForLookup(entityName: string, config?: AxiosRequestConfig): Promise<string> {
    const url = `${this.getApiAddress()}/EntityLookup/Url/${entityName}`;
    return this.baseService.get<undefined, string>(url, undefined, config);
  }

  private readonly getApiAddress = () => this.configuration.searchBaseAddress;
}
