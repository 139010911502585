import { Backdrop, Button, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import { ColorPalette, TypographySettings } from '../config';
import { useFeatureToggles } from '../../feature-toggles';

export default function EducationEnvironmentTerms() {
  const [dismissed, setDismissed] = useState(!!sessionStorage.getItem('UDDEnvTermsAccepted'));
  const { isFeatureEnabled } = useFeatureToggles();

  function handleOk() {
    sessionStorage.setItem('UDDEnvTermsAccepted', '1');
    setDismissed(true);
  }

  return !isFeatureEnabled('UDDEnvTermsPopUp') || dismissed ? null : (
    <>
      <Dialog
        open
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          color: ColorPalette.DefaultTextColor,
          fontFamily: TypographySettings.DefaultFontFamily,
          fontSize: TypographySettings.DefaultFontSize,
          zIndex: 21001,
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            color: ColorPalette.DefaultTextColor,
            fontFamily: TypographySettings.AlternativeFontFamily,
            fontSize: 20,
            fontWeight: 500,
            paddingBottom: 3,
            paddingTop: 3,
          }}
        >
          Acceptér vilkår for brug af Schultz Fasits uddannelsesmiljø
        </DialogTitle>
        <DialogContent
          sx={{
            overflowY: 'visible',
            fontSize: 14,
            fontFamily: TypographySettings.DefaultFontFamily,
            color: ColorPalette.DefaultTextColor,
            '& strong': { fontWeight: 700 },
            '& li': {
              marginTop: 1,
            },
            '& ul': {
              marginTop: 3,
            },
          }}
        >
          <Box>
            <p>
              <strong>Du er ved at logge ind i Schultz Fasits uddannelsesmiljø, observér følgende:</strong>
            </p>
            <ul>
              <li>Uddannelsesmiljøet må alene benyttes til uddannelsesaktiviteter.</li>
              <li>
                Uddannelsesmiljøet må kun benyttes af personer, der også har eller skal have adgang til Schultz Fasits produktionsmiljø og som derfor allerede er underlagt
                tavshedspligt i deres ansættelse.
              </li>
              <li>
                Alle data i uddannelsesmiljøet er fortrolige og skal behandles som sådan og må således ikke misbruges, videreformidles eller offentliggøres. Schultz fraskriver sig
                ethvert ansvar for tab eller skade som følge af misbrug af data i uddannelsesmiljøet.
              </li>
            </ul>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 2, paddingTop: 0 }}>
          <Button
            sx={{
              textTransform: 'unset',
              fontFamily: TypographySettings.DefaultFontFamily,
              fontWeight: TypographySettings.DefaultFontSemiBold,
              borderColor: ColorPalette.PrimaryMain,
              color: ColorPalette.PrimaryMain,
              '&:hover': {
                backgroundColor: 'rgba(73, 120, 142, 0.04)',
                borderColor: ColorPalette.PrimaryMain,
                color: ColorPalette.PrimaryMain,
              },
            }}
            variant="outlined"
            onClick={handleOk}
          >
            Acceptér
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backdropFilter: 'blur(5px)',
          backgroundColor: 'rgb(54, 70, 94, 0.12)',
          zIndex: 21000,
        }}
        open
      />
    </>
  );
}
