import { win } from './window';
/* istanbul ignore file */
export function downloadAsFile(blobPart: BlobPart, filename: string) {
  const url = win.URL.createObjectURL(new Blob([blobPart]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode && link.parentNode.removeChild(link);
}
