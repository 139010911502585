import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Popper, PopperProps } from '@mui/material';
import { IAutocompleteValue } from 'areas/search/save-dialog';
import { IOption } from '../field-interfaces';
import { SingleSelect } from '../../../../../components/single-select/single-select';

export interface IAutoCompleteValueSelectorProps {
  options: IOption[];
  value?: IOption;
  className?: string;
  label: string;
  handleOnChange: (value: string) => void;
  error?: string | string[];
}

export const testIds = {
  CustomAutoCompleteId: 'CustomAutoCompleteId',
};

const useStyles = makeStyles({
  inputRoot: {
    '.MuiFilledInput-root &.MuiFilledInput-input': {
      width: (props: any) => `${props.value?.label.length ?? 23}ch`,
    },
  },
});

function CustomPopper(props: PopperProps) {
  const styles = {
    popper: {
      width: 'auto',
    },
  };
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
}

export function AutoCompleteValueSelector(props: IAutoCompleteValueSelectorProps) {
  const { options, handleOnChange, label, className, value, error } = props;
  const classes = useStyles(props);

  const onChange = React.useCallback(
    (newValue: IAutocompleteValue) => {
      handleOnChange(newValue.value);
    },
    [handleOnChange]
  );

  return (
    <SingleSelect<IAutocompleteValue>
      data-testid={testIds.CustomAutoCompleteId}
      label={label}
      renderOption={(optionProps: object, option: IAutocompleteValue) => (
        <li {...optionProps} key={option.value}>
          {option.title}
        </li>
      )}
      value={{ title: value?.label ?? '', value: value?.name ?? '' }}
      options={options.map(option => ({
        title: option.label,
        value: option.name,
      }))}
      autoHighlight
      getOptionLabel={option => option.title}
      onChange={onChange}
      error={!!error}
      errorText={error}
      inputElementRootClass={classes.inputRoot}
      isOptionEqualToValue={(option: IAutocompleteValue, equalValue: IAutocompleteValue) => option.value === equalValue.value}
      popperComponent={CustomPopper}
      style={{ minWidth: '300px' }}
    />
  );
}
