import { OmnibarEntry, OmnibarContext } from '../omnibar-interfaces';

export default function entrySort(optionA: OmnibarEntry, optionB: OmnibarEntry, currentContext: OmnibarContext) {
  const { context: contextA, group: groupA = '', label: labelA } = optionA;
  const { context: contextB, group: groupB = '', label: labelB } = optionB;

  if (contextA === currentContext) {
    if (contextA === contextB) {
      if (groupA < groupB) return -1;
      if (groupA > groupB) return 1;
      if (groupA !== groupB) return 0;
      if (labelA < labelB) return -1;
      if (labelA > labelB) return 1;
    }
    return -1;
  }
  if (contextB === currentContext) return 1;
  if (groupA < groupB) return -1;
  if (groupA > groupB) return 1;
  if (groupA !== groupB) return 0;
  if (labelA < labelB) return -1;
  if (labelA > labelB) return 1;
  return 0;
}
