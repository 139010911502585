import React from 'react';

interface IDeprecationWarningProps {
  type: 'prop' | 'component';
  name: string;
  location: string;
  more?: string;
  children?: React.ReactNode;
}

export function DeprecationWarning(props: IDeprecationWarningProps) {
  const { type, name, location, more = '', children } = props;

  if (!!process.env && !window) {
    if (type === 'prop') {
      console.warn(`[Deprecation] The property "${name}" in "${location}" will be deprecated soon. ${more}`);
    }
  }

  return <span>{children}</span>;
}
