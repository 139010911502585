import CssBaseline from '@mui/material/CssBaseline';
import { daDK } from '@mui/material/locale';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import * as React from 'react';
import { ColorPalette, Dimensions, TypographySettings } from '../config';
import { daDK as daDKGrid } from '../data-grid/data-grid.daDK';

export interface ICssBaseDecoratorOverrides {
  dimensionsOverride?: object;
  typographyOverride?: object;
}

export const DefaultTheme = ({ dimensionsOverride, typographyOverride }: ICssBaseDecoratorOverrides) => {
  const defaultDimensions = { ...Dimensions, ...dimensionsOverride };
  const defaultTypographySettings = {
    ...TypographySettings,
    ...typographyOverride,
  };

  return createTheme(
    {
      spacing: defaultDimensions.ThemeSpacingUnit,
      palette: {
        background: {
          default: ColorPalette.ApexTintedLight,
        },
        primary: {
          light: ColorPalette.PrimaryLight,
          main: ColorPalette.PrimaryMain,
          dark: ColorPalette.PrimaryDark,
          contrastText: ColorPalette.TextColorBright,
        },
        secondary: {
          light: ColorPalette.SecondaryLight,
          main: ColorPalette.HotAurora,
          dark: ColorPalette.SecondaryDark,
          contrastText: ColorPalette.TextColorBright,
        },
        error: {
          light: ColorPalette.SecondaryLight,
          main: ColorPalette.HotAurora,
          dark: ColorPalette.SecondaryDark,
          contrastText: ColorPalette.TextColorBright,
        },
        text: {
          primary: ColorPalette.DefaultTextColor,
          secondary: ColorPalette.SecondaryTextColor,
        },
      },
      typography: {
        htmlFontSize: defaultTypographySettings.RootFontSize,
        fontSize: defaultTypographySettings.DefaultFontSize,
        fontFamily: defaultTypographySettings.DefaultFontFamily,
        h3: {
          fontSize: '1.125rem',
          lineHeight: 1.5,
        },
        fontWeightBold: 500,
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            '& strong': {
              fontWeight: 600,
            },
          },
        },
        MuiDataGrid: {
          styleOverrides: {
            root: {
              backgroundColor: '#fff',
              fontSize: '14px',
              border: '1px solid rgba(0, 0, 0, 0.15)',
              '& .FasitDataGridToolbar-title': {
                fontFamily: defaultTypographySettings.AlternativeFontFamily,
                fontWeight: defaultTypographySettings.AlternativeFontSemiBold,
                fontSize: defaultTypographySettings.DefaultFontSize,
                marginRight: 'auto',
                transform: 'translateY(1px)',
                letterSpacing: 0.2,
              },
              '& .MuiDataGrid-panel': {
                marginTop: '-43px !important',
              },
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                padding: 0,
              },
              '& .MuiDataGrid-toolbarContainer': {
                backgroundColor: '#F4F4F4',
                borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
                padding: '10px 16px',
                justifyContent: 'end',
                '&.hasAddButton': {
                  paddingTop: 5,
                  paddingBottom: 3,
                  paddingLeft: 5,
                },
                '&.contextGridToolbar': {
                  backgroundColor: '#fbe0ea',
                  borderBottomColor: 'rgba(224, 224, 224, 1)',
                  paddingTop: 5,
                  paddingBottom: 3,
                  paddingLeft: 5,
                },
                '& .MuiButton-root': {
                  color: 'rgba(0, 0, 0, 0.65)',
                  minWidth: 0,
                  marginLeft: 8,
                  '& .MuiButton-startIcon': {
                    margin: 0,
                    '&>*:nth-of-type(1)': {
                      fontSize: 16,
                    },
                  },
                },
              },
              '& .MuiCheckbox-root': {
                '& .MuiSvgIcon-root': {
                  height: 18,
                  width: 18,
                },
              },
              '& .MuiDataGrid-iconSeparator': {
                opacity: 0,
              },
              '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                borderRight: '1px solid rgba(0, 0, 0, 0.15)',
                color: '#000',
                '&:last-of-type': {
                  borderRight: 'none',
                },
              },
              '& .MuiDataGrid-cell[data-field="buttonmenu"]': {
                '.MuiButton-root': {
                  minWidth: 20,
                },
                '> div': {
                  margin: '0 auto',
                },
              },
              '& .MuiDataGrid-columnHeader': {
                color: 'rgba(0, 0, 0, 0.54)',
                '&.MuiDataGrid-columnHeader--sorted': {
                  color: '#000',
                },
              },
              '& .MuiDataGrid-detailPanelToggleCell': {
                svg: {
                  visibility: 'hidden',
                },
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  width: 0,
                  height: 0,
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%) rotate(-90deg)',
                  borderLeft: '5.04px solid transparent',
                  borderRight: '5.04px solid transparent',
                  borderTop: '7px solid rgba(0, 0, 0, 0.8)',
                  transition: 'all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                  cursor: 'pointer',
                },
              },
              '& .MuiDataGrid-detailPanelToggleCell--expanded::after': {
                transform: 'translate(-50%, -50%) rotate(0)',
              },
              '& .MuiDataGrid-overlay': {
                backgroundColor: 'transparent',
                color: '#797979',
              },
              '& .MuiBadge-badge': {
                color: '#797979',
                transform: 'translate(50%, 0)',
              },
              '& .MuiDataGrid-footerContainer': {
                minHeight: 0,
                '& .MuiToolbar-root': {
                  minHeight: 0,
                  paddingTop: 0,
                  paddingBottom: 0,
                },
              },
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
              },
            },
            columnHeaderTitle: {
              fontWeight: TypographySettings.DefaultFontSemiBold,
            },
          },
        },
        MuiTablePagination: {
          styleOverrides: {
            toolbar: {
              backgroundColor: ColorPalette.WHITE,
            },
          },
        },
        MuiLink: {
          styleOverrides: {
            root: {
              fontSize: TypographySettings.DefaultFontSize,
            },
          },
        },
        MuiFilledInput: {
          styleOverrides: {
            root: {
              backgroundColor: '#fff',
              minWidth: '200px',
              '&.Mui-disabled': {
                backgroundColor: 'transparent',
              },
              '&.Mui-focused': {
                backgroundColor: '#fff',
              },
              fontSize: '14px',
              '[readonly] &': {
                backgroundColor: 'transparent',
              },
              '& textarea': {
                overflow: 'auto',
              },
            },
            underline: {
              '&:after': {
                borderBottom: '1px solid #333',
              },
              '&.Mui-disabled:before': {
                borderBottom: '1px solid #333',
              },
              '&.Mui-error:after': {
                borderBottomColor: ColorPalette.HotAurora,
              },
              '&.Mui-focused:after': {
                borderBottomColor: '#2196F3',
              },
            },
            input: {
              '&::-ms-clear': {
                display: 'none',
              },
            },
          },
        },
        MuiFormLabel: {
          styleOverrides: {
            root: {
              fontSize: 14,
              '&.Mui-focused': {
                color: ColorPalette.CityNightSkies,
              },
              '&.Mui-error': {
                color: ColorPalette.HotAurora,
              },
              disabled: {
                '&.Mui-outlined': {
                  color: ColorPalette.DisabledInputLabel,
                },
              },
            },
            asterisk: {
              color: ColorPalette.HotAurora,
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            filled: {
              transform: 'translate(12px, 7px) scale(1)',
              '&.MuiInputLabel-shrink': {
                transform: 'translate(12px, 7px) scale(1)',
              },
            },
            outlined: {
              color: ColorPalette.InputLabel,
              '&.MuiInputLabel-shrink': {
                transform: 'translate(14px, -9px) scale(1)',
              },
              '&.Mui-disabled': {
                color: ColorPalette.DisabledInputLabel,
              },
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              '&.Mui-disabled': {
                backgroundColor: '#f7f7f7',
              },
            },
            notchedOutline: {
              '& legend': {
                fontSize: 14,
                '&>span.notranslate': {
                  padding: 0,
                },
              },
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              fontSize: TypographySettings.DefaultFontSize,
              '&.Mui-focused': {
                notchedOutline: {
                  borderColor: ColorPalette.CityNightSkies,
                },
              },
              '&.Mui-disabled': {
                color: 'rgba(0, 0, 0, .54)',
              },
            },
            input: {
              '&.Mui-disabled': {
                color: 'rgba(0, 0, 0, .54)',
                WebkitTextFillColor: 'inherit',
              },
            },
          },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            label: {
              fontSize: 14,
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            body: {
              color: '#000',
              fontSize: TypographySettings.DefaultFontSize,
            },
            root: {
              fontSize: TypographySettings.DefaultFontSize,
              whiteSpace: 'nowrap',
            },
            head: {
              fontSize: TypographySettings.DefaultFontSize,
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              height: Dimensions.DefaultItemHeight,
            },
            head: {
              height: Dimensions.DefaultItemHeight,
            },
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              fontSize: 12,
            },
          },
        },
        MuiStepIcon: {
          styleOverrides: {
            root: {
              color: ColorPalette.LightAsteroidDust,
              '&.Mui-active': {
                color: ColorPalette.HotAurora,
              },
              '&.Mui-completed': {
                color: ColorPalette.HotAurora,
              },
            },
          },
        },
        MuiStepLabel: {
          styleOverrides: {
            label: {
              fontFamily: TypographySettings.DefaultFontFamily,
              fontSize: TypographySettings.RootFontSize,
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            select: {
              '[readonly] &': {
                pointerEvents: 'none',
              },
              width: 'calc(100% - 44px)',
              '&:focus': {
                background: '#fff',
              },
              '[data-disabled-state] &:focus': {
                background: 'transparent',
              },
            },
            icon: {
              '[readonly] &, [data-disabled-state] &': {
                display: 'none',
              },
            },
          },
        },
        MuiInputAdornment: {
          styleOverrides: {
            root: {
              '[data-disabled-state] &': {
                display: 'none',
              },
            },
            positionEnd: {
              flexShrink: 0,
            },
          },
        },
        MuiMenu: {
          styleOverrides: {
            paper: {
              maxHeight: '352px',
            },
          },
        },
        MuiFormControl: {
          styleOverrides: {
            root: {
              maxWidth: '100%',
              '&[readonly][data-disabled-state]': {
                '& input, & textarea, & legend': {
                  cursor: 'default',
                  color: '#000',
                },
                '& .MuiInputBase-root': {
                  backgroundColor: '#f6f8f9',
                },
              },
              '.MuiAutocomplete-root &[data-disabled-state]': {
                pointerEvents: 'none',
              },
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              display: 'block',
              fontSize: '.8125rem',
            },
          },
        },
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              fontSize: TypographySettings.DefaultFontSize,
            },
          },
        },
        MuiAvatar: {
          styleOverrides: {
            root: {
              backgroundColor: '#333',
              color: '#fff',
              cursor: 'pointer',
              '&:hover img': {
                opacity: 0.8,
              },
              '&.hot-aurora': {
                backgroundColor: ColorPalette.HotAurora,
              },
              '&.hot-aurora:hover': {
                backgroundColor: ColorPalette.HotAuroraDark,
              },
              '&.primary': {
                backgroundColor: ColorPalette.CityNightSkies,
              },
              '&.primary:hover': {
                backgroundColor: ColorPalette.CityNightSkiesDark,
              },
            },
          },
        },
        MuiDialog: {
          styleOverrides: {
            root: {
              color: ColorPalette.DefaultTextColor,
              fontFamily: TypographySettings.DefaultFontFamily,
              fontSize: TypographySettings.DefaultFontSize,
            },
            paper: {
              overflowY: 'visible',
            },
          },
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: {
              color: ColorPalette.DefaultTextColor,
              fontFamily: TypographySettings.AlternativeFontFamily,
              fontSize: 20,
              fontWeight: 500,
              paddingBottom: Dimensions.ThemeSpacingUnit * 3,
              paddingTop: Dimensions.ThemeSpacingUnit * 3,
            },
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              padding: Dimensions.ThemeSpacingUnit * 2,
              paddingTop: 0,
            },
          },
        },
        MuiDialogContent: {
          styleOverrides: {
            root: {
              overflowY: 'visible',
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            root: {
              minHeight: 40,
              boxShadow: 'inset 0 -4px 8px -6px rgba(0,0,0,0.5)',
            },
            indicator: {
              bottom: 'auto',
              top: 0,
              backgroundColor: ColorPalette.HotAurora,
            },
            scrollButtons: {
              width: 'auto',
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              fontFamily: TypographySettings.DefaultFontFamily,
              fontSize: TypographySettings.DefaultFontSize,
              minHeight: 40,
              minWidth: 0,
              paddingLeft: 20,
              paddingRight: 20,
              textTransform: 'unset',
              '@media (min-width: 960px)': {
                fontSize: TypographySettings.DefaultFontSize,
                minWidth: 0,
              },
              '&.Mui-selected': {
                backgroundColor: ColorPalette.ApexTintedLight,
                fontWeight: TypographySettings.DefaultFontSemiBold,
                color: ColorPalette.DefaultTextColor,
                boxShadow: '0 6px 12px -4px rgba(0,0,0,.5)',
              },
            },
            textColorInherit: {
              color: ColorPalette.DefaultTextColor,
              opacity: 1,
            },
            wrapped: {
              fontSize: '.85rem',
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              fontFamily: TypographySettings.DefaultFontFamily,
              fontWeight: TypographySettings.DefaultFontSemiBold,
              textTransform: 'unset',
            },
            containedSecondary: {
              backgroundColor: '#fff',
              color: '#000',
              borderColor: '#A1A1A1',
              '&:hover': {
                backgroundColor: '#F2F2F2',
              },
            },
            outlinedSecondary: {
              borderColor: '#A1A1A1',
              color: '#000',
              '&:hover': {
                backgroundColor: '#fff',
                borderColor: '#A1A1A1',
                color: '#000',
              },
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            deleteIcon: {
              color: ColorPalette.CityNightSkies,
              '&:hover': {
                color: ColorPalette.CityNightSkiesDark,
              },
            },
            outlined: {
              borderColor: ColorPalette.CityNightSkies,
            },
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              '&.Mui-checked': {
                color: ColorPalette.HotAurora,
              },
            },
          },
        },
        MuiAccordionSummary: {
          styleOverrides: {
            root: {
              padding: '0 16px',
              minHeight: 48,
              '&.MuiButtonBase-root': {
                minHeight: 48,
              },
              '.embedded-layout__container &': {
                minHeight: 48,
              },
            },
            content: {
              color: ColorPalette.DefaultTextColor,
              fontSize: TypographySettings.RootFontSize,
              fontFamily: TypographySettings.AlternativeFontFamily,
              fontWeight: TypographySettings.AlternativeFontSemiBold,
              paddingTop: 2,
              letterSpacing: 0.4,
              '&.Mui-expanded': {
                margin: 0,
              },
            },
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              fontFamily: TypographySettings.DefaultFontFamily,
            },
          },
        },
        MuiBadge: {
          styleOverrides: {
            badge: {
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: ColorPalette.LightHeaderBackground,
              '.pagination-bottom &': {
                backgroundColor: ColorPalette.WHITE,
              },
              '&.toolbar--withSelectedCheckboxes': {
                backgroundColor: '#fbe0ea',
                borderBottomColor: 'rgba(224, 224, 224, 1)',
              },
            },
            dense: {
              minHeight: 42,
            },
            gutters: {
              padding: Dimensions.ThemeSpacingUnit,
              paddingLeft: Dimensions.ThemeSpacingUnit * 2,
              paddingRight: Dimensions.ThemeSpacingUnit * 2,
              '@media (min-width: 600px)': {
                paddingLeft: Dimensions.ThemeSpacingUnit * 2,
                paddingRight: Dimensions.ThemeSpacingUnit * 2,
              },
            },
          },
        },
        MuiAutocomplete: {
          styleOverrides: {
            root: {
              '& .MuiOutlinedInput-root': {
                alignItems: 'flex-start',
              },
              '& .MuiFilledInput-root': {
                alignItems: 'flex-start',
                paddingTop: 24,
                '& .MuiFilledInput-input': {
                  paddingTop: 5,
                  paddingBottom: 5,
                },
                '& .MuiAutocomplete-tag': {
                  fontSize: 12,
                  height: 20,
                  marginBottom: 0,
                },
              },
            },
          },
        },
        MuiRadio: {
          styleOverrides: {
            root: {
              '&:hover': {
                color: ColorPalette.HotAurora,
              },
              '&.Mui-checked': {
                color: ColorPalette.HotAurora,
              },
              '&.Mui-disabled': {
                color: '#797979',
              },
            },
          },
        },
      },
    },
    daDK,
    daDKGrid
  );
};

export interface IProps {
  children?: React.ReactNode;
  overrides?: ICssBaseDecoratorOverrides;
}

export function CssBaseDecorator({ children, overrides }: IProps) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={DefaultTheme({ ...overrides })}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
