export function createPostRequest(postRequestForm: string, autoSubmitPostRequest?: boolean) {
  const blob = new Blob(
    [
      `<!DOCTYPE html>
<html>
<head>
    <meta name="viewport" content="width=device-width" />
    <title>GenericPostRequest</title>
    <script>
            function postData()  {
                if (${autoSubmitPostRequest ?? true}) {
                    var frm = document.getElementById("${postRequestFormId}");
                    window.close();
                    frm.submit();
                }
            }
    </script>
</head>
<body onload="postData()">
    <div>
        ${postRequestForm}
    </div>
</body>
</html>
`,
    ],
    {
      type: 'text/html',
    }
  );

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.target = '_blank';
  link.type = 'text/html';
  link.click();
}

export const postRequestFormId = 'postRequestForm';
