import React from 'react';
import { parseISO } from 'date-fns';
import { DatePicker } from '../../../../../../components/date-picker/date-picker';
import { ColorPalette } from '../../../../../../components/config/color-palette';
import { TypographySettings } from '../../../../../../components/config/typography-settings';
import { DatePickerValue } from '../../../../../../components/date-picker/date-picker';
import { IValueEditorProps } from '../value-editor-props';

export const DateRangeTestIds = {
  StartValueId: 'DateRangeTestIds-StartValueId',
  EndValueId: 'DateRangeTestIds-EndValueId',
  ValidationError: 'DateRangeTestIds-ErrorMessage',
};

export function endDateGreaterThanStartDateValidator(startDate: Date, endDate: Date): string | undefined {
  return endDate < startDate ? 'Slutdatoen kan ikke ligge før startdatoen' : undefined;
}

function parseDate(dateAsString: string) {
  const date = new Date(dateAsString);
  if (date.toString() !== 'Invalid Date') {
    return date;
  }

  return parseISO(dateAsString);
}

export function DateRangeEditor(props: IValueEditorProps) {
  const { value, disabled } = props;
  const valueStart = Array.isArray(value) ? value[0] : value;
  const valueEnd = Array.isArray(value) && value.length === 2 ? value[1] : '';
  const valueStartAsDate = parseDate(valueStart);
  const valueEndAsDate = parseDate(valueEnd);

  const handleStartValueHasChanged = (start: DatePickerValue) => {
    props.handleOnChange([(start as string) || '', valueEnd]);
  };

  const handleEndValueHasChanged = (end: DatePickerValue) => {
    props.handleOnChange([valueStart, (end as string) || '']);
  };

  const datePickerProps = {
    disabled,
    label: 'Dato',
    useCurrentDateAsInitialValue: false,
  };

  const validationResult = endDateGreaterThanStartDateValidator(valueStartAsDate, valueEndAsDate);

  return (
    <div>
      <span className="multipleInputsWrapper">
        <DatePicker maxDate={valueEndAsDate} data-testid={DateRangeTestIds.StartValueId} value={valueStart} onChange={handleStartValueHasChanged} key={0} {...datePickerProps} />
        <span key={2} className="rangeSeparator">
          -
        </span>
        <DatePicker minDate={valueStartAsDate} data-testid={DateRangeTestIds.EndValueId} value={valueEnd} onChange={handleEndValueHasChanged} key={1} {...datePickerProps} />
      </span>
      {validationResult && (
        <div
          data-testid={DateRangeTestIds.ValidationError}
          style={{
            color: ColorPalette.HotAurora,
            marginLeft: '5px',
            marginTop: '10px',
            fontFamily: TypographySettings.DefaultFontFamily,
          }}
        >
          {validationResult}
        </div>
      )}
    </div>
  );
}
