/* istanbul ignore file */
/* eslint-disable */
export interface IWindowServiceCloseOptions {
  shouldReloadParentTab: boolean;
}
export interface IPopupDialog {
  callbackFuncInternal: (result: any, saveOptions: any) => void;
  dialogQueue: IDisplayArguments[];
  data: any;
  dialogWindowIsActive: boolean;
  openDialogWindow: Window;
  saveOptions: any;
  windowArguments: IWindowArguments;
  timerIntervalHolder: number;
  childWindowHasSentResult: boolean;
  openWindowUrl: string;
  callBackAnswer: any;
  postMessageHandler: (event: MessageEvent, me: IPopupDialog) => void;
  focusOpenDialog: (me: IPopupDialog) => void;
  addBackdrop: (me: IPopupDialog) => void;
  validateDisplayArguments: (url: string, windowArguments: IWindowArguments, data: any, callback: (result: any, saveOptions: any) => void) => void;
  displayAsync: (url: string, windowArguments: IWindowArguments, data: any, callback: (result: any, saveOptions: any) => void) => void;
  showNextDialog: (me: IPopupDialog) => void;
  sendStartupMessageForChild: (me: IPopupDialog) => void;
  setupWindowEventCommunication: (me: IPopupDialog) => void;
  calculateWindowSpecs: (me: IPopupDialog) => string;
}

export interface IWindowArguments {
  Width: number;
  Height: number;
}

export interface IDisplayArguments {
  url: string;
  windowArguments: IWindowArguments;
  data: any;
  callback: (result: any, saveOptions: any) => void;
}

export class WindowService {
  static close(options?: IWindowServiceCloseOptions) {
    window.close();
  }

  static open(url: string, target?: string) {
    window.open(url, target ?? '_blank');
  }

  static redirect(url: string) {
    window.location.href = url;
  }

  static alert(message: string) {
    window.alert(message);
  }

  static setOnBeforeUnload(message: string) {
    window.onbeforeunload = function () {
      return message;
    };
  }

  static unsetOnBeforeUnload() {
    window.onbeforeunload = () => undefined;
  }

  static setOnMouseLeave(callback: () => void) {
    window.onmouseleave = () => callback();
  }

  static showBlob(blob: Blob, defaultName?: string) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, defaultName);
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
  }

  static isChrome() {
    if (navigator && navigator.userAgent && navigator.userAgent.toLowerCase().indexOf('chrome') > -1) return true;
    return false;
  }

  static PopupDialog: IPopupDialog = {
    callbackFuncInternal: null as any,
    callBackAnswer: null as any,
    dialogQueue: [] as IDisplayArguments[],
    data: null as any,
    dialogWindowIsActive: false,
    openDialogWindow: null as any,
    saveOptions: null,
    windowArguments: null as any,
    timerIntervalHolder: null as any,
    childWindowHasSentResult: false,
    openWindowUrl: null as any,
    postMessageHandler(event: MessageEvent, me: IPopupDialog) {
      // handles the messages from open dialog window
      if (event.source !== me.openDialogWindow) return;

      const message = event.data;
      if (message.type === 'result') {
        me.callBackAnswer = message.content;
        me.childWindowHasSentResult = true;
        me.openDialogWindow.close();
      } else if (message.type === 'childReady') {
        // ChildReady is only sent after startupMessage has been received
        clearInterval(me.timerIntervalHolder);
      } else if (message.type === 'loaded') {
        // Loaded is sent right when child has loaded
        clearInterval(me.timerIntervalHolder);

        // We must ensure that child is properly setup and
        // loaded message might occur before timerIntervalHolder has fired
        me.sendStartupMessageForChild(me);
      }
    },
    focusOpenDialog(me: IPopupDialog) {
      // / <summary>
      // / set focus to the open dialog window
      // / </summary>
      if (me.openDialogWindow === null) return;

      me.openDialogWindow.focus();
    },
    addBackdrop(me: IPopupDialog) {
      // / <summary>
      // / Add  backdrop to prevent focus change from prompt dialog
      // / </summary>

      const backdrop = document.createElement('div');
      backdrop.style.display = 'blocked';
      backdrop.style.top = '0px';
      backdrop.style.left = '0px';
      backdrop.style.position = 'fixed';
      backdrop.style.width = '100%';
      backdrop.style.height = '100%';
      backdrop.style.backgroundColor = 'rgba(0, 0, 0, 0.26)';
      backdrop.style.zIndex = 10000 as any;
      const clickFunction = (function (me) {
        return function (event: any) {
          me.focusOpenDialog(me);
        };
      })(me);
      backdrop.addEventListener('click', clickFunction);
      window.parent.document.body.appendChild(backdrop);
      const windowCheckClosedFunction = function (me: IPopupDialog, clickFunction: any) {
        return function () {
          if (me.openDialogWindow === null || me.openDialogWindow.closed) {
            clearInterval(backdropCheckOpenDialogWindowTimer);
            backdrop.removeEventListener('click', clickFunction);
            window.parent.document.body.removeChild(backdrop);
            if (me.childWindowHasSentResult !== true) {
              me.callbackFuncInternal(null, null);
            } else {
              me.callbackFuncInternal(me.callBackAnswer, me.saveOptions);
            }
            // We must clean up after window close
            // in order to make ready for next dialog window
            me.childWindowHasSentResult = false;
            me.callbackFuncInternal = null as any;
            me.data = null;
            me.openDialogWindow = null as any;
            me.saveOptions = null;
            me.windowArguments = null as any;

            clearInterval(me.timerIntervalHolder);
            if (me.dialogQueue.length > 0) {
              me.showNextDialog(me);
            } else {
              me.dialogWindowIsActive = false;
            }
          }
        };
      };

      var backdropCheckOpenDialogWindowTimer = setInterval(windowCheckClosedFunction(me, clickFunction), 100);
    },
    validateDisplayArguments(url: string, windowArguments: IWindowArguments, data: any, callback: (result: any, saveOptions: any) => void) {
      if (typeof url !== 'string' || url === null || url === '') {
        throw 'PopupDialog_url_is_not_valid';
      }

      if (typeof callback !== 'function' || callback === null) {
        throw 'PopupDialog_callback_is_not_valid';
      }

      if (
        typeof windowArguments !== 'object' ||
        windowArguments === null ||
        typeof windowArguments.Width !== 'number' ||
        windowArguments.Width < 0 ||
        typeof windowArguments.Height !== 'number' ||
        windowArguments.Height < 0
      ) {
        throw 'PopupDialog_windowArguments_are_not_valid';
      }

      if (typeof data !== 'object' || callback === null) {
        throw 'PopupDialog_data_is_not_valid';
      }

      return true;
    },
    displayAsync(url: string, windowArguments: IWindowArguments, data: any, callback: (result: any, saveOptions: any) => void) {
      this.validateDisplayArguments(url, windowArguments, data, callback);
      this.dialogQueue.push({ url, windowArguments, data, callback });

      if (this.dialogWindowIsActive === false) {
        this.showNextDialog(this);
      }
    },
    showNextDialog(me: IPopupDialog) {
      const nextDialog = me.dialogQueue.shift();
      if (nextDialog === undefined || nextDialog === null) {
        return;
      }
      me.openWindowUrl = nextDialog.url;
      const { url } = nextDialog;
      const { windowArguments } = nextDialog;

      me.windowArguments = windowArguments;
      me.callbackFuncInternal = nextDialog.callback;
      me.data = nextDialog.data;

      const windowSpecs = me.calculateWindowSpecs(me);
      me.dialogWindowIsActive = true;
      me.openDialogWindow = window.open(url, '_blank', windowSpecs) as any;
      try {
        me.openDialogWindow.focus();
      } catch (e) {
        alert('Popup blev blokeret, tillad popup!');
      }
      me.setupWindowEventCommunication(me);
    },
    sendStartupMessageForChild(me: IPopupDialog) {
      if (me.openDialogWindow === null) {
        return;
      }

      me.openDialogWindow.postMessage({ type: 'openerOrigin' }, me.openWindowUrl);

      me.openDialogWindow.postMessage(
        {
          type: 'dialogData',
          content: me.data,
        },
        me.openWindowUrl
      );
    },
    setupWindowEventCommunication(me: IPopupDialog) {
      me.focusOpenDialog(me);
      me.addBackdrop(me);

      const timeFunction = function (me: IPopupDialog): TimerHandler {
        return function () {
          me.sendStartupMessageForChild(me);
        };
      };

      me.timerIntervalHolder = setInterval(timeFunction(me), 500);
      const messageFunction = function (me: IPopupDialog) {
        return function (event: MessageEvent) {
          me.postMessageHandler(event, me);
        };
      };

      window.addEventListener('message', messageFunction(me), false);
    },
    calculateWindowSpecs(me: IPopupDialog): string {
      const zoomLevel = window.devicePixelRatio ? window.devicePixelRatio : 1;
      let width = me.windowArguments.Width * zoomLevel;
      let height = me.windowArguments.Height * zoomLevel;

      if (width > window.screen.width) {
        width = window.screen.width;
      }
      if (height > window.screen.height) {
        height = window.screen.height;
      }

      const centerOfScreenX = window.screen.width / 2;
      const centerOfScreenY = window.screen.height / 2;
      const verticalScreenPosition = centerOfScreenX - width / 2;
      const horizontalScreenPosition = centerOfScreenY - height / 2;

      return `width=${Math.floor(width)}px,height=${Math.floor(height)}px,top=${Math.floor(horizontalScreenPosition)}px,left=${Math.floor(
        verticalScreenPosition
      )}px,resizable=no,status=no`;
    },
  };
}
