import React, { useState, useEffect } from 'react';
import { ChangeJobcenter } from '../../../areas/citizen/details/components/change-jobcenter/change-jobcenter';
import { useBroadcastContext } from '../../../hooks/use-broadcast';

export function OmnibarChangeJobcenter(props: { forceRefresh: Date; citizenId: string }) {
  const { forceRefresh, citizenId } = props;
  const [showChangeJobcenterModal, setShowChangeJobcenterModal] = useState(true);
  const { send } = useBroadcastContext('CitizenCoreUpdate');

  function handleChangeJobcenterSave() {
    send();
    setShowChangeJobcenterModal(false);
  }
  useEffect(() => {
    setShowChangeJobcenterModal(true);
  }, [forceRefresh]);

  return (
    <ChangeJobcenter
      isOpen={showChangeJobcenterModal}
      onClose={() => setShowChangeJobcenterModal(false)}
      onCancel={() => setShowChangeJobcenterModal(false)}
      onSave={() => handleChangeJobcenterSave()}
      citizenId={citizenId}
    />
  );
}
