import * as React from 'react';
import MuiCircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1000,
    },
    progressWrapper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 200,
      transform: 'translateX(-50%) translateY(-50%)',
      textAlign: 'center',
    },
    textWrapper: {
      marginTop: 8,
      position: 'relative',
    },
    text: {
      animation: '$fadeInOut 6s linear',
      animationDelay: '5s',
      animationIterationCount: 'infinite',
      '&:nth-child(2)': {
        animationDelay: '8s',
      },
      fontSize: 14,
      left: 0,
      opacity: 0,
      position: 'absolute',
      right: 0,
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    '@keyframes fadeInOut': {
      '0%': {
        opacity: 0,
      },
      '25%': {
        opacity: 1,
      },
      '50%': {
        opacity: 0,
      },
      '100%': {
        opacity: 0,
      },
    },
  })
);

export function CircularProgressCentered() {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.progressWrapper}>
        <MuiCircularProgress />
        <div className={classes.textWrapper}>
          <Typography className={classes.text}>Arbejder stadig</Typography>
          <Typography className={classes.text}>Vent venligst</Typography>
        </div>
      </div>
    </div>
  );
}
