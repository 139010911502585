import React from 'react';
import { DatePicker } from '../../../../components/date-picker/date-picker';
import { IDatePickerProps } from '../../../../components/date-picker/date-picker';
import { validateDefaultDateFormat } from '../../../../components/date-picker/date-validators';
import { validateExpandedDateFormat } from '../../../../components/date-picker/date-validators';

function isDynamicDate(value: string): boolean {
  const dynamicDatePattern = /(^@)((dd$)|(dd|mm)([+-])([1-9]\d?))$/;
  return dynamicDatePattern.test(value);
}

export function validateDynamicDate(value: string): Date | string | undefined {
  if (isDynamicDate(value)) {
    return value;
  }
  return undefined;
}

export function DynamicDatePicker(props: Omit<IDatePickerProps, 'validators'>) {
  return <DatePicker invalidDateMessage="Indtast et dato udtryk" {...props} validators={[validateDynamicDate, validateDefaultDateFormat, validateExpandedDateFormat]} />;
}
