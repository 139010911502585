/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { apm } from '@elastic/apm-rum';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { AbsolutelyCentered } from '../absolutely-centered';
import { ColorPalette } from '../config';

interface IErrorBoundaryProps {
  errorFallback?: React.ReactNode;
}

interface IErrorBoundaryState {
  failed: boolean;
  errorFallbackElement: React.ReactNode;
}

function DefaultErrorFallback(message: React.ReactNode) {
  return (
    <AbsolutelyCentered>
      <Box
        sx={{
          fontFamily: 'Roboto, sans-serif',
          fontSize: '1.3rem',
          fontWeight: 500,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            color: ColorPalette.Crimson,
            fontSize: '2em',
            mb: 2,
          }}
        >
          <FontAwesomeIcon icon={faTriangleExclamation} size="lg" />
        </Box>
        {message}
      </Box>
    </AbsolutelyCentered>
  );
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { failed: false, errorFallbackElement: props.errorFallback || DefaultErrorFallback('Der opstod en ukendt fejl') };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { failed: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    if (error.toString().indexOf('ChunkLoadError') > -1) {
      const {
        location: { hash },
      } = window;

      let iteration = 0;
      let preservedHash: string[] = [];
      if (hash) {
        const hashArray = hash.split('&');
        const chunkLoadErrorInterator = hashArray.find(item => item.indexOf('ChunkLoadError') > -1);
        preservedHash = hashArray.filter(item => item.indexOf('ChunkLoadError') === -1);
        if (chunkLoadErrorInterator) {
          iteration = Number(chunkLoadErrorInterator.split('=')[1]);
        }
      }

      if (iteration < 3) {
        window.location.hash = `${preservedHash.length ? `${preservedHash.join('&')}&` : ''}ChunkLoadError=${iteration + 1}`;
        console.log(`Reloading: ${iteration + 1}`);
        this.setState({
          errorFallbackElement: (
            <AbsolutelyCentered>
              <CircularProgress variant="determinate" value={80} />
            </AbsolutelyCentered>
          ),
        });
        window.location.reload();
      } else {
        console.log('Stopping reload, throwing error and logging to APM');
        window.location.hash = preservedHash.length ? preservedHash.join('&') : '';
        // eslint-disable-next-line no-console
        this.logAndCapture(error, errorInfo);
      }
    }
    if (error.message.indexOf('401') > -1) {
      this.setState({
        errorFallbackElement: DefaultErrorFallback(
          <Box
            sx={{
              fontFamily: 'Roboto, sans-serif',
              fontSize: '1.3rem',
              fontWeight: 500,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                mb: 1
              }}
            >
              Du er ikke logget ind i Fasit.
            </Box>
            <Box
              sx={{
                fontSize: '1rem',
                fontWeight: 400,
              }}
            >
              Log ind igen.
            </Box>
            <Box
              sx={{
                fontSize: '1rem',
                fontWeight: 400,
                mt: 1
              }}
            >
              Hvis du fortsat har problemer skal du kontakte din systemadministrator.
            </Box>
          </Box>
        )
      });
      this.logAndCapture(error, errorInfo);
    } else {
      this.logAndCapture(error, errorInfo);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  logAndCapture = (error: any, errorInfo: any) => {
    console.error('componentDidCatch', error, errorInfo);
    apm.captureError(error);
  };

  render() {
    const { children } = this.props;
    const { failed, errorFallbackElement } = this.state;

    return failed ? errorFallbackElement : children;
  }
}
