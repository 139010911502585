import { AxiosRequestConfig } from 'axios';
import { SecureApiService } from '../../../api-service';
import { IConfiguration, configuration } from '../../../configuration';
import { IBulkAction } from './bulkaction';
import { noCacheOptions } from '../helpers';
import { BulkActionEnum } from './bulkaction-enum';
import { companyAreaPathName } from '../../company/company-area-pathnames';
import { citizenAreaPathName, interviewTabPathName, bookInterviewPath } from '../../citizen/citizen-area-pathnames';

export interface EntityTypeKeyNamePair {
  id: string;
  name: string;
}

export interface IBulkActionApiService {
  getBulkActions(config?: AxiosRequestConfig): Promise<IBulkAction[]>;
  triggerBulkAction(bulkaction: IBulkAction, ids: string[]): Promise<void>;
  createCrowdNotice(ids: string[]): Promise<string[]>;
  createCrowdPersonGroupMarking(ids: string[]): Promise<string[]>;
  createCrowdPersonGroupMarkingRelationShips(crowdPersonGroupMarkingId: string, ids: string[]): Promise<string[]>;
  getObjectTypeCodeFromResultType(resultType: string): Promise<number>;
  getEntityTypeKeyNamePairFromResultType(resultType: string, entityIds: string[]): Promise<EntityTypeKeyNamePair[]>;
  redirectSchultzCase(organizationCode: string, schultzCaseBaseUrl: string, entityIds: string[]): Promise<any>;
}

export class BulkActionApiService implements IBulkActionApiService {
  private readonly baseService: SecureApiService = new SecureApiService();
  private readonly configuration: IConfiguration = configuration;

  getBulkActions(config?: AxiosRequestConfig): Promise<IBulkAction[]> {
    const url = `${this.getApiAddress()}/BulkAction/GetBulkActions`;
    return this.baseService.get<undefined, IBulkAction[]>(url, undefined, noCacheOptions);
  }

  // This method will be removed when crowdNotice functinality is available in apex
  async createCrowdNotice(ids: string[]): Promise<string[]> {
    try {
      const url = `${this.getApiAddress()}/BulkAction/CreateCrowdNotice`;
      return this.baseService.post<string[], string[]>(url, ids);
    } catch (error) {
      throw new Error(error);
    }
  }

  // This method will be removed when crowdpersonGroupMarking functinality is available in apex
  async createCrowdPersonGroupMarking(ids: string[]): Promise<string[]> {
    try {
      const url = `${this.getApiAddress()}/BulkAction/CreateCrowdPersonGroupMarking`;
      return this.baseService.post<string[], string[]>(url, ids);
    } catch (error) {
      throw new Error(error);
    }
  }

  // This method will be removed when crowdpersonGroupMarking functinality is available in apex
  async createCrowdPersonGroupMarkingRelationShips(crowdPersonGroupMarkingId: string, ids: string[]): Promise<string[]> {
    try {
      const url = `${this.getApiAddress()}/BulkAction/CreateCrowdPersonGroupMarkingRelationShips/${crowdPersonGroupMarkingId}`;
      return this.baseService.post<string[], string[]>(url, ids);
    } catch (error) {
      throw new Error(error);
    }
  }

  getObjectTypeCodeFromResultType(resultType: string): Promise<number> {
    const url = `${this.getApiAddress()}/BulkAction/GetObjectTypeCodeFromResultType/${resultType}`;
    return this.baseService.get<undefined, number>(url, undefined);
  }

  getEntityTypeKeyNamePairFromResultType(resultType: string, entityIds: string[]): Promise<EntityTypeKeyNamePair[]> {
    const url = `${this.getApiAddress()}/BulkAction/GetEntityTypeKeyNamePairFromResultType/${resultType}`;
    return this.baseService.post<string[], EntityTypeKeyNamePair[]>(url, entityIds);
  }

  redirectSchultzCase(organizationCode: string, schultzCaseBaseUrl: string, entityIds: string[]): Promise<any> {
    try {
      const muni = BulkActionApiService.extractMuniCode(organizationCode);
      if (!muni) {
        throw new Error(`Could not extract muni code from organization code ${organizationCode}`);
      }
      const url = schultzCaseBaseUrl.replace('{muni}', muni);
      return this.baseService.post<string[], any>(url, entityIds);
    } catch (error) {
      throw new Error(error);
    }
  }

  private static readonly extractMuniCode = (organizationCode: string) => {
    if (organizationCode && organizationCode.length === 5) {
      return organizationCode.substring(0, 3);
    }
    return undefined;
  };

  // eslint-disable-next-line class-methods-use-this
  async triggerBulkAction(bulkaction: IBulkAction, ids: string[]): Promise<void> {
    switch (bulkaction.name) {
      case BulkActionEnum.BulkActionCreateCrowdLetter:
        BulkActionApiService.crowdLetterBulkAction(bulkaction, ids);
        break;
      case BulkActionEnum.BulkActionCreateCrowdLetterCompany:
        BulkActionApiService.crowdLetterCompanyBulkAction(bulkaction, ids);
        break;
      case BulkActionEnum.BulkActionPersonGroupMarking:
        BulkActionApiService.personGroupMarkingBulkAction(bulkaction, ids);
        break;
      case BulkActionEnum.BulkActionShowLink:
        BulkActionApiService.showLinkBulkAction(bulkaction, ids);
        break;
      case BulkActionEnum.BulkActionInterviewNotice:
        BulkActionApiService.crowdBookingBulkAction(bulkaction, ids);
        break;
      default:
    }
  }

  private static async crowdBookingBulkAction(bulkAction: IBulkAction, ids: string[]) {
    if (ids.length === 1) {
      const url = `${window.location.origin}/${citizenAreaPathName}/${ids[0]}/${interviewTabPathName}/${bookInterviewPath}`;
      window.open(url);
      return;
    }
    window.addEventListener('message', isReady, false);
    const url = `${window.location.origin}${bulkAction.url}`;
    const win = window.open(url) as any;
    const key = 'crowBookingCitizenIds';
    function isReady(event: MessageEvent) {
      if (event.data.topic === `${key}_Ready`) {
        win.postMessage({ data: ids, topic: `${key}_Topic` });
      }
    }
  }

  private static async showLinkBulkAction(bulkAction: IBulkAction, ids: string[]) {
    let preText = '';
    let text = '';

    ids.forEach(id => {
      let link = '';

      switch (bulkAction.resultType?.toLocaleLowerCase()) {
        case 'company':
          link = `${window.location.origin}/standalone/${companyAreaPathName}/${id.replace(/[{}]/g, '')}/details`;
          break;
        case 'citizen':
          link = `${window.location.origin}/citizen/${id.replace(/[{}]/g, '')}/details`;
          break;
        case 'cases':
          link = `${window.location.origin}/gotoresource/si_case?resourceId=${id}`;
          break;
        default:
      }

      text += `${preText}${link}`;
      preText = '\r\n\r\n';
    });

    window.prompt('Kopiér link til udklipsholder: Ctrl+C, Enter', text);
  }

  private static crowdLetterBulkAction(bulkAction: IBulkAction, ids: string[]) {
    const url = window.location.origin + bulkAction.url;
    const popup = window.open(url);

    const timer = setInterval(() => {
      if (popup) {
        popup.postMessage({ type: 'crowdLetter', payload: ids }, window.location.origin);

        clearInterval(timer);
      }
    }, 7000);
  }

  private static crowdLetterCompanyBulkAction(bulkAction: IBulkAction, ids: string[]) {
    const url = window.location.origin + bulkAction.url;
    const popup = window.open(url);

    const timer = setInterval(() => {
      if (popup) {
        popup.postMessage({ type: 'crowdLetterCompany', payload: ids }, window.location.origin);

        clearInterval(timer);
      }
    }, 7000);
  }

  private static personGroupMarkingBulkAction(bulkAction: IBulkAction, ids: string[]) {
    const url = window.location.origin + bulkAction.url;
    const popup = window.open(url);

    const timer = setInterval(() => {
      if (popup) {
        popup.postMessage({ type: 'bulkPersonGroupMarking', payload: ids }, window.location.origin);

        clearInterval(timer);
      }
    }, 2000);
  }

  private readonly getApiAddress = () => this.configuration.searchBaseAddress;
}
