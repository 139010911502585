/* istanbul ignore file */
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faTasks as faTasksLight,
  faUser as faUserLight,
  faHome as faHomeLight,
  faInboxOut as faInboxOutLight,
  faCog as faCogLight,
  faIndustry as faIndustryLight,
  faHandshake as faHandshakeLight,
  faFolder,
  faClipboardCheck,
  faFileCertificate,
  faAddressBook,
  faCommentLines,
  faBuilding,
  faBriefcase,
  faBook,
  faLandmark,
  faClipboardList,
} from '@fortawesome/pro-light-svg-icons';
import {
  faTasks as faTasksSolid,
  faUser as faUserSolid,
  faHome as faHomeSolid,
  faInboxOut as faInboxOutSolid,
  faCog as faCogSolid,
  faHandshake as faHandshakeSolid,
  faIndustry as faIndustryAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { homeAreaPathName, homeAreaMenuName } from '../../../areas/area-pathnames';
import { searchAreaPathName, searchAreaMenuName } from '../../../areas/search/search-area-pathnames';
import { citizenAreaMenuName, citizenAreaSubMenuName, citizensOverviewAreaPathName, citizenCreateReportGuide } from '../../../areas/citizen/citizen-area-pathnames';
import * as citizensArea from '../../../areas/citizen/overview/citizens-overview-area-pathnames';
import { outboxAreaPathName, outboxAreaMenuName } from '../../../areas/outbox/outbox-area-pathnames';
import { adminAreaPathName, adminAreaMenuName } from '../../../areas/admin/admin-area-pathnames';
import {
  effortsAreaPathName,
  jobAndOffersAreaMenuName,
  jobAndOffersAreaSubMenuName,
  jobAndOffersRecruitmentsRequestsMenuName,
  companyRecruitmentsPathName,
  jobAndOffersCataloguePathName,
  jobAndOffersCatalogueMenuName,
  jobAdsMenuName,
  jobAdsPathName,
} from '../../../areas/efforts/efforts-area-pathnames';
import { companyAreaMenuName, companyAreaSubMenuName, companyAreaPathName } from '../../../areas/company/company-area-pathnames';
import * as companiesArea from '../../../areas/company/overview/company-overview-area-pathnames';
import { ResourceAction } from '../../../authentication/authorization-service';
import { ResourceOperations, ResourceTypes } from '../../../authentication/resources';

export type NavigationGuid = string;

export interface IMainNavigationItem {
  guid: NavigationGuid;
  name: string;
  subMenuName?: string;
  url: string;
  icon: IconDefinition;
  selectedIcon: IconDefinition;
  exact?: boolean;
  split?: boolean;
  alternative?: boolean;
  children?: IMainNavigationChildItem[];
  featureToggleRequired?: string;
  forceRedirect?: boolean;
  resourceActions?: ResourceAction[];
}

export interface IMainNavigationChildItem {
  guid: NavigationGuid;
  name: string;
  url?: string;
  icon: IconDefinition;
  featurePurchased?: string[];
  openInNewWindow: boolean;
  resourceActions?: ResourceAction[];
  featureToggleRequired?: string;
}

export const mainNavigationItems: IMainNavigationItem[] = [
  {
    guid: '3ebf66b1-2748-4f05-bad5-ae3fe745e132',
    name: homeAreaMenuName,
    icon: faHomeLight,
    selectedIcon: faHomeSolid,
    url: homeAreaPathName,
    exact: true,
    forceRedirect: true,
  },
  {
    guid: '6e3a1aad-6e9c-480e-bce2-fa8eefe36598',
    name: citizenAreaMenuName,
    subMenuName: citizenAreaSubMenuName,
    icon: faUserLight,
    selectedIcon: faUserSolid,
    url: citizensOverviewAreaPathName,
    children: [
      {
        guid: 'a06dc0c9-48cf-47e7-92c5-64c71a1800d8',
        name: citizensArea.citizensAreaCitizensTabLabel,
        url: citizensArea.citizensAreaCitizensTabPathName,
        icon: faUserLight,
        resourceActions: [{ resourceOperation: ResourceOperations.Read, resourceType: ResourceTypes.Citizen }],
        openInNewWindow: false,
      },
      {
        guid: '070c77ca-b739-4a9b-932a-1711685793c0',
        name: citizensArea.citizensAreaCasesTabLabel,
        url: citizensArea.citizensAreaCasesTabPathName,
        icon: faFolder,
        resourceActions: [{ resourceOperation: ResourceOperations.Read, resourceType: ResourceTypes.Citizen }],
        openInNewWindow: false,
      },
      {
        guid: '35c02e1e-bf09-4bfd-8606-4c868887fcf6',
        name: citizensArea.citizensAreaTasksTabLabel,
        url: citizensArea.citizensAreaTasksTabPathName,
        icon: faClipboardCheck,
        resourceActions: [{ resourceOperation: ResourceOperations.Read, resourceType: ResourceTypes.Citizen }],
        openInNewWindow: false,
      },
      {
        guid: '5e2aaa78-1513-4bfa-988f-3c503469d6c9',
        name: citizensArea.citizensAreaCVSearchTabLabel,
        url: '{tenanturl}/{tenant}/Person/CVSearch/Index/',
        icon: faFileCertificate,
        resourceActions: [{ resourceOperation: ResourceOperations.Read, resourceType: ResourceTypes.Cv }],
        featurePurchased: ['virksomhedsservice', 'cV_soegning'],
        openInNewWindow: true,
      },
      {
        guid: 'ea5cc038-ea0c-4d05-a131-20bfd26e155f',
        name: citizensArea.citizensAreaSchultzCaseTabLabel,
        icon: faCommentLines,
        openInNewWindow: true,
        featurePurchased: ['enableSchultzCaseLink'],
      },
      {
        guid: 'f42d2329-013c-4edc-80c3-435e283112f3',
        name: citizensArea.citizensAreaPrivateContactTabLabel,
        url: citizensArea.citizensAreaPrivateContactTabPathName,
        icon: faAddressBook,
        openInNewWindow: false,
      },
      {
        guid: 'd7f85041-772a-4b16-bb14-4e0a71003f24',
        name: citizensArea.citizensAreaCreateRapportLabel,
        url: `/${citizensOverviewAreaPathName}/${citizenCreateReportGuide}`,
        icon: faClipboardList,
        openInNewWindow: true,
        resourceActions: [{ resourceOperation: ResourceOperations.Read, resourceType: ResourceTypes.Report }],
      },
    ],
  },
  {
    guid: '3e3a1aad-6e9c-480e-bce2-fa8eefe36558',
    name: companyAreaMenuName,
    subMenuName: companyAreaSubMenuName,
    icon: faIndustryLight,
    selectedIcon: faIndustryAlt,
    url: companyAreaPathName,
    children: [
      {
        guid: 'f06dc0c9-48cf-47e7-92c5-64c71a1801d8',
        name: companiesArea.companiesAreaCompanyTabLabel,
        url: companiesArea.companiesAreaCompanyTabPathName,
        icon: faIndustryLight,
        openInNewWindow: false,
      },
      {
        guid: '75c02e1e-bf09-4bfd-8606-4c863887fcf6',
        name: companiesArea.companiesAreaTasksTabLabel,
        url: companiesArea.companiesAreaTasksTabPathName,
        icon: faClipboardCheck,
        openInNewWindow: false,
      },
      {
        guid: 'f42d2329-013c-4edc-80c3-435e283112f3',
        name: companiesArea.companiesAreaAppointmentsTabLabel,
        url: companiesArea.companiesAreaAppointmentsTabPathName,
        icon: faClipboardCheck,
        openInNewWindow: false,
      },
    ],
  },
  {
    guid: '43fe2e44-f0a3-4462-9940-467c8dfb1f47',
    name: jobAndOffersAreaMenuName,
    subMenuName: jobAndOffersAreaSubMenuName,
    icon: faHandshakeLight,
    selectedIcon: faHandshakeSolid,
    url: effortsAreaPathName,
    children: [
      {
        guid: 'c735c9b4-e6cf-11ea-adc1-0242ac120002',
        name: jobAndOffersRecruitmentsRequestsMenuName,
        icon: faBriefcase,
        url: companyRecruitmentsPathName,
        openInNewWindow: false,
      },
      {
        guid: 'feb88b32-4b58-4834-8c5a-856e3e93594e',
        name: jobAndOffersCatalogueMenuName,
        url: jobAndOffersCataloguePathName,
        icon: faBook,
        resourceActions: [{ resourceOperation: ResourceOperations.Read, resourceType: ResourceTypes.Offer }],
        openInNewWindow: false,
      },
      {
        guid: '9fc391d3-f201-454f-ac3d-aa394fb77ea7',
        name: jobAdsMenuName,
        url: jobAdsPathName,
        icon: faBriefcase,
        featurePurchased: ['enableJobAds'],
        resourceActions: [{ resourceOperation: ResourceOperations.Read, resourceType: ResourceTypes.JobAd }],
        openInNewWindow: false,
      },
    ],
  },
  {
    guid: '68068958-4d33-4119-9bd9-5531e7db05d2',
    name: outboxAreaMenuName,
    icon: faInboxOutLight,
    selectedIcon: faInboxOutSolid,
    url: outboxAreaPathName,
    resourceActions: [{ resourceOperation: ResourceOperations.Read, resourceType: ResourceTypes.Outbox }],
  },
  {
    guid: 'ab0ae650-584c-4864-a9f8-0e64831d2d01',
    name: searchAreaMenuName,
    icon: faTasksLight,
    selectedIcon: faTasksSolid,
    url: searchAreaPathName,
    resourceActions: [{ resourceOperation: ResourceOperations.Read, resourceType: ResourceTypes.Worklist }],
  },
  {
    guid: 'c42680d1-17ba-4419-84ed-452c08303c8f',
    name: adminAreaMenuName,
    icon: faCogLight,
    selectedIcon: faCogSolid,
    url: adminAreaPathName,
    alternative: true,
    split: true,
    resourceActions: [{ resourceOperation: ResourceOperations.Access, resourceType: ResourceTypes.FasitAdministration }],
  },
];
