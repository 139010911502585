import {
  genericOperators,
  integerOperators,
  stringOperators,
  decimalOperators,
  booleanOperators,
  dateOperators,
  codeListOperators,
  lookupOperators,
  QueryBuilderOperator,
  largeTextOperators,
} from './operators';
import { IOption } from './field-interfaces';
import { QueryBuilderFieldOptions } from '../query/query-builder-field-options';

const filterGenericOperatorsBy = (operators: string[]): IOption[] => {
  return genericOperators.filter(operator => {
    return operators.indexOf(operator.name) > -1;
  });
};

const operatorsByTypeName: Record<QueryBuilderFieldOptions, IOption[]> = {
  text: filterGenericOperatorsBy(stringOperators),
  keyword: filterGenericOperatorsBy(stringOperators),
  integer: filterGenericOperatorsBy(integerOperators),
  double: filterGenericOperatorsBy(decimalOperators),
  boolean: filterGenericOperatorsBy(booleanOperators),
  date: dateOperators,
  codelist: filterGenericOperatorsBy(codeListOperators),
  picklist: filterGenericOperatorsBy(codeListOperators),
  largetext: filterGenericOperatorsBy(largeTextOperators),
  smallinteger: filterGenericOperatorsBy(integerOperators)
};

export const lookupSpecificOperators = filterGenericOperatorsBy(lookupOperators);

export const largeTextSpecificOperators = filterGenericOperatorsBy(largeTextOperators);

export function getOperatorsByTypeName(type: keyof typeof operatorsByTypeName): IOption[] {
  return operatorsByTypeName[type];
}

/* istanbul ignore next // This is just a type lookup. Not worth testing each individual case  */
export function getExpectedNumberOfValues(operator: string): number {
  switch (operator) {
    case QueryBuilderOperator.True:
    case QueryBuilderOperator.False:
    case QueryBuilderOperator.Empty:
    case QueryBuilderOperator.NotEmpty:
      return 0;

    case QueryBuilderOperator.StartsWith:
    case QueryBuilderOperator.NotStartsWith:
    case QueryBuilderOperator.Contains:
    case QueryBuilderOperator.NotContains:
    case QueryBuilderOperator.Equal:
    case QueryBuilderOperator.NotEqual:
    case QueryBuilderOperator.LessThan:
    case QueryBuilderOperator.LessThanOrEqual:
    case QueryBuilderOperator.GreaterThan:
    case QueryBuilderOperator.GreaterThanOrEqual:
    case QueryBuilderOperator.Relative:
      return 1;

    case QueryBuilderOperator.Between:
    case QueryBuilderOperator.NotBetween:
      return 2;

    case QueryBuilderOperator.In:
    case QueryBuilderOperator.NotIn:
      return NaN; // Unbounded, but at least 1
    default:
      throw new Error(`Could not get expected number of values for: ${operator}`);
  }
}

function isOperatorsCompatible(operator: string, prevOperator: string | undefined): boolean {
  const nonInterchangeableOperators = [QueryBuilderOperator.Relative as string]; // When changing between noninterchangeable operators, the field value should be reset

  if (prevOperator && prevOperator !== operator && (nonInterchangeableOperators.includes(operator) || nonInterchangeableOperators.includes(prevOperator))) {
    return false;
  }

  return true;
}

export function getValueForOperator(operator: string, value: string | string[] | undefined, prevOperator?: string | undefined): string | string[] | undefined {
  const fieldValue = isOperatorsCompatible(operator, prevOperator) ? value : '';

  switch (getExpectedNumberOfValues(operator)) {
    case 0:
      return undefined;
    case 1:
      return Array.isArray(fieldValue) ? fieldValue[0] : fieldValue;
    case 2:
      return Array.isArray(fieldValue) ? fieldValue.slice(0, 2) : [fieldValue || ''];
    default:
      return fieldValue;
  }
}

export function getNumberOfValuesRequiredForText(operator: string): number {
  switch (operator) {
    case QueryBuilderOperator.Equal:
    case QueryBuilderOperator.NotEqual:
    case QueryBuilderOperator.GreaterThan:
    case QueryBuilderOperator.GreaterThanOrEqual:
      return 0;
    default:
      return getExpectedNumberOfValues(operator);
  }
}

export function getNumberOfValuesRequired(typeName: string, operator: string): number {
  switch (typeName.toLowerCase()) {
    case QueryBuilderFieldOptions.String:
    case QueryBuilderFieldOptions.Keyword:
    case QueryBuilderFieldOptions.LargeText:
      return getNumberOfValuesRequiredForText(operator);
    case QueryBuilderFieldOptions.Integer:
    case QueryBuilderFieldOptions.SmallInteger:
    case QueryBuilderFieldOptions.Double:
    case QueryBuilderFieldOptions.Date:
    case QueryBuilderFieldOptions.Codelist:
    case QueryBuilderFieldOptions.Picklist:
      return getExpectedNumberOfValues(operator);
    case QueryBuilderFieldOptions.Boolean:
      return 0;
    default:
      throw new Error(`Could not get number of values required ${typeName}/${operator}`);
  }
}
