import * as React from 'react';
import { BrowseControl } from '../browse-control';
import { ILookupControlProps } from './lookup-control-props';
import './lookup-control.scss';
import { ILookupSelectedObject, IGenericLookupWindowComponentProps } from './generic-lookup-window-component';

const KEY_BACK_SPACE = 8;
const KEY_DELETE = 46;

type LookupControlFunctionComponent = (<TLookupWindowComponentResult extends ILookupSelectedObject[]>(props: ILookupControlProps<TLookupWindowComponentResult>) => JSX.Element) &
  React.FunctionComponent<ILookupControlProps<any>>;

export const lookupControlTestIds = {
  browse: 'lookup-control-browse',
};

export const LookupControl: LookupControlFunctionComponent = <TLookupWindowComponentResult extends ILookupSelectedObject[]>(
  props: ILookupControlProps<TLookupWindowComponentResult>
) => {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState<TLookupWindowComponentResult>();

  const inputRef = React.useRef<HTMLInputElement>();

  const onCancel = () => {
    setOpen(false);
  };

  const onValueChosen = (value: TLookupWindowComponentResult) => {
    setOpen(false);
    if (props.autoApplySelectedValue) {
      setSelectedValue(value);
    }

    props.onValueChanged(value);
  };

  const clearSelection = () => {
    onValueChosen(([] as any) as TLookupWindowComponentResult);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const key: any = event.key || event.keyCode;
    if (key === KEY_DELETE || key === KEY_BACK_SPACE || key === 'Delete' || (key === 'Backspace' && !props.onRawChange)) {
      clearSelection();
    }
  };

  const computedValue = (): string => {
    let value = props.value;
    if (props.autoApplySelectedValue && selectedValue) {
      value = selectedValue;
    }
    return value
      .map(obj => {
        return obj.text;
      })
      .join(', ');
  };

  const handleButtonClick = () => {
    setOpen(true);
  };

  const focusInput = () => {
    inputRef.current!.select();
  };

  const prepareInjectables: () => IGenericLookupWindowComponentProps<TLookupWindowComponentResult> = () => {
    return {
      onValueChosen: (value: TLookupWindowComponentResult) => {
        onValueChosen(value);
      },
      onCancel: () => {
        onCancel();
      },
    };
  };

  return (
    <>
      <BrowseControl
        label={props.label}
        onButtonClick={handleButtonClick}
        onClick={focusInput}
        onKeyDown={handleKeyDown}
        onChange={props.onRawChange}
        value={computedValue()}
        error={props.error}
        data-testid={lookupControlTestIds.browse}
        helperText={props.helperText}
        errorText={props.errorText}
        disabled={props.disabled}
        required={props.required}
        readOnly={props.readOnly}
        fullWidth={props.fullWidth}
        inputRef={inputRef}
      />
      {open && props.lookupWindowComponent(prepareInjectables())}
    </>
  );
};

LookupControl.defaultProps = {
  required: false,
  disabled: false,
  fullWidth: false,
  readOnly: false,
};
