import { apm } from '@elastic/apm-rum';
import { moduleClientFactory } from '../../api-service/module-client-factory';
import { companyAreaPathName, companyTasksEditPathName } from '../../areas/company/company-area-pathnames';
import { ContextType } from '../../api-service/clients';
import { isFeatureEnabled } from '../../feature-toggles';

export async function redirectToTask(taskId: string, crmBaseUrl: string): Promise<string> {
  const jobcenterTaskClient = moduleClientFactory.createJobcenterTaskClient();
  const { taskContext } = await jobcenterTaskClient.getTaskContext({ id: taskId });
  const disableCRMReferences = isFeatureEnabled('DisableCRMReferences');

  switch (taskContext?.contextType) {
    case ContextType.Company:
      return `/standalone/${companyAreaPathName}/${taskContext.contextId}/${companyTasksEditPathName}/${taskId}`;
    case ContextType.Citizen:
      return `/standalone/citizen/${taskContext.contextId}/tasks/edit/${taskId}`;
    case ContextType.IncomingMail:
      return `/incoming-mail/${taskId}`;
    case ContextType.General:
      return `/general-task/${taskId}`;
    default:
      break;
  }

  if (disableCRMReferences) {
    apm.captureError(new Error('CRM URL reference prevented by DisableCRMReferences FT in: "components/resource-redirect/task-redirect"'));
    return '';
  }

  return `${crmBaseUrl}/main.aspx?etn=si_task&pagetype=entityrecord&extraqs=?id=${taskId}`;
}
