import { useEffect, useState } from 'react';
import * as React from 'react';
import { INotifierMessageBox, Dispatch } from './interfaces';
import { ConfirmDialog } from '../confirm-dialog/confirm-dialog';

export interface INotifierMessageBoxProps {
  message?: INotifierMessageBox;
  dispatch: Dispatch;
}
export function NotifierMessageBox(props: INotifierMessageBoxProps) {
  const { message, dispatch } = props;
  const [isOpen, setIsOpen] = useState(false);

  function handleConfirmClick() {
    setIsOpen(false);
    dispatch({ type: 'dismiss', payload: { id: message?.id } });
  }

  useEffect(() => {
    if (message) {
      setIsOpen(true);
    }
  }, [message]);

  return (
    <ConfirmDialog
      title={message?.warningAsInfo ? 'Besked fra DFDG' : message?.title}
      message={message?.detail}
      open={isOpen}
      onConfirmClick={handleConfirmClick}
      type={message?.severity === 'info' || message?.warningAsInfo ? 'info' : 'warning'}
      link={message?.helpLink}
    >
      {message?.correlationIdentifier && message.severity !== 'info' && !message?.warningAsInfo && !message?.hideExtraInfo && (
        <>
          Brug venligst følgende ID ved supporthenvendelser: <strong>{message?.correlationIdentifier}</strong>
        </>
      )}
    </ConfirmDialog>
  );
}
