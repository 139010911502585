import React from 'react';
import { OmnibarEntry } from '../omnibar-interfaces';
import { ResourceOperations, ResourceTypes } from '../../../authentication/resources';
import { negativeEventCreatePath } from '../../../areas/citizen/citizen-area-pathnames';
import { OmnibarChangeJobcenter } from '../controls/omnibar-change-jobcenter';

const functions: OmnibarEntry[] = [
  {
    id: '8d57d273-7f8c-4a44-961b-ac9cf92e4169',
    label: 'Hop til...',
    context: 'citizen',
    group: 'Borgerområde',
    dynamicLinksAction: (contextId: string) => ({
      resourceType: 'Case',
      resourceId: contextId,
    }),
  },
  {
    id: 'ca111549-f8f2-4b71-80d7-c6d876640192',
    label: 'A-kasse',
    context: 'citizen',
    group: 'Borgerområde',
    url: '/deeplink/citizen/:contextId/unemploymentbenefits',
  },
  {
    id: '6f887d9b-3080-4469-9cb3-9f7c2a265d00',
    label: 'Asyl & Integration',
    context: 'citizen',
    group: 'Borgerområde',
    url: '/deeplink/citizen/:contextId/integration-details',
  },
  {
    id: 'e387348d-3513-4417-99eb-697680b8b721',
    label: 'Borgere',
    context: 'none',
    group: 'Borgerområde',
    url: '/deeplink/citizens',
    resourceActions: [{ resourceOperation: ResourceOperations.Read, resourceType: ResourceTypes.Citizen }],
  },
  {
    id: 'eea29d20-bfb8-4f5e-9eb3-2cb102a3bb6e',
    label: 'Borgeroverblik',
    context: 'citizen',
    group: 'Borgerområde',
    url: '/deeplink/citizen/:contextId/details',
  },
  {
    id: '34118845-91fb-40e3-8674-42c51484ef2c',
    label: 'Dan brev',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/embedded/citizen/${contextId}/communication/guide-letter`);
    },
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.CitizenLetter }],
  },
  {
    id: '0ce2e9ef-fb2d-453e-b51b-a44b340c546f',
    label: 'Dan dokument',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/citizen/${contextId}/journal/document/create`);
    },
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.Document }],
  },
  {
    id: '0ce2e9ef-fb2d-453e-b51b-a44b340c1234',
    label: 'Redigér plan',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/citizen/${contextId}/plan/edit`);
    },
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.Plan }],
  },
  {
    id: '4c20be02-a758-483e-916d-6c94d914b9c6',
    label: 'Historik',
    context: 'citizen',
    group: 'Borgerområde',
    url: '/deeplink/citizen/:contextId/history',
  },
  {
    id: '5cb9cefc-441a-4218-9449-e74edcb9bbb8',
    label: 'CV & Jobsøgning',
    context: 'citizen',
    group: 'Borgerområde',
    url: '/deeplink/citizen/:contextId/cvjobsearch',
  },
  {
    id: '1247277f-39ff-445b-8758-80070c95eada',
    label: 'Journal & dokumenter',
    context: 'citizen',
    group: 'Borgerområde',
    url: '/deeplink/citizen/:contextId/journal',
  },
  {
    id: '8df305a1-86a2-4e98-8567-f7536ea8aafc',
    label: 'Opgaver',
    context: 'none',
    group: 'Borgerområde',
    url: '/deeplink/citizens/tasks',
    resourceActions: [{ resourceOperation: ResourceOperations.Read, resourceType: ResourceTypes.CitizenTask }],
  },
  {
    id: '9b54dae2-5b00-4133-8777-d51254217d8b',
    label: 'Opret aktivitet',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/offer-and-activity/create-activity-offer-select?citizenId=${contextId}`);
    },
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.Activity }],
  },
  {
    id: 'a8fa99cd-098e-4042-ae65-9778aa18ee6f',
    label: 'Opret journalnotat',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/standalone/citizen/${contextId}/journal/note/create`);
    },
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.JournalNote }],
  },
  {
    id: '96f52adc-777d-4608-a190-d5a4e74d1e5f',
    label: 'Opret kontaktgruppe',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/standalone/citizen/${contextId}/contact-group/create`);
    },
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.ContactGroup }],
  },
  {
    id: '7210d3c2-1f27-4d12-bf70-1f8f4ae034b8',
    label: 'Opret helbredsbegrænsning',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/standalone/citizen/${contextId}/health-limitation/create`);
    },
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.Availability }],
  },
  {
    id: '3f129728-eb33-4d65-94b9-c5a7c03fb022',
    label: 'Opstart helhedsorientet indsats',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/citizen/${contextId}/social-integration/create-hoi-guide`);
    },
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.Case }],
    featurePurchased: ['helhedsorienteretindsats'],
    isPossiblyRestricted: true,
  },
  {
    id: 'b8c00dd9-0b8e-43fe-8460-1065ee5f356c',
    label: 'Plan & Aktiviteter',
    context: 'citizen',
    group: 'Borgerområde',
    url: '/deeplink/citizen/:contextId/plan/view',
  },
  {
    id: '98e966c5-cfaa-47ce-98c4-9b475d22bfa7',
    label: 'Registrér underretningspligtig hændelse',
    context: 'citizen',
    group: 'Borgerhandling',
    url: `/deeplink/citizen/:contextId/${negativeEventCreatePath}`,
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.Availability }],
  },
  {
    id: 'a907d734-1286-419a-8cee-9ae86caad544',
    label: 'Rådighed & Fritagelser',
    context: 'citizen',
    group: 'Borgerområde',
    url: '/deeplink/citizen/:contextId/citizen-availability',
  },
  {
    id: 'a0ced348-c972-415d-832b-bbd4cbde96ec',
    label: 'Sager',
    context: 'none',
    group: 'Borgerområde',
    url: '/deeplink/citizens/cases',
  },
  {
    id: '58fbb8e6-9110-433c-aa9e-c7cf45777751',
    label: 'Samtaler',
    context: 'citizen',
    group: 'Borgerområde',
    url: '/deeplink/citizen/:contextId/interview',
  },
  {
    id: '5c8e1e50-ca87-4abe-975a-bfe5205e804b',
    label: 'Indkald til samtale',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/standalone/citizen/${contextId}/interview/guide-book-interview`);
    },
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.Interview }],
    isConditionallyEnabled: true,
  },
  {
    id: 'd95ca6c2-887a-4fcd-b854-fb339dd71430',
    label: 'Gennemfør samtale',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/standalone/citizen/${contextId}/interview/guide-conduct-interview`);
    },
    isConditionallyEnabled: true,
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.Interview }],
  },
  {
    id: '90ec834b-cde6-431d-be51-bba9d6c2f5df',
    label: 'Udsted frist',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/standalone/citizen/${contextId}/interview/guide-interview-deadline`);
    },
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.InterviewDeadline }],
  },
  {
    id: '04c11af4-b80e-4477-9365-074a2054e62d',
    label: 'Opret opgave',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/standalone/citizen/${contextId}/tasks/create`);
    },
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.CitizenTask }],
  },
  {
    id: 'e9503613-a80b-41ea-b366-46c0a30d4ffb',
    label: 'Opret negativ hændelse',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/standalone/citizen/${contextId}/negative-event/create`);
    },
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.Availability }],
  },
  {
    id: '80f966fb-83d3-47c1-8d35-62fcd9ab09d1',
    label: 'Opret gruppering',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/embedded/citizen/${contextId}/interview/guide-interview-person-category`);
    },
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.PersonCategory }],
  },
  {
    id: '2c9942bb-8e29-4fad-80c6-4576a3d8fb8a',
    label: 'Opret afmelding',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/standalone/citizen/${contextId}/enrollment/cancel`);
    },
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.Enrollment }],
  },
  {
    id: '8fbb3f3b-59bd-461d-bb60-de3b5efddf89',
    label: 'Åbn Find job',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/efforts/company-recruitments/job-match/${contextId}`);
    },
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.CandidateReferral }],
    isConditionallyEnabled: true,
  },
  {
    id: 'afbb3f3b-59bd-461d-bb60-de3b5efddf89',
    label: 'Opret tilmelding',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/standalone/citizen/${contextId}/enrollment/enroll`);
    },
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.Enrollment }],
  },
  {
    id: '293de945-2cd2-4f48-b23d-afc69090c12c',
    label: 'Opret fravær',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/standalone/citizen/${contextId}/absence/create`);
    },
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.Availability }],
    isPossiblyRestricted: true,
  },
  {
    id: '8fbb373b-59bd-461d-bb60-de3b5efddf89',
    label: 'Opret journalnotat',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/standalone/citizen/${contextId}/journal/note/create`);
    },
    resourceActions: [{ resourceOperation: ResourceOperations.JournalNote.Create, resourceType: ResourceTypes.JournalNote }],
  },
  {
    id: '1347277f-39ff-445b-8758-80070c95eada',
    label: 'Aktindsigt',
    context: 'citizen',
    group: 'Borgerområde',
    url: '/deeplink/citizen/:contextId/journal?omnibar-link=aktindsigter',
  },
  {
    id: 'c62ce676-e8ae-4235-a764-13308fd982c2',
    label: 'Kandidatformidlinger',
    context: 'citizen',
    group: 'Borgerområde',
    url: '/deeplink/citizen/:contextId/cvjobsearch?omnibar-link=kandidatformidlinger',
  },
  {
    id: 'b9b00dd9-0b8e-43fe-8460-1065ee5f356c',
    label: 'Kontrakt (Integration)',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/standalone/citizen/${contextId}/contract/edit`);
    },
  },
  {
    id: '24ca709c-5914-42cb-a5bb-ef8a67734f3a',
    label: 'Opret sag',
    context: 'citizen',
    group: 'Saghandling',
    url: '/deeplink/citizen/:contextId/details?omnibar-link=createcase',
  },
  {
    id: '24ca709c-5914-42cb-a5bb-ef8a67734f3a',
    label: 'Opret persongruppemarkering',
    context: 'citizen',
    group: 'Persongruppemarkering',
    url: '/deeplink/citizen/:contextId/details?omnibar-link=createpersongroupmarking',
  },
  {
    id: '24ca7dse-5914-42cb-a5bb-ef8a67734f3a',
    label: 'Opret underretning til A-kasse',
    context: 'citizen',
    group: 'A-Borgerområde',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/standalone/citizen/${contextId}/unemploymentbenefits/nuph`);
    },
  },
  {
    id: '24ca709c-5914-42cb-a5bb-ef8a67734f29',
    label: 'Skift jobcenter',
    context: 'citizen',
    group: 'Borgerhandling',
    getModalControl: (contextId: string) => <OmnibarChangeJobcenter forceRefresh={new Date()} citizenId={contextId} />,
  },
  {
    id: '24ca709c-5914-42cb-a5bb-ef8a67734f3b',
    label: 'Vis forsørgelseshistorik',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string, crmUrl: string, tenantName: string) => {
      const url = `${crmUrl}/${tenantName}/Person/SustenanceHistoryModernized/YearByCitizenId?contactId={${contextId}}`;
      window.open(url);
    },
  },
  {
    id: '24ca709c-5914-42cb-a5bb-ef8a67734f3c',
    label: 'Vis ungedatabasen',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string, crmUrl: string, tenantName: string) => {
      const url = `${crmUrl}/${tenantName}/Person/EducationEventsModernized/Index?contactId={${contextId}}`;
      window.open(url);
    },
  },
  {
    id: '24ca709c-5914-42cb-a5bb-ef8a67734f3d',
    label: 'Vis jobcenterhistorik',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string, crmUrl: string, tenantName: string) => {
      const url = `${crmUrl}/${tenantName}/Person/PersonHistoryModernized/JobcenterHistory?contactId={${contextId}}`;
      window.open(url);
    },
  },
  {
    id: '24ca709c-5914-42cb-a5bb-ef8a67734f3d',
    label: 'Vis tilmeldingshistorik',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string, crmUrl: string, tenantName: string) => {
      const url = `${crmUrl}/CitizenEnrollmentHistoryModernized/Index/${tenantName}/${contextId}`;
      window.open(url);
    },
  },
  {
    id: '24ca709c-5914-42cb-a5bb-ef8a67734f3f',
    label: 'Vis kommentarer',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string, crmUrl: string, tenantName: string) => {
      window.open(`/deeplink/standalone/citizen/${contextId}/citizencomments`);
    },
  },
  {
    id: '24ca709c-5914-42cb-a5bb-ef8a67734f3g',
    label: 'Vis dialogmodul',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string, crmUrl: string, tenantName: string) => {
      const url = `${crmUrl}/${tenantName}/Person/CitizenMessagesModernized/Index?contactId={${contextId}}`;
      window.open(url);
    },
  },
  {
    id: '24ca709c-5914-42cb-a5bb-ef8a67734f3e',
    label: 'Vis tilmeldinger',
    context: 'citizen',
    group: 'Borgerhandling',
    url: '/deeplink/citizen/:contextId/cvjobsearch?omnibar-link=showEnrollments',
  },
  {
    id: '8fbb373b-59xx-461d-bb60-de3b5efddf89',
    label: 'Opret borgers kontaktperson',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/standalone/citizen/${contextId}/citizen-person-contact`);
    },
    resourceActions: [{ resourceOperation: ResourceOperations.Write, resourceType: ResourceTypes.Citizen }],
  },
  {
    id: '24ddddse-5914-42cb-a5bb-ef8a67734f3a',
    label: 'Vis Aktivitetshistorik',
    context: 'citizen',
    group: 'Borgerhandling',
    callbackFn: (contextId: string) => {
      window.open(`/deeplink/standalone/citizen/${contextId}/activity-history`);
    },
  },
];

export default functions;
