import React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { FilledTextFieldProps, TextFieldProps } from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { TextField } from '../text-field';
import './number-field.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface INumericValue extends NumberFormatValues {}

export interface INumberFieldProps {
  children?: FilledTextFieldProps['children'];
  className?: FilledTextFieldProps['className'];
  disabled?: boolean;
  error?: boolean;
  errorText?: FilledTextFieldProps['helperText'];
  fullWidth?: boolean;
  helperText?: FilledTextFieldProps['helperText'];
  InputLabelProps?: FilledTextFieldProps['InputLabelProps'];
  inputProps?: FilledTextFieldProps['inputProps'];
  inputRef?: FilledTextFieldProps['inputRef'];
  label?: FilledTextFieldProps['label'];
  name?: string;
  onChange?: (values: INumericValue) => void;
  readOnly?: boolean;
  required?: boolean;
  value?: string | number;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  allowNegative?: boolean;
  decimalSeparator?: string;
  thousandSeparator?: string;
  filters?: Array<(values: INumericValue) => boolean>;
  precision?: number;
  multiline?: boolean;
  variant?: TextFieldProps['variant'] | FilledTextFieldProps['variant'];
  placeholder?: string;
  InputProps?: TextFieldProps['InputProps'];
}

export type IIntegerFieldProps = Omit<INumberFieldProps, 'decimalSeparator' | 'precision'>;

export function IntegerField(props: IIntegerFieldProps) {
  return <NumberField decimalSeparator={undefined} precision={0} {...props} />;
}

const useStyles = makeStyles({
  root: {
    minWidth: 0,
    width: 60,
  },
  input: {
    padding: '6px 5px 7px 6px',
    minWidth: '20px',
  },
});

export function SmallIntegerField(props: IIntegerFieldProps) {
  const { placeholder, ...rest } = props;
  const classes = useStyles();

  return (
    <NumberField
      inputProps={{ placeholder }}
      decimalSeparator={undefined}
      precision={0}
      {...rest}
      multiline={false}
      InputProps={{ classes: { root: classes.root, input: classes.input } }}
    />
  );
}

export function DecimalField(props: INumberFieldProps) {
  return <NumberField {...props} />;
}

export function NumberField(props: INumberFieldProps) {
  const { filters, precision, onChange, onBlur, ...rest } = props;
  function handleBlur(e: any) {
    onBlur &&
      onBlur({
        target: {
          value: e.target.value.replace(/\./g, ''),
        },
      } as any);
  }
  const applyFilters = (values: NumberFormatValues): boolean => {
    let result = true;
    if (filters) {
      filters.forEach(filter => {
        result = result && filter(values);
      });
    }
    return result;
  };

  return <NumberFormat customInput={TextField} isAllowed={applyFilters} decimalScale={precision} onBlur={handleBlur} onValueChange={onChange} {...rest} />;
}

/* Danish culture specification
    http://std.dkuug.dk/cultreg/registrations/narrative/da_DK,_4.3.html
 */
NumberField.defaultProps = {
  allowNegative: true,
  decimalSeparator: ',',
  thousandSeparator: '.',
  precision: 2,
};
