import React from 'react';
import { Alert, AlertTitle, IconButton, Collapse, Badge } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTimes, faCopy } from '@fortawesome/pro-light-svg-icons';
import { red } from '@mui/material/colors';
import { useNotifier } from './notifier-context';
import { INotifierMessage } from './interfaces';
import { ColorPalette } from '../config/color-palette';
import { TypographySettings } from '../config/typography-settings';
import { useBroadcastContext } from '../../hooks';
import { formatDateTime } from '../../utilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorContainer: {
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 2000,
    },
    iconButton: {
      '&.MuiIconButton-root': {
        color: ColorPalette.WHITE,
        height: 40,
        width: 40,
      },
    },
    errorRoot: {
      fontWeight: TypographySettings.DefaultFontWeight,
      fontFamily: TypographySettings.DefaultFontFamily,
      fontSize: 15,
      margin: '16px 16px 0 16px',
      '& strong': {
        fontWeight: TypographySettings.DefaultFontWeight,
      },
    },
    errorTitle: {
      fontFamily: TypographySettings.AlternativeFontFamily,
      fontWeight: TypographySettings.AlternativeFontSemiBold,
    },
    errorFilled: {
      backgroundColor: red.A700,
    },
    badgeRoot: {
      backgroundColor: red.A700,
      color: ColorPalette.WHITE,
      position: 'absolute',
      right: 26,
      bottom: -26,
    },
    badge: {
      backgroundColor: red.A700,
    },
    action: {
      '&.MuiAlert-action': {
        paddingTop: 0,
        alignItems: 'center',
      },
    },
  })
);

export function NotifierDisplay() {
  const {
    state: { errors },
  } = useNotifier();
  const { errorContainer, badge, badgeRoot } = useStyles();

  const MaxVisibleItems = 3;

  function getRenderErrors() {
    return errors.slice(0, errors.filter((x: INotifierMessage) => x.dismissed).length + MaxVisibleItems);
  }

  function getQueuedErrors() {
    return errors.slice(getRenderErrors().length);
  }

  const renderErrors = getRenderErrors();
  const queuedErrors = getQueuedErrors();

  return (
    <div className={errorContainer}>
      {renderErrors.map((err: INotifierMessage) => (
        <NotifierError error={err} key={err.id} />
      ))}
      {!!queuedErrors.length && <Badge badgeContent={`+${queuedErrors.length}`} classes={{ root: badgeRoot, badge }} />}
    </div>
  );
}

function CopyToClipboard({ text }: { text?: string }) {
  const { send: sendInfo } = useBroadcastContext('GlobalToasterInfo');

  const isInIframe = (() => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  })();

  return text && isInIframe === false ? (
    <IconButton
      sx={{ color: '#fff', fontSize: 20 }}
      title="Kopiér information til udklipsholder"
      onClick={() => {
        navigator.clipboard.writeText(text);
        sendInfo('Kopieret til udklipsholder');
      }}
    >
      <FontAwesomeIcon icon={faCopy} />
    </IconButton>
  ) : null;
}

export function NotifierError({ error }: { error: INotifierMessage }) {
  const { message, correlationIdentifier, dismissed = false, timestamp, id } = error;
  const classes = useStyles();
  const { dispatch } = useNotifier();

  function handleOnClick(event: React.SyntheticEvent | MouseEvent) {
    event.preventDefault();
    return false;
  }

  function handleClose() {
    dispatch({ type: 'dismiss', payload: { id } });
  }

  return (
    <Collapse in={!dismissed}>
      <Alert
        severity="error"
        onClick={handleOnClick}
        variant="filled"
        onClose={handleOnClick}
        action={
          <IconButton aria-label="luk fejlbesked" title="Luk fejlbesked" className={classes.iconButton} onClick={handleClose} tabIndex={0} size="large">
            <FontAwesomeIcon icon={faTimes} size="sm" />
          </IconButton>
        }
        classes={{ root: classes.errorRoot, filledError: classes.errorFilled, action: classes.action }}
      >
        <AlertTitle classes={{ root: classes.errorTitle }}>{message}</AlertTitle>
        {correlationIdentifier && (
          <>
            Brug venligst følgende ID ved supporthenvendelser: <strong>{correlationIdentifier}</strong>{' '}
            <CopyToClipboard text={`${formatDateTime(timestamp)} ${correlationIdentifier}`} />
          </>
        )}
      </Alert>
    </Collapse>
  );
}
