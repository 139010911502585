/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import { CancelToken } from 'axios';
import { GetAssessmentUrlResponse } from '../../../../../api-service/clients';
import { moduleClientFactory } from '../../../../../api-service/module-client-factory';
import { useApiOnRender } from '../../../../../hooks';
import { AbsolutelyCentered } from '../../../../../components/absolutely-centered/absolutely-centered';
import { CircularProgress } from '../../../../../components/circular-progress/circular-progress';
import { ConfirmDialog } from '../../../../../components/confirm-dialog/confirm-dialog';
import { CssBaseDecorator } from '../../../../../components/cssbasedecorator/css-base-decorator';

export function AssessmentRerouter() {
  const { assessmentId } = useParams<{ assessmentId: string }>();

  const citizenAvailabilityClient = moduleClientFactory.createCitizenAvailabilityClient();
  const getAssessmentUrlApi = useApiOnRender(
    (cancelToken: CancelToken, id: string) => citizenAvailabilityClient.getAssessmentUrl({ recordId: id }, cancelToken),
    {} as GetAssessmentUrlResponse,
    assessmentId
  );

  const assessmentUrl = getAssessmentUrlApi.result?.assessmentUrl;
  return (
    <CssBaseDecorator>
      {getAssessmentUrlApi.isLoading ? (
        <AbsolutelyCentered>
          <CircularProgress />
        </AbsolutelyCentered>
      ) : (
        <>
          <ConfirmDialog
            type="alert"
            title="Noget gik galt ved hentning af rådighedsvurderings URL, vinduet lukkes"
            open={getAssessmentUrlApi.isError}
            onConfirmClick={() => {
              window.close();
            }}
            confirmButtonText="Ok"
          />
          {assessmentUrl && window.location.assign(assessmentUrl)}
        </>
      )}
    </CssBaseDecorator>
  );
}
