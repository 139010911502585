import { useMemo } from 'react';
import { ISearchField, ISearchSchema } from '../components/query';
import { SearchResultTypeProvider, ISearchResultTypeProvider, IResultTypeOption } from '../components/query/search-result-type';

export function useSearchResultTypes(searchFields: ISearchField[], universe?: ISearchSchema): IResultTypeOption[] {
  const resultTypeProvider: ISearchResultTypeProvider = new SearchResultTypeProvider();
  return useMemo(
    () => (universe ? resultTypeProvider.getSearchResultTypes(universe, searchFields) : []),
    // eslint-disable-next-line
    [universe, searchFields]
  );
}
