/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { IValueEditorProps } from '../value-editor-props';
import { ColorPalette } from '../../../../../../components/config/color-palette';
import { TypographySettings } from '../../../../../../components/config/typography-settings';
import { INumericValue } from '../../../../../../components/number-fields/number-field';

export const NumberRangeTestIds = {
  StartValueId: 'NumberRangeTestIds-StartValueId',
  EndValueId: 'NumberRangeTestIds-EndValueId',
  ValidationError: 'NumberRangeTestIds-ErrorMessage',
};

export interface INumberRangeEditorProps extends IValueEditorProps {
  component: React.FunctionComponent<INumericValue>;
}

function endValueGreaterThanStartValue(startValue: string, endDate: string): string | undefined {
  return Number.parseInt(endDate, 10) < Number.parseInt(startValue, 10) ? 'Startværdien kan ikke være større end slutværdien' : undefined;
}

function onlyPositiveNumbers(startValue: string, endValue: string): string | undefined {
  return Number.parseInt(startValue) < 1 || Number.parseInt(endValue) < 1 ? 'Værdierne skal være større end 0' : undefined;
}

function startValueMustBeEntered(startValue: string): string | undefined {
  return !startValue ? 'Startværdi skal angives og være større end 0' : undefined;
}

function enteredValuesMathcesOneOrMore(startValue: string, endValue: string): string | undefined {
  return Number.parseInt(startValue) === 1 && !endValue ? 'Brug muligheden "1 ELLER FLERE"' : undefined;
}

function enteredValuesMathcesNone(startValue: string, endValue: string): string | undefined {
  return Number.parseInt(startValue) === 0 && Number.parseInt(endValue) === 0 ? 'Brug muligheden "INGEN"' : undefined;
}

function validate(startValue: string, endValue: string, validateGraterThanZero?: boolean, validateStartValueMustBeEntered?: boolean): string | undefined {
  let validationResult = endValueGreaterThanStartValue(startValue, endValue);
  if(validateStartValueMustBeEntered && !validationResult) {
    validationResult = startValueMustBeEntered(startValue);
  }
  if(!validationResult) {
    validationResult =  enteredValuesMathcesOneOrMore(startValue, endValue);
  }
  if(!validationResult) {
    validationResult =  enteredValuesMathcesNone(startValue, endValue);
  }
  if(validateGraterThanZero && !validationResult) {
    validationResult = onlyPositiveNumbers(startValue, endValue);
  }
  return validationResult;
}

export function NumberRangeEditor(props: INumberRangeEditorProps) {
  const { value, component, title, disabled, separator, placeholder, validateGraterThanZero, validateDisabled, validateStartValueMustBeEntered } = props;
  const valueStart = Array.isArray(value) ? value[0] : value;
  const valueEnd = Array.isArray(value) && value.length === 2 ? value[1] : '';

  const firstPlaceholder = Array.isArray(placeholder) ? placeholder[0] : placeholder;
  const secondPlaceholder = Array.isArray(placeholder) && placeholder.length === 2 ? placeholder[1] : '';

  const handleStartValueHasChanged = (args: React.FocusEvent<HTMLInputElement>) => {
    props.handleOnChange([args.target.value, valueEnd]);
  };

  const handleEndValueHasChanged = (args: React.FocusEvent<HTMLInputElement>) => {
    props.handleOnChange([valueStart, args.target.value]);
  };

  let validationResult;
  if(!validateDisabled) {
    validationResult = validate(valueStart, valueEnd, validateGraterThanZero, validateStartValueMustBeEntered);
  }

  return (
    <div>
      <span className="multipleInputsWrapper"> 
        {React.createElement(component, {
          name: 'range-start-component',
          label: title,
          fullWidth: false,
          onBlur: handleStartValueHasChanged,
          value: valueStart,
          disabled,
          key: 0,
          thousandSeparator: '',
          'data-testid': NumberRangeTestIds.StartValueId,
          placeholder: firstPlaceholder,
        } as any)}
        <span key={2} className="rangeSeparator">
          {separator}
        </span>
        {React.createElement(component, {
          name: 'range-end-component',
          label: title,
          fullWidth: false,
          onBlur: handleEndValueHasChanged,
          value: valueEnd,
          disabled,
          key: 1,
          thousandSeparator: '',
          'data-testid': NumberRangeTestIds.EndValueId,
          placeholder: secondPlaceholder,
        } as any)}
      </span>
      {validationResult && (
        <div
          data-testid={NumberRangeTestIds.ValidationError}
          style={{
            color: ColorPalette.HotAurora,
            marginLeft: '5px',
            marginTop: '0px',
            fontFamily: TypographySettings.DefaultFontFamily,
          }}
        >
          {validationResult}
        </div>
      )}
    </div>
  );
}

NumberRangeEditor.defaultProps = {
  seperator: '-',
};
