import { useMemo } from 'react';
import { ISearchField } from '../components/query';

function findResultType(searchFields: ISearchField[], resultType: string): ISearchField | undefined {
  const found = searchFields.find(x => x.name === resultType);
  if (found) {
    return found;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const searchField of searchFields) {
    if (searchField.isList && searchField.fields) {
      const found2 = findResultType(searchField.fields, resultType);
      if (found2) {
        return found2;
      }
    }
  }
  return undefined;
}

function getNestedResultTypes(searchFields: ISearchField[], resultType?: string): ISearchField[] {
  if (resultType) {
    const resultField = findResultType(searchFields, resultType);
    return (resultField && resultField.fields && resultField.fields.filter(x => x.isList)) || [];
  }
  return searchFields.filter(x => x.isList);
}

export function useNestedResultTypes(searchFields: ISearchField[], resultType?: string) {
  return useMemo(() => getNestedResultTypes(searchFields, resultType), [searchFields, resultType]);
}
