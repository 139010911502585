/* istanbul ignore file */
import { createStore, combineReducers, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { all, fork } from 'redux-saga/effects';
import { configuration } from '../../../configuration';
import { notifyReducer } from './reducers/notify-reducer';

import { rankedSearchReducer, rankedSearchSaga } from './reducers/ranked-search-reducer';

export const rankedSearchRootReducer = combineReducers({
  rankedSearch: rankedSearchReducer,
  notifications: notifyReducer,
});

export function* rootSaga() {
  yield all([fork(rankedSearchSaga)]);
}

export type RankedSearchStoreState = ReturnType<typeof rankedSearchRootReducer>;

export function configureRankedSearchStore() {
  const { showReduxDevTools } = configuration;
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];

  const middlewareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(rankedSearchRootReducer, showReduxDevTools ? composeWithDevTools(middlewareEnhancer) : middlewareEnhancer);
  sagaMiddleware.run(rootSaga);
  return store;
}
