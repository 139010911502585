import { ICommunicationMessage, IBroadcastChannel, MessageTopic } from './use-broadcast';

export function postBroadcastChannel(
  message: ICommunicationMessage,
  subscribers: { [topic: string]: { id: string; callback: (message: ICommunicationMessage) => void }[] | undefined },
  broadcastChannelGlobal?: IBroadcastChannel
) {
  if (broadcastChannelGlobal) {
    broadcastChannelGlobal.postMessage(message);
  }
  const handlersForThisWindow = subscribers[message.topic];
  if (handlersForThisWindow === undefined || handlersForThisWindow.length === 0) {
    return;
  }
  handlersForThisWindow.forEach(handler =>
    setTimeout(() => {
      handler.callback(message);
    }, 100)
  );
}

export function subscribeBroadcastChannel(
  id: string,
  callback: (message: ICommunicationMessage) => void,
  subscribers: { id: string; callback: (message: ICommunicationMessage) => void }[]
) {
  if (subscribers.find(x => x.id === id) === undefined) {
    subscribers.push({ id, callback });
  }
}

export function unsubscribeBroadcastChannel(
  id: string,
  topic: MessageTopic,
  subscribers: { [topic: string]: { id: string; callback: (message: ICommunicationMessage) => void }[] | undefined }
) {
  const handlers = subscribers[topic];
  if (handlers === undefined) {
    return;
  }
  const handlerIndex = handlers.findIndex(x => x.id === id);
  if (handlerIndex === -1) {
    return;
  }
  handlers.splice(handlerIndex, 1);
}
