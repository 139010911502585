import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { useApiOnRender } from '../../hooks/use-api';
import { ErrorZone } from '../error-zone';
import { IConfigurationClient } from '../../api-service/clients';

export interface ICrmHostUrlContext {
  url?: string;
  isLoading?: boolean;
}

const CrmHostUrlContext = React.createContext<ICrmHostUrlContext>({} as ICrmHostUrlContext);

export function WithCrmHostUrlContext(props: PropsWithChildren<{ configurationClient: IConfigurationClient }>) {
  const { configurationClient, children } = props;
  const crmHostUrlApi = useApiOnRender(cancelToken => configurationClient.getCrmHostUrl(cancelToken), { url: '' });

  const { result, isLoading, isError, error } = crmHostUrlApi;
  const contextData: ICrmHostUrlContext = useMemo(() => ({ ...result, isLoading }), [result, isLoading]);

  return (
    <CrmHostUrlContext.Provider value={contextData}>
      {isError && <ErrorZone error={error} />}
      {children}
    </CrmHostUrlContext.Provider>
  );
}

export function useCrmHostUrlContext() {
  return useContext(CrmHostUrlContext);
}
