import React, { useState } from 'react';
import { Box } from '../box/box';
import { Button } from '../button/button';

export interface IToggleTextProps {
  text: string;
  maxLength?: number;
}

export function ToggleText(props: IToggleTextProps) {
  const { text, maxLength } = props;
  const [showMore, setShowMore] = useState(false);

  return (
    <Box my={1}>
      {maxLength && text.length > maxLength && !showMore ? `${text.substring(0, maxLength)}...` : text}
      {maxLength && text.length > maxLength && (
        <Button variant="text" onClick={() => setShowMore(!showMore)}>
          {showMore ? 'vis mindre' : 'vis mere'}
        </Button>
      )}
    </Box>
  );
}
