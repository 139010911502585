import MuiFormControlLabel from '@mui/material/FormControlLabel';
import * as React from 'react';
import { IFormControlLabelProps } from './form-control-label-props';

const Component = React.Component;

export class FormControlLabel<TComponentProps> extends Component<IFormControlLabelProps<TComponentProps>> {
  constructor(props: IFormControlLabelProps<TComponentProps>) {
    super(props);
  }

  public render() {
    return <MuiFormControlLabel {...this.props} />;
  }
}
