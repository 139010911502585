import { AxiosRequestConfig } from 'axios';
import { secureAxiosInstance, secureAxiosInstanceWithErrorMessageInterceptor } from '../api-service';
import { getTenant } from './get-tenant';

export function addTenantHeaderToRequest(requestConfig: AxiosRequestConfig) {
  const tenant = getTenant();
  if (!requestConfig.headers) requestConfig.headers = {};
  requestConfig.headers['X-Tenant'] = `${tenant.name}`;
  return requestConfig;
}

export async function SetUpTenantInfoSendWithHttpRequests() {
  secureAxiosInstance.interceptors.request.use(
    async requestConfig => addTenantHeaderToRequest(requestConfig),
    error => Promise.reject(error)
  );
  secureAxiosInstanceWithErrorMessageInterceptor.interceptors.request.use(
    async requestConfig => addTenantHeaderToRequest(requestConfig),
    error => Promise.reject(error)
  );
}
