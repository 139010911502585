import * as React from 'react';
import { IRuleProps } from './rule-props';
import { INestedRuleBase } from './rule-interface';
import { IQueryBuilderContext } from './query-builder-context';
import { IField } from './field-interfaces';

interface IRegistration {
  name: string;
  ruleFactory: (context: IQueryBuilderContext, fields: IField[], parent: INestedRuleBase) => any;
  componentFactory: (key: string | number, props: IRuleProps<any>) => React.ReactNode;
}

export class RuleFactory {
  private static readonly registrations: IRegistration[] = [];

  public static componentFactory(key: string | number, props: IRuleProps<any>) {
    for (const registration of RuleFactory.registrations) {
      const reactNode = registration.componentFactory(key, props);
      if (reactNode != null) return reactNode;
    }
    throw Error(`component not registered for ${props}`);
  }

  public static factory(name: string, context: IQueryBuilderContext, fields: IField[], parent: INestedRuleBase) {
    const registration = RuleFactory.registrations.find(x => x.name === name);
    if (registration) {
      return registration.ruleFactory(context, fields, parent);
    }
    throw Error(`component registration not found ${name}`);
  }

  public static register(
    name: string,
    ruleFactory: (context: IQueryBuilderContext, fields: IField[], parent: INestedRuleBase) => any,
    componentFactory: (key: string | number, props: IRuleProps<any>) => React.ReactNode
  ) {
    RuleFactory.registrations.push({ name, ruleFactory, componentFactory });
  }
}
