import { useRef, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDefer<T extends any[]>(callback: (...args: T) => void, delay: number | undefined, ...args: T): void;
export function useDefer(callback: (...args: any[]) => void, delay: number | undefined, ...args: any[]): void {
  const savedCallback = useRef<(...args: any[]) => void>(callback);

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
    // eslint-disable-next-line
  }, [callback]);

  // Set up the timeout.
  useEffect(() => {
    function tick() {
      savedCallback.current(...args);
    }
    if (delay) {
      const timerId = setTimeout(tick, delay);
      return () => clearTimeout(timerId);
    }
    return undefined;
    // eslint-disable-next-line
  }, [...args, delay]);
}
