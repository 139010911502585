export function arraySortByFieldAsc<T>(values: T[], field: keyof T): T[] {
  return values.sort((a, b) => {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  });
}
