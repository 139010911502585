import { OmnibarEntry } from '../omnibar-interfaces';

const localStorageKey = 'topSelectedResults';
function getItems() {
  const topSelectedResults = localStorage.getItem(localStorageKey) ? JSON.parse(localStorage.getItem(localStorageKey) || '') : {};
  return topSelectedResults;
}

export function updateSelectionCount(entry: OmnibarEntry) {
  const topSelectedResults = getItems();
  const updatedResults = {
    ...topSelectedResults,
    [entry.id]: (topSelectedResults[entry.id] || 0) + 1,
  };
  localStorage.setItem(localStorageKey, JSON.stringify(updatedResults));
}

export function getTopSelectedResults(entries: OmnibarEntry[]) {
  const topSelectedResults = getItems();

  const sortedResults = Object.keys(topSelectedResults)
    .sort((a, b) => topSelectedResults[b] - topSelectedResults[a])
    .slice(0, 5);

  const resultEntries: OmnibarEntry[] = sortedResults
    .map((id: string) => entries.find((entry: OmnibarEntry) => entry.id === id))
    .filter((entry: OmnibarEntry | undefined): entry is OmnibarEntry => entry !== undefined)
    .map((entry: OmnibarEntry) => ({
      ...entry,
      group: 'Oftest brugt',
    }));

  const numberOfTopResults = resultEntries.length;
  const remainingEntries: OmnibarEntry[] = entries.filter((entry: OmnibarEntry) => !sortedResults.includes(entry.id));

  return { numberOfTopResults, allItemsSorted: resultEntries.concat(remainingEntries) };
}
